import {
  GET_LABELS_REQUEST,
  GET_LABELS_SUCCESS,
  GET_LABELS_FAILURE,
  CREATE_LABEL_REQUEST,
  CREATE_LABEL_SUCCESS,
  CREATE_LABEL_FAILURE,
  UPDATE_LABEL_REQUEST,
  UPDATE_LABEL_SUCCESS,
  UPDATE_LABEL_FAILURE,
  DELETE_LABEL_REQUEST,
  DELETE_LABEL_SUCCESS,
  DELETE_LABEL_FAILURE,
} from "./labelTypes"
import apis from "../../services/ApiServices"
import { CREATE_LABEL_SUCCESS as CREATE_LABEL_SUCCESS_EVENT } from "../../Utils/analyticsEvents"
import { sendAnalyticsEvent } from "../../Utils/analytics"
import {
  createLabelV2,
  updateLabelV2,
  getAllLabelsByBusinessIdV2,
  deleteLabelV2,
} from "../../services/ApiServicesv2"

/*----------------------------- GET LABEL ACTIONS -----------------------------*/
export const getLabelsRequest = () => {
  return {
    type: GET_LABELS_REQUEST,
  }
}

export const getLabelsSuccess = (labels) => {
  return {
    type: GET_LABELS_SUCCESS,
    payload: labels,
  }
}

export const getLabelsFailure = (error_message) => {
  return {
    type: GET_LABELS_FAILURE,
    payload: error_message,
  }
}

export const getLabels = (businessId) => {
  return async (dispatch) => {
    let response = await getAllLabelsByBusinessIdV2(businessId)

    if (response.data.status === "success") {
      const items = response?.data?.data?.sort(
        (a, b) => b.created_at - a.created_at,
      )
      dispatch(getLabelsSuccess(items))
    } else {
      dispatch(getLabelsFailure("Error fetching labels"))
    }
  }
}
/*----------------------------- GET LABEL ACTIONS -----------------------------*/

/*----------------------------- CREATE LABEL ACTIONS -----------------------------*/
export const createLabelRequest = () => {
  return {
    type: CREATE_LABEL_REQUEST,
  }
}

export const createLabelSuccess = (label) => {
  return {
    type: CREATE_LABEL_SUCCESS,
    payload: label,
  }
}

export const createLabelFailure = (error_message) => {
  return {
    type: CREATE_LABEL_FAILURE,
    payload: error_message,
  }
}

export const createLabel = (businessId, labelObj) => {
  return async (dispatch) => {
    dispatch(createLabelRequest())
    let brandDetails = await apis.getBrandDetails(businessId)
    let postData = {
      brand_id: brandDetails.result.items[0]
        ? brandDetails.result.items[0].id
        : businessId,
      business_id: businessId,
      label_json: { ...labelObj },
    }

    let response = await createLabelV2(postData)

    if (response?.data?.status === "success") {
      dispatch(createLabelSuccess(response?.data?.data[0]))
      sendAnalyticsEvent(CREATE_LABEL_SUCCESS_EVENT, {
        "Page Name": "Dashboard",
        "Label ID": response?.data?.data[0]?.id,
      })
    } else {
      dispatch(createLabelFailure("Error creating label"))
    }
  }
}
/*----------------------------- CREATE LABEL ACTIONS -----------------------------*/

/*----------------------------- UPDATE LABEL ACTIONS -----------------------------*/
export const updateLabelRequest = () => {
  return {
    type: UPDATE_LABEL_REQUEST,
  }
}

export const updatelabelSuccess = (label) => {
  return {
    type: UPDATE_LABEL_SUCCESS,
    payload: label,
  }
}

export const updateLabelFailure = (error_message) => {
  return {
    type: UPDATE_LABEL_FAILURE,
    payload: error_message,
  }
}

export const updateLabel = (labelObj) => {
  return async (dispatch) => {
    dispatch(updateLabelRequest())

    let response = await updateLabelV2(labelObj)

    if (response?.data?.status === "success") {
      dispatch(updatelabelSuccess(response?.data?.data[0]))
    } else {
      dispatch(updateLabelFailure("Error updating label"))
    }
  }
}
/*----------------------------- UPDATE LABEL ACTIONS -----------------------------*/

/*----------------------------- DELETE LABEL ACTIONS -----------------------------*/
export const deleteLabelRequest = () => {
  return {
    type: DELETE_LABEL_REQUEST,
  }
}

export const deleteLabelSuccess = (label) => {
  return {
    type: DELETE_LABEL_SUCCESS,
    payload: label,
  }
}

export const deleteLabelFailure = (error_message) => {
  return {
    type: DELETE_LABEL_FAILURE,
    payload: error_message,
  }
}

export const deleteLabel = (businessId, id) => {
  return async (dispatch) => {
    dispatch(deleteLabelRequest())

    let input = { id }
    let response = await deleteLabelV2(input)

    if (response.data.status !== "success") {
      dispatch(deleteLabelFailure("Error deleting label"))
    }
  }
}
/*----------------------------- DELETE LABEL ACTIONS -----------------------------*/
