import React, { useState, useEffect } from "react"
import { FaFileImage, FaFilePdf } from "react-icons/fa"
import { getFileNameUsingCurrentTime } from "../../common/FileNameCurrentTime/FileNameCurrentTime"

const PreviousArtworks = ({ version, status, files }) => {
  const [isPdf, setIsPdf] = useState(false)

  useEffect(() => {
    if (Array.isArray(files)) {
      setIsPdf(false)
    } else {
      setIsPdf(true)
    }
  }, [files])

  const downloadFilesWithDelay = (fileUrls) => {
    fileUrls.forEach((url, index) => {
      setTimeout(() => {
        const a = document.createElement("a")
        a.href = url
        const timestamp = getFileNameUsingCurrentTime()
        const original_file_name = url.split("/").pop().split("?")[0]
        const file_extension = original_file_name.split(".").pop()
        a.download = `${timestamp}_${index + 1}.${file_extension}`
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
      }, index * 1000)
    })
  }

  const onArtworkClick = () => {
    if (Array.isArray(files)) {
      downloadFilesWithDelay(files)
    } else {
      downloadFilesWithDelay([files])
    }
  }

  return (
    <div className="flex flex-row" onClick={onArtworkClick}>
      <div className="flex gap-1 bg-[#F1F1F1] p-2 m-1 rounded-md border w-2/4">
        <div className="flex">
          {isPdf ? (
            <FaFilePdf className="text-red-500 mr-2 mt-1" />
          ) : (
            <FaFileImage className="text-blue-500 mr-2 mt-1" />
          )}
          <span className="font-medium">Version {version}</span>
        </div>
      </div>
      <div className="flex items-center">
        <span
          className={`flex border text-xs rounded-lg m-1 p-1 items-center ${
            status === "Pending"
              ? "text-yellow-500"
              : status === "Approved"
              ? "text-green-500"
              : status === "Rejected"
              ? "text-red-500"
              : "text-yellow-500"
          }`}
        >
          {status}
        </span>
      </div>
    </div>
  )
}

export default PreviousArtworks
