import React from "react"
export default function GCC() {
  return (
    <svg
      width="32"
      height="24"
      viewBox="0 0 32 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 0H32V24H0V0Z"
        fill="#F7FCFF"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 0V8H32V0H0Z"
        fill="#5EAA22"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 16V24H32V16H0Z"
        fill="#272727"
      />
      <rect width="10" height="24" fill="#E31D1C" />
    </svg>
  )
}
