import { combineReducers } from "redux"
import loginReducer from "./Login/loginReducer"
import registerReducer from "./Register/registerReducer"
import profileReducer from "./Profile/profileReducer"
import subscriptionReducer from "./Subscription/SubscriptionReducer"
import uiReducer from "./UI/UIReducer"
import faqReducer from "./Faq/faqReducer"
import blogsReducer from "./Blogs/blogReducer"
import categoriesReducer from "./Categories/categoriesReducer"
import stepsReducer from "./Steps/stepsReducer"
import labelReducer from "./Labels/labelReducer"
import recipeReducer from "./Recipes/recipeReducer"
import orderReducer from "./LabelOrders/reducer"

const rootReducer = combineReducers({
  login: loginReducer,
  register: registerReducer,
  profile: profileReducer,
  subscription: subscriptionReducer,
  ui: uiReducer,
  faq: faqReducer,
  blogs: blogsReducer,
  categories: categoriesReducer,
  steps: stepsReducer,
  labels: labelReducer,
  recipes: recipeReducer,
  orders: orderReducer,
})

export default rootReducer
