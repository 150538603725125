import React from "react"
import ReactDOM from "react-dom"
import "./index.css"
import App from "./App"
import Amplify, { API } from "aws-amplify"
import config from "./config"
import * as Sentry from "@sentry/react"
import { Integrations } from "@sentry/tracing"

const ENABLE_SENTRY = false

if (ENABLE_SENTRY) {
  Sentry.init({
    environment:
      process.env.NODE_ENV === "production" ? "production" : "development",
    dsn: "https://8d4f82d149314a6191e609743c79d5fb@o1125925.ingest.sentry.io/6166426",
    beforeBreadcrumb(breadcrumb, hint) {
      if (breadcrumb.category === "console") {
        return null
      }
      return breadcrumb
    },
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.2,
  })
}
const aws_exports = config.AWS_EXPORTS[config.ENV]

Amplify.configure(aws_exports)
API.configure(aws_exports)

ReactDOM.render(
  // <React.StrictMode>
  <App />,
  // </React.StrictMode>,
  document.getElementById("root"),
)
