import classNames from "classnames"
import React from "react"

const QkDate = (props) => {
  const key = props.data.key
  const value = props.values[key]

  if (!props.values || props.data.visible === false) return <></>

  return (
    <div>
      <input
        name={key}
        id={key}
        type="date"
        value={value || ""}
        onChange={(e) => props.handleChange(e.target.value)}
        className={classNames(
          "relative z-0 shadow-sm border w-full rounded-lg leading-8  placeholder:text-textgraylight focus:ring-black focus:border-none",
        )}
        disabled={props.data.disabled}
      />
    </div>
  )
}

export default QkDate
