import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { sendAnalyticsEvent } from "../../Utils/analytics"
import { containsLinkpath } from "../../Utils/helpers"
import { DropdownIcon } from "../../assets/icons/Icons"

export default function Submenu({ list, item, path }) {
  const [isOpen, isSetOpen] = useState(false)
  useEffect(() => {
    for (var i in list) {
      if (containsLinkpath(list[i].linkpath, path)) {
        isSetOpen(true)
        return
      }
    }
  }, [])
  return (
    <>
      <div
        onClick={() => isSetOpen(!isOpen)}
        to={item.to}
        className={`${isOpen ? "text-amber-300" : ""} block cursor-pointer
        `}
      >
        <li
          key={item.key}
          className="flex flex-row items-center mt-2 h-8 transform group transition-transform ease-in duration-500"
        >
          <span className="inline-flex items-center justify-center h-12 w-12 text-lg">
            {item.icon}
          </span>
          <span className="text-sm  group-hover:text-amber-300">
            {item.name}
          </span>
          <span className="ml-1 group-hover:[&_svg]:stroke-amber-300">
            <DropdownIcon rotate={isOpen ? "180" : ""} />
          </span>
        </li>
      </div>

      {list.map((submenuItem) => (
        <Link
          onClick={() => {
            sendAnalyticsEvent(submenuItem.event, {
              "Page Name": path.substring(1),
            })
          }}
          to={submenuItem.to}
          className={`block
            ${
              isOpen
                ? " opacity-1 max-h-40"
                : " max-h-0 opacity-0 overflow-hidden"
            }
            ${
              containsLinkpath(submenuItem.linkpath, path)
                ? "text-amber-300 [&_svg]:stroke-amber-300 "
                : ""
            } 
            transition-all duration-500 ease-in-out `}
        >
          <li
            key={submenuItem.key}
            className="flex flex-row items-center mt-2 h-8 transform group transition-transform ease-in duration-500"
          >
            <span className="inline-flex items-center justify-center h-12 w-12 text-lg">
              {submenuItem.icon}
            </span>
            <span className="text-sm group-hover:text-amber-300">
              {submenuItem.name}
            </span>
          </li>
        </Link>
      ))}
    </>
  )
}
