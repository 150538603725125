import {
  UPDATE_QUANTITY,
  UPDATE_ORDERS,
  SET_USER_LABEL_LIMIT,
  UPDATE_CURRENT_ORDER,
  RESET_CURRENT_ORDER,
} from "./types"
import apis from "../../services/ApiServices"

export const updateQuantity = (quantity) => {
  return {
    type: UPDATE_QUANTITY,
    payload: quantity,
  }
}

export const updateOrders = (orders) => {
  return {
    type: UPDATE_ORDERS,
    payload: orders,
  }
}

export const updateCurrentOrder = (obj) => {
  return {
    type: UPDATE_CURRENT_ORDER,
    payload: obj,
  }
}

export const setUserLabelLimit = (limit) => {
  return {
    type: SET_USER_LABEL_LIMIT,
    payload: limit,
  }
}

export const resetCurrentOrder = (obj) => {
  return {
    type: RESET_CURRENT_ORDER,
    payload: obj,
  }
}

export const getOrders = (businessId) => {
  return async (dispatch) => {
    // fetch all the users orders from the payment table here
    let response = await apis.getUserPayments(businessId)
    let orders = response.result.items.filter((item) => item.type === "PAYMENT")
    let activeOrders = orders.filter((order) => order.status === "COMPLETED")
    let total_labels = 0

    for (let order of activeOrders) {
      if (order.details.label_quantity) {
        let label_quantity = order.details.label_quantity
        total_labels += parseInt(label_quantity)
      }
    }

    dispatch(setUserLabelLimit(total_labels))
    dispatch(updateOrders(orders))
  }
}

export const updateOrder = (businessId, paymentId, status, details) => {
  return async (dispatch) => {
    let response = await apis.updatelabelPayment({
      status,
      details,
      paymentId,
      businessId,
    })
    console.log("update order response: ", response)

    dispatch(getOrders(businessId))
  }
}

export const resetCurrentOrderValue = () => {
  return async (dispatch) => {
    let current_order = {
      order_id: "",
      label_quantity: 0,
      promo_code: 0,
      country: "",
      firstname: "",
      lastname: "",
      phone_number: "",
      address: "",
      postal_code: "",
      state: "",
      city: "",
      organization_name: "",
      gstin: "",
      pan_no: "",
    }
    dispatch(resetCurrentOrder(current_order))
  }
}
