import {
  GET_FAQS_REQUEST,
  GET_FAQS_SUCCESS,
  GET_FAQS_FAILURE,
} from "./faqTypes"

const initialState = {
  loading: false,
  error: false,
  error_message: "",
  faqs: [],
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_FAQS_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case GET_FAQS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        error_message: false,
        faqs: action.payload,
      }
    case GET_FAQS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        error_message: "error retrieving faqs",
      }
    default:
      return state
  }
}

export default reducer
