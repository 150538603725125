import React, { useEffect, useState } from "react"
import classNames from "classnames"
import { toast } from "react-toastify"

export default function QkFormNumberField({ data, handleChange, values }) {
  const disable = data && data.disabled
  const [error, setError] = useState("")
  const [active, setActive] = useState(false)
  const [inputValue, setInputValue] = useState("")

  const placeholder = `${data.title} ${data.required ? "*" : ""}`

  useEffect(() => {
    if (values[data.key] || values[data.key] === 0) {
      setActive(true)
    } else {
      setActive(false)
      setError("")
    }
  }, [values])

  const handleBlur = () => {
    if (data.validator === undefined || data.validator === "") return

    if (Array.isArray(data.validator)) {
      for (const [index, validator] of data.validator.entries()) {
        let validated = true
        if (typeof validator === "string") {
          let output = values
          let currentValue = values[data.key]

          validated = eval(validator)
        } else if (typeof validator === "function") {
          validated = validator(values)
        } else {
          validated = validator
        }

        if (validated) setError("")
        else {
          setError(data.error[index] ?? "Please check your provided value")
          break
        }
      }
    } else {
      let validated = true

      if (typeof data.validator === "string") {
        let output = values
        let currentValue = values[data.key]

        validated = eval(data.validator)
      } else if (typeof data.validator === "function") {
        validated = data.validator(values)
      } else {
        validated = data.validator
      }

      if (validated) setError("")
      else setError(data.error ?? "Please check your provided value")
    }
  }

  const hasLeadingZero = (value) => {
    return /^0[0-9]+|^0\.0+$/.test(value)
  }

  useEffect(() => {
    if (!hasLeadingZero(values?.key)) {
      handleBlur()
    }
  }, [values])

  const handleInputChange = (e) => {
    const value = e.target.value
    setInputValue(value)

    const validProportion = /^-?\d*(\.\d{0,2})?$/

    if (data?.key === "proportion") {
      if (validProportion.test(value)) {
        setActive(value !== "")
        handleChange(value)
      }
    } else {
      const validNumeric = /^-?\d*(\.\d{0,2})?$/
      if (validNumeric.test(value)) {
        setActive(value !== "")
        handleChange(value)
      }
    }
  }

  useEffect(() => {
    handleBlur()
  }, [inputValue])

  return (
    <label>
      {!data.hideTitleOnActive && active && (
        <p
          className={` ${
            disable && "cursor-not-allowed opacity-50"
          } absolute mt-[-15px] text-textgray bg-[white] ml-[10px] px-[5px] py-0 scale-75 z-10`}
        >
          {placeholder}
        </p>
      )}
      <input
        type="text"
        maxLength={data.maxLength ?? 20}
        placeholder={placeholder}
        className={classNames(
          data.customStyle,
          "flex-grow shadow-sm border w-full px-4 leading-8 placeholder:text-textgraylight focus:ring-black focus:border-none",
          error ? "border-red-500 border-2" : "border-bordergray",
          "rounded-lg",
          "",
          disable ? "opacity-50 cursor-not-allowed" : "",
        )}
        disabled={disable}
        name={data.key}
        value={values[data.key] ?? ""}
        onChange={handleInputChange}
        // onBlur={handleBlur}
      />

      {error && <span className="text-xs text-red-500">{error}</span>}
    </label>
  )
}
