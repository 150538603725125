import moment from "moment"
import ta from "time-ago"

const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
]
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
]
const date_prefixes = { 1: "st", 2: "nd", 3: "rd" }

export const unixTimestampToReadableDatetime = (date) => {
  var MyDate = new Date(date)
  let MyDateString =
    ("0" + MyDate.getDate()).slice(-2) +
    "-" +
    ("0" + (MyDate.getMonth() + 1)).slice(-2) +
    "-" +
    MyDate.getFullYear()
  return MyDateString
  // return moment(date).date() + "-" + (moment(date).month() + 1) + "-" + moment(date).year();
}
export const getDayDifference = (ModifiedDate) => {
  return ta.ago(ModifiedDate)
}

export function getCurrentDate() {
  let d = new Date()
  let day = days[d.getDay()]
  let month = months[d.getMonth()]
  let date = d.getDate()
  let year = d.getFullYear()
  let date_arr = date.toString().split("")
  let date_last_num = date_arr[date_arr.length - 1]

  if (date > 10 && date <= 20) {
    date = date + "th"
  } else if (date_last_num in date_prefixes) {
    date = date + date_prefixes[date_last_num]
  } else {
    date = date + "th"
  }

  return day + " " + month + " " + date + " " + year
}

export function getCurrentTime() {
  let date = new Date()
  let hours = date.getHours()
  let minutes = date.getMinutes()
  let seconds = date.getSeconds()

  hours = parseInt(hours) < 10 ? "0" + hours : hours
  minutes = parseInt(minutes) < 10 ? "0" + minutes : minutes
  seconds = parseInt(seconds) < 10 ? "0" + seconds : seconds

  return hours + ":" + minutes + ":" + seconds
}

// get Difference
export function timeSince(date) {
  const seconds = Math.floor((date - new Date()) / 1000)
  let interval = seconds / 31536000
  // if (interval > 1) {
  //   return Math.floor(interval)
  // }
  // interval = seconds / 2592000

  // if (interval > 1) {
  //   return Math.floor(interval)
  // }
  interval = seconds / 86400
  if (interval > 1) {
    return Math.floor(interval)
  }
  // interval = seconds / 3600;
  // if (interval > 1) {
  //   return Math.floor(interval) + " hours  ";
  // }
  // interval = seconds / 60;
  // if (interval > 1) {
  //   return Math.floor(interval) + " minutes left";
  // }
  // return Math.floor(seconds) + " seconds left";
}

export function epochToDate(epochTime) {
  const date = new Date(epochTime * 1000)
  const day = String(date.getDate()).padStart(2, "0")
  const month = String(date.getMonth() + 1).padStart(2, "0")
  const year = date.getFullYear()
  return `${day}-${month}-${year}`
}

export function daysSinceEpoch(epochTime) {
  const epochTimeInMilliseconds =
    epochTime < 1e12 ? epochTime * 1000 : epochTime

  const futureDate = new Date(epochTimeInMilliseconds)

  const today = new Date()
  today.setHours(0, 0, 0, 0)

  const timeDiff = futureDate - today
  const daysDiff = timeDiff / (1000 * 60 * 60 * 24)

  return Math.ceil(daysDiff)
}

export function getUnixTimestamp() {
  const options = {
    timeZone: "Asia/Kolkata",
    hour12: false,
  }
  const indianTime = new Date().toLocaleString("en-US", options)
  const indianUnixTimestamp = new Date(indianTime).getTime()
  return Math.floor(indianUnixTimestamp / 1000)
}

export function formatDate(epochDate) {
  const date = new Date(epochDate * 1000)
  const options = { day: "numeric", month: "short", year: "numeric" }
  return date.toLocaleDateString(undefined, options)
}
