import { FieldType } from "../constants/enum"
import nonMandatoryKeyMapping from "./mapping/non_mandatory_key_mapping.json"

const fatValidation = (values, total_fat) => {
  switch (values.name) {
    case "mufa":
    case "pufa":
    case "omega_3":
    case "omega_6":
      return parseFloat(values.quantity) > parseFloat(total_fat ?? "0")
        ? false
        : true
    default:
      return true
  }
}
const nonMandatoryDeclaration = (output, schemaData) => ({
  name: "",
  description: "",
  fields: [
    {
      title: "Nutrient",
      type: FieldType.DROPDOWN,
      niValueFrom: output.ni_info_type,
      reference: "",
      validator: "",
      layout: 6,
      description: "",
      list: nonMandatoryKeyMapping,
      key: "name",
      visible: true,
    },
    {
      title: "Quantity per 100 g/ml",
      type: FieldType.NUMBER,
      reference: "",
      // validator: (values) =>
      //   fatValidation(
      //     values,
      //     output?.mandatory_nutrient?.map((ob) => ob["total_fat"])[0],
      //   ),
      // error: "Cannot be higher than total fat ",
      layout: 5,
      description: "",
      list: [],
      items: [],
      key: "quantity",
      visible: true,
    },
  ],
})
export default nonMandatoryDeclaration
