import React, { useEffect, useRef } from "react"
import { useState } from "react"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import drag from "../../assets/drag.png"
import { getAdditiveInfo, getIngredientInfo } from "./helper"

export default function DragDropComponent({ data, handleChange, values }) {
  const finalingredients = values[data.key]?.filter((v) => v) ?? []
  const initalValues = useRef(finalingredients)
  const [mounted, setMounted] = useState(false)
  const ingredientsData = values.ingredients ?? []
  const additivesData = values.additives ?? []

  const ingAndAdditivesNames = [
    ...new Set(
      [
        ...ingredientsData
          ?.filter((ing) => ing?.name)
          ?.map((ing) => getIngredientInfo(ing)?.name?.trim()),
        ...additivesData
          ?.filter((additive) => additive?.name)
          ?.map((additive) =>
            getAdditiveInfo(additive, data?.country)?.name?.trim(),
          ),
      ]?.filter((name) => name.trim()),
    ),
  ]

  useEffect(() => {
    if (
      initalValues.current.length === 0 &&
      finalingredients.length > 0 &&
      !mounted
    ) {
      setMounted(true)
      return
    }

    if (
      ingAndAdditivesNames.filter(
        (ingredient) =>
          !finalingredients.some((ingName) => ingName?.name === ingredient),
      )?.length > 0 ||
      ingAndAdditivesNames.length !== finalingredients.length
    ) {
      generatePreviewData()
    }
  }, [values])

  const generatePreviewData = () => {
    let tempTokens = []

    const ingredientAdded = ingAndAdditivesNames.filter(
      (ingredient) =>
        !finalingredients.some((ingName) => ingName?.name === ingredient),
    )
    const ingredientRemoved = finalingredients.filter(
      (ingredient) =>
        !ingAndAdditivesNames.some((ingName) => ingName === ingredient?.name),
    )

    const formattedIng = ingredientsData
      .filter((v) => v.name)
      .map((v) => getIngredientInfo(v))
    const formattedAdditives = additivesData.map((v) => getAdditiveInfo(v))

    initalValues.current = [...ingredientsData, ...additivesData]

    if (ingredientRemoved?.length > 0 || ingredientAdded?.length > 0) {
      finalingredients.forEach((ingredient) => {
        const findIng = formattedIng?.find(
          (ing) => ing?.name === ingredient?.name,
        )
        const findAdditive = formattedAdditives?.find(
          (ing) => ing?.name === ingredient?.name,
        )
        if (findIng) {
          tempTokens.push(findIng)
        }
        if (findAdditive) {
          tempTokens.push(findAdditive)
        }
      })
    }
    if (ingredientAdded?.length > 0) {
      const findIng = formattedIng?.find(
        (ing) => ing?.name === ingredientAdded?.[0],
      )
      const findAdditive = formattedAdditives?.find(
        (additive) => additive?.name === ingredientAdded?.[0],
      )

      if (findIng) {
        tempTokens.push(findIng)
      }
      if (findAdditive) {
        tempTokens.push(findAdditive)
      }
    }
    // const ingWithProportion = tempTokens?.filter(
    //   (token) => token?.info?.proportion,
    // )
    // const ingWithoutProportion = tempTokens?.filter(
    //   (token) => !token?.info?.proportion,
    // )
    // handleChange(
    //   ingWithProportion
    //     ?.sort((ingA, ingB) => ingB?.info?.proportion - ingA?.info?.proportion)
    //     ?.concat(ingWithoutProportion),
    // )
    const combinedTokens = tempTokens?.filter(
      (token) => token?.info?.proportion,
    )
    const combinedTokensWithoutProportion = tempTokens?.filter(
      (token) => !token?.info?.proportion,
    )

    const sortedTokens = combinedTokens
      ?.sort((a, b) => b?.info?.proportion - a?.info?.proportion)
      ?.concat(combinedTokensWithoutProportion)

    handleChange(sortedTokens)
  }

  const onDragEnd = (result) => {
    const items = reorder(
      finalingredients,
      result.source.index,
      result.destination?.index ?? finalingredients?.length - 1,
    )
    initalValues.current = items
    handleChange(items)
  }

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
  }
  return (
    <>
      <DragDropContext onDragEnd={(e) => onDragEnd(e)}>
        <Droppable droppableId="droppable">
          {(provided) => {
            return (
              <div ref={provided.innerRef} {...provided.draggableProps}>
                {finalingredients?.map((v, index) => {
                  return (
                    <Draggable key={v.name} draggableId={v.name} index={index}>
                      {(provided) => (
                        <div
                          className="relative bg-gray-100 p-4 rounded-lg my-1"
                          key={index}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <span className="inline-flex">
                            <img
                              src={drag}
                              alt="drag-icon"
                              className="w-4 h-4 mr-5"
                            />{" "}
                            {v.name}
                          </span>
                        </div>
                      )}
                    </Draggable>
                  )
                })}
                {provided.placeholder}
              </div>
            )
          }}
        </Droppable>
      </DragDropContext>
    </>
  )
}
