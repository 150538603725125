import React from "react"

const hasPermission = (permissions, user_permissions) => {
  if (user_permissions.length === permissions.length) {
    const sortedPermissions = permissions
    const sortedUserPermissions = user_permissions

    if (sortedPermissions === sortedUserPermissions) {
      return "Admin"
    } else if (sortedPermissions === "Admin,Creator,Moderator,User") {
      return "Admin"
    } else if (sortedPermissions === "Admin,Moderator,Creator,User") {
      return "Admin"
    } else if (sortedPermissions === "Admin,Moderaor,Creator,User") {
      return "Admin"
    } else if (sortedPermissions === "Admin") {
      return "Admin"
    } else if (sortedPermissions === "Creator,User") {
      return "Admin"
    } else if (sortedPermissions === "Moderaor,User") {
      return "Admin"
    } else if (sortedPermissions === "") {
      return "Manager"
    }
  }
}

const Featureflag = (props) => {
  // use redux state to get user permissions
  // const permissions = useSelector(state => state.login.permissions)

  // mock user
  const admin = "Admin"
  const manager = "Manager"
  const user = "User"

  if (hasPermission(props.permissions, admin)) {
    return props.children
  }
  if (hasPermission(props.permissions, manager)) {
    return props.children
  }
  if (hasPermission(props.permissions, user)) {
    return props.children
  }
  if (props.permissions.length === 0) return props.children

  return null
}

export default Featureflag
