const messages = {
  genericError: "Something Went Wrong!",
  brandBlankError: "Brand can't be blank",
  recipeNameBlankError: "Recipe Name can't be blank",
  mealTypeBlankError: "Meal Type can't be blank",
  servingSizeUnitBlankError: "Serving Size Unit can't be blank",
  servingSizeBlankError: "Serving Size can't be blank",
  recipeYieldBlankError: "Recipe Yield can't be blank",
  recipeYieldUnitBlankError: "Recipe Yield unit can't be blank",
  keyIngredientsFieldError: "List of Key Ingredients can't be blank",
  otherIngredientsFieldError: "List of Other Ingredients can't be blank",
  ingredientValueError:
    "Ingredients quantity cannot be blank or zero. Either add quantity or remove ingredient",
  preparationMethodsError: "Preparation methods can't be blank",
  merchantRecipeSaved: "saved. Redirecting you to home in 5 seconds",
  viewMerchantYoutubeVideo: "Demo video",
  downloadRecipeError: "Something went wrong while downloading recipes",
  draftRecipeCreatedSuccess: "Draft recipe created successfully.",
  draftRecipeInputWarning: "You can`t upload more than 50 recipes at once.",
  downloadRecipeRequestReceived:
    "We have received your request. We will send downloaded recipes to your registered email id",
  emailRequired: "Email id is required",
  emailNotValid: "Entered Email Id is not a valid email.",
  ppfSavedSuccessMessage: "Label saved successfully!",
  validImageErrorMessage: "Given file type is not allowed.",
  brandSuccessMessage: "Brand saved successfully!",
  formatError: "Selected image Format is not valid!",
  recipeLimitErrorMessage: "You have reached your recipe limit.",
  qrCodeSuccessMessage: "QR code generated successfully.",
  brandSaveSuccess: "Brand Saved Successfully",
  brandLimitError: "You can add only 25 brands",
  categoryBlankError: "Category can't be blank",
  cuisineBlankError: "Cuisine can't be blank",
  mealCourseBlankError: "Meal course can't be blank",
  businessNameError: "Busniess name can't be blank",
  addressError: "Address can't be blank",
  postalcodeBlankError: "Postal code can't be blank",
  postalcodeRegexError: "Postal code must be of 6 digits",
  stateBlankError: "State can't be blank",
  stateRegexError: "State name should contain only alphabets",
  cityBlankError: "City can't be blank",
  cityRegexError: "City name should contain only alphabets",
  countryBlankError: "Country can't be blank",
  countryRegexError: "Country name should contain only alphabets",
  saveFoodTagMessage: "Food Tags saved successfully",
  saveFoodTagError: "Food Tags could not be saved",
  userIngredientDeleteError: "Failed to delete ingredient",
}

export { messages }
