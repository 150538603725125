import { NAVBAR_ACTIVE, NAVBAR_INACTIVE, PPF_FEATURES } from "./UITypes"

const initialState = {
  navbar: {
    active: false,
  },
  ppfPlanFeatures: {},
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case NAVBAR_ACTIVE:
      return {
        ...state,
        navbar: {
          ...state.navbar,
          active: true,
        },
      }
    case NAVBAR_INACTIVE:
      return {
        ...state,
        navbar: {
          ...state.navbar,
          active: false,
        },
      }
    case PPF_FEATURES:
      return {
        ...state,
        ppfPlanFeatures: action.payload,
      }
    default:
      return state
  }
}

export default reducer
