import { FieldType } from "../constants/enum"

const BLACKLISTED_CATEGORIES = ["13", "16", "17"]

const AiLabelCategoriesModal = (output, dropdown_values) => ({
  name: "Categories Selection For AI Label Creation",
  description: "",
  analyticsEvent: "",
  tags: [],
  fields: [
    {
      title: "--",
      type: FieldType.TEXT,
      validators: "",
      reference: "",
      description: "",
      default: output?.category_level_1?.key,
      layout: 3,
      key: "level_1",
      visible: true,
      required: false,
      readOnly: true,
    },
    {
      title: "Select Level 1 Value",
      type: FieldType.DROPDOWN,
      validators: "",
      reference: "",
      description: "",
      layout: 9,
      list: dropdown_values?.level_1
        ?.filter(
          (obj) =>
            obj &&
            obj.level_value &&
            !BLACKLISTED_CATEGORIES.includes(obj.level_value),
        )
        .map((obj) => ({
          key: obj.level_value,
          value: `
            ${obj.level_value !== "0" ? obj.level_value + " " : ""}
            ${obj.category_value}`,
        })),
      items: [],
      key: "category_level_1",
      visible: true,
      required: true,
      truncate: false,
    },
    {
      title: "--",
      type: FieldType.TEXT,
      validators: "",
      reference: "",
      description: "",
      default: output?.category_level_2?.key,
      layout: 3,
      key: "level_2",
      visible: true,
      required: false,
      readOnly: true,
    },
    {
      title: "Select Level 2 Value",
      type: FieldType.DROPDOWN,
      validators: "",
      reference: "",
      description: "",
      layout: 9,
      list: dropdown_values?.level_2
        ?.filter((obj) => obj && obj.level_value)
        .map((obj) => ({
          key: obj.level_value,
          value: `
            ${obj.level_value !== "0" ? obj.level_value + " " : ""}
            ${obj.category_value}`,
        })),
      items: [],
      key: "category_level_2",
      visible: true,
      required: false,
      truncate: false,
      disable: dropdown_values?.level_2.length > 0 ? false : true,
    },
    {
      title: "--",
      type: FieldType.TEXT,
      validators: "",
      reference: "",
      description: "",
      default: output?.category_level_3?.key,
      layout: 3,
      key: "level_3",
      visible: true,
      required: false,
      readOnly: true,
    },
    {
      title: "Select Level 3 Value",
      type: FieldType.DROPDOWN,
      validators: "",
      reference: "",
      description: "",
      layout: 9,
      list: dropdown_values?.level_3
        ?.filter((obj) => obj && obj.level_value)
        .map((obj) => ({
          key: obj.level_value,
          value: `
            ${obj.level_value !== "0" ? obj.level_value + " " : ""}
            ${obj.category_value}`,
        })),
      items: [],
      key: "category_level_3",
      visible: true,
      required: false,
      truncate: false,
      disable: dropdown_values?.level_3.length > 0 ? false : true,
    },
    {
      title: "--",
      type: FieldType.TEXT,
      validators: "",
      reference: "",
      description: "",
      default: output?.category_level_4?.key,
      layout: 3,
      key: "level_4",
      visible: true,
      required: false,
      readOnly: true,
    },
    {
      title: "Select Level 4 Value",
      type: FieldType.DROPDOWN,
      validators: "",
      reference: "",
      description: "",
      layout: 9,
      list: dropdown_values?.level_4
        ?.filter((obj) => obj && obj.level_value)
        .map((obj) => ({
          key: obj.level_value,
          value: `
            ${obj.level_value !== "0" ? obj.level_value + " " : ""}
            ${obj.category_value}`,
        })),
      items: [],
      key: "category_level_4",
      visible: true,
      required: false,
      truncate: false,
      disable: dropdown_values?.level_4.length > 0 ? false : true,
    },
  ],
})

export default AiLabelCategoriesModal
