import React from "react"
import QkFieldInput from "../../qkFormV2/QkFieldInput"
import { FieldType } from "../../constants/enum"
import organicCertificateFromNPOP from "../../schema/componentNPOP"
import organicCertificateFromPGSIndia from "../../schema/componentPgsIndia"

const OrganicCertificateFromComponent = (props) => {
  const key = props?.data?.key
  const NPOPComponents = organicCertificateFromNPOP(props?.values)
  const PGSIndiaComponents = organicCertificateFromPGSIndia(props?.values)

  const handleInputChange = (value) => {
    let newValue = []

    if (value === "Not Applicable") {
      newValue = props?.values[key]?.includes(value) ? [] : [value]
    } else {
      if (props?.values[key]?.includes("Not Applicable")) {
        newValue = [value]
      } else {
        newValue = props?.values[key]?.includes(value)
          ? props?.values[key].filter((item) => item !== value)
          : [...(props?.values[key] || []), value]
      }
    }

    const newValues = { ...props?.values }
    newValues[key] = newValue
    props?.handleChange(key, newValue)
  }

  return (
    <div>
      <div
        dangerouslySetInnerHTML={{ __html: props.data?.description ?? "" }}
      />
      <div>
        <div className={`my-1 mb-3`}>
          <label className="text-sm font-medium text-gray-700 flex items-center">
            <input
              name={"NPOP"}
              type="checkbox"
              checked={props?.values[key]?.includes("NPOP")}
              className="focus:outline-none focus:ring-0 h-4 w-4  bg-transparent border-[#2F80ED] border-2 mr-2"
              onChange={() => handleInputChange("NPOP")}
              disabled={
                props?.values[key]?.includes("Not Applicable") &&
                v?.key !== "Not Applicable"
              }
            />
            <span className="ml-1">NPOP</span>
          </label>
        </div>
        {props?.values[key]?.includes("NPOP") && (
          <div className="grid grid-cols-12 gap-8">
            {NPOPComponents?.map((_data) => (
              <QkFieldInput
                key={_data.key}
                data={_data}
                values={props?.values}
                handleChange={(d) => props?.handleChange(_data?.key, d)}
              />
            ))}
          </div>
        )}
      </div>
      <div>
        <div className={`my-1 mb-3`}>
          <label className="text-sm font-medium text-gray-700 flex items-center">
            <input
              name={"PGS-India Organic"}
              type="checkbox"
              checked={props?.values[key]?.includes("PGS-India Organic")}
              className="focus:outline-none focus:ring-0 h-4 w-4  bg-transparent border-[#2F80ED] border-2 mr-2"
              onChange={() => handleInputChange("PGS-India Organic")}
              disabled={
                props?.values[key]?.includes("Not Applicable") &&
                v?.key !== "Not Applicable"
              }
            />
            <span className="ml-1">PGS-India Organic</span>
          </label>
        </div>
        {props?.values[key]?.includes("PGS-India Organic") && (
          <div className="grid grid-cols-12 gap-8">
            {PGSIndiaComponents?.map((_data) => (
              <QkFieldInput
                key={_data.key}
                data={_data}
                values={props?.values}
                handleChange={(d) => props?.handleChange(_data?.key, d)}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default OrganicCertificateFromComponent
