import { legacy_createStore as createStore } from "redux"
import { applyMiddleware } from "redux"
import rootReducer from "./rootReducer"
import { persistStore, persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage" // defaults to localStorage for web
import logger from "redux-logger"
import thunk from "redux-thunk"

const persistConfig = {
  key: "root",
  storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export default () => {
  let middleware = [thunk]

  if (process.env.NODE_ENV === "development") {
    middleware = [...middleware, logger]
  }

  const store = createStore(persistedReducer, applyMiddleware(...middleware))

  const persistor = persistStore(store)

  return { store, persistor }
}
