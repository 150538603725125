import { FieldType } from "../constants/enum"

const blendedOil = {
  name: "",
  description: "",
  fields: [
    {
      title: "Name of the Oil",
      type: FieldType.TEXT,
      reference: "",
      validators: "",
      layout: 5,
      description: "",
      list: [],
      items: [],
      key: "name",
    },
    {
      title: "Blended Oil % by weight",
      type: FieldType.NUMBER,
      reference: "",
      validators: "",
      layout: 5,
      description: "",
      list: [],
      items: [],
      key: "weight",
    },
  ],
}

export default blendedOil
