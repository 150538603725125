import ApiServices from "../../services/ApiServices"
import React, { useState, useEffect } from "react"

export default function AWSImage(props) {
  const [link, setLink] = useState("")

  useEffect(() => {
    ApiServices.getFromS3(props.s3Key).then(setLink)
  }, [props.s3Key])

  return link !== "" ? (
    <img src={link?.data} alt="" {...props} crossOrigin="" />
  ) : (
    <></>
  )
}
