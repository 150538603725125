import {
  UPDATE_QUANTITY,
  UPDATE_ORDERS,
  SET_USER_LABEL_LIMIT,
  UPDATE_CURRENT_ORDER,
  RESET_CURRENT_ORDER,
} from "./types"

const initialState = {
  user_orders: [],
  user_label_limit: 0,
  current_order: {
    order_id: "",
    label_quantity: 0,
    promo_code: 0,
    country: "",
    firstname: "",
    lastname: "",
    phone_number: "",
    address: "",
    postal_code: "",
    state: "",
    city: "",
    organization_name: "",
    gstin: "",
    pan_no: "",
  },
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_QUANTITY:
      return {
        ...state,
        current_order: {
          ...state.current_order,
          label_quantity: action.payload,
        },
      }
    case UPDATE_ORDERS:
      return {
        ...state,
        user_orders: [...action.payload],
      }

    case SET_USER_LABEL_LIMIT:
      return {
        ...state,
        user_label_limit: action.payload,
      }

    case UPDATE_CURRENT_ORDER:
      return {
        ...state,
        current_order: {
          ...state.current_order,
          ...action.payload,
        },
      }

    case RESET_CURRENT_ORDER:
      return {
        ...state,
        current_order: {
          ...action.payload,
        },
      }

    default:
      return state
  }
}

export default reducer
