import {
  Box,
  Button,
  Container,
  Grid,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@material-ui/core"
import React, { Component } from "react"
// import { withGoogleMap, GoogleMap, Marker } from "react-google-maps"
import { Link } from "react-router-dom"
import PublicLayout from "../../common/layouts/PublicLayout"
import "./ContactUs.css"
import { connect } from "react-redux"
import * as Sentry from "@sentry/react"
import { getProfile } from "../../Redux"
import ApiServices from "../../services/ApiServices"
import AlertBox from "../../common/alert/AlertBox"
import Loading from "../Loading/Loading"
import "./ContactUs.css"
import { Helmet } from "react-helmet"
import { toast } from "react-toastify"
import useMediaQuery from "@material-ui/core/useMediaQuery"

const mapStateToProps = (state) => {
  return {
    lbUserId: state.login.lbUserId,
    email: state.login.email,
    cognitoUserId: state.login.cognitoUserId,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getProfile: (lbUserId) => dispatch(getProfile(lbUserId)),
  }
}

class ContactUs extends Component {
  constructor(props) {
    super(props)
    this.state = {
      name: "",
      businesses: [],
      business: "",
      phone: "",
      email: "",
      organisation: "",
      designation: "",
      subject: "",
      message: "",
      success: "none",
      loading: false,
    }
  }

  componentDidMount = async () => {
    this.setState({ ...this.state, loading: true })
    this.setState({
      ...this.state,
      loading: false,
    })
  }

  resetState = () => {
    this.setState({
      ...this.state,
      business: "",
      name: "",
      email: "",
      phone: "",
      organisation: "",
      designation: "",
      subject: "",
      message: "",
      success: true,
    })
  }

  handleSubmit = async (event) => {
    event.preventDefault()
    let postData = {
      type: "CONTACT_US",
      details: {
        business: this.state.business,
        name: this.state.name,
        email: this.state.email,
        phone: this.state.phone,
        organisation: this.state.organisation,
        designation: this.state.designation,
        subject: this.state.subject,
        message: this.state.message,
      },
    }
    try {
      const sendEmail = await ApiServices.sendContactUsMail(postData)
      this.setState({
        ...this.state,
        success: true,
        business: "",
        name: "",
        phone: "",
        email: "",
        organisation: "",
        designation: "",
        message: "",
      })
      toast.success("Email sent successfully!")
      this.resetState()
      return false
    } catch (e) {
      console.log(e)
      toast.error("Something went wrong!")
      return false
    }
  }
  handleClose = () => {
    this.setState({
      ...this.state,
      open: false,
    })
  }
  render() {
    // const GoogleMapExample = withGoogleMap(props => (
    //     <GoogleMap
    //         defaultCenter={{ lat: 19.075314480255834, lng: 72.88153973865361 }}
    //         defaultZoom={13}
    //     >
    //         <Marker position={{ lat: 19.075314480255834, lng: 72.88153973865361 }} />
    //     </GoogleMap>
    // ));
    return (
      <div>
        <Helmet>
          <title>FoLSol®| Contact Us</title>
          <meta name="description" content="Food Label Solution contact" />
          <meta name="keywords" content="" />
        </Helmet>
        {this.state.loading ? (
          <Loading />
        ) : (
          <PublicLayout>
            {this.state.success === true ? (
              <AlertBox message={"Mail Sent Successfully !"} type={"success"} />
            ) : this.state.success === false ? (
              <AlertBox message={"Something went wrong !"} type={"error"} />
            ) : (
              ""
            )}

            <main className="main">
              <Box className="container">
                <Container>
                  <Typography
                    component="h3"
                    variant="h3"
                    className="contact-heading"
                  >
                    {" "}
                    Contact Us
                  </Typography>
                  <div className="flex-grow">
                    {/* <Grid item lg={12} xs={12} sm={6}>
                                        <GoogleMapExample
                                            containerElement={<div style={{ height: `500px`, width: '610px' }} />}
                                            mapElement={<div style={{ height: `100%` }} />}
                                        />
                                    </Grid> */}
                    {/* <Grid container spacing={3}> */}
                    <Grid container spacing={3}>
                      <Grid item lg={12} xs={12}>
                        <Paper className="p-2">
                          {/* <h2 className="contact-heading">Contact Us</h2> */}
                          <Typography
                            varient={`subtitle2`}
                            className="contact-content"
                          >
                            We are happy to answer any question that you may
                            have. Please view the <Link to={`/faqs`}>FAQs</Link>{" "}
                            page for all your queries.
                            <br /> For any further questions, just fill the form
                            below or mail us at{" "}
                            <a href="mailto:support@foodlabelsolutions.com">
                              support@foodlabelsolutions.com
                            </a>
                          </Typography>
                          <form onSubmit={this.handleSubmit}>
                            <Grid container spacing={3}>
                              <Grid item lg={4} xs={12}>
                                <TextField
                                  select
                                  label="Select Business"
                                  // value={this.state.business}
                                  onChange={(e) =>
                                    this.setState({ business: e.target.value })
                                  }
                                >
                                  <MenuItem value={`Business Enquiry`}>
                                    Business Enquiry
                                  </MenuItem>
                                  <MenuItem value={`Payment Related`}>
                                    Payment Related
                                  </MenuItem>
                                  <MenuItem value={`Tech Troubleshoot`}>
                                    Tech Troubleshoot
                                  </MenuItem>
                                  <MenuItem value={`Work with us`}>
                                    Work with us
                                  </MenuItem>
                                  <MenuItem value={`Front of Pack Labeling`}>
                                    Front of Pack Labeling
                                  </MenuItem>
                                  <MenuItem value={`Others`}>Others</MenuItem>
                                </TextField>
                              </Grid>
                              <Grid item lg={4} xs={12}>
                                <TextField
                                  label="Name"
                                  id="demo-simple-select"
                                  value={this.state.name}
                                  onChange={(e) =>
                                    this.setState({ name: e.target.value })
                                  }
                                />
                              </Grid>
                              <Grid item lg={4} xs={12}>
                                <TextField
                                  label="Email"
                                  type="email"
                                  id="demo-simple-select"
                                  value={this.state.email}
                                  onChange={(e) =>
                                    this.setState({ email: e.target.value })
                                  }
                                />
                              </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                              <Grid item lg={4} xs={12}>
                                <TextField
                                  label="Phone no"
                                  id="demo-simple-select"
                                  value={this.state.phone}
                                  onChange={(e) =>
                                    this.setState({ phone: e.target.value })
                                  }
                                />
                              </Grid>
                              <Grid item lg={4} xs={12}>
                                <TextField
                                  label="Organisation"
                                  id="demo-simple-select"
                                  value={this.state.organisation}
                                  onChange={(e) =>
                                    this.setState({
                                      organisation: e.target.value,
                                    })
                                  }
                                />
                              </Grid>
                              <Grid item lg={4} xs={12}>
                                <TextField
                                  label="Designation"
                                  id="demo-simple-select"
                                  value={this.state.designation}
                                  onChange={(e) =>
                                    this.setState({
                                      designation: e.target.value,
                                    })
                                  }
                                />
                              </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                              <Grid item lg={12} xs={12}>
                                <TextField
                                  label="Enter Subject"
                                  id="demo-simple-select"
                                  // value={this.state.subject}
                                  value={`FoLSol®Enquiry`}
                                  // onChange={e => this.setState({ subject: e.target.value })}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                              <Grid item lg={12} xs={12}>
                                <TextField
                                  label="Write message here..."
                                  id="outlined-multiline-static"
                                  multiline
                                  rows={5}
                                  value={this.state.message}
                                  onChange={(e) =>
                                    this.setState({ message: e.target.value })
                                  }
                                />
                              </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                              <Grid item lg={12} xs={12}>
                                <Button
                                  color="primary"
                                  variant="contained"
                                  type="submit"
                                >
                                  Send
                                </Button>
                              </Grid>
                            </Grid>
                          </form>
                        </Paper>
                      </Grid>
                    </Grid>
                  </div>
                </Container>
              </Box>
            </main>
          </PublicLayout>
        )}
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactUs)
