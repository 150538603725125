import React from "react"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import Typography from "@material-ui/core/Typography"
import AppBarCollapse from "./AppBarCollapse"
import { Link } from "react-router-dom"
import folsol_logo from "../../assets/images/folsol_logo_original.png"
import { useSelector, useDispatch } from "react-redux"

const styles = {
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  navigation: {
    boxShadow: "0 6px 31px -2px rgba(0, 0, 0, 0.1)",
    background: "black",
    color: "white",
  },
  toggleDrawer: {},
  appTitle: {
    color: "black",
  },
  toolbar: {
    background: "white",
    padding: 10,
    paddingLeft: 30,
    color: "black",
  },
  toolbarActive: {
    background: "black",
    color: "white",
    padding: 10,
    paddingLeft: 30,
  },
}

function ButtonAppBar(props) {
  const { classes } = props
  return (
    <AppBar className={classes.navigation}>
      <Toolbar className={classes.toolbar}>
        <Link to={{ pathname: "/", home: true }} className="logo-link">
          <img src={folsol_logo} className="folsol-logo" alt="FoLSol®logo" />
        </Link>
        <AppBarCollapse />
      </Toolbar>
    </AppBar>
  )
}

ButtonAppBar.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(ButtonAppBar)
