import React from "react"
import "./Loading.css"

const Loading = ({ background = "" }) => {
  return (
    <div className={`${background ?? "bg-[#000]"} loading-screen`}>
      <div className="loader">Loading...</div>
    </div>
  )
}

export default Loading
