import { FieldType } from "../constants/enum"

const laboratoryTest = (title) => ({
  name: "",
  description: "",
  fields: [
    {
      title: title,
      type: FieldType.RADIO,
      reference: "",
      validators: "",
      layout: 6,
      description: "",
      list: [],
      items: ["Yes", "No"],
      key: "is_compound",
      visible: true,
      required: true,
    },
    // {
    //   title: "Upload Lab Report",
    //   type: FieldType.FILE,
    //   reference: "",
    //   validators: "",
    //   layout: 6,
    //   description: "",
    //   list: [],
    //   items: [],
    //   key: "lab_report",
    //   variant: "small",
    //   visible: `output.is_compound == "Yes"`,
    // },
  ],
})

export default laboratoryTest
