export const allergenOptions = [
  "wheat",
  "barley",
  "rye",
  "oats",
  "spelt",
  "gluten",
  "milk",
  "egg",
  "nuts",
  "soy",
  "fish",
  "crustacean",
  "sulphite",
]

export const allergenOptionsPfl = [
  "Wheat",
  "Barley",
  "Rye",
  "Oats",
  "Spelt",
  "Gluten",
  "Milk",
  "Egg",
  "Nuts",
  "Soy",
  "Fish",
  "Crustacean",
  "Sulphite",
]
