import React from "react"
import { Link } from "react-router-dom"
import { BreadcrumbIcon } from "../../assets/icons/Icons"
export default function Breadcrumbs({ linksRef }) {
  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol className="inline-flex items-center space-x-1">
        {linksRef?.map((l, index) =>
          index !== linksRef.length - 1 ? (
            <li key={index}>
              <div className="flex items-center">
                {index !== 0 && <BreadcrumbIcon />}
                <Link
                  to={l.link}
                  className="text-base font-medium text-textgray hover:text-orange-500"
                >
                  {l.page}
                </Link>
              </div>
            </li>
          ) : (
            <li aria-current="page" key={index}>
              <div className="flex items-center">
                <BreadcrumbIcon />
                <span
                  title={l.page}
                  className="text-base font-medium text-black truncate max-w-[570px] "
                >
                  {l.page}
                </span>
              </div>
            </li>
          ),
        )}
      </ol>
    </nav>
  )
}
