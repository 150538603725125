import React, { Fragment, useState } from "react"
import { toast } from "react-toastify"
import { Menu, Transition } from "@headlessui/react"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import FeatureFlag from "../../components/FeatureFlag/FeatureFlag"
import InputLabel from "@material-ui/core/InputLabel"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import MenuItem from "@material-ui/core/MenuItem"
import Slide from "@material-ui/core/Slide"
import TextField from "@material-ui/core/TextField"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
import FormControl from "@material-ui/core/FormControl"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import Checkbox from "@material-ui/core/Checkbox"
import Select from "@material-ui/core/Select"
import FormHelperText from "@material-ui/core/FormHelperText"
import folsol_logo from "../../assets/images/folsol_logo_original.png"
import newLogo from "../../assets/images/New2Logo.png"
import defaultUser from "../../assets/images/default/default-user.png"
import { Link } from "react-router-dom"
import ApiServices from "../../services/ApiServices"
import {
  logoutUser,
  setUserHasBusinesses,
  AddBusiness,
  allowToProtectedRoutes,
  setBusinessesType,
} from "../../Redux"
import { useDispatch, useSelector } from "react-redux"
import isValidLicense from "../../Utils/licenseValidator"
import { withRouter } from "react-router-dom"
import { withLastLocation } from "react-router-last-location"
import config from "../../config"
import { sendAnalyticsEvent } from "../../Utils/analytics"
import {
  BASE_RECIPE_SIDEBAR_ICON_CLICKED,
  CONTACT_US_SIDEBAR_ICON_CLICKED,
  DASHBOARD_SIDEBAR_ICON_CLICKED,
  INGREDIENT_SIDEBAR_ICON_CLICKED,
  BUSINESS_PROFILE_SIDEBAR_ICON_CLICKED,
  RECIPE_SIDEBAR_ICON_CLICKED,
  NavbarAnalyticsEventMap,
  RESOURCES_SIDEBAR_ICON_CLICKED,
  PRINT_LABEL_SIDEBAR_ICON_CLICKED,
  AILABELREVIEW_SIDEBAR_ICON_CLICKED,
  CALORIE_BALANCE_SIDEBAR_ICON_CLICKED,
} from "../../Utils/analyticsEvents"
import { COLORS } from "../../Utils/colorPalette"
import { FONT_SIZES } from "../../constants/FONTS"
import ExpiredPlan from "../../common/ExpiredPlan/ExpiredPlan"
import { infoCenterUrl } from "../../constants/externalLinks"
import { classNames, containsLinkpath } from "../../Utils/helpers"
import {
  HomeIcon,
  RecipeIcon,
  ReviewIcon,
  IngredientsIcon,
  BaseRecipesIcon,
  TagsIcon,
  NutritionGuideIcon,
  BusinessIcon,
  TeamIcon,
  ResourcesIcon,
  HelpCenterIcon,
  LabelIcon,
  ApiIcon,
  QrIcon,
  HomeIconFilled,
  RecipeIconFilled,
  PrintLabelIconFilled,
  ReviewIconFilled,
  IngredientsIconFilled,
  BaseRecipesIconFilled,
  TagsIconFilled,
  NutritionGuideIconFilled,
  BusinessIconFilled,
  TeamIconFilled,
  HelpCenterIconFilled,
  LabelIconFilled,
  QrIconFilled,
  AILabelValidation,
  AILabelValidationIconFilled,
  AILabelReviewIcon,
  AILabelReviewIconFilled,
  DigitalLockerIcon,
  DigitalLockerIconFilled,
} from "../../assets/icons/Icons.js"
import Submenu from "./Submenu"
import { BusinessType } from "../../constants/enum"
import PrintMenuIcon from "../../common/Icons/PrintMenuIcon"
import { BASE_ROUTES } from "../../constants/routes"
import { getBusinessRoute } from "../../Utils/businessRoute"
import PricingModal from "../../pages/PpfPricing/paymentModal"
import MLPricingModal from "../../pages/PaymentGateway/paymentModal"
import BusinessSelector from "./BusinessSelector"
import Spinner from "../../common/Icons/Spinner"
import { hasActivePlan } from "../../helpers/helper"
import { setPermission as setUserPermission } from "../../Redux"
import {
  getUserRole,
  getBusinessById,
  getUserDetailsFromToken,
} from "../../services/ApiServicesv2"
import DigitalLockerModal from "./DigitalLockerModal"
import EarthIcon from "../../common/Icons/EarthIcon"
import EarthFilledIcon from "../../common/Icons/EarthFilledIcon"

const ENABLE_USER_MODULE = config.ENABLE_USERMODULE

const TransitionForDialog = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const SidebarLink = (props) => {
  if (props.native) {
    return <a href={props.to}>{props.children}</a>
  }
  return <Link {...props}>{props.children}</Link>
}

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  desktopHamburger: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  openDrawerButton: {
    marginRight: theme.spacing(2),
    display: "inline",
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("lg")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  },
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("lg")]: {
      width: 75,
      flexShrink: 0,
    },
    marginRight: theme.spacing(0.5),
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    ...theme.mixins.toolbar,
  },
  url: {
    position: "absolute",
    right: 0,
  },
  link: {
    textDecoration: "none",
    color: "#FFF",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(1),
    },
  },
  listItem: {
    marginBottom: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
    display: "flex",
    flexDirection: "column",
  },
  listItemText: {
    justifyContent: "center",
    overflow: "hidden",
  },
  listHamburgerClosed: {
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
  },
  listHamburgerOpen: {
    display: "flex",
    flexDirection: "row",
    textAlign: "left",
    fontSize: FONT_SIZES.md,
  },
  listItemTextColor: {
    color: "black",
    whiteSpace: "nowrap",
  },
  active: {
    "& > *, & > *:hover": {
      background: COLORS.gold.dark,
      color: COLORS.black.dark,
    },
  },
  sidebarMenu: {
    padding: 0,
    marginTop: theme.spacing(0.5),
  },
  navbarLinkStyles: {
    fontSize: FONT_SIZES.sm,
    textTransform: "capitalize",
    fontWeight: 600,
  },
  businessSwitch: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  businessChange: {
    padding: theme.spacing(1),
    background: theme.palette.warning.dark,
    color: theme.palette.background.default,
  },
  navbarMobileLinkStyles: {
    fontSize: FONT_SIZES.sm,
    textTransform: "capitalize",
    fontWeight: 600,
    color: "black",
  },
  imageStyles: {
    maxWidth: theme.spacing(6),
    maxHeight: theme.spacing(6),
    borderRadius: "50%",
  },
}))

function Sidebar(props) {
  const dispatch = useDispatch()
  const businessType = useSelector((state) => state.login.business_type)
  const ppfPlanFeatures = useSelector((state) => state.ui.ppfPlanFeatures)
  const classes = useStyles()
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"))
  const [openModal, setOpenModal] = React.useState(false)
  const active_business = useSelector((state) => state.login.active_business)
  const lbUserId = useSelector((state) => state.login.lbUserId)
  const businessList = useSelector((state) => state.login.allBusinesses)
  const [Allbusinesses, setAllBusinesses] = React.useState([])
  const profile = useSelector((state) => state.profile)
  const path = window.location.pathname
  const [business, setBusiness] = React.useState("")
  const [num_outlets, setNumOutlets] = React.useState(null)
  const [company_name, setCompanyName] = React.useState(null)
  const [organization_name, setOrganizationName] = React.useState(null)
  const [licence_type, setLicenceType] = React.useState(null)
  const [fssai_licence_no, setFssaiLicenceNo] = React.useState(null)
  const [businessError, setBusinessError] = React.useState(false)
  const [checked, setChecked] = React.useState(false)
  const [confirmed, setConfirmed] = React.useState(false)
  const [license_error, setLicenseError] = React.useState(false)
  const [companyNameError, setCompanyNameError] = React.useState(false)
  const [organizationNameError, setOrganizationNameError] =
    React.useState(false)
  const [licenceTypeError, setLicenceTypeError] = React.useState(false)
  const [licenceNoError, setLicenceNoError] = React.useState(false)
  const [numOutletError, setNumOutletError] = React.useState(false)
  const [isUpgradeModalOpen, setIsUpgradeModalOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const openDigitalLockerModal = () => setIsModalOpen(true)
  const closeDigitalLockerModal = () => setIsModalOpen(false)

  const allow_to_protected_routes = useSelector(
    (state) => state.subscription.allow_to_protected_routes,
  )
  const [addBusinessType, setAddBusinesstype] = React.useState("")
  const [permissions, setPermission] = React.useState([]) //['ADMIN', 'MANAGER']
  const [currentUserRole, setCurrentUserRole] = React.useState("")

  const userDetails = JSON.parse(localStorage.getItem("persist:root"))

  const businessId = useSelector((state) => state.login.active_business)

  React.useEffect(() => {
    updateUserPermissions(businessId)
  }, [businessId])

  React.useEffect(() => {
    if (userDetails && userDetails.login) {
      const existingLogin = JSON.parse(userDetails.login)
      setCurrentUserRole(existingLogin.role)
      if (!existingLogin.lbUserId) {
        getUserBusinessMapping()
      }
    } else {
      getUserBusinessMapping()
    }
  }, [userDetails])

  async function updateUserPermissions(businessId) {
    try {
      const userDetails = JSON.parse(
        window.localStorage.getItem("persist:root"),
      )
      if (businessId && userDetails && userDetails.login) {
        const existingLogin = JSON.parse(userDetails.login)
        // Use await when calling getUserRole
        const user_role = await getUserRole(businessId, existingLogin.lbUserId)
        if (!user_role || user_role.code !== 200) {
          toast.error("Please contact administrator.")
          // logoutUser()
          return
        }

        existingLogin.role = user_role.data.role
        setPermission(user_role.data.role)
        userDetails.login = JSON.stringify(existingLogin)
        localStorage.setItem("persist:root", JSON.stringify(userDetails))
      }
    } catch (error) {
      console.error(error)
    }
  }
  React.useEffect(() => {
    if (!allowToProtectedRoutes) {
      window.location.reload()
    }
  }, [allow_to_protected_routes])

  const fetchLoggedInUserData = async () => {
    setLoading(true)
    await getUserBusinessMapping()
    await getAllBusiness()
    await checkTotalBusiness()
    setLoading(false)
  }

  React.useEffect(() => {
    fetchLoggedInUserData()
  }, [])

  React.useEffect(() => {
    checkActivePlan()
  }, [active_business])

  React.useEffect(() => {
    getAllBusiness()
  }, [businessList])

  const getAllBusiness = async () => {
    try {
      const allBusiness = businessList

      let businessesArray = []
      if (allBusiness) {
        for (let allbusi of allBusiness) {
          const business_details = await getBusinessById(allbusi.id)
          if (business_details.code === 200) {
            businessesArray.push({
              name: business_details?.data?.business_name,
              id: business_details?.data?.id,
              type: business_details?.data?.type,
            })
          } else {
            toast.error(
              "Unable To Business Details Please Try Again In Some Time",
            )
          }
        }
        setAllBusinesses(businessesArray)
      }
    } catch (err) {
      console.log("Error: ", err)
    }
  }

  const checkTotalBusiness = async () => {
    if (businessList && businessList.length > 0) {
      const business_types_array = businessList.map(async (business) => {
        const response = await getBusinessById(business.id)
        return response?.data?.type
      })

      const business_types = await Promise.all(business_types_array)

      let business_is_manufacturer = false
      let business_is_restaurant = false

      business_types.forEach((type) => {
        if (type === "Manufacturer") {
          business_is_manufacturer = true
        }
        if (type === "Restaurant") {
          business_is_restaurant = true
        }
      })

      if (business_is_manufacturer && !business_is_restaurant) {
        setAddBusinesstype("Restaurant")
        setBusiness("Restaurant")
      } else if (!business_is_manufacturer && business_is_restaurant) {
        setAddBusinesstype("Manufacturer")
        setBusiness("Manufacturer")
      } else {
        setAddBusinesstype("both")
      }
    }
  }

  let loginDetails
  const getUserBusinessMapping = async () => {
    if (!active_business) {
      return
    }

    const user_details = JSON.parse(localStorage.getItem("User_Details"))
    const business_details = JSON.parse(
      localStorage.getItem("Business_Details"),
    )
    let postData = {
      lbUserId: lbUserId || user_details.id,
      businessId: active_business || business_details[0].id,
    }
    let userRoleData = await getUserRole(postData.businessId, postData.lbUserId)
    const userDetails = JSON.parse(localStorage.getItem("persist:root"))
    if (!userDetails || !userDetails.login) {
      const user_details_from_token = await getUserDetailsFromToken()

      if (user_details_from_token.code !== 200) {
        toast.error(user_details_from_token.message || "Try again in some time")
        return
      }
      const new_user_details = {}
      user_details_from_token.data.role = userRoleData.data.role
      new_user_details.login = user_details_from_token.data
      new_user_details.login.lbUserId = user_details_from_token.data.id
      loginDetails = new_user_details.login
      localStorage.setItem("persist:root", JSON.stringify(new_user_details))
    }

    if (userRoleData && userRoleData.status === "success") {
      if (userRoleData && userRoleData.data && userRoleData.data.role) {
        setPermission(userRoleData.data.role)
      }
    }
  }

  const handleCheckBox = (type) => {
    if (type === "confirm") {
      setConfirmed(!confirmed)
    } else {
      setChecked(!checked)
    }
  }

  const switchBusiness = async (businessId) => {
    const business_details = await getBusinessById(businessId)

    let permission

    const businessMapDetails = await getUserRole(businessId, lbUserId)

    if (businessMapDetails && businessMapDetails?.data) {
      permission = businessMapDetails?.data?.role
    }

    let business_type = ""

    if (business_details?.data?.type === "Manufacturer") {
      business_type = "Manufacturer"
      dispatch(setUserPermission(businessId, lbUserId))
      dispatch(setBusinessesType(business_type))
      dispatch(setUserHasBusinesses(businessId, permission))
      props.history.push({
        pathname: `${BASE_ROUTES.PPF}/label-maker/`,
      })
    } else {
      business_type = "Restaurant"
      dispatch(setUserPermission(businessId, lbUserId))
      dispatch(setBusinessesType(business_type))
      dispatch(setUserHasBusinesses(businessId, permission))
      props.history.push({
        pathname: `${BASE_ROUTES.MENU_LABEL}/recipe/`,
      })
    }
  }

  const logoutHandler = async () => {
    analyticsEventHandler("Logout")
    dispatch(logoutUser())
  }

  const handleCloseModal = () => {
    setOpenModal(false)
  }

  const analyticsEventHandler = (eventName) => {
    const eventProps = {
      "Current URL": window.location.pathname,
    }
    sendAnalyticsEvent(NavbarAnalyticsEventMap[eventName], eventProps)
  }

  const validateInputFields = () => {
    let error_count = 0
    if (!business) {
      setBusinessError(true)
      error_count++
    }
    if (!company_name) {
      setCompanyNameError(true)
      error_count++
    }
    if (business === "restaurant" && !organization_name) {
      setOrganizationNameError(true)
      error_count++
    }
    if (business === "restaurant" && !num_outlets) {
      setNumOutletError(true)
      error_count++
    }
    if (!licence_type) {
      setLicenceTypeError(true)
      error_count++
    }
    if (!fssai_licence_no) {
      setLicenceNoError(true)
      error_count++
    }
    if (fssai_licence_no && licence_type) {
      let is_valid = isValidLicense(fssai_licence_no, licence_type)
      if (!is_valid) {
        setLicenseError(true)
        error_count++
      }
    }
    return error_count
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    let validate = validateInputFields()
    if (validate > 0) {
      return
    } else {
      const businessType = business.charAt(0).toUpperCase() + business.slice(1)
      const Business = {
        name: business,
        details: {
          company_name: company_name,
          business_type: businessType,
          licence_type: licence_type,
          fssai_licence_no: fssai_licence_no,
          organization_name: organization_name,
          num_outlets: num_outlets,
        },
      }
      let response2 = await ApiServices.addBusiness(Business)
      let creds = {
        lbUserId: lbUserId,
        businessId: response2.result.items[0].businessId,
      }
      let response3 = await ApiServices.createUserBusinessMapping(creds)
      if ("success" in response3) {
        let newRecord = {
          businessId: creds.businessId,
          lbUserId: lbUserId,
          status: "ACTIVE",
        }
        dispatch(AddBusiness(newRecord))
        dispatch(setBusinessesType(businessType))
        dispatch(setUserHasBusinesses(creds.businessId))
        setOpenModal(false)
      }
    }
  }

  const checkActivePlan = async () => {
    const active_plan = await hasActivePlan()

    if (!active_plan) {
      setIsUpgradeModalOpen(true)
    }
  }

  const renderProfileImage = (
    <>
      {profile.profileImage ? (
        <span className="h-12 w-12 ml-2 sm:ml-3 mr-2 bg-gray-100 rounded-full overflow-hidden">
          <img
            src={profile.profileImage}
            alt="dummy"
            className="h-full w-full object-cover"
          />
        </span>
      ) : (
        <span className="h-12 w-12 ml-2 sm:ml-3 mr-2 bg-gray-100 rounded-full overflow-hidden">
          <img
            alt="Profile"
            src={defaultUser}
            className="h-full w-full object-cover"
          />
        </span>
      )}
    </>
  )

  const getRouteAccToBusiness = (routes) => {
    return getBusinessRoute(routes, businessType)
  }

  const commonElements = [
    {
      event: DASHBOARD_SIDEBAR_ICON_CLICKED,
      to: getRouteAccToBusiness(`/user-dashboard/`),
      key: "userDashboard",
      icon: <HomeIcon />,
      iconFilled: <HomeIconFilled />,
      name: "Home",
      linkpath: getRouteAccToBusiness(["/user-dashboard"]),
      type: "common",
      enabled: true,
    },
    {
      event: DASHBOARD_SIDEBAR_ICON_CLICKED,
      to: `${BASE_ROUTES.PPF}/label-maker/`,
      key: "labelMaker",
      icon: <LabelIcon />,
      iconFilled: <LabelIconFilled />,
      name: "Label Maker",
      linkpath: [
        `${BASE_ROUTES.PPF}/label-maker`,
        `${BASE_ROUTES.PPF}/label/v3/(.*)`,
      ],
      type: "Manufacturer",
      native: false,
      enabled: ppfPlanFeatures?.["LABEL_MAKER"],
    },
    {
      event: DASHBOARD_SIDEBAR_ICON_CLICKED,
      to: `${BASE_ROUTES.PPF}/ai-label-validation/`,
      key: "AILabelValidation",
      icon: <AILabelValidation />,
      iconFilled: <AILabelValidationIconFilled />,
      name: "AI Label Validation",
      linkpath: [`${BASE_ROUTES.PPF}/ai-label-validation`],
      type: "Manufacturer",
      native: false,
      enabled: ppfPlanFeatures?.["LABEL_VALIDATION"],
    },
    {
      event: DASHBOARD_SIDEBAR_ICON_CLICKED,
      to: `${BASE_ROUTES.PPF}/ai-label-review/`,
      key: "AILabelReview",
      icon: <AILabelReviewIcon />,
      iconFilled: <AILabelReviewIconFilled />,
      name: "Artwork Review",
      linkpath: [`${BASE_ROUTES.PPF}/ai-label-review`],
      type: "Manufacturer",
      native: false,
      enabled: ppfPlanFeatures?.["LABEL_VALIDATION"],
    },
    {
      event: DASHBOARD_SIDEBAR_ICON_CLICKED,
      to: `${BASE_ROUTES.PPF}/label-drive/`,
      key: "labeldrive",
      icon: <DigitalLockerIcon />,
      iconFilled: <DigitalLockerIconFilled />,
      name: "Label Drive",
      linkpath: [`${BASE_ROUTES.PPF}/label-drive`],
      type: "Manufacturer",
      native: false,
      enabled: ppfPlanFeatures?.["LABEL_VALIDATION"],
    },
    {
      event: PRINT_LABEL_SIDEBAR_ICON_CLICKED,
      to: `${BASE_ROUTES.PPF}/print-label/`,
      key: "printLabel",
      icon: <PrintMenuIcon />,
      iconFilled: <PrintLabelIconFilled />,
      name: "Print a Label",
      linkpath: [`${BASE_ROUTES.PPF}/print-label`],
      type: "Manufacturer",
      native: false,
      enabled: ppfPlanFeatures?.["READY_FOR_PRINT"],
    },

    // {
    //   event: PRINT_LABEL_SIDEBAR_ICON_CLICKED,
    //   to: `${BASE_ROUTES.PPF}/mera-qrcode/`,
    //   key: "meraQrCode",
    //   icon: <QrIcon />,
    //   iconFilled: <QrIconFilled />,
    //   name: "Mera QR Code",
    //   linkpath: [
    //     `${BASE_ROUTES.PPF}/mera-qrcode`,
    //     `${BASE_ROUTES.PPF}/digital-label/(.*)/(.*)`,
    //   ],
    //   type: "Manufacturer",
    //   native: false,
    //   enabled: ppfPlanFeatures?.["MERA_QR_CODE"],
    // },
    {
      event: RECIPE_SIDEBAR_ICON_CLICKED,
      to: "",
      key: "nutritionAnalysis",
      submenu: true,
      icon: <IngredientsIcon />,
      iconFilled: <IngredientsIconFilled />,
      name: "Nutrition Analysis",
      linkpath: [""],
      type: "Manufacturer",
      native: false,
      enabled: ppfPlanFeatures?.["NUTRITION_ANALYSIS"],
    },
    {
      event: RECIPE_SIDEBAR_ICON_CLICKED,
      to: `${BASE_ROUTES.PPF}/nutrition-analysis/recipes/`,
      key: "createNewRecipe",
      parent: "nutritionAnalysis",
      name: "Recipes",
      linkpath: [
        `${BASE_ROUTES.PPF}/nutrition-analysis/recipes`,
        `${BASE_ROUTES.PPF}/nutrition-analysis/create-new-recipe`,
        `${BASE_ROUTES.PPF}/nutrition-analysis/edit-new-recipe/(.*)`,
        `${BASE_ROUTES.PPF}/nutrition-analysis/nutrition-label`,
      ],
      type: "Manufacturer",
      native: false,
      enabled: ppfPlanFeatures?.["NUTRITION_ANALYSIS"],
    },
    {
      event: RECIPE_SIDEBAR_ICON_CLICKED,
      to: `${BASE_ROUTES.PPF}/nutrition-analysis/baseRecipes/`,
      key: "createBaseRecipe",
      parent: "nutritionAnalysis",
      name: "Base Recipes",
      linkpath: [
        `${BASE_ROUTES.PPF}/nutrition-analysis/baseRecipes`,
        `${BASE_ROUTES.PPF}/nutrition-analysis/create-base-recipe`,
        `${BASE_ROUTES.PPF}/nutrition-analysis/edit-base-recipe/(.*)`,
      ],
      type: "Manufacturer",
      native: false,
      enabled: ppfPlanFeatures?.["NUTRITION_ANALYSIS"],
    },
    {
      event: RECIPE_SIDEBAR_ICON_CLICKED,
      to: `${BASE_ROUTES.PPF}/nutrition-analysis/ingredients/`,
      key: "createIngredient",
      parent: "nutritionAnalysis",
      name: "Ingredients",
      linkpath: [
        `${BASE_ROUTES.PPF}/nutrition-analysis/ingredients`,
        `${BASE_ROUTES.PPF}/nutrition-analysis/create-ingredient`,
        `${BASE_ROUTES.PPF}/nutrition-analysis/edit-ingredient/(.*)`,
      ],
      type: "Manufacturer",
      native: false,
      enabled: ppfPlanFeatures?.["NUTRITION_ANALYSIS"],
    },
    {
      event: "",
      to: `${BASE_ROUTES.MENU_LABEL}/recipe/`,
      key: "Recipes",
      icon: <RecipeIcon />,
      iconFilled: <RecipeIconFilled />,
      name: "Recipes",
      linkpath: [
        `${BASE_ROUTES.MENU_LABEL}/recipe`,
        `${BASE_ROUTES.MENU_LABEL}/add-recipe`,
        `${BASE_ROUTES.MENU_LABEL}/add-new-recipe`,
        `${BASE_ROUTES.MENU_LABEL}/download`,
        `${BASE_ROUTES.MENU_LABEL}/create-combo-meals`,
        `${BASE_ROUTES.MENU_LABEL}/edit-combo-meals`,
      ],
      type: "Restaurant",
      native: false,
      enabled: true,
    },
    {
      event: "",
      to: `/review/`,
      key: "Review",
      icon: <ReviewIcon />,
      iconFilled: <ReviewIconFilled />,
      name: "Review",
      linkpath: [`${BASE_ROUTES.MENU_LABEL}/(.*)/review`],
      type: "Restaurant",
      native: false,
      enabled: true,
    },
    {
      event: INGREDIENT_SIDEBAR_ICON_CLICKED,
      to: `${BASE_ROUTES.MENU_LABEL}/ingredients/`,
      key: "Ingredients",
      icon: <IngredientsIcon />,
      iconFilled: <IngredientsIconFilled />,
      name: "Ingredients",
      linkpath: [`${BASE_ROUTES.MENU_LABEL}/ingredients`],
      type: "Restaurant",
      native: false,
      enabled: true,
    },
    {
      event: BASE_RECIPE_SIDEBAR_ICON_CLICKED,
      to: `${BASE_ROUTES.MENU_LABEL}/base-recipe/`,
      key: "Base Recipes",
      icon: <BaseRecipesIcon />,
      iconFilled: <BaseRecipesIconFilled />,
      name: "Base Recipes",
      linkpath: [`${BASE_ROUTES.MENU_LABEL}/base-recipe`],
      type: "Restaurant",
      native: false,
      enabled: true,
    },
    ...(config.ALLOWED_VERSION_USERS.includes(profile.email)
      ? [
          {
            event: RESOURCES_SIDEBAR_ICON_CLICKED,
            to: `${BASE_ROUTES.MENU_LABEL}/buffet-tags/`,
            key: "Guide",
            icon: <TagsIcon />,
            iconFilled: <TagsIconFilled />,
            name: "Food Tags",
            linkpath: [
              `${BASE_ROUTES.MENU_LABEL}/buffet-tags`,
              `${BASE_ROUTES.MENU_LABEL}/buffet-tag/create`,
            ],
            type: "Restaurant",
            native: false,
            enabled: true,
          },
          {
            event: RESOURCES_SIDEBAR_ICON_CLICKED,
            to: `${BASE_ROUTES.MENU_LABEL}/guide/`,
            key: "Guide",
            icon: <NutritionGuideIcon />,
            iconFilled: <NutritionGuideIconFilled />,
            name: "Nutrition Guide",
            linkpath: [
              `${BASE_ROUTES.MENU_LABEL}/guide`,
              `${BASE_ROUTES.MENU_LABEL}/guide/create-new-guide`,
              `${BASE_ROUTES.MENU_LABEL}/guide/(.*)`,
            ],
            type: "Restaurant",
            native: false,
            enabled: true,
          },
        ]
      : []),

    {
      event: BUSINESS_PROFILE_SIDEBAR_ICON_CLICKED,
      to: getRouteAccToBusiness(`/business-profile/`),
      key: "businessProfile`",
      icon: <BusinessIcon />,
      iconFilled: <BusinessIconFilled />,
      name: "Business",
      linkpath: getRouteAccToBusiness([
        "/business-profile",
        "/edit-brand/(.*)",
        "/add-brand",
      ]),
      type: "common",
      native: false,
      enabled: true,
    },
    currentUserRole === "Admin" && {
      event: "",
      to: `/users/`,
      key: "Users",
      icon: <TeamIcon />,
      iconFilled: <TeamIconFilled />,
      name: "Team",
      linkpath: getRouteAccToBusiness(["/(.*)/users"]),
      type: "userModule",
      native: false,
      enabled: true,
    },
    {
      event: CALORIE_BALANCE_SIDEBAR_ICON_CLICKED,
      to: `${BASE_ROUTES.MENU_LABEL}/calorie-balance/`,
      key: "CalorieBalance",
      icon: <EarthIcon />,
      iconFilled: <EarthFilledIcon />,
      name: (
        <div className="flex gap-1 items-center">
          Calorie Balance
          <img src={newLogo} alt="New Feature" className=" w-8 h-8" />
        </div>
      ),
      linkpath: [`${BASE_ROUTES.MENU_LABEL}/calorie-balance`],
      type: "Restaurant",
      native: false,
      enabled: true,
    },
    {
      event: PRINT_LABEL_SIDEBAR_ICON_CLICKED,
      to: `${BASE_ROUTES.PPF}/exports/`,
      key: "exportsLabeling",
      icon: <EarthIcon />,
      iconFilled: <EarthFilledIcon />,
      name: (
        <div className="flex gap-1 items-center">
          Exports Labelling
          <img src={newLogo} alt="New Feature" className=" w-8 h-8" />
        </div>
      ),
      linkpath: [`${BASE_ROUTES.PPF}/exports`],
      type: "Manufacturer",
      native: false,
      enabled: true,
    },
    // {
    //   event: RESOURCES_SIDEBAR_ICON_CLICKED,
    //   to: `${BASE_ROUTES.MENU_LABEL}/partner-api/`,
    //   key: "partnerApi",
    //   icon: <ApiIcon />,
    //   iconFilled: <ApiIcon />,
    //   name: "APIs",
    //   linkpath: [`${BASE_ROUTES.MENU_LABEL}/partner-api`],
    //   type: "Restaurant",
    //   native: false,
    //   enabled: true,
    // },
  ]

  let ItemList = []
  if (loginDetails?.role !== "User") {
    ItemList = [...ItemList, ...commonElements]
  } else {
    ItemList = [...ItemList, ...commonElements]
  }
  let OtherItemsList = [
    {
      event: RESOURCES_SIDEBAR_ICON_CLICKED,
      to: "/resources/menu-labelling-pdf-resources/",
      key: "Resources",
      icon: <ResourcesIcon />,
      iconFilled: <ResourcesIcon />,
      name: "Resources",
      linkpath: ["/resources/menu-labelling-pdf-resources/"],
      type: "Restaurant",
      native: true,
      enabled: true,
    },

    {
      event: CONTACT_US_SIDEBAR_ICON_CLICKED,
      to: `/contact-us/`,
      key: "Help Center",
      icon: <HelpCenterIcon />,
      iconFilled: <HelpCenterIconFilled />,
      name: "Help Center",
      linkpath: ["/contact-us"],
      type: "common",
      native: false,
      enabled: true,
    },
  ]

  const drawer = (
    <div
      className="py-4 w-52 bg-black top-0 sticky h-screen"
      // style={mobileOpen ? openWidth : closeWidth}
    >
      <div className="text-center">
        <Link to={{ pathname: "/", home: true }} className={classes.link}>
          <img
            src={folsol_logo}
            className="folsol-logo inline"
            alt="FoLSol®Logo"
          />
        </Link>
        <hr className="w-3/4 mx-auto mt-3 bg-gray-200 border-1 rounded"></hr>
        <p className="text-white">
          {businessType === BusinessType.RESTAURANT
            ? "Menu Labelling"
            : "Packed Food Labelling"}
        </p>
      </div>
      <ul className="text-sm text-slate-400">
        {ItemList.map((item, idx) =>
          (businessType === item.type || item.type === "common") &&
          item.enabled &&
          item.submenu ? (
            <Submenu
              list={commonElements.filter((page) => page.parent === item.key)}
              item={item}
              path={path}
            />
          ) : (businessType === item.type || item.type === "common") &&
            item.enabled &&
            !item.hasOwnProperty("parent") ? (
            <SidebarLink
              key={idx}
              onClick={() => {
                sendAnalyticsEvent(item.event, {
                  "Page Name": path.substring(1),
                })
              }}
              to={
                item.name === "Review"
                  ? `${BASE_ROUTES.MENU_LABEL}/` + active_business + item.to
                  : item.to
              }
              className={`
              ${
                containsLinkpath(item.linkpath, path)
                  ? "text-amber-300 block"
                  : path === "/" + active_business + item.to
                  ? "text-amber-300 block"
                  : "block"
              } 
                 `}
              native={item.native}
            >
              <li
                key={item.key}
                className="flex flex-row items-center mt-2 h-8 transform group transition-transform ease-in duration-500"
              >
                <span className="inline-flex items-center justify-center h-12 w-12 text-lg">
                  {containsLinkpath(item.linkpath, path)
                    ? item.iconFilled
                    : path === "/" + active_business + item.to
                    ? item.iconFilled
                    : item.icon}
                </span>
                <span className="text-sm group-hover:text-amber-300">
                  {item.name}
                </span>
              </li>
            </SidebarLink>
          ) : item.type === "userModule" ? (
            <FeatureFlag permissions={permissions} key={idx}>
              <SidebarLink
                to={getRouteAccToBusiness(
                  `/${active_business}${item.to}`,
                  businessType,
                )}
                className={
                  path ===
                  getRouteAccToBusiness(
                    `/${active_business}${item.to}`,
                    businessType,
                  )
                    ? "text-amber-300 block"
                    : "block"
                }
                native={item.native}
              >
                <li
                  key={item.key}
                  className="flex flex-row items-center mt-2 h-8 transform group transition-transform ease-in duration-500"
                >
                  <span className="inline-flex items-center justify-center h-12 w-12 text-lg">
                    {path ===
                    getRouteAccToBusiness(
                      `/${active_business}${item.to}`,
                      businessType,
                    )
                      ? item.iconFilled
                      : item.icon}
                  </span>
                  <span className="text-sm group-hover:text-amber-300">
                    {item.name}
                  </span>
                </li>
              </SidebarLink>
            </FeatureFlag>
          ) : null,
        )}
      </ul>
      <ul className="text-sm text-slate-400 p-4 absolute bottom-0">
        {OtherItemsList.map(
          (item, idx) =>
            (businessType === item.type || item.type === "common") && (
              <SidebarLink
                key={idx}
                onClick={() =>
                  sendAnalyticsEvent(item.event, {
                    "Page Name": path.substring(1),
                  })
                }
                to={item.to}
                className={`
              ${
                containsLinkpath(item.linkpath, path)
                  ? "text-amber-300 block"
                  : "block"
              } `}
                native={item.native}
              >
                <li
                  key={item.key}
                  className="flex flex-row items-center mt-2 h-8 transform group transition-transform ease-in duration-500"
                >
                  <span className="inline-flex items-center justify-center h-12 w-12 text-lg">
                    {containsLinkpath(item.linkpath, path)
                      ? item.iconFilled
                      : item.icon}
                  </span>
                  <span className="text-sm group-hover:text-amber-300">
                    {item.name}
                  </span>
                </li>
              </SidebarLink>
            ),
        )}
      </ul>
    </div>
  )

  return (
    <>
      <ExpiredPlan />

      <div className="flex">
        <nav>{drawer}</nav>

        <div className="flex flex-col w-full">
          <div className="sticky top-0 z-10 flex flex-row items-center pl-6 pr-6 py-1 bg-white shadow-md">
            <div className="flex flex-shrink-0 items-center ml-auto">
              <button
                className="rounded-md text-white px-6 py-2 bg-blue-600 mr-2 font-bold"
                onClick={() => setIsUpgradeModalOpen(true)}
              >
                Upgrade Now
              </button>
              <BusinessSelector
                Allbusinesses={Allbusinesses}
                active_business={active_business}
                switchBusiness={switchBusiness}
                lbUserId={lbUserId}
              />
              <Menu as="div" className="relative">
                <div>
                  <Menu.Button className="inline-flex items-center p-2 hover:bg-gray-100 focus:bg-gray-100 rounded-lg">
                    <span className="sr-only">User Menu</span>
                    {renderProfileImage}
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none cursor-pointer">
                    <Menu.Item>
                      {({ active }) => (
                        <Link
                          to="/profile"
                          href="#"
                          className={classNames(
                            active ? "bg-gray-100" : "",
                            "block px-4 py-2 text-sm text-gray-700",
                          )}
                        >
                          Profile
                        </Link>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <span
                          onClick={logoutHandler}
                          href="#"
                          className={classNames(
                            active ? "bg-gray-100" : "",
                            "block px-4 py-2 text-sm text-gray-700",
                          )}
                        >
                          Logout
                        </span>
                      )}
                    </Menu.Item>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>

          <main className={"pl-6 pr-6 py-3 bg-[#f5f9ff] h-full"}>
            {loading ? (
              <div className="h-screen flex items-center justify-center">
                <Spinner className="w-8 h-8" />
                <div className="text-base ml-1 text-[#aaa]">
                  Loading details
                </div>
              </div>
            ) : (
              props.children
            )}
            <Dialog
              fullScreen={fullScreen}
              open={openModal}
              TransitionComponent={TransitionForDialog}
              keepMounted
              onClose={handleCloseModal}
              aria-labelledby="alert-dialog-slide-title"
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogTitle id="alert-dialog-slide-title">
                {"Add New Business"}
              </DialogTitle>
              <form
                onSubmit={handleSubmit}
                className="complete-registration-form"
                id="form-business"
              >
                <DialogContent>
                  <Grid
                    container
                    spacing={3}
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    style={{ minHeight: "60vh" }}
                  >
                    <Grid item xs={12}>
                      <h1 className="registration-heading">
                        Add New Business{" "}
                      </h1>
                      <Paper className="">
                        <Typography
                          component="h5"
                          variant="h5"
                          style={{ paddingLeft: "15px" }}
                        >
                          Business
                        </Typography>
                        <Grid container direction="row" spacing={3}>
                          <Grid item xs={12}>
                            <FormControl error={businessError ? true : false}>
                              <InputLabel id="business-label">
                                Select Business
                              </InputLabel>
                              <Select
                                defaultValue={""}
                                fullWidth
                                value={business}
                                onChange={(event) =>
                                  setBusiness(event.target.value)
                                }
                              >
                                <MenuItem value="">
                                  --Select Business--
                                </MenuItem>
                                {addBusinessType === "restaurant" ? (
                                  <MenuItem value={"restaurant"}>
                                    Restaurant
                                  </MenuItem>
                                ) : addBusinessType === "manufacturer" ? (
                                  <MenuItem value={"manufacturer"}>
                                    Packaged Food Manufacturer
                                  </MenuItem>
                                ) : (
                                  <span>
                                    <MenuItem value={"restaurant"}>
                                      Restaurant
                                    </MenuItem>
                                    <MenuItem value={"manufacturer"}>
                                      Packaged Food Manufacturer
                                    </MenuItem>
                                  </span>
                                )}
                              </Select>
                              <FormHelperText>
                                {businessError ? "Select Your business" : ""}
                              </FormHelperText>
                            </FormControl>
                          </Grid>
                        </Grid>
                        <Typography
                          component="h5"
                          variant="h5"
                          style={{ paddingLeft: "15px" }}
                        >
                          {business === "restaurant"
                            ? "Restaurant Details"
                            : "Company Details"}
                        </Typography>
                        <Grid container direction="row" spacing={3}>
                          <Grid item xs={6}>
                            <TextField
                              error={companyNameError ? true : false}
                              helperText={
                                companyNameError
                                  ? "Company Name is mandatory"
                                  : ""
                              }
                              type="text"
                              label={
                                business === "restaurant"
                                  ? "* Restaurant Name"
                                  : "* Company Name"
                              }
                              name="company_name"
                              className="verify-input"
                              id="company-name"
                              fullWidth
                              value={company_name || ""}
                              onChange={(event) =>
                                setCompanyName(event.target.value)
                              }
                            />
                          </Grid>
                          {business === "restaurant" ? (
                            <Grid item xs={6}>
                              <TextField
                                error={organizationNameError ? true : false}
                                helperText={
                                  organizationNameError
                                    ? "Organization Name is mandatory"
                                    : ""
                                }
                                type="text"
                                label="* Organization Name"
                                fullWidth
                                value={organization_name || ""}
                                onChange={(e) =>
                                  setOrganizationName(e.target.value)
                                }
                              />
                            </Grid>
                          ) : null}
                          <Grid item xs={6}>
                            <FormControl
                              error={licenceTypeError ? true : false}
                            >
                              <InputLabel id="licence-type-label">
                                Licence type
                              </InputLabel>
                              <Select
                                defaultValue={""}
                                name="licence_type"
                                fullWidth
                                value={licence_type || ""}
                                onChange={(event) =>
                                  setLicenceType(event.target.value)
                                }
                              >
                                <MenuItem value="">FSSAI Licence Type</MenuItem>
                                <MenuItem value={"state"}>State</MenuItem>
                                <MenuItem value={"central"}>Central</MenuItem>
                              </Select>
                              <FormHelperText>
                                {licenceTypeError
                                  ? "FSSAI Licence Type is mandatory"
                                  : ""}
                              </FormHelperText>
                            </FormControl>
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              error={
                                licenceTypeError
                                  ? true
                                  : license_error
                                  ? true
                                  : false
                              }
                              helperText={
                                licenceTypeError
                                  ? "Licence Type is mandatory"
                                  : license_error
                                  ? "FSSAI License number is invalid"
                                  : ""
                              }
                              type="text"
                              label="* FSSAI Licence no"
                              fullWidth
                              id="fssai-licence"
                              className="verify-input"
                              value={fssai_licence_no || ""}
                              onChange={(event) =>
                                setFssaiLicenceNo(event.target.value)
                              }
                            />
                          </Grid>
                          {business === "restaurant" ? (
                            <Grid item xs={6}>
                              <TextField
                                error={numOutletError ? true : false}
                                type="number"
                                label="* No of outlets in India"
                                fullWidth
                                className="verify-input"
                                value={num_outlets || ""}
                                onChange={(e) => setNumOutlets(e.target.value)}
                              />
                            </Grid>
                          ) : null}
                        </Grid>
                        <Grid container direction="row" spacing={2}>
                          <Grid item xs={12}>
                            <Checkbox
                              checked={checked}
                              onChange={() => handleCheckBox("checkbox")}
                              inputProps={{ "aria-label": "primary checkbox" }}
                            />
                            I accept all{" "}
                            <Link to={"/terms-and-conditions"}>
                              terms and conditions
                            </Link>
                            , <Link to={"/privacy-policy"}>privacy policy</Link>
                            , <Link to={"/disclaimers"}>disclaimers</Link>.
                            <br />
                            <FormHelperText className="error-text">
                              {!checked
                                ? "Please accept term and conditions."
                                : ""}{" "}
                            </FormHelperText>
                          </Grid>
                          <Grid item xs={12}>
                            <Checkbox
                              checked={confirmed}
                              onChange={() => handleCheckBox("confirm")}
                              inputProps={{ "aria-label": "primary checkbox" }}
                            />{" "}
                            Please check and confirm all details and
                            supportings.
                            <FormHelperText className="error-text">
                              {!confirmed
                                ? "Please check confirm all details and supportings field."
                                : ""}{" "}
                            </FormHelperText>
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button color="secondary" type="submit" variant="contained">
                    submit
                  </Button>
                  <Button
                    onClick={handleCloseModal}
                    color="secondary"
                    variant="contained"
                  >
                    Close
                  </Button>
                </DialogActions>
              </form>
            </Dialog>
          </main>
          <footer className="flex bottom-0 shadow-md p-4 bg-white justify-between text-textgray font-medium">
            <div className="flex justify-around w-1/3">
              <Link
                to={{ pathname: "/", home: true }}
                onClick={() => analyticsEventHandler("Home")}
              >
                <span>Home</span>
              </Link>
              <div className="h-4 w-px bg-slate-500/20"></div>
              <Link
                to="/about-us"
                onClick={() => analyticsEventHandler("AboutUs")}
              >
                <span>About us</span>
              </Link>
              <div className="h-4 w-px bg-slate-500/20"></div>
              <Link
                to="/pricing"
                onClick={() => analyticsEventHandler("Pricing")}
              >
                <span>Pricing</span>
              </Link>
              <div className="h-4 w-px bg-slate-500/20"></div>
              <Link
                to={infoCenterUrl}
                onClick={() => analyticsEventHandler("Blogs")}
              >
                <span>Info Centre</span>
              </Link>
              <div className="h-4 w-px bg-slate-500/20"></div>
              <Link onClick={() => analyticsEventHandler("FAQs")} to="/faqs">
                <span>FAQs</span>
              </Link>
            </div>
            <div className="flex justify-around">
              <span>Copyright © All rights reserved </span>
              <div className="h-4 mx-2 w-px bg-slate-500/20"></div>
              <a className="text-whitehite" href="https://labelblind.com/">
                The Nutrition Alchemy
              </a>
            </div>
          </footer>
        </div>
      </div>
      {businessType === "Manufacturer" ? (
        <PricingModal
          open={isUpgradeModalOpen}
          onClose={() => setIsUpgradeModalOpen(false)}
        />
      ) : (
        <MLPricingModal
          open={isUpgradeModalOpen}
          onClose={() => setIsUpgradeModalOpen(false)}
        />
      )}
    </>
  )
}

export default withLastLocation(withRouter(Sidebar))
