import StarterPricingIcon from "../../assets/images/StarterPricingIcon.svg"
import BusinessPlanIcon from "../../assets/images/BusinessPlanIcon.svg"
import EnterprisePlanIcon from "../../assets/images/EnterprisePlanIcon.svg"

export const starterPlan = {
  ANALYZE_RECIPES: true,
  NUTRITION_INFORMATION: true,
  ALLERGENS_INDICATION: true,
  NUTRIENT_AND_HEALTH_TAGS: true,
  STANDARD_RECIPE_TEMPLATES: true,
  NUTRITION_AND_ALLERGEN_GUIDE: true,
  UNLIMITED_INGREDIENTS_DB: true,
  UNLIMITED_BASE_RECIPES: true,
  MENU_LABELLING_SOP: true,
  DUPLICATE_RECIPES: false,
  MENU_OPTICS: false,
  FOOD_TAGS: false,
  USER_ACCOUNTS: 1,
  BRANDS: 1,
  NUTRITION_FACTSHEET: false,
  ARCHIVING: false,
  FOP_INTEGRATION: false,
  POS_INTEGRATION: false,
  SUPPORT: "Email",
  ONBOARDING_TRAINING: false,
  REGULATORY_UPDATES: false,
  IMPORTED_PF_VALIDATION: false,
  HEALTH_MENU_VALIDATION: false,
}
export const businessPlan = {
  ANALYZE_RECIPES: true,
  NUTRITION_INFORMATION: true,
  ALLERGENS_INDICATION: true,
  NUTRIENT_AND_HEALTH_TAGS: true,
  STANDARD_RECIPE_TEMPLATES: true,
  NUTRITION_AND_ALLERGEN_GUIDE: true,
  UNLIMITED_INGREDIENTS_DB: true,
  UNLIMITED_BASE_RECIPES: true,
  MENU_LABELLING_SOP: true,
  DUPLICATE_RECIPES: true,
  MENU_OPTICS: true,
  FOOD_TAGS: true,
  USER_ACCOUNTS: 3,
  BRANDS: 2,
  NUTRITION_FACTSHEET: true,
  ARCHIVING: true,
  FOP_INTEGRATION: true,
  POS_INTEGRATION: true,
  SUPPORT: "Email",
  ONBOARDING_TRAINING: true,
  REGULATORY_UPDATES: true,
  IMPORTED_PF_VALIDATION: true,
  HEALTH_MENU_VALIDATION: true,
}

export const enterprisePlan = {
  ANALYZE_RECIPES: true,
  NUTRITION_INFORMATION: true,
  ALLERGENS_INDICATION: true,
  NUTRIENT_AND_HEALTH_TAGS: true,
  STANDARD_RECIPE_TEMPLATES: true,
  NUTRITION_AND_ALLERGEN_GUIDE: true,
  UNLIMITED_INGREDIENTS_DB: true,
  UNLIMITED_BASE_RECIPES: true,
  MENU_LABELLING_SOP: true,
  DUPLICATE_RECIPES: true,
  MENU_OPTICS: true,
  FOOD_TAGS: true,
  USER_ACCOUNTS: "Custom",
  BRANDS: "Unlimited",
  NUTRITION_FACTSHEET: true,
  ARCHIVING: true,
  FOP_INTEGRATION: true,
  POS_INTEGRATION: true,
  SUPPORT: "Email & Phone",
  ONBOARDING_TRAINING: true,
  REGULATORY_UPDATES: true,
  IMPORTED_PF_VALIDATION: true,
  HEALTH_MENU_VALIDATION: true,
}

export const plansJson = [
  {
    key: "Menu Labelling",
    values: [
      {
        key: "ANALYZE_RECIPES",
      },
      {
        key: "NUTRITION_INFORMATION",
      },
      {
        key: "ALLERGENS_INDICATION",
      },
      {
        key: "NUTRIENT_AND_HEALTH_TAGS",
      },
      {
        key: "STANDARD_RECIPE_TEMPLATES",
        exclusive: true,
      },
      {
        key: "NUTRITION_AND_ALLERGEN_GUIDE",
      },
    ],
  },
  {
    key: "Label Management System",
    values: [
      {
        key: "UNLIMITED_INGREDIENTS_DB",
        exclusive: true,
      },
      {
        key: "UNLIMITED_BASE_RECIPES",
        exclusive: true,
      },
      {
        key: "MENU_LABELLING_SOP",
      },
      {
        key: "DUPLICATE_RECIPES",
      },
      {
        key: "MENU_OPTICS",
      },
      {
        key: "FOOD_TAGS",
        exclusive: true,
      },
      {
        key: "USER_ACCOUNTS",
      },
      {
        key: "BRANDS",
      },
      {
        key: "NUTRITION_FACTSHEET",
      },
      {
        key: "ARCHIVING",
      },
    ],
  },
  {
    key: "Integrations",
    values: [
      {
        key: "FOP_INTEGRATION",
        exclusive: true,
      },
      {
        key: "POS_INTEGRATION",
        exclusive: true,
      },
    ],
  },
  {
    key: "Support",
    values: [
      {
        key: "SUPPORT",
      },
      {
        key: "ONBOARDING_TRAINING",
      },
      {
        key: "REGULATORY_UPDATES",
      },
      {
        key: "IMPORTED_PF_VALIDATION",
      },
      {
        key: "HEALTH_MENU_VALIDATION",
      },
    ],
  },
]

const data = {
  ANALYZE_RECIPES: true,
  NUTRITION_INFORMATION: true,
  ALLERGENS_INDICATION: true,
  NUTRIENT_AND_HEALTH_TAGS: true,
  STANDARD_RECIPE_TEMPLATES: true,
  NUTRITION_AND_ALLERGEN_GUIDE: true,
  UNLIMITED_INGREDIENTS_DB: true,
  UNLIMITED_BASE_RECIPES: true,
  MENU_LABELLING_SOP: true,
  DUPLICATE_RECIPES: false,
  MENU_OPTICS: false,
  FOOD_TAGS: false,
  USER_ACCOUNTS: 1,
  BRANDS: 1,
  NUTRITION_FACTSHEET: false,
  ARCHIVING: false,
  FOP_INTEGRATION: false,
  POS_INTEGRATION: false,
  SUPPORT: "Email",
  ONBOARDING_TRAINING: false,
  REGULATORY_UPDATES: false,
  IMPORTED_PF_VALIDATION: false,
  HEALTH_MENU_VALIDATION: false,
}

export const planDuration = {
  1: "Billed Monthly",
  3: "Billed Quaterly",
  6: "Billed Half Yearly",
  12: "Billed Yearly",
}

export function defaultStarterPlanDetails(authenticated) {
  return {
    icon: StarterPricingIcon,
    name: "",
    recipes: "",
    description:
      "SME food service establishments, single or limited location, need quick labelling, limited menu items.",
    pricing: "",
    duration: "",
    additional: "plus, Rs 5000 annual Cloud Set-up",
    ctaText: authenticated ? "Upgrade Now" : "Start Free Trial",
    ctaClass: "bg-[#B8CC3F] w-full flex justify-center align-center",
    id: "",
    onClick: () => {},
    features: {},
  }
}

export const defaultBusinessPlanDetails = (authenticated) => ({
  icon: BusinessPlanIcon,
  name: "Business Plan",
  recipes: "500 Recipes",
  description: "Multi-location, multi-cuisine, larger number of menu items.",
  pricing: "7500 / mth",
  duration: "Billed Half Yearly",
  additional: "plus, Rs 5000 annual Cloud Set-up",
  ctaText: authenticated ? "Upgrade Now" : "Start Free Trial",
  ctaClass: "bg-[#3F48CC] w-full flex justify-center align-center",
  id: "123456",
  onClick: () => {},
  features: businessPlan,
})

export const defaultEnterprisePlanDetails = (authenticated) => ({
  icon: EnterprisePlanIcon,
  name: "Enterprise Plan",
  recipes: "Unlimited Recipes",
  description:
    "Large establishment, multi-city, multi-restaurant, customized needs.",
  pricing: "Custom Pricing",
  duration: "Billed Monthly",
  additional: "plus, Rs 9500 annual Cloud Set-up",
  ctaText: authenticated ? "Contact Us" : "Start Free Trial",
  ctaClass: "bg-[#DF6F13] w-full flex justify-center align-center",
  id: "123456",
  onClick: () => {},
  features: enterprisePlan,
})
