export const fetchMessages = async () => {
  return [
    "Checking your label artwork",
    "Running FoLSol®-AI algorithms on your label",
    "Validating label for compliance",
    "A label has over 70 checkpoints for compliance",
    "Checking Name of Food and Category",
    "Checking Ingredients and category-specific Additives",
    "Checking Nutrition Information and RDAs",
    "Checking Manufacturer, Packer, Marketer Information and License nos.",
    "Checking Instructions of Use and Storage Instructions",
    "Checking Net Weight, MRP and USP",
    "Checking FSSAI Logo, Veg or Non Veg logo, Organic Logo, Vegan Logo",
    "Did you know - 50% of cases of violation of regulations is due to mislabelling",
    "Did you know - Companies spend upto 3-4 weeks on an average to validate and finalise one food label",
    "The first FoLSol® client is the world’s largest coffeehouse chain",
    "The first FoLSol®-AI client is the world’s second largest food and beverage brand",
    "FoLSol®-AI validates a food label in 60 seconds !",
    "FoLSol®-AI minimises human effort by 50%, leaving you to only run your eyes through the check",
    "FoLSol®-AI algorithms have been trained on 1000s of food labels using OCR and neural networks",
    "Food Labelling Regulations are not few but a combination of nearly 20 guidelines",
    "Export Regulations are stringent about Allergens as a major concern for consumer health and safety.",
    "Major reason for product rejection overseas is the use of non-permissible ingredients as per the regulations of the importing country.",
    "99% business organisations are using AI to improve workflow processes",
    "Food Labelling in India is 70 years old",
    "Global Food Labelling has a history of over 120 years",
    "FoLSol®-AI label validation report coming up shortly …",
  ]
}
