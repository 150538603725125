export const COLORS = {
  grey: {
    n1: "#212933",
    n2: "#424F61",
    n3: "#5F7188",
    n4: "#788599",
    n5: "#8E9AAC",
    n6: "#B4BDCA",
    n6a: "#CCCCCC",
    n7: "#CCD4DC",
    n8: "#E7ECF2",
    n9: "#F7F9FF",
    n10: "#f8f8f8",
    n11: "#fafafa",
    n12: "#F5F5F5",
  },
  black: {
    dark: "#000000",
    matteblack: "#28282B",
    jetblack: "#343434",
    charcoal: "#36454F",
    onyx: "#353935",
  },
  gold: {
    darkest: "#f5d000",
    darker: "#ffdb0f",
    dark: "#ffd700",
    light: "#ffe02e",
    lighter: "#ffe44d",
    lightest: "#fafcdc",
    chip: "#ffb74d",
    pendingChip: "#ad8910",
  },
  green: {
    darkest: "#43A047",
    darker: "#4CAF50",
    dark: "#81C784",
    light: "#A5D6A7",
    lighter: "#C8E6C9",
    lightest: "#E8F5E9",
  },
  yellow: {
    darkest: "#FDD835",
    darker: "#FFEB3B",
    dark: "#FFEE58",
    light: "#FFF59D",
    lighter: "#FFF9C4",
    lightest: "#fcfbed",
  },
  red: {
    darkest: "#C62828",
    darker: "#E53935",
    dark: "#F44336",
    light: "#E57373",
    lighter: "#FFCDD2",
    lightest: "#FFEBEE",
  },
  ascent: {
    snow: "#FBFBFB",
    red: "#d0021b",
    danger: "#BF2828",
    green: "#24DBB5",
    blue: "#0088FF",
    yellow: "#f5a623",
    callButtonGreen: "#70d601",
    orange: "#FF8100",
    lightGreen: "#7ED321",
    asterick: "#ff0000",
    tableBorder: "#e0e0e0",
  },
  bg: {
    white: "#FFFFFF",
    light: "#F8F8FE",
    dark: "#000000",
    blue: "#f3f7fc",
  },
}
