import React, { useEffect, useState } from "react"
import { Route, Redirect } from "react-router-dom"
import { useSelector } from "react-redux"
import ApiServices from "../services/ApiServices"
import { createUserRole } from "../services/ApiServicesv2"
import { toast } from "react-toastify"

const InviteRoute = ({ component: Component, ...rest }) => {
  const authenticated = useSelector((state) => state.login.authenticated)
  const resetPassword = useSelector((state) => state.login.resetPassword)
  const lbUserId = useSelector((state) => state.login.lbUserId)
  const businessId = useSelector((state) => state.login.active_business)
  const permission = useSelector((state) => state.login.role)

  const admin = "Admin"
  const manager = "Manager"

  useEffect(async () => {
    await getUserBusinessMapping()
    // const payload = {
    //   business_id: "8580af16-1619-456c-83e5-591311f766da",
    //   business_name: "Test Manufacturer",
    //   lbUserId: "77e4b4d9-e422-4627-9058-66e69175ccec",
    //   email: "surajk.labelblind@gmail.com",
    //   role: "Admin",
    //   send_email: false,
    //   request_type: "signup",
    // }
    // console.log(payload)

    // const result = await createUserRole(payload)

    // if (result?.code !== 200) {
    //   toast.error(result?.message)
    // }
  }, [])

  const getUserBusinessMapping = async () => {
    // if (hasPermission(permission, admin.permission)) {
    //   setPermission("Admin")
    // }
    // if (hasPermission(businessMappData.result.items[0].permission, Manager.permission)) {
    //   setPermission("Manager")
    // }
  }

  // const user = { permission: props.permission };

  const hasPermission = (permissions, user_permissions) => {
    if (user_permissions === permissions) {
      return true
    } else {
      return false
    }
  }

  return (
    <Route
      {...rest}
      component={(props) => {
        if (resetPassword) {
          ;<Redirect to="/reset-password" />
        } else if (authenticated) {
          if (hasPermission(permission, admin)) {
            return <Component {...props} />
          } else if (hasPermission(permission, manager)) {
            return <Component {...props} />
          } else if (permission.length === 0) {
            return <Component {...props} />
          } else {
            return <Redirect to="/" />
          }
        } else {
          return <Redirect to="/login" />
        }
      }}
    />
  )
}

export default InviteRoute
