import Amplify, { Auth, API, Storage } from "aws-amplify"
import config from "../config"
import { unixTimestampToReadableDatetime } from "../Utils/dateTime"
import moment from "moment"
import {
  getAllLabelsByBusinessIdV2,
  getAllBrandsByBusinessId,
} from "../services/ApiServicesv2"
import {
  uploadPDFFileS3,
  getFileFomS3,
  uploadImageS3,
  getImageS3,
  deleteFileFromS3,
} from "./fileUpload"

let AWS = require("aws-sdk")

const cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider({
  apiVersion: "2016-04-19",
  region: "ap-south-1",
})

Amplify.configure(config.AWS_EXPORTS)

const apis = {
  USER_MANAGEMENT: "UserManagement",
  BUSINESS_MANAGEMENT: "BusinessManagement",
  CONTENT_MANAGEMENT: "ContentManagement",
  FOOD_MANAGEMENT: "FoodManagement",
  ITEM_MANAGEMENT: "ItemsManagement",
  NOTIFICATION_MANAGEMENT: "NotificationManagement",
  PAYMENT_MANAGEMENT: "PaymentManagement",
  AMPLITUDE: "AmplitudeManagement",
  PNL_MANAGEMENT: "PnlManagement",
}

let recipePromise
const apiFunction = {
  signUp: async ({ username, password, email, phone_number, name }) => {
    try {
      const { user } = await Auth.signUp({
        username: phone_number,
        password,
        attributes: {
          phone_number,
          email,
          name,
        },
        // attributes: {
        //   email,
        //   phone_number,
        //   name,
        //   // 'custom:lbUserid': uuidv4(),
        //   "custom:lb_user_type": "customers",
        // },
      })
      return user
    } catch (error) {
      return error
    }
  },

  signIn: async ({ username, password }) => {
    try {
      const user = await Auth.signIn(username, password)
      return user
    } catch (error) {
      return error
    }
  },

  signOut: async () => {
    try {
      await Auth.signOut()
    } catch (error) {
      console.log("error signing out: ", error)
    }
  },

  createUser: async (user) => {
    const apiName = apis.USER_MANAGEMENT
    const path = "/users"
    const postOptions = {
      body: { ...user },
    }
    const response = await API.post(apiName, path, postOptions)
    return response
  },

  getUsers: async () => {
    const apiName = apis.USER_MANAGEMENT
    const path = "/users"
    const response = await API.get(apiName, path)
    return response
  },

  getUser: async (lbUserId) => {
    const apiName = apis.USER_MANAGEMENT
    const path = "/users/" + lbUserId
    const response = await API.get(apiName, path)
    return response
  },

  updateUser: async (user) => {
    const apiName = apis.USER_MANAGEMENT
    const path = "/users/" + user.lbUserId
    const postOptions = {
      body: { ...user },
    }
    const response = await API.put(apiName, path, postOptions)
    return response
  },

  getUserBusinesses: async (lbUserId) => {
    const apiName = apis.USER_MANAGEMENT
    const path = "/users/" + lbUserId + "/businesses"
    const response = await API.get(apiName, path)
    return response
  },

  createUserBusinessMapping: async (creds) => {
    const apiName = apis.USER_MANAGEMENT
    const path = "/users/" + creds.lbUserId + "/businesses/" + creds.businessId
    const postOptions = {
      permission: ["Admin"],
    }
    const response = await API.post(apiName, path, postOptions)
    return response
  },

  updateUserBusinessMapping: async (creds) => {
    const apiName = apis.USER_MANAGEMENT
    const path = "/users/" + creds.lbUserId + "/businesses/" + creds.businessId
    const postOptions = {
      permission: ["Admin"],
      designation: "Developer",
    }
    const response = await API.put(apiName, path, postOptions)
    return response
  },
  getUserBusinessesMapping: async (input) => {
    const apiName = apis.USER_MANAGEMENT
    const path = "/users/" + input.lbUserId + "/businesses/" + input.businessId
    const response = await API.get(apiName, path)
    return response
  },

  addBusiness: async (business) => {
    const apiName = apis.BUSINESS_MANAGEMENT
    const path = "/businesses"
    const postOptions = {
      body: { ...business },
    }
    const response = await API.post(apiName, path, postOptions)
    return response
  },

  updateBusiness: async (postData) => {
    const apiName = apis.BUSINESS_MANAGEMENT
    const path = "/businesses/" + postData.businessId
    const postOptions = {
      body: { ...postData },
    }
    const response = await API.put(apiName, path, postOptions)
    return response
  },

  getBusinesses: async () => {
    const apiName = apis.BUSINESS_MANAGEMENT
    const path = "/businesses"
    const response = await API.get(apiName, path)
    return response
  },

  getAllContent: async () => {
    const apiName = apis.CONTENT_MANAGEMENT
    const path = "/content"
    const response = await API.get(apiName, path)
    return response
  },

  addContent: async (content) => {
    const apiName = apis.CONTENT_MANAGEMENT
    const path = "/content"
    const postOptions = {
      body: {
        ...content,
      },
    }
    const response = await API.post(apiName, path, postOptions)
    return response
  },

  getContent: async (input) => {
    const apiName = apis.CONTENT_MANAGEMENT
    const path = "/content/" + input.id + "/" + input.type
    const response = await API.get(apiName, path)
    return response
  },

  updateContent: async (input) => {
    const apiName = apis.CONTENT_MANAGEMENT
    const path = "/content/" + input.id + "/" + input.type
    const postOptions = {
      body: { ...input },
    }
    const response = await API.put(apiName, path, postOptions)
    return response
  },

  deleteContent: async (input) => {
    const apiName = apis.CONTENT_MANAGEMENT
    const path = "/content/" + input.id + "/" + input.type
    const response = await API.del(apiName, path)
    return response
  },

  getAllRecipe: async (query) => {
    const apiName = apis.FOOD_MANAGEMENT
    const path = "/recipes?" + (query ? query : "")
    const response = await API.get(apiName, path)
    return response
  },

  getAllRecipeByCategory: async (input) => {
    const apiName = apis.FOOD_MANAGEMENT
    const postOptions = {
      body: { ...input },
    }
    const path = "/foods"
    const response = await API.post(apiName, path, postOptions)
    return response
  },

  saveUserRecipe: async (input) => {
    const apiName = apis.ITEM_MANAGEMENT
    const path = "/business/" + input.businessId + "/recipes"
    const postOptions = {
      body: { ...input },
    }
    const response = await API.post(apiName, path, postOptions)
    return response
  },

  getUserRecipe: async (input) => {
    if (recipePromise) {
      API.cancel(recipePromise, "Cancelled previous request")
    }
    try {
      const apiName = apis.ITEM_MANAGEMENT
      const path = "/business/" + input.businessId + "/recipes"
      recipePromise = API.get(apiName, path)
      const response = await recipePromise
      return response
    } catch (error) {
      if (API.isCancel(error)) {
        console.log(error.message)
      }
    }
  },

  getRecipesPaginated: async (data) => {
    const apiName = apis.ITEM_MANAGEMENT
    const path = "/business/recipes-paginated"
    const postOptions = {
      body: data,
    }
    const response = await API.post(apiName, path, postOptions)

    return response
  },

  searchRecipes: async ({ limit, businessId, brandId, query }) => {
    const apiName = apis.ITEM_MANAGEMENT
    const path = "/business/search-recipes"
    const postOptions = {
      body: { limit, businessId, brandId, query },
    }
    const response = await API.post(apiName, path, postOptions)
    return response
  },

  createBrand: async (input) => {
    const apiName = apis.BUSINESS_MANAGEMENT
    const path = "/businesses/" + input.businessId + "/brands"
    const postOptions = {
      body: { ...input },
    }
    const response = await API.post(apiName, path, postOptions)
    return response
  },

  getBrandDetails: async (businessId) => {
    const apiName = apis.BUSINESS_MANAGEMENT
    const path = "/businesses/" + businessId + "/brands"
    const response = await API.get(apiName, path)
    return response
  },

  getUserRecipeDetails: async (input) => {
    if (!input.recipeId) return
    const apiName = apis.ITEM_MANAGEMENT
    const path = "/business/" + input.businessId + "/recipes/" + input.recipeId
    const response = await API.get(apiName, path)
    return response
  },

  getUserCalculatedRecipeDetails: async (input) => {
    const apiName = apis.ITEM_MANAGEMENT
    const path =
      "/business/" + input.businessId + "/calculate-recipe/" + input.recipeId
    const response = await API.get(apiName, path)
    return response
  },

  updateUserRecipe: async (input) => {
    if (!input.id) return
    const apiName = apis.ITEM_MANAGEMENT
    const path = "/business/" + input.businessId + "/recipes/" + input.id
    const postOptions = {
      body: { ...input },
      // headers: {
      //   lbuserid: lbUserId
      // }
    }
    const response = await API.put(apiName, path, postOptions)
    return response
  },

  getBusinessDetails: async (businessId) => {
    const apiName = apis.BUSINESS_MANAGEMENT
    const path = "/businesses/" + businessId
    const response = await API.get(apiName, path)
    return response
  },

  getBrandsDetails: async (businessId, brandId) => {
    const apiName = apis.BUSINESS_MANAGEMENT
    const path = "/businesses/" + businessId + "/brands/" + brandId
    const response = await API.get(apiName, path)
    return response
  },

  sendContactUsMail: async (input) => {
    const apiName = apis.NOTIFICATION_MANAGEMENT
    const path = "/notifications/"
    const postOptions = {
      body: { ...input },
    }
    const response = await API.post(apiName, path, postOptions)
    return response
  },

  sendReportDownloadMail: async (data) => {
    const apiName = apis.NOTIFICATION_MANAGEMENT
    const path = "/notifications/report-download"
    const postOptions = {
      body: { ...data },
    }
    const response = await API.post(apiName, path, postOptions)
    return response
  },

  sendHelpCenterMail: async (input) => {
    const apiName = apis.NOTIFICATION_MANAGEMENT
    const path = "/notifications/" + input.lbUserId
    const postOptions = {
      body: { ...input },
    }
    const response = await API.post(apiName, path, postOptions)
    return response
  },

  uploadToS3: async (file, s3_file_name) => {
    try {
      // let upload_response = await Storage.put(s3_file_name, file, {
      //   contentType: "application/pdf",
      //   progressCallback: (progress) => {
      //     console.log(`Uploaded: ${progress.loaded}/${progress.total}`)
      //   },
      // })
      const upload_response = await uploadPDFFileS3(file, s3_file_name)
      return upload_response
    } catch (err) {
      return err
    }
  },
  uploadFileToS3: async (file, s3_file_name) => {
    try {
      // let upload_response = await Storage.put(s3_file_name, file, {
      //   contentType: "application/pdf",
      //   progressCallback: (progress) => {
      //     console.log(`Uploaded: ${progress.loaded}/${progress.total}`)
      //   },
      // })
      // return upload_response
      const upload_response = await uploadPDFFileS3(file, s3_file_name)
      return upload_response
    } catch (err) {
      return err
    }
  },
  deleteFileFromS3: async (s3_file_name) => {
    try {
      // let delete_response = await Storage.remove(s3_file_name)
      const delete_response = await deleteFileFromS3(s3_file_name)
      return delete_response
    } catch (err) {
      return err
    }
  },

  uploadImagesToS3: async (file, s3_file_name) => {
    try {
      // let upload_response = await Storage.put(s3_file_name, file, {
      //   ACL: "public-read",
      //   contentType: "application/image",
      //   progressCallback: (progress) => {
      //     console.log(`Uploaded: ${progress.loaded}/${progress.total}`)
      //   },
      // })

      const upload_response = await uploadImageS3(file, s3_file_name)
      return upload_response
    } catch (err) {
      return err
    }
  },

  uploadUserToS3: async (file, s3_file_name) => {
    try {
      let upload_response = await Storage.put(s3_file_name, file, {
        contentType: "application/image",
        progressCallback: (progress) => {
          //console.log(`Uploaded: ${progress.loaded}/${progress.total}`)
        },
      })
      return upload_response
    } catch (err) {
      return err
    }
  },

  getFromS3: async (key) => {
    try {
      // let url = await Storage.get(key, { level: "public" })
      if (/\.(jpg|jpeg|svg|png|gif)$/.test(key)) {
        const url = await getImageS3(key)
        return url
      } else {
        const url = await getFileFomS3(key)
        return url
      }
    } catch (e) {
      console.log("S3 Error", e)
      return null
    }
  },

  downloadFromS3: async (key) => {
    try {
      let url = await Storage.get(key, { download: true })
      return url
    } catch (e) {
      return null
    }
  },

  createLabel: async (input) => {
    const apiName = apis.ITEM_MANAGEMENT
    const path = "/business/" + input.businessId + "/labels/"
    const postOptions = {
      body: { ...input },
    }
    const response = await API.post(apiName, path, postOptions)
    return response
  },

  // LABEL APIs
  getLabels: async (businessId) => {
    const apiName = apis.ITEM_MANAGEMENT
    const path = "/business/" + businessId + "/labels/"
    const response = await API.get(apiName, path)
    return response
  },

  getLabel: async (businessId, labelId) => {
    const apiName = apis.ITEM_MANAGEMENT
    const path = "/business/" + businessId + "/labels/" + labelId
    const response = await API.get(apiName, path)
    return response
  },

  updateLabel: async (input) => {
    const updated_on = unixTimestampToReadableDatetime(Date.now())
    const updatedInput = { ...input, updated_on }
    const apiName = apis.ITEM_MANAGEMENT
    const path =
      "/business/" + updatedInput.businessId + "/labels/" + updatedInput.id
    const postOptions = {
      body: updatedInput,
    }
    const response = await API.put(apiName, path, postOptions)
    return response
  },

  deleteLabel: async (input) => {
    const apiName = apis.ITEM_MANAGEMENT
    const path = "/business/" + input.businessId + "/labels/" + input.id
    const response = await API.del(apiName, path)
    return response
  },

  createQrCode: async (slug, businessId, labelId) => {
    const apiName = apis.PNL_MANAGEMENT
    const path = "/items/create-qrcode"
    const body = {
      slug,
      labelId,
      businessId,
    }

    const response = await API.post(apiName, path, {
      body,
    })
    return response
  },

  elasticSearch: async (input) => {
    const apiName = apis.FOOD_MANAGEMENT
    const path = "/foods/" + input.index + "/search"
    const postOptions = {
      body: input.keywords,
    }
    const response = await API.post(apiName, path, postOptions)
    return response
  },

  getAllElasticSearch: async (input) => {
    const apiName = apis.FOOD_MANAGEMENT
    const path = "/foods/" + input.index
    const response = await API.get(apiName, path)
    return response
  },

  getNutritionCalculation: async (input) => {
    const apiName = apis.FOOD_MANAGEMENT
    const path = "/foods/calculator"
    const postOptions = {
      body: input,
    }
    const response = await API.post(apiName, path, postOptions)
    return response
  },

  fuzzySearchFromElasticSearch: async (input) => {
    const apiName = apis.FOOD_MANAGEMENT
    const path = "/foods/" + input.index + "/fuzzy"
    const postOptions = {
      body: input.body,
    }
    const response = await API.post(apiName, path, postOptions)
    return response
  },
  // Get Ingredients From LB Elasticsearch
  SearchFromLBProduct: async (input) => {
    const apiName = apis.FOOD_MANAGEMENT
    const path = "/elasticsearch/lbrecords"
    const postOptions = {
      body: input,
    }
    const response = await API.post(apiName, path, postOptions)
    return response
  },
  sendCustomEMail: async (input) => {
    const apiName = apis.NOTIFICATION_MANAGEMENT
    const path = "/notifications/" + input.lbUserId + "/custom-mail"
    const postOptions = {
      body: { ...input },
    }
    const response = await API.post(apiName, path, postOptions)
    return response
  },

  // After Invite User Add to cognito
  addUserToCognito: async ({
    username,
    lbUserId,
    email,
    phone_number,
    name,
  }) => {
    var params = {
      UserPoolId: config.USER_POOL /* required */,
      Username: username /* required */,
      DesiredDeliveryMediums: ["EMAIL"],
      UserAttributes: [
        {
          Name: "email_verified" /* required */,
          Value: "true",
        },
        {
          Name: "email",
          Value: email,
        },
        {
          Name: "phone_number",
          Value: phone_number,
        },
        {
          Name: "name",
          Value: name,
        },
        {
          Name: "custom:lbUserid",
          Value: lbUserId,
        },
      ],
    }
    cognitoidentityserviceprovider.adminCreateUser(
      params,
      function (err, data) {
        if (err) {
          console.log(err, err.stack) // an error occurred
        } else {
          var groupParams = {
            GroupName: "customers" /* required */,
            UserPoolId: config.USER_POOL /* required */,
            Username: email /* required */,
          }
          cognitoidentityserviceprovider.adminAddUserToGroup(
            groupParams,
            function (err, data) {
              if (err) {
                console.log(err, err.stack) // an error occurred
              } else {
                return data
              }
            },
          )
          // console.log(data);           // successful response
          // return data;
        }
      },
    )
  },

  // After Invite User First Login change Password
  setCognitoUserPassword: async ({ email, password }) => {
    var params = {
      Password: password /* required */,
      UserPoolId: config.USER_POOL /* required */,
      Username: email /* required */,
      Permanent: true,
    }
    cognitoidentityserviceprovider.adminSetUserPassword(
      params,
      function (err, data) {
        if (err) {
          alert(err, err.stack)
          console.log(err, err.stack) // an error occurred
        } else {
          console.log(data) // successful response
          return data
        }
      },
    )
  },

  // Get Cognito User Details
  getCognitoUserDetails: async ({ email }) => {
    var params = {
      UserPoolId: config.USER_POOL /* required */,
      Username: email /* required */,
    }
    cognitoidentityserviceprovider.adminGetUser(params, function (err, data) {
      if (err) {
        console.log(err, err.stack) // an error occurred
      } else {
        console.log(data) // successful response
        return data
      }
    })
  },

  // Get user details
  getUserDetails: async (businessId) => {
    // TODO: Replace this function
    const response = await fetch(
      `${config.APIENDPOINT}/api/v2/permissions/${businessId}/list`,
    )
    const data = await response.json()

    return data
  },

  // edit user details
  editUserDetails: async (users) => {
    // TODO: Replace this function
    const apiName = apis.USER_MANAGEMENT
    const path = "/users/editInvitedUser"
    const postOptions = {
      body: { ...users },
    }
    const response = await API.post(apiName, path, postOptions)
    return response
  },

  inviteUser: async (input) => {
    // TODO: Replace this function
    const apiName = apis.USER_MANAGEMENT
    const path = "/invite"
    const postOptions = {
      body: { ...input },
    }
    const response = await API.post(apiName, path, postOptions)
    return response
  },

  sendEmailForRecipe: async (input) => {
    const apiName = apis.NOTIFICATION_MANAGEMENT
    const path = "/email/recipe/statusUpdateEmail"
    const postOptions = {
      body: { ...input },
    }
    const response = await API.post(apiName, path, postOptions)
    return response
  },

  checkExistedUser: async (emailId) => {
    // TODO: Replace this function
    const apiName = apis.USER_MANAGEMENT
    const path = "/invite/" + emailId
    const response = await API.get(apiName, path)
    return response
  },

  editBrands: async (data) => {
    const apiName = apis.BUSINESS_MANAGEMENT
    const path = "/businesses/" + data.businessId + "/brands/" + data.id
    const postOptions = {
      body: { ...data },
    }
    const response = await API.put(apiName, path, postOptions)
    return response
  },

  // remove brands from invited user
  removeBrandFromInvitedUser: async (data) => {
    // TODO: Replace this function
    const apiName = apis.BUSINESS_MANAGEMENT
    const path = "/businesses/" + data.businessId + "/brands/" + data.brandId
    const response = await API.delete(apiName, path)
    return response
  },

  logAmplitudeEvent: async (eventType, eventDetails, userDetails) => {
    const apiName = apis.AMPLITUDE
    const path = "/amplitude/create-log"
    const postOptions = {
      body: { eventDetails, userDetails, eventType },
    }
    const response = await API.post(apiName, path, postOptions)
    return response
  },

  getMerchantRecipeDetails: async (params) => {
    const apiName = apis.ITEM_MANAGEMENT
    const path = "/merchant-recipe/" + params.businessId + "/" + params.recipeId
    const response = await API.get(apiName, path)
    return response
  },

  updateMerchantRecipeDetails: async (params, data) => {
    const apiName = apis.ITEM_MANAGEMENT
    const path = "/merchant-recipe/" + params.businessId + "/" + params.recipeId
    const postOptions = {
      body: { ...data },
    }
    const response = await API.put(apiName, path, postOptions)
    return response
  },

  recipeDetails: async (data) => {
    const apiName = apis.ITEM_MANAGEMENT
    const path = "/business/" + data.businessId + "/recipes"
    const postOptions = {
      body: { ...data },
    }
    const response = await API.post(apiName, path, postOptions)
    return response
  },

  postMerchantSearchIngredient: async (data) => {
    const apiName = apis.ITEM_MANAGEMENT
    const path = "/merchant-recipe/search-ingredients"
    const postOptions = {
      body: { ...data },
    }
    const response = await API.post(apiName, path, postOptions)
    return response
  },

  downloadRecipes: async (data) => {
    const apiName = apis.ITEM_MANAGEMENT
    const path = "/business/bulk-recipes"
    const postOptions = {
      body: data,
    }
    const response = await API.post(apiName, path, postOptions)

    return response
  },
  downloadRecipesUsingWorker: async (data) => {
    const apiName = apis.ITEM_MANAGEMENT
    const path = "/business/bulk-recipes-using-worker"
    const postOptions = {
      body: data,
    }
    const response = await API.post(apiName, path, postOptions)

    return response
  },

  getBatchUser: async (data) => {
    const apiName = apis.USER_MANAGEMENT
    const path = "/users/allUsers"
    const postOptions = {
      body: data,
    }
    const response = await API.post(apiName, path, postOptions)
    return response
  },

  getBatchBrandsDetails: async (data) => {
    const apiName = apis.BUSINESS_MANAGEMENT
    const path = "/businesses/allBrands"
    const postOptions = {
      body: data,
    }
    const response = await API.post(apiName, path, postOptions)
    // Filter all brands which are not deleted
    response.result = response?.result?.items?.filter(
      (v) => v.status == "ACTIVE",
    )
    return response
  },

  sendRegisteredUserInviteEmail: async (inputs) => {
    const apiName = apis.NOTIFICATION_MANAGEMENT
    const path = "/email/inviteMail"
    const postOptions = {
      body: inputs,
    }
    const response = await API.post(apiName, path, postOptions)
    return response
  },

  fetchStandardRecipe: async (recipeId) => {
    const apiName = apis.ITEM_MANAGEMENT
    const path = `/business/standard-recipe/${recipeId}`
    const response = await API.get(apiName, path)
    return response
  },

  createBulkRecipes: async (inputs) => {
    const apiName = apis.ITEM_MANAGEMENT
    const path = "/business/create-recipes"
    const postOptions = {
      body: { recipes: inputs },
    }
    const response = await API.post(apiName, path, postOptions)
    return response
  },

  getIncompletedRecipes: async (inputs) => {
    const apiName = apis.ITEM_MANAGEMENT
    const path = "/business/incompleted-recipe"
    const postOptions = {
      body: inputs,
    }
    const response = await API.post(apiName, path, postOptions)
    return response
  },

  getAllRecipeContent: async () => {
    const apiName = apis.CONTENT_MANAGEMENT
    const path = "/content/recipe-categories"
    const response = await API.get(apiName, path)
    return response
  },

  getAllRecipeContentByType: async (type) => {
    const apiName = apis.CONTENT_MANAGEMENT
    const path = "/content/categories/" + type
    const response = await API.get(apiName, path)

    return response
  },

  revokePermission: async (inputs) => {
    // TODO: Replace this function
    const { permission_id, ...body } = inputs

    const response = await fetch(
      `${config.APIENDPOINT}/api/v2/permissions/${permission_id}`,
      {
        method: "PUT",
        body: JSON.stringify(body),
        headers: {
          "Content-Type": "application/json",
        },
      },
    )
    const data = await response.json()
    return data
  },

  createBrands: async (input) => {
    const apiName = apis.BUSINESS_MANAGEMENT
    const path = "/businesses/create-brands"
    const postOptions = {
      body: { ...input },
    }
    const response = await API.post(apiName, path, postOptions)
    return response
  },

  addAddress: async (input) => {
    const apiName = apis.BUSINESS_MANAGEMENT
    const path = `/businesses/${input.businessId}/create-address`
    const postOptions = {
      body: { ...input },
    }
    const response = await API.post(apiName, path, postOptions)
    return response
  },

  editAddress: async (input) => {
    const apiName = apis.BUSINESS_MANAGEMENT
    const path = `/businesses/${input.businessId}/update-address/${input.addressId}`
    const postOptions = {
      body: { ...input },
    }
    const response = await API.put(apiName, path, postOptions)
    return response
  },

  deleteAddress: async (businessId, addressId) => {
    const apiName = apis.BUSINESS_MANAGEMENT
    const path = `/businesses/${businessId}/update-address/${addressId}`
    const postOptions = {
      body: { status: "DELETED" },
    }
    const response = await API.put(apiName, path, postOptions)
    return response
  },

  getAddresses: async (businessId) => {
    const apiName = apis.BUSINESS_MANAGEMENT
    const path = `/businesses/${businessId}/get-addresses`
    const response = await API.get(apiName, path)
    return response
  },

  getAddress: async (businessId, addressId) => {
    const apiName = apis.BUSINESS_MANAGEMENT
    const path = `/businesses/${businessId}/get-address/${addressId}`
    const response = await API.get(apiName, path)
    return response
  },

  createPnl: async (inputs) => {
    const apiName = apis.PNL_MANAGEMENT
    const path = "/items/v2/create-pnl"
    const postOptions = {
      body: { ...inputs },
    }
    const response = await API.post(apiName, path, postOptions)
    return response
  },

  updatePnl: async (inputs) => {
    const apiName = apis.PNL_MANAGEMENT
    const path = "/items/v2/update-pnl"
    const postOptions = {
      body: { ...inputs },
    }
    const response = await API.put(apiName, path, postOptions)
    return response
  },

  getAllPnl: async (businessId) => {
    const apiName = apis.PNL_MANAGEMENT
    const path = `/items/v2/get-pnl/${businessId}`
    const response = await API.get(apiName, path)
    return response
  },

  getPnlRecord: async (businessId, labelId, brandId) => {
    const apiName = apis.PNL_MANAGEMENT
    const path = `/items/v2/pnl/${businessId}/${labelId}`
    const response = await API.get(apiName, path)
    return response
  },

  setPrefereedMFA: async (email, mfa) => {
    try {
      const apiName = apis.PNL_MANAGEMENT
      const path = "/pnl/disableMFA"
      const postOptions = {
        body: { email: email, mfa: mfa },
      }
      const response = await API.post(apiName, path, postOptions)
      return response
    } catch (err) {
      console.log(err)
    }
  },

  getGuideRecipes: async (inputs) => {
    try {
      const apiName = apis.ITEM_MANAGEMENT
      const path = "/business/get-recipes"
      const postOptions = {
        body: { ...inputs },
      }
      const response = await API.post(apiName, path, postOptions)
      return response
    } catch (err) {
      console.log(err)
    }
  },
}

export async function getBrands(
  superAdminAccess,
  userType,
  businessId,
  lbUserId,
) {
  let brands = []
  if (userType === "Admin" || userType === "Manager" || userType === "User") {
    const brandDetails = await getAllBrandsByBusinessId(businessId)
    brands = brandDetails?.data
  } else {
    const businessMappings = await apiFunction.getUserBusinesses(
      lbUserId,
      businessId,
    )

    if (businessMappings?.result?.items?.length > 0) {
      let brandIds = []
      businessMappings?.result?.items.map((brand) => {
        if (brand.businessId !== businessId) {
          brandIds.push({ businessId: businessId, brandId: brand.businessId })
        }
      })
      if (brandIds?.length > 0) {
        let iterator = Math.ceil(brandIds?.length / 100)
        let result = []
        for (let i = 0; i < iterator; i++) {
          let brandData = await apiFunction.getBatchBrandsDetails({
            businessData: brandIds.slice(i * 100, (i + 1) * 100),
          })
          result.push(...brandData?.result)
        }
        brands = result
      }
    }
  }

  return brands
}

export const getAccessToken = async () => {
  const currentSession = await Auth.currentSession()
  const accessToken = currentSession.getAccessTokenV2()

  return accessToken.getJwtToken()
}

export const getAccessTokenV2 = async () => {
  return window.localStorage.getItem("Access_Token")
}

async function getCommonHeaders() {
  const accessToken = await getAccessTokenV2()
  return {
    Authorization: `Bearer ${accessToken}`,
    "Content-Type": "application/json",
  }
}

export const createRecipeVersion = async (recipeId, changelog, value) => {
  let accessToken = await getAccessTokenV2()

  let res = await fetch(`${config.APIENDPOINT}/api/recipes`, {
    method: "POST",
    body: JSON.stringify({
      recipeId,
      changelog,
      data: value,
    }),
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
  })
  let response = await res.json()

  return response.result
}

export const fetchRecipeVersions = async (recipeId) => {
  if (!recipeId) return []
  let accessToken = await getAccessTokenV2()

  let res = await fetch(`${config.APIENDPOINT}/api/recipes/${recipeId}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
  let response = await res.json()

  return response.result
}

export const fetchLatestRecipeVersion = async (recipeId) => {
  let accessToken = await getAccessTokenV2()

  let res = await fetch(
    `${config.APIENDPOINT}/api/recipes/${recipeId}/latest`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  )
  let response = await res.json()

  return response.result
}

export const createGuide = async (data) => {
  let accessToken = await getAccessTokenV2()

  let res = await fetch(`${config.APIENDPOINT}/api/guides`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
  })
  let response = await res.json()

  return response.result
}

export const getAllGuides = async () => {
  let accessToken = await getAccessTokenV2()

  let res = await fetch(`${config.APIENDPOINT}/api/guides`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
  let response = await res.json()

  return response.result
}

export const getGuide = async (id) => {
  try {
    let accessToken = await getAccessTokenV2()

    let res = await fetch(`${config.APIENDPOINT}/api/guides/${id}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
    let response = await res.json()

    return response.result
  } catch (e) {
    console.error(e)
    return []
  }
}

export const duplicateGuide = async (id) => {
  try {
    let accessToken = await getAccessTokenV2()

    let res = await fetch(`${config.APIENDPOINT}/api/guides/duplicate`, {
      method: "POST",
      body: JSON.stringify({
        id,
      }),
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    })
    let response = await res.json()

    return response.result
  } catch (e) {
    console.error(e)
    return []
  }
}

export const enableGuide = async (id) => {
  try {
    let accessToken = await getAccessTokenV2()

    let res = await fetch(`${config.APIENDPOINT}/api/guides/${id}/enable`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
    let response = await res.json()

    return response.result
  } catch (e) {
    console.error(e)
    return []
  }
}

export const updateGuide = async (id, data) => {
  console.log(data)
  let accessToken = await getAccessTokenV2()

  let res = await fetch(`${config.APIENDPOINT}/api/guides/${id}`, {
    method: "PUT",
    body: JSON.stringify({
      brandId: data.brandId,
      name: data.name,
      sections: data.sections,
      recipesMapping: data.recipesMapping,
      state: data.state,
      slug: data.slug,
    }),
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
  })
  let response = await res.json()

  return response.result
}

export const deleteGuide = async (id) => {
  let accessToken = await getAccessTokenV2()

  let res = await fetch(`${config.APIENDPOINT}/api/guides/${id}`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
  let response = await res.json()

  return response.result
}

export const getAllFoodTags = async () => {
  if (!config.DISABLE_FOOD_TAGS) {
    let accessToken = await getAccessTokenV2()

    let res = await fetch(`${config.APIENDPOINT}/api/buffet-tag`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
    let response = await res.json()

    return response.result
  }
}

export const getBuffet = async (id) => {
  try {
    if (!config.DISABLE_FOOD_TAGS) {
      let accessToken = await getAccessTokenV2()

      let res = await fetch(`${config.APIENDPOINT}/api/buffet-tag/${id}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      let response = await res.json()

      return response.result
    }
  } catch (e) {
    console.error(e)
    return []
  }
}

export const createBuffet = async (data) => {
  if (!config.DISABLE_FOOD_TAGS) {
    let accessToken = await getAccessTokenV2()

    let res = await fetch(`${config.APIENDPOINT}/api/buffet-tag`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    })
    let response = await res.json()

    return response.result
  }
}

export const updateBuffet = async (id, data) => {
  if (!config.DISABLE_FOOD_TAGS) {
    let accessToken = await getAccessTokenV2()

    let res = await fetch(`${config.APIENDPOINT}/api/buffet-tag/${id}`, {
      method: "PUT",
      body: JSON.stringify({
        brandId: data.brandId,
        name: data.name,
        sections: data.sections,
        recipesMapping: data.recipesMapping,
      }),
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    })
    let response = await res.json()

    return response.result
  }
}

export const deleteBuffet = async (id) => {
  if (!config.DISABLE_FOOD_TAGS) {
    let accessToken = await getAccessTokenV2()

    let res = await fetch(`${config.APIENDPOINT}/api/buffet-tag/${id}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
    let response = await res.json()

    return response.result
  }
}

export const createMealCourse = async (input) => {
  let accessToken = await getAccessTokenV2()
  const res = await fetch(`${config.APIENDPOINT}/api/meal-course`, {
    method: "POST",
    body: JSON.stringify(input),
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
  })
  const response = await res.json()

  return response
}

export const getMealCourseByBrandId = async (brandid) => {
  const accessToken = await getAccessTokenV2()

  const res = await fetch(
    `${config.APIENDPOINT}/api/meal-course/get-by-brand/${brandid}`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  )
  const response = await res.json()

  return response.result
}
export const getMealCourseByBusinessId = async (businessId) => {
  const accessToken = await getAccessTokenV2()

  const res = await fetch(
    `${config.APIENDPOINT}/api/meal-course/business/${businessId}`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  )
  const response = await res.json()

  return response
}

export const updateMealCourse = async (mealCourseId, input) => {
  let accessToken = await getAccessTokenV2()

  const res = await fetch(
    `${config.APIENDPOINT}/api/meal-course/${mealCourseId}`,
    {
      method: "PUT",
      body: JSON.stringify(input),
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    },
  )
  const response = await res.json()

  return response.result
}

export const fetchAllPlans = async () => {
  const res = await fetch(`${config.APIENDPOINT}/api/plans`, {
    method: "GET",
  })
  const response = await res.json()
  return response.result
}

export const fetchPlanDetails = async (planId) => {
  const res = await fetch(`${config.APIENDPOINT}/api/plans/${planId}`, {
    method: "GET",
  })
  const response = await res.json()
  return response.result
}

export const createStripeCustomer = async (customer) => {
  const token = await getAccessTokenV2()

  const body = {
    ...customer,
    payment_method: [],
    description: "",
  }

  await fetch(`${config.APIENDPOINT}/api/stripe/create-customers`, {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
}

function extractBusinessId() {
  const raw_data = JSON.parse(localStorage.getItem("persist:root") ?? "{}")
  const { login } = raw_data
  const { active_business } = JSON.parse(login)
  return active_business
}

export function getBusinessIdFromLocalStorage() {
  const business_details = JSON.parse(
    localStorage.getItem("persist:root") ?? "{}",
  )
  const { login } = business_details
  const { active_business } = JSON.parse(login)

  return active_business
}

export async function getStripeCustomer(business_id = "") {
  try {
    const headers = await getCommonHeaders()

    const businessId = business_id || getBusinessIdFromLocalStorage()

    if (!businessId) return null

    let raw = await fetch(
      `${config.APIENDPOINT}/api/stripe/get-customer?business_id=${businessId}`,
      { headers },
    )
    let response = await raw.json()

    return response.result
  } catch (e) {
    console.error(e)

    return {}
  }
}

export default apiFunction
