import React, { useState, useEffect } from "react"
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
// import DialogTitle from '@material-ui/core/DialogTitle';
import { Link } from "react-router-dom"
import ApiServices from "../../services/ApiServices"
import { useSelector } from "react-redux"

const ExpiredPlan = () => {
  const businessId = useSelector((state) => state.login.active_business)
  const [open, setOpen] = useState(false)
  const lbUserId = useSelector((state) => state.login.lbUserId)
  const businessList = useSelector((state) => state.login.allBusinesses)

  useEffect(() => {
    async function fetch() {
      const today = new Date()
      const businesses = businessList?.filter(
        (ele) => ele.businessId === businessId,
      )
      let createdAtDate
      if (businesses?.result?.items?.length > 0) {
        const date = businesses?.result?.items[0]?.expiryDate
        createdAtDate = new Date(date)
        if (date) {
          if (createdAtDate.getTime() < today.getTime()) {
            setOpen(true)
          }
        } else {
          const result = await ApiServices.getUser(lbUserId)
          createdAtDate = new Date(result?.result?.items[0]?.createdAt)
          if (createdAtDate.getTime() < today.getTime()) {
            setOpen(true)
          }
        }
      }
    }
    fetch()
  }, [])

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {/* <DialogTitle id="alert-dialog-title">{""}</DialogTitle> */}
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <p>
            You have no any active plans. To activate a plan{" "}
            <Link to="/pricing">click here</Link>
          </p>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  )
}

export default ExpiredPlan
