import {
  GET_LABELS_REQUEST,
  GET_LABELS_SUCCESS,
  GET_LABELS_FAILURE,
  CREATE_LABEL_REQUEST,
  CREATE_LABEL_SUCCESS,
  CREATE_LABEL_FAILURE,
  UPDATE_LABEL_REQUEST,
  UPDATE_LABEL_SUCCESS,
  UPDATE_LABEL_FAILURE,
  DELETE_LABEL_REQUEST,
  DELETE_LABEL_SUCCESS,
  DELETE_LABEL_FAILURE,
} from "./labelTypes"

const initialState = {
  loading_labels: false,
  creating_label: false,
  updating_label: false,
  deleting_label: false,
  error: false,
  error_message: "",
  labels: [],
}

const reducer = (state = initialState, action) => {
  let labels = null
  switch (action.type) {
    /* --------------------- GET EVENTS --------------------- */
    case GET_LABELS_REQUEST:
      return {
        ...state,
        loading_labels: true,
      }

    case GET_LABELS_SUCCESS:
      return {
        ...state,
        loading_labels: false,
        error: false,
        error_message: "",
        labels: action.payload,
      }

    case GET_LABELS_FAILURE:
      return {
        ...state,
        loading_labels: false,
        error: true,
        error_message: action.payload,
      }
    /* --------------------- GET EVENTS --------------------- */

    /* --------------------- CREATE EVENTS --------------------- */
    case CREATE_LABEL_REQUEST:
      return {
        ...state,
        creating_label: true,
      }

    case CREATE_LABEL_SUCCESS:
      return {
        ...state,
        error: false,
        error_message: "",
        creating_label: false,
        labels: [action.payload, ...state.labels],
      }

    case CREATE_LABEL_FAILURE:
      return {
        ...state,
        creating_label: false,
        error: true,
        error_message: action.payload,
      }
    /* --------------------- CREATE EVENTS --------------------- */

    /* --------------------- DELETE EVENTS --------------------- */
    case DELETE_LABEL_REQUEST:
      return {
        ...state,
        deleting_label: true,
      }

    case DELETE_LABEL_SUCCESS:
      labels = [...state.labels]
      let label_to_delete = action.payload
      let delete_index = labels.findIndex(
        (label) => label.id === label_to_delete.id,
      )

      labels.splice(delete_index, 1)

      return {
        ...state,
        deleting_label: false,
        error: false,
        error_message: "",
        labels: [...labels],
      }

    case DELETE_LABEL_FAILURE:
      return {
        ...state,
        deleting_label: false,
        error: true,
        error_message: action.payload,
      }
    /* --------------------- DELETE EVENTS --------------------- */

    /* --------------------- UPDATE EVENTS --------------------- */
    case UPDATE_LABEL_REQUEST:
      return {
        ...state,
        updating_label: true,
      }

    case UPDATE_LABEL_SUCCESS:
      labels = [...state.labels]
      let label_to_update = action.payload
      let update_index = labels.findIndex(
        (label) => label.id === label_to_update.id,
      )

      labels[update_index] = label_to_update

      return {
        ...state,
        updating_label: false,
        error: false,
        error_message: "",
        labels: [...labels],
      }

    case UPDATE_LABEL_FAILURE:
      return {
        ...state,
        updating_label: false,
        error: false,
        error_message: action.payload,
      }
    /* --------------------- UPDATE EVENTS --------------------- */

    default:
      return state
  }
}

export default reducer
