import React from "react"
import { Helmet } from "react-helmet"

const HelmetComponent = ({ pageTitle, pageContent, pageKeyword }) => {
  return (
    <Helmet>
      <title>FoLSol®| {pageTitle}</title>
      <meta
        name="description"
        content={pageContent ? pageContent : "Food Label Solution"}
      />
      <meta name="keywords" content={pageKeyword ? pageKeyword : ""} />
    </Helmet>
  )
}

export default HelmetComponent
