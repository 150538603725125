import { Boolean } from "aws-sdk/clients/cloudsearch"

export type QkFormField = {
  key: string
  type: FieldType
  title?: string
  required?: boolean
  visible?: boolean | string
  pattern?: RegExp
  component?: any
  fields: QkFormField[]
}

export enum FieldType {
  TEXT = "TEXT",
  LONG_TEXT = "LONG_TEXT",
  AUTOSUGGEST = "AUTOSUGGEST",
  SEARCH = "SEARCH",
  COMPONENT = "COMPONENT",
  INPUT_TABLE = "INPUT_TABLE",
  MULTI_INPUT = "MULTI_INPUT",
}

export enum IngredientType {
  Ingredients = "ingredients",
  LbProducts = "lb_products",
  UserIngredients = "user_ingredients",
  BaseRecipe = "base_recipe",
  Recipe = "recipe",
}

export type Serving = {
  unit: string
  size: number
}

export type RawIngredient = {
  ing_id: number
  name: string
  vegNonVegeterian: string
  serving: Serving
}

export type PackedIngredient = {
  lb_id: number
  details: {
    products_details: {
      product_name: string
    }
    ingridients_and_additives: {
      vegetarian_nonvegetarian_vegan: string
    }
    nutrition_information: {
      per_100_g_100_ml: string
    }
  }
}

export type UserIngredient = {
  uing_id: number
  productName: string
  vegNonVegeterian: string
}

export type BaseRecipe = {
  recipe_id: number
  name: string
  recipe_yield_unit: string
  vegNonVegeterian: string
  recipe_yield: string
  more_polyols: Boolean
  more_polydextrose: boolean
  caffeine_added: boolean
  monosodium_glutamate: boolean
  plant_stanols_esters: boolean
  plant_stanol_amount: any
  non_caloric_sweetener: boolean
  all_sweetners: boolean
  special_declaration: string
}
export type Recipe = {
  recipe_id: number
  name: string
  recipe_yield_unit: string
  vegNonVegeterian: string
  serving_size: string
  more_polyols: Boolean
  more_polydextrose: boolean
  caffeine_added: boolean
  monosodium_glutamate: boolean
  plant_stanols_esters: boolean
  plant_stanol_amount: any
  non_caloric_sweetener: boolean
  all_sweetners: boolean
  special_declaration: string
}

export type SearchIngredientsResponse = {
  [IngredientType.Ingredients]: RawIngredient[]
  [IngredientType.LbProducts]: PackedIngredient[]
  [IngredientType.UserIngredients]: UserIngredient[]
  [IngredientType.BaseRecipe]: BaseRecipe[]
  [IngredientType.Recipe]: Recipe[]
}

export type MappedIngredient = {
  id: number
  name: string
  vegNonVegeterian: string
  unit: string
  serving?: Serving
}

export type MappedResponse = {
  indexName: string
  type: string
  data: MappedIngredient[]
}[]

export type IngredientUnits = "g" | "ml"
