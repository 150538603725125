import React from "react"
import InformationCircle from "../common/Icons/InformationCircle"
import { Popover } from "@headlessui/react"

type QkHelpProps = {
  helpText: string
}

export default function QkHelp({ helpText }: QkHelpProps) {
  return (
    <Popover className="relative">
      <Popover.Button className="p-1 focus:ring-0 focus:outline-none">
        <InformationCircle className="w-4 h-4" />
      </Popover.Button>
      <Popover.Panel className="absolute z-50 bg-white p-2 w-72 shadow-lg rounded-lg ring-1 ring-black ring-opacity-5 label-help text-sm">
        <div dangerouslySetInnerHTML={{ __html: helpText }} />
      </Popover.Panel>
    </Popover>
  )
}
