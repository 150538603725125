import predefinedData from "./mapping/predefined_data"
import laboratoryTest from "./componentLaboratoryTest"
import { FieldType } from "../constants/enum"
import sweetnersMapping from "./mapping/sweetners_mapping.json"

export const laboratory_test = (data) => {
  if (
    data?.category &&
    data.category !== "" &&
    predefinedData[data?.category]
  ) {
    return predefinedData[data?.category]["laboratory_test"]?.map((v) => ({
      title: v.title,
      displayTitle: false,
      type: FieldType.MULTI_INPUT,
      component: laboratoryTest(v.title),
      reference: "",
      validators: "",
      layout: 6,
      description: "",
      list: [],
      items: [],
      key: v.key,
      visible:
        data?.category &&
        predefinedData[data?.category] &&
        predefinedData[data?.category]["laboratory_test"]?.length > 0
          ? true
          : false,
      required:
        data?.category &&
        predefinedData[data?.category] &&
        predefinedData[data?.category]["laboratory_test"]?.length > 0
          ? true
          : false,
    }))
  } else {
    return []
  }
}

export const keyManufacturerProcesses = (data) => {
  return {
    title: "Key manufacturing processes used for your product",
    type: FieldType.CHECKBOX,
    multiple: true,
    reference: "",
    validators: "",
    itemsPerRow: 3,
    layout: 12,
    description: "",
    list: [],
    items:
      data?.category && predefinedData[data?.category]
        ? predefinedData[data?.category]["key_manufacturing_processes"]
        : [],
    key: "manufacturing_process",
    visible:
      data?.category &&
      predefinedData[data?.category] &&
      predefinedData[data?.category]["key_manufacturing_processes"]?.length > 0
        ? true
        : false,
    required:
      data?.category &&
      predefinedData[data?.category] &&
      predefinedData[data?.category]["key_manufacturing_processes"]?.length > 0
        ? true
        : false,
  }
}

export const generateSweetners = () => {
  let fields = []

  sweetnersMapping.forEach((v) => {
    fields.push({
      title: v.title,
      type: FieldType.RADIO,
      reference: "",
      validators: "",
      layout: 6,
      description: "",
      list: [],
      items: ["Yes", "No"],
      key: v.key,
      visible: true,
      default: "No",
      required: true,
    })
    fields.push({
      type: FieldType.BLANK,
      layout: 6,
      visible: `output.${v.key} == "No"`,
    })
    fields.push({
      title: "Purity and Weight % of Marker Compound",
      type: FieldType.TEXT,
      reference: "",
      validators: "",
      layout: 6,
      description: "",
      list: [],
      items: [],
      key: `${v.key}_weight_of_marker`,
      visible: `output.${v.key} == "Yes"`,
    })
  })

  return fields
}

export const check_laboratory_test = (data, output) => {
  if (
    data?.category &&
    data.category !== "" &&
    predefinedData[data?.category]
  ) {
    let flag = false
    predefinedData[data?.category]["laboratory_test"]?.forEach((v) => {
      if (
        output[v.key] &&
        output[v.key]?.length > 0 &&
        output[v.key][0]?.is_compound === "No"
      ) {
        flag = true
      } else {
        flag = false
      }
    })
    return flag
  } else {
    return false
  }
}

export const veganDisplay = (output) => {
  const notDisplayCategory = [
    "1",
    "1.1",
    "1.1.1",
    "1.1.1.1",
    "1.1.1.2",
    "1.1.2",
    "1.2",
    "1.2.1",
    "1.2.1.1",
    "1.2.1.2",
    "1.2.2",
    "1.3",
    "1.3.1",
    "1.3.2",
    "1.3.2.1",
    "1.3.2.2",
    "1.4",
    "1.4.1",
    "1.4.2",
    "1.4.3",
    "1.4.4",
    "1.5",
    "1.5.1",
    "1.5.1.1",
    "1.5.2",
    "1.6",
    "1.6.1",
    "1.6.2",
    "1.6.2.1",
    "1.6.2.2",
    "1.6.2.3",
    "1.6.3",
    "1.6.4",
    "1.6.4.1",
    "1.6.4.2",
    "1.6.5",
    "1.6.6",
    "1.7",
    "1.8",
    "1.8.1",
    "1.8.2",
    "8",
    "8.1",
    "8.1.1",
    "8.1.2",
    "8.2",
    "8.2.1",
    "8.2.1.1",
    "8.2.1.2",
    "8.2.1.3",
    "8.2.2",
    "8.2.3",
    "8.3",
    "8.3.1",
    "8.3.1.1",
    "8.3.1.2",
    "8.3.1.3",
    "8.3.2",
    "8.3.3",
    "8.4",
    "9",
    "9.1",
    "9.1.1",
    "9.1.2",
    "9.2",
    "9.2.1",
    "9.2.2",
    "9.2.3",
    "9.2.4",
    "9.2.4.1",
    "9.2.4.2",
    "9.2.4.3",
    "9.2.5",
    "9.3",
    "9.3.1",
    "9.3.2",
    "9.3.3",
    "9.3.4",
    "9.4",
    "10",
    "10.1",
    "10.2",
    "10.2.1",
    "10.2.2",
    "10.2.3",
    "10.3",
    "10.4",
    "11.5",
    "16",
    "18.1.1",
  ]
  if (notDisplayCategory.includes(output?.category?.category)) {
    return ["Vegetarian", "Non Vegetarian", "Contains Egg"]
  } else {
    return ["Vegetarian", "Non Vegetarian", "Contains Egg", "Vegan"]
  }
}

export const checkOtherCriticaltest = (data, output) => {
  if (
    data?.category &&
    data?.category !== "" &&
    predefinedData[data?.category] &&
    predefinedData[data?.category]["critical_tests"]
  ) {
    const filterArray = (arr1, arr2) => {
      const filtered = arr1.filter((el) => {
        return arr2.indexOf(el.name) === -1
      })
      return filtered
    }
    const arr1 = output?.other_critical_tests
    const arr2 = predefinedData[data?.category]["critical_tests"]
    const filteredData = filterArray(arr1, arr2)
    if (filteredData.length > 0) {
      return true
    } else {
      return false
    }
  }
}
