import React, { Fragment, useState, useEffect, useMemo } from "react"
import { Listbox, Transition } from "@headlessui/react"
import CheckIcon from "../common/Icons/CheckIcon"
import { DropdownIcon } from "../assets/icons/Icons"
import { classNames } from "../Utils/helpers"
import { mapListToFormOptions } from "./helper"
import { useSelector } from "react-redux"
import { getRecipesv2 } from "../services/ApiServicesv2"
import { Skeleton } from "@mui/material"
import { toast } from "react-toastify"

export default function QkRecipeDropdownSearch({ data, handleChange, values }) {
  const businessId = useSelector((state) => state.login.active_business)
  const disable = data?.disabled
  const initialValue = values[data.key] ?? (data.multiple === true ? [] : "")
  const [active, setActive] = useState(!!values[data.key])
  const [showPlaceholder, setShowPlaceholder] = useState(true)
  const placeholder = `${data.title} ${data.required ? "*" : ""}`
  const [list, setList] = useState([])
  const [selectedRecipe, setSelectedRecipe] = useState(values[data?.key])
  const [loading, setLoading] = useState(false)

  const fetchRecipes = async () => {
    try {
      setLoading(true)
      handleChange([])
      const brand = data?.selected_brand
      const mealCourse = values?.selected_meal_course || ""
      const postData = {
        from: 0,
        limit: 999,
        businessId: businessId,
        type: "NEW_RECIPE,COMBO_MEALS",
        brandIds: brand,
        mealCourse: mealCourse,
      }

      const all_recipes = await getRecipesv2(postData)

      setList(() => {
        return all_recipes?.result?.map((v) => ({
          key: v.recipe_id,
          value: v.name,
          raw: {
            ...v,
          },
        }))
      })
    } catch (e) {
      toast.error("Failed to fetch recipes")
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchRecipes()
  }, [values?.selected_meal_course, data?.selected_brand])

  useEffect(() => {
    setShowPlaceholder(!data.hideTitleOnActive && active)
  }, [data, values, active])

  useEffect(() => {
    setSelectedRecipe(values[data?.key])
  }, [values])

  useEffect(() => {
    setSelectedRecipe([])
  }, [data?.selected_brand])

  const [searchTerm, setSearchTerm] = useState("")
  const [filteredItems, setFilteredItems] = useState(list || [])

  useEffect(() => {
    setActive(selectedRecipe?.length > 0)
  }, [selectedRecipe])

  useEffect(() => {
    if (data.multiple) {
      if (typeof values[data?.key] === "string") handleChange([])
    }
  }, [data, handleChange, values])

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setFilteredItems(
        searchTerm
          ? list.filter((item) =>
              item.value.toLowerCase().includes(searchTerm.toLowerCase()),
            )
          : list,
      )
    }, 300)

    return () => clearTimeout(timeoutId)
  }, [searchTerm, list])

  const showClearSelection = selectedRecipe

  const groupResultsByIndexName = () => {
    let groupedResults = {}

    filteredItems.forEach((item) => {
      let group_name

      if (item && item.raw && item.raw.type && item.raw.type === "NEW_RECIPE") {
        group_name = "RECIPES"
      } else if (
        item &&
        item.raw &&
        item.raw.type &&
        item.raw.type === "COMBO_MEALS"
      ) {
        group_name = "COMBO MEALS"
      }

      if (!groupedResults[group_name]) {
        groupedResults[group_name] = []
      }

      groupedResults[group_name].push(item)
    })

    return groupedResults
  }

  const groupedResults = groupResultsByIndexName()

  const handleSelectAll = () => {
    if (data.multiple) {
      const allKeys = list
        .filter((item) => item.key !== "select_all")
        .map((item) => item.key)
      handleChange(allKeys)
    }
  }

  return (
    <Listbox
      value={initialValue}
      onChange={(value) => {
        if (value !== "") setActive(true)
        else setActive(false)
        handleChange(value)
      }}
      multiple={data.multiple ?? false}
      disabled={disable}
    >
      {({ open }) => (
        <>
          <div className="relative">
            {showPlaceholder && (
              <p className="absolute left-0 mt-[-15px] text-textgray bg-white ml-[15px] leading-8 px-[5px] py-0 scale-75 z-10">
                {placeholder}
              </p>
            )}
            <Listbox.Button
              className={classNames(
                data.customStyle,
                "relative w-full border border-bordergray rounded-lg shadow-sm pl-5 pr-10 py-[0.6rem] text-left cursor-default focus:outline-none focus:ring-1 focus:ring-black focus:border-none",
                disable ? "pointer-events-none opacity-75" : "",
                active ? "text-black" : "text-textgraylight",
              )}
              disabled={disable}
            >
              <span
                className={classNames(
                  "block truncate text-base ml-1 leading-8",
                  selectedRecipe?.length > 0
                    ? "text-black"
                    : "text-textgraylight",
                )}
              >
                {data?.multiple === true && Array.isArray(selectedRecipe)
                  ? selectedRecipe.length > 0
                    ? selectedRecipe
                        .map((v) => list.find((d) => d?.key === v)?.value)
                        .join(", ")
                    : placeholder
                  : list.find((v) => v.key === selectedRecipe)?.value ??
                    placeholder}
              </span>
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <DropdownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-20 mt-1 w-full max-h-60 bg-white rounded-md shadow-lg py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                <div className="px-3 pt-2">
                  <input
                    type="text"
                    className="w-full p-2 mb-2 border border-gray-300 rounded-md"
                    placeholder="Type to search..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    onKeyDown={(e) => {
                      e.code === "Space" ? e.stopPropagation() : null
                    }}
                  />
                </div>
                <div className="flex flex-col items-start">
                  {showClearSelection && (
                    <button
                      className="text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9"
                      onClick={() => handleChange(data.multiple ? [] : "")}
                    >
                      <span className="font-normal block truncate">
                        Clear Selection
                      </span>
                    </button>
                  )}
                  {data?.multiple && (
                    <button
                      className="text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9"
                      onClick={() =>
                        handleChange(list?.map((item) => item.key))
                      }
                    >
                      <span className="font-normal block truncate">
                        Select All
                      </span>
                    </button>
                  )}
                </div>

                {loading && (
                  <>
                    {Array.from({ length: 2 }).map((_, index) => (
                      <Listbox.Option className="m-4" key={index}>
                        <Skeleton variant="rounded" height={40} />
                      </Listbox.Option>
                    ))}
                  </>
                )}

                {!loading && filteredItems.length === 0 && (
                  <div className="px-4 py-2 text-gray-500 bg-[#efeded]">
                    No Recipes Available
                  </div>
                )}

                {!loading &&
                  Object.entries(groupedResults).map(([indexName, items]) => (
                    <React.Fragment key={indexName}>
                      {indexName && indexName !== "undefined" && (
                        <p className="px-2 py-1 font-bold bg-slate-300 text-gray-700 mb-1 capitalize">
                          {indexName}
                        </p>
                      )}
                      {items.map((item, index) => {
                        const alreadySelected =
                          data?.disable_other_options === true
                            ? data?.selected_list?.includes(item.key)
                            : false

                        return (
                          <Listbox.Option
                            key={item.key}
                            className={({ active }) =>
                              classNames(
                                active
                                  ? "text-white bg-indigo-600"
                                  : "text-black",
                                " select-none relative py-2 pl-10 pr-4",
                                alreadySelected
                                  ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                                  : "cursor-pointer",
                              )
                            }
                            disabled={alreadySelected}
                            value={item.key}
                          >
                            {({ selected, active }) => (
                              <>
                                <div
                                  className={`flex justify-between items-center block  ${
                                    selected ? "font-semibold" : "font-normal"
                                  }`}
                                >
                                  <span
                                    title={item.value}
                                    className="justify-start truncate max-w-[75%]"
                                  >
                                    {item.value}
                                  </span>

                                  <div className="flex items-center truncate max-w-[25%]">
                                    {item && item.type === "COMBO_MEALS" && (
                                      <div className="px-2 py-1 text-[13px] text-center text-white rounded-2xl bg-orange-500">
                                        {"Combo Meals"}
                                      </div>
                                    )}
                                    {item && item.brand_name && (
                                      <span className="bg-yellow-200 text-black rounded-md px-2 py-1 ml-2 flex items-center w-auto">
                                        {item.brand_name}
                                      </span>
                                    )}
                                    {item && item.raw && item.raw.city && (
                                      <span
                                        title={item.raw.city}
                                        className="bg-yellow-200 text-black rounded-md px-2 py-1 ml-2 flex items-center w-auto"
                                      >
                                        {item.raw.city}
                                      </span>
                                    )}
                                  </div>
                                </div>

                                {selected && (
                                  <span
                                    className={classNames(
                                      "absolute inset-y-0 left-0 flex items-center pl-3",
                                      active
                                        ? "text-indigo-600"
                                        : "text-indigo-600",
                                    )}
                                  >
                                    {/* <CheckIcon
                                      className="w-5 h-5"
                                      aria-hidden="true"
                                    /> */}
                                    {Array.isArray(values[data.key]) &&
                                      true && (
                                        <span
                                          className={`${
                                            active
                                              ? "text-white"
                                              : "text-indigo-600"
                                          }`}
                                        >
                                          {values[data.key].indexOf(item.key) +
                                            1}
                                        </span>
                                      )}
                                  </span>
                                )}
                              </>
                            )}
                          </Listbox.Option>
                        )
                      })}
                    </React.Fragment>
                  ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  )
}
