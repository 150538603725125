import {
  GET_PROFILE_REQUEST,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_FAILURE,
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAILURE,
} from "./profileTypes"

const initialState = {
  loading: false,
  error: false,
  error_message: "",
  firstName: "",
  lastName: "",
  designation: "",
  phoneNumber: "",
  address: "",
  email: "",
  city: "",
  state: "",
  zip: "",
  profileImage: "",
  userType: "",
  superadminAccess: false,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PROFILE_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case GET_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        firstName: action.payload.first_name || "",
        lastName: action.payload.last_name || "",
        designation: action.payload.designation || "",
        phoneNumber: action.payload.phone_number,
        address: action.payload.address || "",
        email: action.payload.email || "",
        city: action.payload.city || "",
        state: action.payload.state || "",
        zip: action.payload.zip || "",
        profileImage: action.payload.profile_picture || "",
        userType: action.payload.type || "",
        superadminAccess: action.payload.type === "SUPERADMIN" || "SUPERADMIN",
      }
    case GET_PROFILE_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        error_message: action.payload,
      }
    case UPDATE_PROFILE_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case UPDATE_PROFILE_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      }
    default:
      return state
  }
}

export default reducer
