import React from "react"
export default function AusNZ() {
  return (
    <svg
      width="32"
      height="24"
      viewBox="0 0 32 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 0V24H32V0H0Z"
        fill="#2E42A5"
      />
      <g clip-path="url(#clip0_5761_9733)">
        <rect width="18" height="14" fill="#2E42A5" />
        <path
          d="M-2.00391 13.0011L1.95704 14.7383L18.0902 1.89L20.1796 -0.691528L15.9439 -1.27215L9.36354 4.26454L4.06704 7.99497L-2.00391 13.0011Z"
          fill="#F7FCFF"
        />
        <path
          d="M-1.46094 14.2184L0.556984 15.2266L19.43 -0.931152H16.5966L-1.46094 14.2184Z"
          fill="#F50100"
        />
        <path
          d="M20.0039 13.0011L16.043 14.7383L-0.0902405 1.89L-2.17955 -0.691528L2.05612 -1.27215L8.63646 4.26454L13.933 7.99497L20.0039 13.0011Z"
          fill="#F7FCFF"
        />
        <path
          d="M19.8691 13.8747L17.8512 14.8828L9.81489 7.96486L7.43229 7.19193L-2.3802 -0.682604H0.453194L10.2602 7.00502L12.8651 7.93184L19.8691 13.8747Z"
          fill="#F50100"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M9.98451 0H7.98451V6H0V8H7.98451V14H9.98451V8H18V6H9.98451V0Z"
          fill="#F50100"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6.48451 0H11.4845V4.5H18V9.5H11.4845L11.5 14H6.5L6.48451 9.5H0V4.5H6.48451V0ZM7.98451 6H0V8H7.98451V14H9.98451V8H18V6H9.98451V0H7.98451V6Z"
          fill="#F7FCFF"
        />
      </g>
      <g clip-path="url(#clip1_5761_9733)">
        <path
          d="M9 20.5L7.69835 21.7029L7.82725 19.9352L6.07522 19.6676L7.53761 18.6662L6.65451 17.1295L8.34917 17.6485L9 16L9.65083 17.6485L11.3455 17.1295L10.4624 18.6662L11.9248 19.6676L10.1727 19.9352L10.3017 21.7029L9 20.5Z"
          fill="#F7FCFF"
        />
        <path
          d="M22 13L21.1322 13.8019L21.2182 12.6235L20.0501 12.445L21.0251 11.7775L20.4363 10.753L21.5661 11.099L22 10L22.4339 11.099L23.5637 10.753L22.9749 11.7775L23.9499 12.445L22.7818 12.6235L22.8678 13.8019L22 13Z"
          fill="#F7FCFF"
        />
        <path
          d="M24 5L23.1322 5.80194L23.2182 4.62349L22.0501 4.44504L23.0251 3.77748L22.4363 2.75302L23.5661 3.09903L24 2L24.4339 3.09903L25.5637 2.75302L24.9749 3.77748L25.9499 4.44504L24.7818 4.62349L24.8678 5.80194L24 5Z"
          fill="#F7FCFF"
        />
        <path
          d="M29 9L28.1322 9.80194L28.2182 8.62349L27.0501 8.44504L28.0251 7.77748L27.4363 6.75302L28.5661 7.09903L29 6L29.4339 7.09903L30.5637 6.75302L29.9749 7.77748L30.9499 8.44504L29.7818 8.62349L29.8678 9.80194L29 9Z"
          fill="#F7FCFF"
        />
        <path
          d="M25 22L24.1322 22.8019L24.2182 21.6235L23.0501 21.445L24.0251 20.7775L23.4363 19.753L24.5661 20.099L25 19L25.4339 20.099L26.5637 19.753L25.9749 20.7775L26.9499 21.445L25.7818 21.6235L25.8678 22.8019L25 22Z"
          fill="#F7FCFF"
        />
        <path
          d="M28.5 14.25L27.6183 14.7135L27.7867 13.7318L27.0734 13.0365L28.0592 12.8932L28.5 12L28.9408 12.8932L29.9266 13.0365L29.2133 13.7318L29.3817 14.7135L28.5 14.25Z"
          fill="#F7FCFF"
        />
      </g>
      <defs>
        <clipPath id="clip0_5761_9733">
          <rect width="18" height="14" fill="white" />
        </clipPath>
        <clipPath id="clip1_5761_9733">
          <rect
            width="25"
            height="21"
            fill="white"
            transform="translate(6 2)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
