import React, { useState } from "react"
import InformationCircle from "../common/Icons/InformationCircle"
import Popover from "@mui/material/Popover"
import Typography from "@mui/material/Typography"
import { uuidv4 } from "../Utils/helpers"
import { classNames } from "../Utils/helpers"
import { mapListToFormOptions } from "./helper"
import QkHelp from "./QkHelp"
import { useLocation } from "react-router-dom"
import { Button } from "@mui/material"

export default function QkRadio({ data, handleChange, values }) {
  const location = useLocation()

  const fullUrl =
    window.location.origin + location.pathname + location.search + location.hash

  let mlCheck = fullUrl.includes("ml")

  let recipeCheck =
    fullUrl.includes("add-new-recipe") || fullUrl.includes("edit-recipe")

  const radioGroup = uuidv4()

  const itemsPerRow = data.itemsPerRow ?? 3

  let list = mapListToFormOptions(data)

  const [anchorEl, setAnchorEl] = useState(null)

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  const id = open ? "simple-popover" : undefined

  return (
    <>
      <div
        className={`inline-flex items-center ${
          data.disabled ? "opacity-50 cursor-not-allowed text-[grey]" : ""
        }`}
      >
        <p className="px-4">
          {data.title} {data.required && "*"}
        </p>
        {data.help && <QkHelp helpText={data.help} />}
      </div>

      <div className={`grid grid-cols-${itemsPerRow} gap-2 px-4`}>
        {list?.map(({ key, value }) => (
          <div className="inline-flex items-center my-1" key={key}>
            {!data.hideRadio ? (
              <label
                className={`inline-flex items-center my-1 text-sm font-bold ${
                  data.disabled
                    ? "opacity-50 cursor-not-allowed text-opacity-50"
                    : ""
                }`}
              >
                <input
                  name={radioGroup}
                  type="radio"
                  value={key}
                  disabled={data?.disabled}
                  checked={values[data.key] === key}
                  onChange={(e) => handleChange(e.target.value)}
                  className={`focus:outline-none focus:ring-0 h-4 w-4 checked:bg-orange-500 checked:focus:bg-orange-500 focus:bg-orange-500 border-bordergray mr-1 font-bold ${
                    data.disabled ? "opacity-50 cursor-not-allowed" : ""
                  }`}
                />
                {value === "Vegan" ? (
                  <>
                    <span>{value}</span>

                    <div
                      aria-describedby={id}
                      className="ml-5"
                      onClick={handlePopoverOpen}
                    >
                      <InformationCircle className="w-4 h-4 bg-blue color-white" />
                    </div>
                    <Popover
                      id={id}
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handlePopoverClose}
                      disableScrollLock={true}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                    >
                      <div className="bg-white p-2 w-72 shadow-lg rounded-lg label-help text-sm">
                        <div className="text-[12px] font-semibold">
                          The food that are not products of animal origin and in
                          which, at no stage of production and processing,
                          ingredients, including additives, flavourings, enzymes
                          and carriers, or processing aids that are of animal
                          origin has been used. <br />
                          <br />
                          Refer to Food Safety and Standards (Vegan Foods)
                          Regulations, 2022.
                          <br />
                          <br /> 5. Vegan food compliance. - (1) The Food
                          Business Operator shall submit an application to the
                          concerned licensing authority with all necessary
                          details in a format as may be specified by the Food
                          Authority.
                        </div>
                      </div>
                    </Popover>
                  </>
                ) : (
                  value
                )}
              </label>
            ) : (
              <button
                key={key}
                onClick={() => handleChange(key)}
                disabled={data.disabled}
                className={classNames(
                  `inline-flex rounded-lg overflow-clip border-4 ${
                    data.disabled &&
                    "cursor-not-allowed opacity-50 text-opacity-50"
                  }`,
                  values[data.key] === key && "border-orange-500",
                  values[data.key] !== key && "border-transparent opacity-60",
                )}
              >
                {value}
              </button>
            )}
          </div>
        ))}
      </div>
    </>
  )
}
