import {
  GET_STEPS_REQUEST,
  GET_STEPS_SUCCESS,
  GET_STEPS_FAILURE,
} from "./stepsTypes"

const initialState = {
  loading: false,
  error: false,
  error_message: "",
  steps: [],
  saving_steps: false,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_STEPS_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case GET_STEPS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        error_message: "",
        steps: action.payload,
      }
    case GET_STEPS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        error_message: "Error retrieving steps",
      }
    default:
      return state
  }
}

export default reducer
