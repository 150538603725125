import React from "react"

export default function QkFieldInfo(props) {
  if (props.data?.description && props.data?.description?.length > 0)
    return (
      <div
        className={`${props.data.disabled && "cursor-not-allowed opacity-50"}`}
      >
        {props.data?.title && (
          <>
            <h1 className="text-xl font-bold">{props.data.title}</h1>
            <div className="h-1 mt-1 w-12 bg-primary mb-4"></div>
          </>
        )}
        <div
          dangerouslySetInnerHTML={{ __html: props.data?.description ?? "" }}
        />
      </div>
    )
  return <></>
}
