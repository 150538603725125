import React, { Fragment, useEffect, useState } from "react"
import { Listbox, Transition } from "@headlessui/react"
import CheckIcon from "../common/Icons/CheckIcon"
import { DropdownIcon } from "../assets/icons/Icons"
import { classNames } from "../Utils/helpers"
import { mapListToFormOptions } from "./helper"
import InformationCircleIcon from "../common/InformationCircle/InformationCircleIcon"
import KeyboardArrowUpSharpIcon from "@mui/icons-material/KeyboardArrowUpSharp"
import TruncatedString from "../common/TruncatedString/TruncatedString"

export default function QkDropup({ data, handleChange, values }) {
  // disable option on non mandatory nutrition
  const disableNutrition = [
    // "Oryzanol (mg)",
    // "Folic Acid (mcg)",
    // "Iodine (mg)",
    // "Vitamin B6 (mg)",
    // "Vitamin B12 (mcg)",
    // "Vitamin D (mcg)",
    // "Vitamin K1 (mcg)",
    // "Vitamin B1 (mg)",
    // "Vitamin B2 (mg)",
    // "Biotin (mcg)",
    // "Vitamin B3 (mg)",
    // "Vitamin E (mg)",
    // "Chloride (mg)",
    // "Omega 3 fatty acids (g)",
    // "Omega 6 fatty acids (g)",
  ]

  const disable = data?.disabled
  const initialValue = values[data.key] ?? (data.multiple === true ? [] : "")
  const [active, setActive] = useState(!!values[data.key])
  const placeholder = `${data.title} ${data.required ? "*" : ""}`

  let list = data?.listFromExternalSource
    ? data?.listResource(values)
    : mapListToFormOptions(data)

  // Sort the list if the key is present
  if (data.sort) {
    list?.sort((a, b) => {
      if (data.sort === -1) return b.value.localeCompare(a.value)
      return a.value.localeCompare(b.value)
    })
  }

  function scrollToTop() {
    const listboxOptions = document.querySelector(".listbox-options")
    if (listboxOptions) {
      listboxOptions.scrollTop = 0
    }
  }

  // nutrition list with disable option
  const nutrientList = list
    ?.map((i) => {
      if (disableNutrition.includes(i.value)) {
        return { ...i, disable: true }
      } else {
        return i
      }
    })
    .sort((a, b) => {
      // If 'disable' is true, move the element to the end
      const a_Disable = a.disable || false
      const b_Disable = b.disable || false

      return a_Disable - b_Disable
    })

  useEffect(() => {
    if (data.multiple) {
      if (typeof values[data?.key] === "string") handleChange([])
    }
  }, [])

  const showClearSelection = values[data.key]

  return (
    <Listbox
      value={initialValue}
      onChange={(value) => {
        if (value !== "") setActive(true)
        else setActive(false)
        handleChange(value)
      }}
      multiple={data.multiple ?? false}
      disabled={disable}
    >
      {({ open }) => (
        <>
          <div
            className={`relative ${
              disable ? "opacity-75 pointer-events-none" : ""
            }`}
          >
            {!data.hideTitleOnActive && active && (
              <p className="absolute left-0 mt-[-15px] text-textgray bg-[white] ml-[10px] px-[5px] py-0 scale-75 z-10">
                {placeholder}
              </p>
            )}
            <Listbox.Button
              placeholder={placeholder}
              disabled={disable}
              className={`${
                data.customStyle
              } bg-white relative w-full border border-bordergray rounded-lg shadow-sm pl-5 pr-10 mr-10 py-[0.6rem] text-left cursor-default focus:outline-none focus:ring-1 focus:ring-black focus:border-none ${
                disable ? "opacity-75 cursor-not-allowed" : ""
              }`}
            >
              {data?.multiple === true && Array.isArray(values[data?.key]) ? (
                <span className={"block truncate ml-1 leading-8"}>
                  {values[data?.key] && values[data?.key].length > 0 ? (
                    values[data?.key]
                      ?.map(
                        (v) =>
                          list?.find((d) => d?.key === v)?.value ?? (
                            <p className="text-textgraylight">{placeholder}</p>
                          ),
                      )
                      .join(", ")
                  ) : (
                    <p className="text-textgraylight">{placeholder}</p>
                  )}
                </span>
              ) : (
                <span className={"block truncate w-[95%]"}>
                  {values[data.key] ? (
                    <p className="leading-8 pl-3 ">
                      <TruncatedString
                        text={
                          list?.find((v) => v.key === values[data.key])
                            ?.value ?? values[data.key]
                        }
                        size={55}
                        width={"400px"}
                      />
                    </p>
                  ) : (
                    <p className=" ml-1 text-textgraylight leading-8">
                      {placeholder}
                    </p>
                  )}
                </span>
              )}
              <span className="absolute inset-y-0 mr-4 right-0 flex items-center pr-2 cursor-pointer">
                {/* <DropdownIcon
                  className={`h-5 w-5 text-textgraylight ${data.customIconStyle}`}
                  aria-hidden="true"
                /> */}
                <KeyboardArrowUpSharpIcon />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="bottom-full flex-col-reverse listbox-options my-3 absolute top-[-170px] z-20 w-full bg-white shadow-lg max-h-[200px] rounded-lg py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                {showClearSelection && (
                  <button
                    className="text-gray-900 cursor-default select-none sticky py-2 pl-3 pr-9"
                    onClick={() => handleChange(data.multiple ? [] : "")}
                  >
                    <span className="font-normal block truncate">
                      Clear Selection
                    </span>
                  </button>
                )}
                {(data.niValueFrom === "My Recipes" ? nutrientList : list).map(
                  (v) => (
                    <Listbox.Option
                      key={v?.key}
                      className={({ active }) =>
                        classNames(
                          active ? "text-white bg-indigo-600" : "text-gray-900",
                          `cursor-default select-none relative py-2 pl-3 pr-9 flex items-center ${
                            v.disable ? "text-gray-200" : ""
                          }`,
                        )
                      }
                      value={v?.key}
                      disabled={v.disable}
                    >
                      {({ selected, active }) => (
                        <>
                          <span className="flex items-center">
                            <input
                              type="checkbox"
                              checked={selected}
                              disabled={v.disable}
                              onChange={() => {
                                const currentValues = values[data.key]
                                const updatedValues = data.multiple
                                  ? selected
                                    ? currentValues?.filter?.(
                                        (item) => item !== v.key,
                                      ) || []
                                    : [...(currentValues || []), v.key]
                                  : v.key

                                handleChange(updatedValues)
                              }}
                              className="mr-2 h-5 w-5 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                            />
                            <span
                              className={classNames(
                                selected ? "font-semibold" : "font-normal",
                                `block ${data?.truncate ? "truncate" : ""}`,
                              )}
                            >
                              {v.value}
                            </span>
                          </span>
                        </>
                      )}
                    </Listbox.Option>
                  ),
                )}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  )
}
