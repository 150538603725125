import React, { useState } from "react"
import classNames from "classnames"
import PlusCircle from "../common/Icons/PlusCircle"
import { DustbinIcon } from "../assets/icons/Icons"

export default function MultiLine({ data, handleChange, values }) {
  const value = values[data.key] ?? [""]
  const [error, setError] = useState("")
  const [active, setActive] = useState(values[data.key] ? true : false)
  const placeholder = `${data.title} ${data.required ? "*" : ""}`

  const handleBlur = () => {
    // Check if validator key is present
    if (data.validator === undefined || data.validator === "") return

    let validated = true

    // Check for validator condition
    if (typeof data.validator === "string") {
      let output = values
      let currentValue = values[data.key]

      validated = eval(data.validator)
    } else {
      validated = data.validator
    }

    // Set or remove error message
    if (validated) setError("")
    else setError(data.error ?? "Please check your provided value")
  }
  return (
    <>
      {value.map((v, idx) => (
        <div
          className={`relative w-full mt-3 ${
            props.data.disabled ? "opacity-50 pointer-events-none" : ""
          }`}
        >
          {!data.hideTitleOnActive && active && (
            <p className="absolute mt-[-15px] text-textgray bg-[white] ml-[10px] px-[5px] py-0 scale-75 z-10">
              {placeholder}
            </p>
          )}
          <input
            type="text"
            maxLength={data.maxLength ?? 30}
            placeholder={placeholder}
            className={classNames(
              "relative z-0 shadow-sm border w-full rounded-lg px-4 leading-8  placeholder:text-textgraylight focus:ring-black focus:border-none",
              error ? "border-red-500 border-2" : "border-bordergray",
            )}
            name={data.key}
            value={v}
            onChange={(e) => {
              if (e.target.value !== "") setActive(true)
              else setActive(false)
              let temp = value
              temp[idx] = e.target.value
              handleChange(temp)
            }}
            onBlur={handleBlur}
            readOnly={data.readOnly}
          />

          {value.length > 1 && (
            <button
              className="absolute top-0 right-0 p-3"
              onClick={() => {
                handleChange(value.filter((_, i) => i !== idx))
              }}
            >
              <DustbinIcon className=" w-4 h-4" />
            </button>
          )}
        </div>
      ))}
      <button
        className="inline-flex py-2 items-center text-primary"
        onClick={() => handleChange([...value, ""])}
      >
        <PlusCircle className="h-4 w-4 text-primary" />
        <h1 className="text-primary ml-1 text-sm">Add More</h1>
      </button>
    </>
  )
}
