import { FieldType } from "../constants/enum"

const organicCertificateFromNPOP = (output) => [
  {
    title: "Upload NPOP Certificate (Only PDF upto 2MB are allowed)",
    type: "file",
    allowedFileType: ["application/pdf"],
    maxAllowedSize: 2 * 1024 * 1024,
    multiple: false,
    reference: "",
    validator: "",
    layout: 12,
    description: "",
    list: [],
    items: [],
    key: "npop_certificate",
    visible:
      output?.organic_certificate_from?.includes("NPOP") &&
      output?.organic_product === "Yes",
    required:
      output?.organic_certificate_from?.includes("NPOP") &&
      output?.organic_product === "Yes",
    help: "The file upload size can be maximum of 2 MB",
  },
  {
    title: "",
    type: FieldType.INFO,
    description: `This product is certified Organic by`,
    layout: 4,
    visible:
      output?.organic_certificate_from?.includes("NPOP") &&
      output?.organic_product === "Yes",
    key: "info",
  },
  {
    title: "Accredited certification agency name",
    type: FieldType.TEXT,
    multiple: false,
    reference: "",
    validator: "",
    layout: 4,
    description: "",
    list: [],
    items: [],
    key: "npop_accredited_certification_agency",
    required:
      output?.organic_certificate_from?.includes("NPOP") &&
      output?.organic_product === "Yes",
    visible:
      output?.organic_certificate_from?.includes("NPOP") &&
      output?.organic_product === "Yes",
  },
  {
    title: "Accreditation number",
    type: FieldType.TEXT,
    multiple: false,
    reference: "",
    validator: "",
    layout: 4,
    description: "",
    list: [],
    items: [],
    key: "npop_accreditation_number",
    visible:
      output?.organic_certificate_from?.includes("NPOP") &&
      output?.organic_product === "Yes",
    required:
      output?.organic_certificate_from?.includes("NPOP") &&
      output?.organic_product === "Yes",
  },
]

export default organicCertificateFromNPOP
