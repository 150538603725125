import React from "react"
export default function MULTI() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.91389 5.91389C5.80767 6.88689 5.75 7.92282 5.75 9C5.75 10.0772 5.80767 11.1131 5.91389 12.0861C6.88689 12.1923 7.92282 12.25 9 12.25C10.0772 12.25 11.1131 12.1923 12.0861 12.0861C12.1923 11.1131 12.25 10.0772 12.25 9C12.25 7.92282 12.1923 6.8869 12.0861 5.91389C11.1131 5.80768 10.0772 5.75 9 5.75C7.92282 5.75 6.88689 5.80767 5.91389 5.91389Z"
        fill="#2F80ED"
      />
      <path
        d="M4.38411 6.12906C4.29633 7.04901 4.25 8.01125 4.25 9C4.25 9.98875 4.29633 10.951 4.38411 11.8709C3.29194 11.6808 2.31029 11.4262 1.47813 11.1236C0.956008 10.9337 0.504782 10.7293 0.127368 10.5174C0.0436152 10.0241 0 9.51713 0 9C0 8.48287 0.0436153 7.97592 0.127368 7.48264C0.504783 7.27072 0.956008 7.06628 1.47813 6.87642C2.31029 6.57381 3.29194 6.31922 4.38411 6.12906Z"
        fill="#2F80ED"
      />
      <path
        d="M6.12906 4.38411C7.049 4.29634 8.01125 4.25 9 4.25C9.98875 4.25 10.951 4.29634 11.8709 4.38411C11.6808 3.29194 11.4262 2.31029 11.1236 1.47813C10.9337 0.956009 10.7293 0.504785 10.5174 0.127371C10.0241 0.0436184 9.51713 0 9 0C8.48287 0 7.97592 0.0436153 7.48264 0.127368C7.27072 0.504783 7.06628 0.956008 6.87642 1.47813C6.57382 2.31029 6.31922 3.29194 6.12906 4.38411Z"
        fill="#2F80ED"
      />
      <path
        d="M13.6159 6.12906C13.7037 7.04901 13.75 8.01125 13.75 9C13.75 9.98875 13.7037 10.951 13.6159 11.8709C14.7081 11.6808 15.6897 11.4262 16.5219 11.1236C17.044 10.9337 17.4952 10.7293 17.8726 10.5174C17.9564 10.0241 18 9.51713 18 9C18 8.48287 17.9564 7.97592 17.8726 7.48264C17.4952 7.27072 17.044 7.06628 16.5219 6.87642C15.6897 6.57382 14.7081 6.31922 13.6159 6.12906Z"
        fill="#2F80ED"
      />
      <path
        d="M17.326 5.57662C17.2302 5.53918 17.133 5.50255 17.0345 5.46673C15.9953 5.08884 14.7715 4.78618 13.4228 4.57723C13.2138 3.22852 12.9112 2.0047 12.5333 0.965518C12.4974 0.867 12.4608 0.769786 12.4234 0.673961C14.6407 1.58663 16.4134 3.35928 17.326 5.57662Z"
        fill="#2F80ED"
      />
      <path
        d="M17.326 12.4234C17.2302 12.4608 17.133 12.4974 17.0345 12.5333C15.9953 12.9112 14.7715 13.2138 13.4228 13.4228C13.2138 14.7715 12.9112 15.9953 12.5333 17.0345C12.4974 17.133 12.4608 17.2302 12.4234 17.326C14.6407 16.4134 16.4134 14.6407 17.326 12.4234Z"
        fill="#2F80ED"
      />
      <path
        d="M11.8709 13.6159C10.951 13.7037 9.98875 13.75 9 13.75C8.01125 13.75 7.049 13.7037 6.12906 13.6159C6.31922 14.7081 6.57382 15.6897 6.87642 16.5219C7.06628 17.044 7.27072 17.4952 7.48264 17.8726C7.97592 17.9564 8.48287 18 9 18C9.51713 18 10.0241 17.9564 10.5174 17.8726C10.7293 17.4952 10.9337 17.044 11.1236 16.5219C11.4262 15.6897 11.6808 14.7081 11.8709 13.6159Z"
        fill="#2F80ED"
      />
      <path
        d="M4.57723 13.4228C3.22852 13.2138 2.0047 12.9112 0.965519 12.5333C0.866999 12.4974 0.769782 12.4608 0.673956 12.4234C1.58663 14.6407 3.35928 16.4134 5.57661 17.326C5.53918 17.2302 5.50255 17.133 5.46673 17.0345C5.08884 15.9953 4.78618 14.7715 4.57723 13.4228Z"
        fill="#2F80ED"
      />
      <path
        d="M4.57723 4.57723C3.22852 4.78618 2.0047 5.08884 0.965519 5.46673C0.867 5.50255 0.769783 5.53918 0.673958 5.57661C1.58663 3.35928 3.35928 1.58663 5.57661 0.673958C5.53918 0.769784 5.50255 0.866999 5.46673 0.965518C5.08884 2.0047 4.78618 3.22852 4.57723 4.57723Z"
        fill="#2F80ED"
      />
    </svg>
  )
}
