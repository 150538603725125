import React from "react"
import { mapListToFormOptions } from "./helper"

type NewValue = Array<any>

interface QkCheckboxProps {
  data: {
    key: string
    title: string
    disabled?: boolean
    allowColSpan?: boolean
    itemsPerRow?: number
  }
  handleChange: (newValue: NewValue) => void
  values: { [key: string]: any }
}

export default function QkCheckbox({
  data,
  handleChange,
  values,
}: QkCheckboxProps) {
  const disable = data?.disabled
  const key = data.key
  const itemsPerRow = data.itemsPerRow ?? 1

  let list = mapListToFormOptions(data)

  const handleInputChange = (value: string) => {
    let newValue = []

    if (value === "Not Applicable") {
      newValue = values[key]?.includes(value) ? [] : [value]
    } else {
      if (values[key]?.includes("Not Applicable")) {
        newValue = [value]
      } else {
        newValue = values[key]?.includes(value)
          ? values[key].filter((item: string) => item !== value)
          : [...(values[key] || []), value]
      }
    }

    const newValues = { ...values }
    newValues[key] = newValue
    handleChange(newValue)
  }

  const CheckSelectOrNot = (v: any): boolean => {
    if (v.key === "Oats") {
      return values[key]?.includes("Oat") || values[key]?.includes("Oats")
    }
    return values[key]?.includes(v.key)
  }

  return (
    <>
      <span className="text-lg font-semibold block">{data.title}</span>
      <div className={`grid grid-cols-${itemsPerRow} gap-2 my-3`}>
        {list?.map((v: any, index: React.Key | null | undefined) => (
          <div
            key={index}
            className={`my-1 ${disable ? "opacity-75 cursor-not-allowed" : ""}`}
          >
            <label
              className={`text-sm font-medium text-gray-700 inline items-center ${
                disable ? "cursor-not-allowed opacity-50" : ""
              }`}
            >
              <input
                name={key}
                type="checkbox"
                checked={CheckSelectOrNot(v)}
                className={`focus:outline-none focus:ring-0 h-4 w-4  bg-transparent border-[#2F80ED] border-2 mr-2 ${
                  disable ? "cursor-not-allowed opacity-50" : ""
                }`}
                onChange={() => handleInputChange(v.key)}
                disabled={
                  disable ||
                  (values[key]?.includes("Not Applicable") &&
                    v.key !== "Not Applicable")
                }
              />
              <span className="ml-1">{v.value}</span>
            </label>
          </div>
        ))}
      </div>
    </>
  )
}
