import React from "react"
import { FieldType } from "../constants/enum"
import additives from "./componentAdditives"
import ingredients from "./componentIngredients"
import fortified from "./componentFortified"
import mandatoryNutrients from "./componentNutritionInformation"
import nonMandatoryNutrients from "./componentOtherNutrientInfo"
import targetAudience from "./mapping/target_audience.json"
import { generateSweetners, veganDisplay } from "./helpers"
import consumerCare from "./componentConsumerCare"
import useStorageInstructions from "./componentUseStorageInstructions"
import allergens from "./mapping/allergens.json"
import niInfoValidator from "./validators/niInfoValidator"
import DragDropComponent from "../components/DragDropComponent/DragDropInput"
import PlasticIcon from "../common/PlasticIcon"
import packDimension from "./componentPackDimension"
import labelDimension from "./componentLabelDimension"
import blendedOil from "./componentBlendedOil"
import { RECIPE_TYPES } from "../Utils/constants"
import { getMDCheckboxes } from "../components/LabelPreview/mandatoryDeclaration"
import { BASE_ROUTES } from "../constants/routes"
import SelectedRecipeWidget from "../pages/LabelMaker/selected_recipe_widget"
import OrganicCertificateFromComponent from "../components/OrganicCertificationFromComponent"

const dropdown_values = {
  level_1: [],
  level_2: [],
  level_3: [],
  level_4: [],
}

const AILabelValidationSchema = (schemaData, output) => {
  const isEditPage = window.location.href.includes("edit")

  return [
    ...(!isEditPage
      ? [
          {
            name: "Select Label",
            description: "",
            fields: [
              {
                title: "Search Label",
                type: FieldType.DROPDOWNSEARCH,
                validator: "",
                disable: isEditPage,
                description: "",
                layout: 6,
                list: schemaData.labels,
                items: [],
                key: "label_id",
                visible: true,
                required: false,
              },
            ],
          },
        ]
      : []),
    {
      name: "Product Information",
      description: "",
      fields: [
        {
          title: "Product Name",
          type: FieldType.TEXT,
          validators: "",
          description: "",
          list: [],
          key: "product_name",
          required: true,
          visible: true,
          disable: isEditPage,
        },
        {
          title: "Select Brand",
          type: FieldType.DROPDOWN,
          validator: "",
          description: "",
          layout: 6,
          list: schemaData.brands,
          items: [],
          key: "brand_id",
          visible: true,
          required: true,
          disable: isEditPage,
        },
        {
          title: "Select Category",
          type: FieldType.CATEGORY,
          validator: "",
          description: "",
          layout: 6,
          list: schemaData.category,
          items: [],
          key: "category",
          visible: true,
          required: true,
          disabled: output?.isLabelSelected,
          disable: isEditPage,
        },
        {
          title: "Product Code",
          type: FieldType.TEXT,
          validator: "",
          description: "",
          list: [],
          key: "product_code",
          required: true,
          visible: true,
          disable: isEditPage,
        },
        {
          title: "Retail/Non Retail Product",
          type: FieldType.RADIO,
          layout: 12,
          validator: "",
          description: "",
          items: ["Retail Product", "Non-Retail Product"],
          itemsPerRow: 2,
          key: "is_retail",
          required: true,
          visible: true,
          disabled: isEditPage,
          customStyle: "gap-15",
        },
      ],
    },
    {
      name: "Upload Artwork for Validation",
      description: "",
      fields: [
        {
          title: "Upload Artwork (PDF, JPEG, PNG Max 10 MB)",
          type: "ai_file",
          multiple: false,
          reference: "",
          validators: "",
          layout: 100,
          description: "",
          list: [],
          items: [],
          key: "label_artwork",
        },
      ],
    },
    // Uncomment and use the following sections as needed.
    // {
    //   name: "Upload Documents to Support Validation",
    //   description: "",
    //   fields: [
    //     {
    //       title: "Upload Document (PDF-Max 10MB)",
    //       type: "ai_file",
    //       multiple: false,
    //       reference: "",
    //       validators: "",
    //       layout: 100,
    //       description: "",
    //       list: [],
    //       items: [],
    //       key: "support_documents",
    //     },
    //   ],
    // },
    // {
    //   name: "Previous Artworks",
    //   description: "",
    //   fields: [
    //     {
    //       title: "",
    //       type: "prev_artworks",
    //       multiple: false,
    //       reference: "",
    //       validators: "",
    //       layout: 100,
    //       description: "",
    //       list: [],
    //       items: [],
    //       key: "previous_artwork",
    //     },
    //   ],
    // },
  ]
}

export default AILabelValidationSchema
