import { FieldType } from "../constants/enum"

const organicCertificateFromPGSIndia = (output) => [
  {
    title: "Upload PGS India Certificate (Only PDF 2MB are allowed)",
    type: "file",
    allowedFileType: ["application/pdf"],
    maxAllowedSize: 2 * 1024 * 1024,
    multiple: false,
    reference: "",
    validator: "",
    layout: 12,
    description: "",
    list: [],
    items: [],
    key: "pgs_india_certificate",
    visible:
      output?.organic_certificate_from?.includes("PGS-India Organic") &&
      output?.organic_product === "Yes",
    required:
      output?.organic_certificate_from?.includes("PGS-India Organic") &&
      output?.organic_product === "Yes",
    help: "The file upload size can be maximum of 2 MB",
  },
  {
    title: "Description of PGS group",
    type: FieldType.TEXT,
    multiple: false,
    reference: "",
    validator: "",
    layout: 6,
    description: "",
    list: [],
    items: [],
    key: "pgs_group_description",
    visible:
      output?.organic_certificate_from?.includes("PGS-India Organic") &&
      output?.organic_product === "Yes",
    required:
      output?.organic_certificate_from?.includes("PGS-India Organic") &&
      output?.organic_product === "Yes",
  },
  {
    title: "Unique ID code",
    type: FieldType.TEXT,
    multiple: false,
    reference: "",
    validator: "",
    layout: 6,
    description: "",
    list: [],
    items: [],
    key: "pgs_unique_id_code",
    visible:
      output?.organic_certificate_from?.includes("PGS-India Organic") &&
      output?.organic_product === "Yes",
    required:
      output?.organic_certificate_from?.includes("PGS-India Organic") &&
      output?.organic_product === "Yes",
  },
]

export default organicCertificateFromPGSIndia
