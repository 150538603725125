import {
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core"
import { Facebook, LinkedIn, Instagram, YouTube } from "@material-ui/icons"
import React, { Component } from "react"
import "./Footer.css"

export default class Footer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showResources: false,
    }
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick = (e) => {
    alert(e)
    e.preventDefault()
    this.setState({ showResources: !this.state.showResources })
  }

  render() {
    return (
      <div>
        <footer className="page-footer">
          <Grid container spacing={3} justifyContent="center">
            <Grid item xs={4}>
              <List component="nav">
                <ListItem key="Home" component="h1">
                  <ListItemText primary="Quick Link" />
                </ListItem>
                <hr />
                <ListItem
                  button
                  key="Terms of Use"
                  component="a"
                  href="/terms-of-use"
                >
                  <ListItemText primary="Terms of Use" />
                </ListItem>
                <ListItem
                  button
                  key="End User License Agreement"
                  component="a"
                  href="/end-user-license-agreement"
                >
                  <ListItemText primary="End User License Agreement" />
                </ListItem>
                <ListItem
                  button
                  key="Disclaimers"
                  component="a"
                  href="/disclaimers"
                >
                  <ListItemText primary="Disclaimers" />
                </ListItem>
                <ListItem
                  button
                  key="Privacy Policy"
                  component="a"
                  href="/privacy-policy"
                >
                  <ListItemText primary="Privacy Policy" />
                </ListItem>
                <ListItem
                  button
                  key="Refund Policy"
                  component="a"
                  href="/refund-policy"
                >
                  <ListItemText primary="Refund Policy" />
                </ListItem>
                <ListItem button key="About Us" component="a" href="/about-us">
                  <ListItemText primary="About Us" />
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={4}>
              <List component="nav">
                <ListItem key="Home" component="h1">
                  <ListItemText primary="Contacts" />
                </ListItem>
                <hr />
                <ListItem
                  button
                  key="Contact Us"
                  component="a"
                  href="/contact-us"
                >
                  <ListItemText primary="Contact Us" />
                </ListItem>
                {/* <ListItem button key="Developers" component="a" href="/developers">
                                    <ListItemText primary="Developers" />
                                </ListItem> */}
                {/* <ListItem button key="Sitemap" component="a" href="/sitemap.xml">
                                    <ListItemText primary="Sitemap" />
                                </ListItem> */}
              </List>
            </Grid>
            <Grid item xs={4}>
              <List component="nav">
                <ListItem key="Home" component="h1" variant="subtitile1">
                  <ListItemText primary="Contact us" />
                </ListItem>
                <hr />
                <ListItem
                  button
                  key="FAQs"
                  href="mailto:support@foodlabelsolutions.com"
                >
                  <ListItemText primary="support@foodlabelsolutions.com" />
                </ListItem>
                <ListItem button key="address" component="h1">
                  <ListItemText primary="Mumbai, India" />
                </ListItem>
                {/* <ListItem button key="no" component="h1">
                                    <ListItemText primary="+91 98927 22166" />
                                </ListItem> */}
                <div
                  id="google_translate_element"
                  className="language-button"
                ></div>
              </List>
            </Grid>
            <hr style={{ width: "85%" }} />
            <Grid item xs={12} className="copyright-text">
              <Typography component="h6" variant="h6">
                Copyright © All rights reserved | The Nutrition Alchemy
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                component="a"
                href="https://www.facebook.com/labelblindofficial"
                target="_blank"
              >
                <IconButton aria-label="">
                  <Facebook className="facebook" />
                </IconButton>
              </Typography>
              <Typography
                component="a"
                href="https://www.instagram.com/labelblind_lb/?hl=en"
                target="_blank"
              >
                <IconButton aria-label="">
                  <Instagram className="instagram" />
                </IconButton>
              </Typography>
              <Typography
                component="a"
                href="https://www.linkedin.com/company/14552048/admin/"
                target="_blank"
              >
                <IconButton aria-label="">
                  <LinkedIn className="linkedin" />
                </IconButton>
              </Typography>
              <Typography
                component="a"
                href="https://www.youtube.com/channel/UCoj65gaVPXTTebBtiOlNi7w"
                target="_blank"
              >
                <IconButton aria-label="">
                  <YouTube className="youtube" />
                </IconButton>
              </Typography>
            </Grid>
          </Grid>
        </footer>
      </div>
    )
  }
}
