import React from "react"

const SugarIngredients = () => {
  const tableData = [
    [
      "Sugars-based ingredients that require grouping",
      "Sugars-based ingredients that are sweetening agents",
      "Sugars-based ingredients that are functional",
    ],
    ["Barbados sugar (Muscovado)", "Agave syrup", "Condensed milk"],
    [
      "Barley malt (unfermented)",
      "Apple cider syrup",
      "Sweetened condensed milk",
    ],
    ["Beet sugar", "Apple syrup", "Decharacterized juice"],
    [
      "Burnt sugar (caramelized sugar)",
      "Brown rice syrup",
      "Fruit juice (except lime/lemon juice)",
    ],
    [
      "Cane juice",
      "Brown sugar",
      "Fruit juice concentrate (except lime/lemon juice concentrate)",
    ],
    ["Cane juice crystals", "Yellow sugar", "Fruit paste"],
    ["Cane sugar", "Golden sugar", "Fruit purée"],
    ["Caster (castor) sugar", "Carob syrup", "Fruit purée concentrate"],
    ["Coarse sugar", "Concentrated maple water", "Malted milk"],
    ["Coconut palm sugar", "Corn sweetener", "Malted milk powder"],
    ["Coconut sugar", "Corn syrup", "Maltodextrin (no fibre)"],
    ["Crystalline fructose", "Corn syrup solids", "Nectar"],
    ["Date sugar", "Dextrose Anhydrous", ""],
    ["Dehydrated cane juice", "Dextrose Monohydrate", ""],
    ["Demerara-style sugar", "Fancy molasses", ""],
    [
      "Evaporated cane juice (evaporated cane sugar, dried cane syrup, cane syrup solids)",
      "Glucose or Glucose syrup",
      "",
    ],
    ["Fondant sugar", "Glucose solid or Dried glucose syrup", ""],
    ["Free-flowing brown sugars", "Golden syrup", ""],
    ["Fructose", "Honey", ""],
    ["Fructose solids", "Icing sugar", ""],
    ["Fructose syrup", "Confectioner's sugar", ""],
    ["Fruit sugar", "Powdered sugar", ""],
    ["Galactose", "Invert sugar", ""],
    ["Glucose-fructose", "Liquid invert sugar", ""],
    ["Granulated sugar", "Liquid sugar", ""],
    ["Grape sugar", "Malt syrup", ""],
    ["High-fructose corn syrup", "Malted barley extract", ""],
    ["High-maltose corn syrup", "Malted barley syrup", ""],
    ["Isomaltose", "Maple Syrup", ""],
    ["Isomaltulose", "Maple water", ""],
    ["Lactose", "Oat syrup solids", ""],
    ["Maltose", "Other syrups", ""],
    ["Maple Sugar", "Potato syrup solids", ""],
    ["Organic sugar", "Raisin syrup", ""],
    ["Palm sugar", "Refined sugar syrup", ""],
    ["Pearl sugar (sanding sugar)", "Refiners' syrup", ""],
    [
      "Panocha (cane sugar produced by a crude milling process)",
      "Golden syrup",
      "",
    ],
    ["Raw sugar", "Refiners' molasses", ""],
    ["Saccharose (sucrose)", "Blackstrap molasses", ""],
    ["Soft sugar", "Cooking molasses", ""],
    ["Sucrose", "Rice syrup", ""],
    ["Sugar", "Sorghum syrup", ""],
    ["Superfine sugar", "Sweet sorghum", ""],
    ["Table sugar", "Table molasses", ""],
    ["Turbinado sugar", "Tapioca syrup", ""],
    ["White sugar", "Tapioca syrup solids", ""],
    ["", "Wheat syrup", ""],
  ]

  return (
    <div style={{ padding: "20px" }}>
      <table
        style={{ width: "100%", borderCollapse: "collapse", fontSize: "16px" }}
      >
        <thead>
          <tr>
            <th style={headerStyle}>
              Sugars-based ingredients that require grouping
            </th>
            <th style={headerStyle}>
              Sugars-based ingredients that are sweetening agents
            </th>
            <th style={headerStyle}>
              Sugars-based ingredients that are functional
            </th>
          </tr>
        </thead>
        <tbody>
          {tableData.slice(1).map((row, index) => (
            <tr key={index} style={rowStyle}>
              {row.map((cell, cellIndex) => (
                <td key={cellIndex} style={cellStyle}>
                  {cell || ""}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

const headerStyle = {
  backgroundColor: "#F97316",
  color: "white",
  padding: "12px",
  textAlign: "left",
  borderBottom: "2px solid #ddd",
  border: "1px solid black",
}

const rowStyle = {
  borderBottom: "1px solid #ddd",
}

const cellStyle = {
  padding: "12px",
  textAlign: "left",
  border: "1px solid black",
}

export default SugarIngredients
