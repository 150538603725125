import {
  getCategoriesRequest,
  setFoodCategories,
  getCategoriesSuccess,
  getCategoriesFailure,
  getStepsRequest,
  getStepsSuccess,
  getStepsFailure,
} from "../../Redux"
import { getFaqs } from "../Faq/faqActions"
import apis from "../../services/ApiServices"
import { getAllCategories } from "../../services/ApiServicesv2"

export const globalContentInitialize = () => {
  return async (dispatch) => {
    dispatch(getCategoriesRequest())
    dispatch(getStepsRequest())
    const response = await apis.getAllContent()
    const type = "FOOD_CATEGORIES"
    let fooddetails
    const foodCategoryResponse = await getAllCategories()
    if (foodCategoryResponse?.status === "success") {
      fooddetails = foodCategoryResponse?.data
    }
    if ("success" in response) {
      const items = response.result.items
      const categories = items.filter((item) => item.type === "CATEGORY")
      const steps = items.filter((item) => item.type === "STEP")
      const planitems = [...response.result.items]
      const filterplans = planitems.filter(
        (plan) => plan.type === "Payment Plan",
      )
      dispatch(getFaqs())
      dispatch(getCategoriesSuccess(categories))
      dispatch(setFoodCategories(fooddetails))
      dispatch(getStepsSuccess(steps))
    } else {
      dispatch(getCategoriesFailure())
      dispatch(getStepsFailure())
    }
  }
}
