export const featuresMapping = {
  // Menu Labelling
  ANALYZE_RECIPES: "Analyze Recipes",
  NUTRITION_INFORMATION: "Nutrition Information (Energy + 14 nutrients)",
  ALLERGENS_INDICATION: "Allergens Indication",
  NUTRIENT_AND_HEALTH_TAGS: "Nutrient and Health Tags",
  STANDARD_RECIPE_TEMPLATES: "Zero-fill Standard recipe templates",
  NUTRITION_AND_ALLERGEN_GUIDE:
    "Digital Nutrition and Allergen Guide with QR Code",
  UNLIMITED_INGREDIENTS_DB: "Unlimited Packed Ingredients Database",
  UNLIMITED_BASE_RECIPES: "Unlimited Base Recipes",
  MENU_LABELLING_SOP:
    "Audit Ready Methodology Documents (SOP for Menu Labelling)",
  DUPLICATE_RECIPES: "Duplicate Recipes",
  MENU_OPTICS: "Menu Optics (Display suggestions on menu card)",
  FOOD_TAGS: "Ready-to-use Food Tags for Display",
  USER_ACCOUNTS: "Number of User Accounts",
  BRANDS: "Number of Brands",
  NUTRITION_FACTSHEET: "Bulk Download of Nutrition Factsheet",
  ARCHIVING: "Archiving - store changes in recipes by time-stamp",
  FOP_INTEGRATION: "Ready to Integrate Model with Food Ordering Platform",
  POS_INTEGRATION: "Ready to Integrate Model with POS System",
  SUPPORT: "Support - Email and Phone",
  ONBOARDING_TRAINING: "Onboarding Training Sessions",
  REGULATORY_UPDATES: "Regulatory Updates via Email",
  IMPORTED_PF_VALIDATION: "Validation of imported packaged foods",
  HEALTH_MENU_VALIDATION:
    "Validation of Healthy Menu (Low cal menu / Vegan Menu / Plant based Menu /Kid's Menu / High Protein Menu)",
}

export const universalAccessFeatures = {
  ANALYZE_RECIPES: true,
  NUTRITION_INFORMATION: true,
  ALLERGENS_INDICATION: true,
  NUTRIENT_AND_HEALTH_TAGS: true,
  STANDARD_RECIPE_TEMPLATES: true,
  NUTRITION_AND_ALLERGEN_GUIDE: true,
  UNLIMITED_INGREDIENTS_DB: true,
  UNLIMITED_BASE_RECIPES: true,
  MENU_LABELLING_SOP: true,
  DUPLICATE_RECIPES: true,
  MENU_OPTICS: true,
  FOOD_TAGS: true,
  USER_ACCOUNTS: 100,
  MAX_RECIPES_LIMIT: 500,
  BRANDS: 100,
  NUTRITION_FACTSHEET: true,
  ARCHIVING: true,
  FOP_INTEGRATION: true,
  POS_INTEGRATION: true,
  SUPPORT: "Email",
  ONBOARDING_TRAINING: true,
  REGULATORY_UPDATES: true,
  IMPORTED_PF_VALIDATION: true,
  HEALTH_MENU_VALIDATION: true,
}

export const ppfUniversalAccessFeatures = {
  LABEL_MAKER: true,
  NI_TABLE: true,
  LM_GUIDELINES: true,
  PLASTIC_WASTE_GUIDELINES: true,
  READY_FOR_PRINT: true,
  EXPORTS_LABEL: true,
  MERA_QR_CODE: true,
  LABEL_VALIDATION: true,
  NUTRITION_ANALYSIS: true,
  NI_INFORMATION_ENERGY: true,
  NI_TABLE_PER_100: true,
  CATEGORY_LC: true,
  UNLIMITED_PACKED_ING_DB: true,
  UNLIMITED_BASE_RECIPES: true,
  USER_ACCOUNTS: 100,
  BRANDS: 100,
  READY_TO_INTEGRATE: true,
  SUPPORT: "Email",
  ONBOARDING_TRAINING: true,
  REGULATORY_UPDATES: true,
}

export const ppfFeaturesMapping = {
  // Packed Product FSSAI
  LABEL_MAKER: "Label Maker - 100% FSSAI Compliant Food Labels",
  NI_TABLE: "Nutrition Information Table",
  LM_GUIDELINES: "Legal Metrology Guidelines",
  PLASTIC_WASTE_GUIDELINES: "Plastic Waste Management Guidelines",
  READY_FOR_PRINT: "Ready for Print Labels - choose from Standard SKU Sizes",
  EXPORTS_LABEL: "Exports Labelling",
  MERA_QR_CODE: "Mera QR Code - Digital Food Labels",
  LABEL_VALIDATION: "Label Validation (Final Artwork)",
  NUTRITION_ANALYSIS: "Nutrition Analysis of Recipes (Theoretical method)",
  NI_INFORMATION_ENERGY: "Nutrition Information (Energy + 14 nutrients)",
  NI_TABLE_PER_100: "NI table - per 100 g/ml, per Serve, %RDA",
  CATEGORY_LC: "Category Specific Labelling Compliance",
  UNLIMITED_PACKED_ING_DB: "Unlimited Packed Ingredients Database",
  UNLIMITED_BASE_RECIPES: "Unlimited Base Recipes",
  USER_ACCOUNTS: "Number of User accounts",
  BRANDS: "Number of Brands",
  READY_TO_INTEGRATE: "Ready to Integrate with other softwares",
  SUPPORT: "Support - Email and Phone",
  ONBOARDING_TRAINING: "Onboarding Training Sessions",
  REGULATORY_UPDATES: "Regulatory Updates via Email",
}
