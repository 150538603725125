import { useMemo } from "react"
import { useLocation } from "react-router-dom"
import geodb from "../data/geodb.json"
import { SELECTED_PLAN_KEY } from "./constants"
import * as Sentry from "@sentry/react"

export const containsText = (text, searchText) =>
  text.toLowerCase().indexOf(searchText.toLowerCase()) > -1

export function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

export function getCountries() {
  return geodb.map((v) => v.name).sort()
}

export function getStates(country) {
  let countryData = geodb.find((v) => v.name === country)

  if (countryData && countryData.stateProvinces) {
    return countryData.stateProvinces
      .map((v) => v?.name)
      .sort((a, b) => a?.localeCompare(b))
  }

  return []
}

export function containsLinkpath(linkpaths, link) {
  for (let i = 0; i < linkpaths.length; i++) {
    if (new RegExp(linkpaths[i]).test(link)) {
      return true
    }
  }

  return false
}

export function truncateText(text, length = 20) {
  if (text?.length > length) return `${text.slice(0, length)}...`

  return text
}

export function uuidv4() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16),
  )
}

export function extractFileFromUrl(url) {
  try {
    if (url) {
      let parsedUrl = new URL(url)
      if (parsedUrl) return parsedUrl.pathname.split("/").pop()
    }
    return ""
  } catch (e) {
    console.error(e)

    return ""
  }
}

export function calculateWords(str) {
  return (
    str?.split(" ").filter(function (n) {
      return n !== ""
    }).length ?? 0
  )
}

export function reorder(list, startIndex, endIndex) {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

export function stringToColour(str) {
  var hash = 0
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }
  var colour = "#"
  for (var i = 0; i < 3; i++) {
    var value = (hash >> (i * 8)) & 0xff
    colour += ("00" + value.toString(16)).substr(-2)
  }
  return colour
}

export function getInitials(name) {
  let rgx = new RegExp(/(\p{L}{1})\p{L}+/, "gu")

  let initials = [...name.matchAll(rgx)] || []
  let result = (
    (initials.shift()?.[1] || "") + (initials.pop()?.[1] || "")
  ).toUpperCase()

  return result
}

export function useQuery() {
  const { search } = useLocation()

  return useMemo(() => new URLSearchParams(search), [search])
}

export function genUniqueArray(arr, key) {
  const unique = arr.filter(
    (obj, index) => arr.findIndex((item) => item[key] === obj[key]) === index,
  )

  return unique
}

export function calculateProgress(schema, output, requiredOnly) {
  let totalFields = 0
  let totalRequiredFields = 0
  let filledFields = 0
  let filledRequiredFields = 0

  for (let i = 0; i < schema.length; i++) {
    totalFields += schema[i].fields.length
    for (let j = 0; j < schema[i].fields.length; j++) {
      if (schema[i].fields[j].required) {
        totalRequiredFields++
      }
    }
  }

  for (let i = 0; i < schema.length; i++) {
    for (let j = 0; j < schema[i].fields.length; j++) {
      const field = schema[i].fields[j]
      if (field.key in output) {
        filledFields++
        if (field.required) {
          filledRequiredFields++
        }
      }
    }
  }

  if (requiredOnly) {
    if (filledRequiredFields === totalRequiredFields) {
      return 100
    } else {
      return Math.round(filledRequiredFields)
    }
  } else {
    return Math.round(filledFields)
  }
}

export function savePlanIdToLocalStorage(id) {
  localStorage.setItem(SELECTED_PLAN_KEY, id)
}

export function captureError(error) {
  const stackTrace = error.stack
  if (process.env.NODE_ENV === "production" && stackTrace) {
    const regex = /at\s+(.*?)\s+\((.*?):(\d+):(\d+)/gm
    const match = regex.exec(stackTrace)
    if (match) {
      const funcName = match[1]
      const filename = match[2]
      Sentry.captureException(error, {
        tags: {
          funcName,
          filename,
        },
      })
    } else {
      Sentry.captureException(error)
    }
  }
  console.error(error)
}
