import React, { useState, useRef } from "react"
import { FaUpload, FaTimes } from "react-icons/fa"
import { CloudUploadIcon } from "../../assets/icons/Icons"

function MultiUpload({ title, onChange }) {
  const [uploads, setUploads] = useState([])
  const fileInputRef = useRef(null)

  const onFileInputChange = (event) => {
    const newFiles = event.target.files
    addFiles(newFiles)
  }

  const addFiles = (newFiles) => {
    if (newFiles.length === 0) {
      return
    }

    const file_array = Array.from(newFiles).map((file) => ({
      file,
      name: file.name,
    }))

    const new_uploads = [...uploads, ...file_array]
    setUploads(new_uploads)
    onChange(new_uploads.map((u) => u.file))
    fileInputRef.current.value = ""
  }

  const removeFile = (index) => {
    const new_uploads = uploads.filter((_, i) => i !== index)
    setUploads(new_uploads)
    onChange(new_uploads.map((u) => u.file))
  }

  return (
    <div className="p-2">
      {uploads.length === 0 ? (
        <div
          className="flex flex-row items-center justify-start p-3 pl-8 gap-2 border-2 border-dashed border-gray-200 rounded-lg cursor-pointer"
          onClick={() => fileInputRef.current.click()}
        >
          <CloudUploadIcon />
          <p>{title}</p>
          <input
            ref={fileInputRef}
            type="file"
            multiple
            onChange={onFileInputChange}
            className="hidden"
          />
        </div>
      ) : (
        <div className="flex items-center p-2 justify-start border-2 border-blue-200 rounded-lg bg-[#F5F5F5]">
          {uploads.map((upload, index) => (
            <div
              key={index}
              className="flex flex-row items-center p-2 space-x-2"
            >
              <span className="font-medium text-blue-600">{upload.name}</span>
              <button
                onClick={() => removeFile(index)}
                className="text-blue-500 hover:text-blue-700"
              >
                <FaTimes size={16} />
              </button>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default MultiUpload
