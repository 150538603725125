enum UserType {
  Admin = "Admin",
  Manager = "Manager",
  User = "User",
}

const accessControl: Record<UserType, Record<string, string[]>> = {
  [UserType.Admin]: {
    home: [
      "ppf_home",
      "ppf_business_name",
      "ppf_plan_name",
      "ppf_account_validity",
      "ppf_total_labels",
      "ppf_completed_labels",
      "ppf_incomplete_labels",
      "ppf_balance_labels",
      "ppf_last_five_labels",
      "ml_home",
      "ml_business_name",
      "ml_plan_name",
      "ml_account_validity",
      "ml_total_recipes",
      "ml_active_recipes",
      "ml_pending_recipes",
      "ml_balance_recipes",
      "ml_last_five_recipes",
    ],
    // label_maker has included ppf base recipe keys
    label_maker: [
      "ppf_label_maker",
      "ppf_show_all_labels",
      "ppf_create_label",
      "ppf_search_label",
      "ppf_updated_label",
      "ppf_download_label",
      "ppf_add_art_work",
      "ppf_duplicate_label",
      "ppf_delete_label",
      "ppf_show_status",
      "ppf_base_recipe_create",
      "ppf_base_recipe_search",
      "ppf_base_recipe_show_list",
      "ppf_base_recipe_action_duplicate",
      "ppf_base_recipe_action_edit",
      "ppf_base_recipe_action_delete",
    ],
    recipes: [
      "ml_create_recipe",
      "ml_select_from_standard_recipe",
      "ml_create_base_recipe",
      "ml_filter_recipe",
      "ml_bulk_download",
      "ml_search_recipe",
      "ml_view_recipe",
      "ml_duplicate_recipe",
      "ml_show_status",
      "ml_edit_recipe",
      "ml_download_factsheet",
      "ml_download_menu_label",
    ],
    nutrition_analysis: [
      "ppf_recipes_create_new_recipes",
      "ppf_recipes_search_recipes",
      "ppf_recipes_show_list_recipes",
      "ppf_recipes_filter_recipes",
      "ppf_recipes_action_view_recipes",
      "ppf_recipes_action_edit_recipes",
      "ppf_recipes_action_duplicate_recipes",
    ],
    review_recipes: [
      "ml_add_comments",
      "ml_view_comments",
      "ml_approval_status",
      "ml_approve_or_reject",
    ],
    ingredients: [
      "ml_create_ingredient",
      "ml_search_ingredient",
      "ml_list_ingredient",
      "ml_edit_ingredient",
      "ml_delete_ingredient",
      "ml_bulk_upload_ingredient",
    ],
    base_recipes: [
      "ml_create_base_recipe",
      "ml_search_base_recipe",
      "ml_list_products",
      "ml_duplicate_base_recipe",
      "ml_edit_base_recipe",
      "ml_delete_base_recipe",
    ],
    ppf_business: [
      // PPF -> Business -> Brands
      "ppf_brands_view",
      "ppf_brands_add",
      "ppf_brands_edit",
      "ppf_brands_delete",
      // PPF -> Business -> Address Book
      "ppf_address_book_view_address",
      "ppf_address_book_add_address",
      "ppf_address_book_edit_address",
      "ppf_address_book_delete_address",
      // PPF -> Business -> Business Details
      "ppf_business_details_edit",
      // PPF -> Business -> Subscription Details - (Billing)
      "ppf_billing_details",
      "ppf_billing_details_current_plan",
      "ppf_billing_details_label_limit",
      "ppf_billing_details_upgrade_subscription",
      "ppf_billing_details_cancel_subscription",
      //PPF -> Business -> Storage instructions
      "ppf_storage_delete"
    ],
    ml_business: [
      // ML -> Business -> Brands
      "ml_brands",
      "ml_brands_view",
      "ml_brands_add",
      "ml_brands_edit",
      "ml_brands_delete",
      "ml_restaurant_profile",
      "ml_business_details_edit",
      // ML -> Business -> Subscription Details - (Billing)
      "ml_billing_details",
      "ml_billing_details_current_plans",
      "ml_billing_details_lable_limit",
      "ml_billing_details_upgrade_now",
      "ml_billing_details_cancel_subscription",
    ],
    team: [
      // PPF -> Team
      "ppf_add_user",
      "ppf_edit_update_user",
      "ppf_delete_user",
      "ppf_add_permission",
      "ppf_update_permission",
      // ML -> Team
      "ml_add_user",
      "ml_edit_update_user",
      "ml_delete_user",
      "ml_add_permission",
      "ml_update_permission",
    ],
    review_label: [
      "ppf_add_comments",
      "ppf_view_comments",
      "ppf_approval_status",
      "ppf_approve_or_reject",
    ],
    print_label: [
      "ppf_search_print_label",
      "ppf_select_dimensions_print_label",
      "ppf_share_print_label",
      "ppf_download_print_label",
    ],
    ppf_ingredients: [
      "ppf_ingredients_create",
      "ppf_ingredients_search",
      "ppf_ingredients_list",
      "ppf_ingredients_action_edit",
      "ppf_ingredients_action_delete",
      "ppf_ingredients_action_bulk_upload",
    ],
  },
  [UserType.Manager]: {
    home: [
      "ppf_label_maker",
      "ppf_show_all_labels",
      "ppf_home",
      "ppf_business_name",
      "ppf_plan_name",
      "ppf_account_validity",
      "ppf_total_labels",
      "ppf_completed_labels",
      "ppf_incomplete_labels",
      "ppf_balance_labels",
      "ppf_last_five_labels",
      "ml_home",
      "ml_business_name",
      "ml_plan_name",
      "ml_account_validity",
      "ml_total_recipes",
      "ml_active_recipes",
      "ml_pending_recipes",
      "ml_balance_recipes",
      "ml_last_five_recipes",
    ],
    // label_maker has included ppf base recipe keys
    label_maker: [
      "ppf_label_maker",
      "ppf_create_label",
      "ppf_search_label",
      "ppf_updated_label",
      "ppf_download_label",
      "ppf_add_art_work",
      "ppf_duplicate_label",
      "ppf_delete_label",
      "ppf_show_status",
      "ppf_base_recipe_create",
      "ppf_base_recipe_search",
      "ppf_base_recipe_show_list",
      "ppf_base_recipe_action_duplicate",
      "ppf_base_recipe_action_edit",
      "ppf_base_recipe_action_delete",
    ],
    recipes: [
      "ml_create_recipe",
      "ml_select_from_standard_recipe",
      "ml_create_base_recipe",
      "ml_filter_recipe",
      "ml_bulk_download",
      "ml_search_recipe",
      "ml_view_recipe",
      "ml_duplicate_recipe",
      "ml_show_status",
      "ml_edit_recipe",
      "ml_download_factsheet",
      "ml_download_menu_label",
    ],
    nutrition_analysis: [
      "ppf_recipes_create_new_recipes",
      "ppf_recipes_search_recipes",
      "ppf_recipes_show_list_recipes",
      "ppf_recipes_filter_recipes",
      "ppf_recipes_action_view_recipes",
      "ppf_recipes_action_edit_recipes",
      "ppf_recipes_action_duplicate_recipes",
    ],
    review_recipes: [
      "ml_add_comments",
      "ml_view_comments",
      "ml_approval_status",
      "ml_approve_or_reject",
    ],
    ingredients: [
      "ml_create_ingredient",
      "ml_search_ingredient",
      "ml_list_ingredient",
      "ml_edit_ingredient",
      "ml_delete_ingredient",
      "ml_bulk_upload_ingredient",
    ],
    base_recipes: [
      "ml_create_base_recipe",
      "ml_search_base_recipe",
      "ml_list_products",
      "ml_duplicate_base_recipe",
      "ml_edit_base_recipe",
      "ml_delete_base_recipe",
    ],
    ppf_business: [
      // PPF -> Business -> Brands
      "ppf_brands_view",
      "ppf_brands_add",
      "ppf_brands_edit",
      "ppf_brands_delete",
      // PPF -> Business -> Address Book
      "ppf_address_book_view_address",
      "ppf_address_book_add_address",
      "ppf_address_book_edit_address",
      "ppf_address_book_delete_address",
      // PPF -> Business -> Business Details
      "ppf_business_details_edit",
      // PPF -> Business -> Subscription Details - (Billing)
      "ppf_billing_details",
      "ppf_billing_details_current_plan",
      "ppf_billing_details_label_limit",
      "ppf_billing_details_upgrade_subscription",
      "ppf_billing_details_cancel_subscription",
    ],
    ml_business: [
      // ML -> Business -> Brands
      "ml_brands",
      "ml_brands_view",
      "ml_brands_add",
      "ml_brands_edit",
      "ml_brands_delete",
      "ml_restaurant_profile",
      "ml_business_details_edit",
      // ML -> Business -> Subscription Details - (Billing)
      "ml_billing_details",
      "ml_billing_details_current_plans",
      "ml_billing_details_lable_limit",
      "ml_billing_details_upgrade_now",
    ],
    review_label: [
      "ppf_add_comments",
      "ppf_view_comments",
      "ppf_approval_status",
      "ppf_approve_or_reject",
    ],
    print_label: [
      "ppf_search_print_label",
      "ppf_select_dimensions_print_label",
      "ppf_share_print_label",
      "ppf_download_print_label",
    ],
    ppf_ingredients: [
      "ppf_ingredients_create",
      "ppf_ingredients_search",
      "ppf_ingredients_list",
      "ppf_ingredients_action_edit",
      "ppf_ingredients_action_delete",
      "ppf_ingredients_action_bulk_upload",
    ],
  },
  [UserType.User]: {
    home: [
      "ppf_home",
      "ppf_business_name",
      "ppf_plan_name",
      "ppf_account_validity",
      "ppf_total_labels",
      "ppf_completed_labels",
      "ppf_incomplete_labels",
      "ppf_balance_labels",
      "ppf_last_five_labels",
      "ml_home",
      "ml_business_name",
      "ml_plan_name",
      "ml_account_validity",
      "ml_total_recipes",
      "ml_active_recipes",
      "ml_pending_recipes",
      "ml_balance_recipes",
      "ml_last_five_recipes",
    ],
    // label_maker has included ppf base recipe keys
    label_maker: [
      "ppf_search_label",
      "ppf_download_label",
      "ppf_add_art_work",
      "ppf_show_status",
      "ppf_base_recipe_search",
      "ppf_base_recipe_show_list",
    ],
    recipes: [
      "ml_filter_recipe",
      "ml_bulk_download",
      "ml_search_recipe",
      "ml_view_recipe",
      "ml_pending_recipe",
      "ml_download_factsheet",
      "ml_download_menu_label",
    ],
    nutrition_analysis: [
      "ppf_recipes_search_recipes",
      "ppf_recipes_show_list_recipes",
      "ppf_recipes_filter_recipes",
      "ppf_recipes_action_view_recipes",
    ],
    review_recipes: [
      "ml_add_comments",
      "ml_view_comments",
      "ml_approval_status",
      "ml_approve_or_reject",
    ],
    ingredients: ["ml_search_ingredient", "ml_list_ingredient"],
    base_recipes: ["ml_search_base_recipe", "ml_list_products"],
    ppf_business: [
      // PPF -> Business -> Brands
      "ppf_brands_view",
      // PPF -> Business -> Address Book
      "ppf_address_book_view_address",
      // PPF -> Business -> Business Details
      "ppf_business_details_edit",
    ],
    ml_business: [
      // ML -> Business -> Brands
      "ml_brands",
      "ml_brands_view",
      "ml_restaurant_profile",
      "ml_business_details_edit",
    ],
    review_label: [
      "ppf_add_comments",
      "ppf_view_comments",
      "ppf_approval_status",
      "ppf_approve_or_reject",
    ],
    print_label: [
      "ppf_search_print_label",
      "ppf_select_dimensions_print_label",
      "ppf_share_print_label",
      "ppf_download_print_label",
    ],
    ppf_ingredients: ["ppf_ingredients_search", "ppf_ingredients_list"],
  },
}

export function getAccess(user_module: string, user_roles: UserType | string) {
  const features: string[] = []
  const role =
    typeof user_roles === "string" ? (user_roles as UserType) : user_roles // Type assertion here

  const moduleAccess = accessControl[role]?.[user_module]

  if (moduleAccess) {
    features.push(...moduleAccess)
  }

  return features
}
// Uncomment the below function when testting on local system
// export function getAccess(user_module: string, user_roles: UserType | string) {
//   const features: string[] = []
//   const test_user = UserType.User
//   const role =
//     typeof user_roles === "string" ? (test_user as UserType) : test_user // Type assertion here

//   const moduleAccess = accessControl[role]?.[user_module]

//   if (moduleAccess) {
//     features.push(...moduleAccess)
//   }

//   return features
// }

// // Sample usage:
// const user_roles = [UserType.Admin]
// const user_module = "home"
// const userHasAccess = getAccess(user_module, user_roles)
// console.log(`User has access: ${userHasAccess}`)

// function hasAccess(
//   userType: UserType,
//   module: string,
//   feature: string,
// ): boolean {
//   const moduleAccess = accessControl[userType]?.[module]
//   return moduleAccess ? moduleAccess.includes(feature) : false
// }
