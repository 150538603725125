import { NAVBAR_ACTIVE, NAVBAR_INACTIVE, PPF_FEATURES } from "./UITypes"

export const navbarActive = () => {
  return {
    type: NAVBAR_ACTIVE,
  }
}

export const navbarInactive = () => {
  return {
    type: NAVBAR_INACTIVE,
  }
}

export const setPPFPlanFeatures = (payload) => {
  return {
    type: PPF_FEATURES,
    payload: payload,
  }
}

// export const pathChange = (path) => {
//     return {
//         type: PATH_CHANGE,

//     }
// }
