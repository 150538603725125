import {
  GET_PROFILE_REQUEST,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_FAILURE,
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAILURE,
} from "./profileTypes"
import apis from "../../services/ApiServices"
import { getUserDetailsFromToken } from "../../services/ApiServicesv2"

export const getProfileRequest = () => {
  return {
    type: GET_PROFILE_REQUEST,
  }
}

export const getProfileSuccess = (profile) => {
  return {
    type: GET_PROFILE_SUCCESS,
    payload: profile,
  }
}

export const getProfileFailure = () => {
  return {
    type: GET_PROFILE_FAILURE,
  }
}
//** So rahther then passing lbUserId we can just pass business details which
/* we got within userToken. it will save us one api call */
export const getProfile = (lbUserId) => {
  return async (dispatch) => {
    dispatch(getProfileRequest())
    try {
      const response = await getUserDetailsFromToken()
      if (response.code === 200) {
        const profile_picture =
          response?.data?.user_details?.profile_picture || ""
        if (profile_picture) {
          let profileImage = await apis.getFromS3(profile_picture)
          profile.profileImage = profileImage
          dispatch(getProfileSuccess(response?.data?.user_details))
        } else {
          // this is temporary  need to change to getProfileFailure
          dispatch(getProfileSuccess(response?.data?.user_details))
        }
      }
    } catch (error) {
      dispatch(getProfileFailure())
    }
  }
}

export const updateProfileRequest = () => {
  return {
    type: UPDATE_PROFILE_REQUEST,
  }
}

export const updateProfileSuccess = () => {
  return {
    type: UPDATE_PROFILE_SUCCESS,
  }
}

export const updateProfileFailure = () => {
  return {
    type: UPDATE_PROFILE_FAILURE,
  }
}

export const updateProfile = (profile) => {
  return async (dispatch) => {
    dispatch(updateProfileRequest())
    let response = await apis.updateUser(profile)
    if ("success" in response) {
      dispatch(updateProfileSuccess())
      dispatch(getProfile(profile.lbUserId))
    } else {
      dispatch(updateProfileFailure())
    }
  }
}
