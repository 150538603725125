import React, { useState } from "react"
import { useSelector } from "react-redux"
import { toast } from "react-toastify"
import { CloudUploadIcon } from "../assets/icons/Icons"
import MinusSmall from "../common/Icons/MinusSmall"
import { fileupload, fileuploadv2 } from "../Utils/fileHandler"
import ApiServices from "../services/ApiServices"
import Spinner from "../common/Icons/Spinner"
import AWSFilePreview from "../components/FileUpload/AWSFilePreview"
import AWSImage from "../components/FileUpload/AWSImage"
import PaperClip from "../common/Icons/PaperClip"
import { truncateText } from "../Utils/helpers"
import { FileUploadVariant } from "../constants/enum"
import { FileTypes } from "../constants/enum"
import { getImageS3 } from "../services/fileUpload"

export default function FileUpload({ data, handleChange, values }) {
  const [loading, setLoading] = useState(false)
  const businessId = useSelector((state) => state.login.active_business)
  const [names, setNames] = useState([])

  const handleUpload = async (file) => {
    const isImage = [
      "image/jpeg",
      "image/png",
      "image/gif",
      "image/svg+xml",
    ].includes(file.type)

    if (isImage) {
      const response = await fileupload(file, businessId)
      return response.data
      // if (response.status === "success") {
      //   const url = await getImageS3(response.data)
      //   console.log({ url })
      //   debugger
      //   return url.data
      // }
      // return response
    } else {
      const response = await fileuploadv2(file, businessId)
      return response.data
      // if (response.status === "success") {
      //   const url = await ApiServices.getFromS3(response.data)
      //   return url.data
      // }
    }
  }

  const onSelectFile = async (e) => {
    setLoading(true)
    if (!e.target.files || e.target.files.length === 0) {
      setLoading(false)
      return
    }
    let isAllowed = data?.allowedFileType?.includes(e.target.files[0].type)
    if (!isAllowed) {
      toast.error("This file type is not accepted")
      setLoading(false)
      return false
    }
    if (e.target.files[0].size > data.maxAllowedSize) {
      toast.error(
        `Max file size allowed is ${data?.maxAllowedSize / (1024 * 1024)}MB`,
      )
      setLoading(false)
      return false
    }
    const link = await handleUpload(e.target.files[0])
    if (data.multiple) {
      if (!values[data.key]) {
        handleChange([link])
      } else handleChange([...values[data.key], link])
    } else {
      handleChange(link)
    }

    if (data.variant === FileUploadVariant.SMALL) {
      setNames((prev) => [...prev, e.target.files[0].name])
    }

    e.target.value = null

    setLoading(false)
  }

  const onRemoveFile = async (e, index) => {
    e.preventDefault()
    setLoading(true)
    await ApiServices.deleteFileFromS3(values[data.key])
    handleChange(
      data.multiple ? values[data.key].filter((_, i) => i !== index) : "",
    )

    if (data.variant === FileUploadVariant.SMALL) {
      setNames((prev) => prev.filter((_, i) => i !== index))
    }

    setLoading(false)
  }

  if (data.variant === FileUploadVariant.SMALL)
    return (
      <div className={`${data.disabled && "cursor-not-allowed opacity-50"}`}>
        <div className="flex w-full items-center bg-gray-200 rounded-lg overflow-hidden">
          <label
            className="block text-xs font-bold cursor-pointer text-white bg-orange-500 p-2"
            htmlFor="user_avatar"
          >
            Upload file
          </label>
          <input
            className={`${
              data.disabled && "cursor-not-allowed opacity-50"
            } w-full cursor-pointer bg-gray-50 border border-bordergray text-gray-900 focus:outline-none focus:border-transparent text-sm rounded-lg`}
            aria-describedby="user_avatar_help"
            id="user_avatar"
            type="file"
            accept={(data.allowedFileType ?? FileTypes.ImagePdfTypes).join(",")}
            onChange={onSelectFile}
            hidden={true}
            disabled={data?.disabled}
          />
          <div className="p-2 flex-grow text-textgraylight text-xs">
            {"No File uploaded"}
          </div>
          {loading && <Spinner className="h-5 w-5 bg-gray-200" />}
        </div>
        <div className="flex py-1 flex-wrap">
          {names.map((v, index) => (
            <div className="p-1 inline-flex m-1 items-center bg-gray-100 rounded wrap">
              <PaperClip className="h-4 w-4" />
              <span className="ml-1 mr-2 truncate">{truncateText(v, 30)}</span>
              <button onClick={(e) => onRemoveFile(e, index)}>&#10006;</button>
            </div>
          ))}
        </div>
      </div>
    )

  return (
    <div className="flex justify-center items-center w-full h-full">
      <label
        htmlFor={data.key}
        className="flex flex-row relative justify-center items-center w-full bg-gray-50 h-full rounded-lg border-2 border-bordergray border-dashed cursor-pointer hover:bg-gray-100"
      >
        {loading && <Spinner className="h-5 w-5 absolute top-2 right-2" />}
        {values[data.key] && values[data.key].length > 0 ? (
          data.multiple ? (
            values[data.key]?.map((v, index) => (
              <div className="p-2 relative" key={v}>
                <button
                  onClick={(e) => onRemoveFile(e, index)}
                  className="bg-red-500 rounded-full w-6 h-6 absolute top-0 right-0"
                >
                  <MinusSmall className=" h-6 w-6 text-white" />
                </button>

                <AWSFilePreview
                  s3Key={v}
                  className="h-24 w-24 rounded-lg object-cover"
                />
              </div>
            ))
          ) : (
            <div className="p-2 relative">
              <button
                onClick={(e) => onRemoveFile(e)}
                className="bg-red-500 rounded-full w-6 h-6 absolute top-0 right-0"
              >
                <MinusSmall className="h-6 w-6 text-white" />
              </button>

              {/* From Below Condition .match validation is removed so all the Image URL is accepted Temporarily. In case facing any issue with Image URL update back again this logic :- values[data.key].match(/\.(png|jpg|gif|svg)$/i)  AND Create a Solution*/}
              <div>
                {values[data.key] ? (
                  values[data.key].includes("https://folsolbucket") ? (
                    values[data.key].includes(".pdf") ? (
                      <AWSFilePreview
                        s3Key={values[data.key]}
                        className="h-24 w-24 rounded-lg object-cover"
                      />
                    ) : values[data.key].includes(".jpeg") ||
                      values[data.key].includes(".png") ? (
                      <AWSImage
                        s3Key={values[data.key]}
                        className="h-24 w-24 rounded-lg object-cover"
                      />
                    ) : (
                      <AWSFilePreview
                        s3Key={values[data.key]}
                        className="h-24 w-24 rounded-lg object-cover"
                      />
                    )
                  ) : (
                    // <img
                    //   src={values[data.key]}
                    //   className="h-24 w-24 rounded-lg object-cover"
                    //   alt="External Image"
                    // />
                    <AWSFilePreview
                      s3Key={values[data.key]}
                      className="h-24 w-24 rounded-lg object-cover"
                    />
                  )
                ) : (
                  <AWSFilePreview
                    s3Key={values[data.key]}
                    className="h-24 w-24 rounded-lg object-cover"
                  />
                )}
              </div>
            </div>
          )
        ) : (
          <div className="flex flex-row justify-center items-center p-3 h-[3.5rem] text-left">
            <CloudUploadIcon className="w-10 h-10 text-gray-400" />
            <p
              className={`text-textgraylight ml-3 ${
                data.title.length > 12 ? "text-sm" : ""
              }`}
            >
              {data.title}
            </p>
          </div>
        )}
        <input
          id={data.key}
          type="file"
          className="hidden"
          accept={(data.allowedFileType ?? FileTypes.ImagePdfTypes).join(",")}
          onChange={onSelectFile}
        />
      </label>
    </div>
  )
}
