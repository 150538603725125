export const ins = [
  {
    "Sr. No": 1,
    "INS Number": 100,
    "Food Additive Name": "Curcumins",
    "Food Additive Display Name": "100 (Curcumins)",
    "Food Additive Class": "Colour",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "YES",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 2,
    "INS Number": "100(i)",
    "Food Additive Name": "Curcumin",
    "Food Additive Display Name": "100(i) (Curcumin)",
    "Food Additive Class": "Colour",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "YES",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 3,
    "INS Number": "100(ii)",
    "Food Additive Name": "Turmeric",
    "Food Additive Display Name": "100(ii) (Turmeric)",
    "Food Additive Class": "Colour",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "YES",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 4,
    "INS Number": 101,
    "Food Additive Name": "Riboflavins",
    "Food Additive Display Name": "101 (Riboflavins)",
    "Food Additive Class": "Colour",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "YES",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 5,
    "INS Number": "101(i)",
    "Food Additive Name": "Riboflavin",
    "Food Additive Display Name": "101(i) (Riboflavin)",
    "Food Additive Class": "Colour",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "YES",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 6,
    "INS Number": "101(ii)",
    "Food Additive Name": "Riboflavin 5’-phosphate, sodium",
    "Food Additive Display Name": "101(ii) (Riboflavin 5’-phosphate, sodium)",
    "Food Additive Class": "Colour",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "YES",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 7,
    "INS Number": 102,
    "Food Additive Name": "Tartrazine",
    "Food Additive Display Name": "102 (Tartrazine)",
    "Food Additive Class": "Colour",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "YES",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 8,
    "INS Number": 103,
    "Food Additive Name": "Alkanet",
    "Food Additive Display Name": "103 (Alkanet)",
    "Food Additive Class": "Colour",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "YES",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 9,
    "INS Number": 104,
    "Food Additive Name": "Quinoline yellow",
    "Food Additive Display Name": "104 (Quinoline yellow)",
    "Food Additive Class": "Colour",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "YES",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 10,
    "INS Number": 107,
    "Food Additive Name": "Yellow 2G",
    "Food Additive Display Name": "107 (Yellow 2G)",
    "Food Additive Class": "Colour",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "YES",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 11,
    "INS Number": 110,
    "Food Additive Name": "Sunset yellow FCF",
    "Food Additive Display Name": "110 (Sunset yellow FCF)",
    "Food Additive Class": "Colour",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "YES",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 12,
    "INS Number": 120,
    "Food Additive Name": "Carmines",
    "Food Additive Display Name": "120 (Carmines)",
    "Food Additive Class": "Colour",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "YES",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 13,
    "INS Number": 121,
    "Food Additive Name": "Citrus red 2",
    "Food Additive Display Name": "121 (Citrus red 2)",
    "Food Additive Class": "Colour",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "YES",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 14,
    "INS Number": 122,
    "Food Additive Name": "Azorubine / Carmoisine",
    "Food Additive Display Name": "122 (Azorubine / Carmoisine)",
    "Food Additive Class": "Colour",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "YES",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 15,
    "INS Number": 123,
    "Food Additive Name": "Amaranth",
    "Food Additive Display Name": "123 (Amaranth)",
    "Food Additive Class": "Colour",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "YES",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 16,
    "INS Number": 124,
    "Food Additive Name": "Ponceau 4R",
    "Food Additive Display Name": "124 (Ponceau 4R)",
    "Food Additive Class": "Colour",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "YES",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 17,
    "INS Number": 125,
    "Food Additive Name": "Ponceau SX",
    "Food Additive Display Name": "125 (Ponceau SX)",
    "Food Additive Class": "Colour",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "YES",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 18,
    "INS Number": 127,
    "Food Additive Name": "Erythrosine",
    "Food Additive Display Name": "127 (Erythrosine)",
    "Food Additive Class": "Colour",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "YES",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 19,
    "INS Number": 128,
    "Food Additive Name": "Red 2G",
    "Food Additive Display Name": "128 (Red 2G)",
    "Food Additive Class": "Colour",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "YES",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 20,
    "INS Number": 129,
    "Food Additive Name": "Allurared AC/Fast Red E",
    "Food Additive Display Name": "129 (Allurared AC/Fast Red E)",
    "Food Additive Class": "Colour",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "YES",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 21,
    "INS Number": 130,
    "Food Additive Name": "Manascorubin",
    "Food Additive Display Name": "130 (Manascorubin)",
    "Food Additive Class": "Colour",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "YES",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 22,
    "INS Number": 131,
    "Food Additive Name": "Patent blue V",
    "Food Additive Display Name": "131 (Patent blue V)",
    "Food Additive Class": "Colour",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "YES",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 23,
    "INS Number": 132,
    "Food Additive Name": "Indigotine",
    "Food Additive Display Name": "132 (Indigotine)",
    "Food Additive Class": "Colour",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "YES",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 24,
    "INS Number": 133,
    "Food Additive Name": "Brilliant blue FCF",
    "Food Additive Display Name": "133 (Brilliant blue FCF)",
    "Food Additive Class": "Colour",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "YES",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 25,
    "INS Number": 140,
    "Food Additive Name": "Chlorophyll",
    "Food Additive Display Name": "140 (Chlorophyll)",
    "Food Additive Class": "Colour",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "YES",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 26,
    "INS Number": 141,
    "Food Additive Name": "Copper chlorophylls",
    "Food Additive Display Name": "141 (Copper chlorophylls)",
    "Food Additive Class": "Colour",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "YES",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 27,
    "INS Number": "141(i)",
    "Food Additive Name": "Chlorophyll copper complex,",
    "Food Additive Display Name": "141(i) (Chlorophyll copper complex,)",
    "Food Additive Class": "Colour",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "YES",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 28,
    "INS Number": "141(ii)",
    "Food Additive Name":
      "Chlorophyll copper complex, sodium and potassium Salts",
    "Food Additive Display Name":
      "141(ii) (Chlorophyll copper complex, sodium and potassium Salts)",
    "Food Additive Class": "Colour",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "YES",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 29,
    "INS Number": 142,
    "Food Additive Name": "Green S",
    "Food Additive Display Name": "142 (Green S)",
    "Food Additive Class": "Colour",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "YES",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 30,
    "INS Number": 143,
    "Food Additive Name": "Fast green FCF",
    "Food Additive Display Name": "143 (Fast green FCF)",
    "Food Additive Class": "Colour",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "YES",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 31,
    "INS Number": "150a",
    "Food Additive Name": "Caramel I-plain",
    "Food Additive Display Name": "150a (Caramel I-plain)",
    "Food Additive Class": "Colour",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "YES",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 32,
    "INS Number": "150b",
    "Food Additive Name": "Caramel II – caustic sulphite process",
    "Food Additive Display Name":
      "150b (Caramel II – caustic sulphite process)",
    "Food Additive Class": "Colour",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "YES",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 33,
    "INS Number": "150c",
    "Food Additive Name": "Caramel III – ammonia process",
    "Food Additive Display Name": "150c (Caramel III – ammonia process)",
    "Food Additive Class": "Colour",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "YES",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 34,
    "INS Number": "150d",
    "Food Additive Name": "Caramel IV-ammonia sulphite Process",
    "Food Additive Display Name": "150d (Caramel IV-ammonia sulphite Process)",
    "Food Additive Class": "Colour",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "YES",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 35,
    "INS Number": 151,
    "Food Additive Name": "Brilliant black PN",
    "Food Additive Display Name": "151 (Brilliant black PN)",
    "Food Additive Class": "Colour",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "YES",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 36,
    "INS Number": 152,
    "Food Additive Name": "Carbon black (hydrocarbon)",
    "Food Additive Display Name": "152 (Carbon black (hydrocarbon))",
    "Food Additive Class": "Colour",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "YES",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 37,
    "INS Number": 153,
    "Food Additive Name": "Vegetable carbon",
    "Food Additive Display Name": "153 (Vegetable carbon)",
    "Food Additive Class": "Colour",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "YES",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 38,
    "INS Number": 154,
    "Food Additive Name": "Brown FK",
    "Food Additive Display Name": "154 (Brown FK)",
    "Food Additive Class": "Colour",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "YES",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 39,
    "INS Number": 155,
    "Food Additive Name": "Brown HT",
    "Food Additive Display Name": "155 (Brown HT)",
    "Food Additive Class": "Colour",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "YES",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 40,
    "INS Number": "160a",
    "Food Additive Name": "Carotenes",
    "Food Additive Display Name": "160a (Carotenes)",
    "Food Additive Class": "Colour",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "YES",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 41,
    "INS Number": "160a(i)",
    "Food Additive Name": "Beta-carotene (synthetic)",
    "Food Additive Display Name": "160a(i) (Beta-carotene (synthetic))",
    "Food Additive Class": "Colour",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "YES",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 42,
    "INS Number": "160a(ii)",
    "Food Additive Name": "Natural extracts",
    "Food Additive Display Name": "160a(ii) (Natural extracts)",
    "Food Additive Class": "Colour",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "YES",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 43,
    "INS Number": "160b",
    "Food Additive Name": "Annatto extracts",
    "Food Additive Display Name": "160b (Annatto extracts)",
    "Food Additive Class": "Colour",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "YES",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 44,
    "INS Number": "160c",
    "Food Additive Name": "Paprika Oleoresins",
    "Food Additive Display Name": "160c (Paprika Oleoresins)",
    "Food Additive Class": "Colour",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "YES",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 45,
    "INS Number": "160d",
    "Food Additive Name": "Lycopene",
    "Food Additive Display Name": "160d (Lycopene)",
    "Food Additive Class": "Colour",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "YES",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 46,
    "INS Number": "160e",
    "Food Additive Name": "Beta-apo-carotental",
    "Food Additive Display Name": "160e (Beta-apo-carotental)",
    "Food Additive Class": "Colour",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "YES",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 47,
    "INS Number": "160f",
    "Food Additive Name": "Beta-apo-8’-carotenic acid, methyl or ethyl ester",
    "Food Additive Display Name":
      "160f (Beta-apo-8’-carotenic acid, methyl or ethyl ester)",
    "Food Additive Class": "Colour",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "YES",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 48,
    "INS Number": "161a",
    "Food Additive Name": "Flavoxanthin",
    "Food Additive Display Name": "161a (Flavoxanthin)",
    "Food Additive Class": "Colour",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "YES",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 49,
    "INS Number": "161b",
    "Food Additive Name": "Lutein",
    "Food Additive Display Name": "161b (Lutein)",
    "Food Additive Class": "Colour",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "YES",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 50,
    "INS Number": "161c",
    "Food Additive Name": "Krytoxanthin",
    "Food Additive Display Name": "161c (Krytoxanthin)",
    "Food Additive Class": "Colour",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "YES",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 51,
    "INS Number": "161d",
    "Food Additive Name": "Rubixanthin",
    "Food Additive Display Name": "161d (Rubixanthin)",
    "Food Additive Class": "Colour",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "YES",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 52,
    "INS Number": "161e",
    "Food Additive Name": "Violoxanthin",
    "Food Additive Display Name": "161e (Violoxanthin)",
    "Food Additive Class": "Colour",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "YES",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 53,
    "INS Number": "161f",
    "Food Additive Name": "Rhodoxanthin",
    "Food Additive Display Name": "161f (Rhodoxanthin)",
    "Food Additive Class": "Colour",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "YES",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 54,
    "INS Number": "161g",
    "Food Additive Name": "Canthaxanthin",
    "Food Additive Display Name": "161g (Canthaxanthin)",
    "Food Additive Class": "Colour",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "YES",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 55,
    "INS Number": 162,
    "Food Additive Name": "Beet red",
    "Food Additive Display Name": "162 (Beet red)",
    "Food Additive Class": "Colour",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "YES",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 56,
    "INS Number": 163,
    "Food Additive Name": "Anthocyanins",
    "Food Additive Display Name": "163 (Anthocyanins)",
    "Food Additive Class": "Colour",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "YES",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 57,
    "INS Number": "163(i)",
    "Food Additive Name": "Anthocyanins",
    "Food Additive Display Name": "163(i) (Anthocyanins)",
    "Food Additive Class": "Colour",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "YES",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 58,
    "INS Number": "163(ii)",
    "Food Additive Name": "Grape skin extract",
    "Food Additive Display Name": "163(ii) (Grape skin extract)",
    "Food Additive Class": "Colour",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "YES",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 59,
    "INS Number": "163(iii)",
    "Food Additive Name": "Blackcurrant extract",
    "Food Additive Display Name": "163(iii) (Blackcurrant extract)",
    "Food Additive Class": "Colour",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "YES",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 60,
    "INS Number": 164,
    "Food Additive Name": "Gardenia yellow",
    "Food Additive Display Name": "164 (Gardenia yellow)",
    "Food Additive Class": "Colour",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "YES",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 61,
    "INS Number": 166,
    "Food Additive Name": "Sandalwood",
    "Food Additive Display Name": "166 (Sandalwood)",
    "Food Additive Class": "Colour",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "YES",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 62,
    "INS Number": 170,
    "Food Additive Name": "Calcium carbonates",
    "Food Additive Display Name": "170 (Calcium carbonates)",
    "Food Additive Class": "Surface Colourant, Anticaking Agent, Stabilizer",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "YES",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "YES",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 63,
    "INS Number": "170(i)",
    "Food Additive Name": "Calcium carbonate",
    "Food Additive Display Name": "170(i) (Calcium carbonate)",
    "Food Additive Class": "Anticaking Agent",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "YES",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 64,
    "INS Number": "170(ii)",
    "Food Additive Name": "Calcium hydrogen carbonate",
    "Food Additive Display Name": "170(ii) (Calcium hydrogen carbonate)",
    "Food Additive Class": "Anticaking Agent",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "YES",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 65,
    "INS Number": 171,
    "Food Additive Name": "Titanium dioxide",
    "Food Additive Display Name": "171 (Titanium dioxide)",
    "Food Additive Class": "Colour",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "YES",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 66,
    "INS Number": 172,
    "Food Additive Name": "Iron oxides",
    "Food Additive Display Name": "172 (Iron oxides)",
    "Food Additive Class": "Colour",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "YES",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 67,
    "INS Number": "172(i)",
    "Food Additive Name": "Iron oxide, black",
    "Food Additive Display Name": "172(i) (Iron oxide, black)",
    "Food Additive Class": "Colour",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "YES",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 68,
    "INS Number": "172(ii)",
    "Food Additive Name": "Iron oxide, red",
    "Food Additive Display Name": "172(ii) (Iron oxide, red)",
    "Food Additive Class": "Colour",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "YES",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 69,
    "INS Number": "172(iii)",
    "Food Additive Name": "Iron oxide, yellow",
    "Food Additive Display Name": "172(iii) (Iron oxide, yellow)",
    "Food Additive Class": "Colour",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "YES",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 70,
    "INS Number": 173,
    "Food Additive Name": "Aluminium",
    "Food Additive Display Name": "173 (Aluminium)",
    "Food Additive Class": "Colour",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "YES",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 71,
    "INS Number": 174,
    "Food Additive Name": "Silver",
    "Food Additive Display Name": "174 (Silver)",
    "Food Additive Class": "Colour",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "YES",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 72,
    "INS Number": 175,
    "Food Additive Name": "Gold",
    "Food Additive Display Name": "175 (Gold)",
    "Food Additive Class": "Colour",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "YES",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 73,
    "INS Number": 180,
    "Food Additive Name": "Lithol rubine BK",
    "Food Additive Display Name": "180 (Lithol rubine BK)",
    "Food Additive Class": "Colour",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "YES",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 74,
    "INS Number": 181,
    "Food Additive Name": "Tannins, food grade",
    "Food Additive Display Name": "181 (Tannins, food grade)",
    "Food Additive Class": "Colour, Emulsifier, Stabilizer, Thickener",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "YES",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "YES",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "YES",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 75,
    "INS Number": 182,
    "Food Additive Name": "Orchil",
    "Food Additive Display Name": "182 (Orchil)",
    "Food Additive Class": "Colour",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "YES",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 76,
    "INS Number": 200,
    "Food Additive Name": "Sorbic acid",
    "Food Additive Display Name": "200 (Sorbic acid)",
    "Food Additive Class": "Preservative",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "YES",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 77,
    "INS Number": 201,
    "Food Additive Name": "Sodium sorbate",
    "Food Additive Display Name": "201 (Sodium sorbate)",
    "Food Additive Class": "Preservative",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "YES",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 78,
    "INS Number": 202,
    "Food Additive Name": "Potassium sorbate",
    "Food Additive Display Name": "202 (Potassium sorbate)",
    "Food Additive Class": "Preservative",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "YES",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 79,
    "INS Number": 203,
    "Food Additive Name": "Calcium sorbate",
    "Food Additive Display Name": "203 (Calcium sorbate)",
    "Food Additive Class": "Preservative",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "YES",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 80,
    "INS Number": 209,
    "Food Additive Name": "Heptyl p-hydroxybenzoate",
    "Food Additive Display Name": "209 (Heptyl p-hydroxybenzoate)",
    "Food Additive Class": "Preservative",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "YES",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 81,
    "INS Number": 210,
    "Food Additive Name": "Benzoic acid",
    "Food Additive Display Name": "210 (Benzoic acid)",
    "Food Additive Class": "Preservative",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "YES",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 82,
    "INS Number": 211,
    "Food Additive Name": "Sodium benzoate",
    "Food Additive Display Name": "211 (Sodium benzoate)",
    "Food Additive Class": "Preservative",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "YES",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 83,
    "INS Number": 212,
    "Food Additive Name": "Potassium benzoate",
    "Food Additive Display Name": "212 (Potassium benzoate)",
    "Food Additive Class": "Preservative",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "YES",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 84,
    "INS Number": 213,
    "Food Additive Name": "Calcium benzoate",
    "Food Additive Display Name": "213 (Calcium benzoate)",
    "Food Additive Class": "Preservative",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "YES",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 85,
    "INS Number": 214,
    "Food Additive Name": "Ethyl p-hydroxybenzoate",
    "Food Additive Display Name": "214 (Ethyl p-hydroxybenzoate)",
    "Food Additive Class": "Preservative",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "YES",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 86,
    "INS Number": 215,
    "Food Additive Name": "Sodium ethyl p-hydroxybenzoate",
    "Food Additive Display Name": "215 (Sodium ethyl p-hydroxybenzoate)",
    "Food Additive Class": "Preservative",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "YES",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 87,
    "INS Number": 216,
    "Food Additive Name": "Propyl p-hydroxybenzoate",
    "Food Additive Display Name": "216 (Propyl p-hydroxybenzoate)",
    "Food Additive Class": "Preservative",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "YES",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 88,
    "INS Number": 217,
    "Food Additive Name": "Sodium propyl p-hydroxybenzoate",
    "Food Additive Display Name": "217 (Sodium propyl p-hydroxybenzoate)",
    "Food Additive Class": "Preservative",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "YES",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 89,
    "INS Number": 218,
    "Food Additive Name": "Methyl p-hydroxybenzoate",
    "Food Additive Display Name": "218 (Methyl p-hydroxybenzoate)",
    "Food Additive Class": "Preservative",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "YES",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 90,
    "INS Number": 219,
    "Food Additive Name": "Sodium methyl p-hydroxybenzoate",
    "Food Additive Display Name": "219 (Sodium methyl p-hydroxybenzoate)",
    "Food Additive Class": "Preservative",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "YES",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 91,
    "INS Number": 220,
    "Food Additive Name": "Sulphur dioxide",
    "Food Additive Display Name": "220 (Sulphur dioxide)",
    "Food Additive Class": "Preservative, Antioxidant",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "YES",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "YES",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 92,
    "INS Number": 221,
    "Food Additive Name": "Sodium sulphite",
    "Food Additive Display Name": "221 (Sodium sulphite)",
    "Food Additive Class": "Preservative, Antioxidant",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "YES",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "YES",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 93,
    "INS Number": 222,
    "Food Additive Name": "Sodium hydrogen sulphite",
    "Food Additive Display Name": "222 (Sodium hydrogen sulphite)",
    "Food Additive Class": "Preservative, Antioxidant",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "YES",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "YES",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 94,
    "INS Number": 223,
    "Food Additive Name": "Sodium metabisulphite",
    "Food Additive Display Name": "223 (Sodium metabisulphite)",
    "Food Additive Class": "Preservative, Bleaching Agent, Antioxidant",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "YES",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "YES",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "YES",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 95,
    "INS Number": 224,
    "Food Additive Name": "Potassium metabisulphite",
    "Food Additive Display Name": "224 (Potassium metabisulphite)",
    "Food Additive Class": "Preservative, Antioxidant",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "YES",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "YES",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 96,
    "INS Number": 225,
    "Food Additive Name": "Potassium sulphite",
    "Food Additive Display Name": "225 (Potassium sulphite)",
    "Food Additive Class": "Preservative, Antioxidant",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "YES",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "YES",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 97,
    "INS Number": 226,
    "Food Additive Name": "Calcium sulphite",
    "Food Additive Display Name": "226 (Calcium sulphite)",
    "Food Additive Class": "Preservative, Antioxidant",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "YES",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "YES",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 98,
    "INS Number": 227,
    "Food Additive Name": "Calcium hydrogen sulphite",
    "Food Additive Display Name": "227 (Calcium hydrogen sulphite)",
    "Food Additive Class": "Preservative, Antioxidant",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "YES",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "YES",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 99,
    "INS Number": 228,
    "Food Additive Name": "Potassium bisulphate",
    "Food Additive Display Name": "228 (Potassium bisulphate)",
    "Food Additive Class": "Preservative, Antioxidant",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "YES",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "YES",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 100,
    "INS Number": 230,
    "Food Additive Name": "Diphenyl",
    "Food Additive Display Name": "230 (Diphenyl)",
    "Food Additive Class": "Preservative",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "YES",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 101,
    "INS Number": 231,
    "Food Additive Name": "Ortho-phenylphenol",
    "Food Additive Display Name": "231 (Ortho-phenylphenol)",
    "Food Additive Class": "Preservative",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "YES",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 102,
    "INS Number": 232,
    "Food Additive Name": "Sodium o-phenylphenol",
    "Food Additive Display Name": "232 (Sodium o-phenylphenol)",
    "Food Additive Class": "Preservative",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "YES",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 103,
    "INS Number": 233,
    "Food Additive Name": "Thiabendazole",
    "Food Additive Display Name": "233 (Thiabendazole)",
    "Food Additive Class": "Preservative",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "YES",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 104,
    "INS Number": 234,
    "Food Additive Name": "Nisin",
    "Food Additive Display Name": "234 (Nisin)",
    "Food Additive Class": "Preservative",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "YES",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 105,
    "INS Number": 235,
    "Food Additive Name": "Pimaricin (natamycin)",
    "Food Additive Display Name": "235 (Pimaricin (natamycin))",
    "Food Additive Class": "Preservative",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "YES",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 106,
    "INS Number": 236,
    "Food Additive Name": "Formic acid",
    "Food Additive Display Name": "236 (Formic acid)",
    "Food Additive Class": "Preservative",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "YES",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 107,
    "INS Number": 237,
    "Food Additive Name": "Sodium formate",
    "Food Additive Display Name": "237 (Sodium formate)",
    "Food Additive Class": "Preservative",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "YES",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 108,
    "INS Number": 238,
    "Food Additive Name": "Calcium formate",
    "Food Additive Display Name": "238 (Calcium formate)",
    "Food Additive Class": "Preservative",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "YES",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 109,
    "INS Number": 239,
    "Food Additive Name": "Hexamethylene tetramine",
    "Food Additive Display Name": "239 (Hexamethylene tetramine)",
    "Food Additive Class": "Preservative",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "YES",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 110,
    "INS Number": 240,
    "Food Additive Name": "Formaldehyde",
    "Food Additive Display Name": "240 (Formaldehyde)",
    "Food Additive Class": "Preservative",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "YES",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 111,
    "INS Number": 241,
    "Food Additive Name": "Gum guaicum",
    "Food Additive Display Name": "241 (Gum guaicum)",
    "Food Additive Class": "Preservative",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "YES",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 112,
    "INS Number": 242,
    "Food Additive Name": "Dimethyl dicarbonate",
    "Food Additive Display Name": "242 (Dimethyl dicarbonate)",
    "Food Additive Class": "Preservative",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "YES",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 113,
    "INS Number": 249,
    "Food Additive Name": "Potassium nitrite",
    "Food Additive Display Name": "249 (Potassium nitrite)",
    "Food Additive Class": "Preservative, Colour Fixative",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "YES",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "YES",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 114,
    "INS Number": 250,
    "Food Additive Name": "Sodium nitrite",
    "Food Additive Display Name": "250 (Sodium nitrite)",
    "Food Additive Class": "Preservative, Colour Fixative",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "YES",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "YES",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 115,
    "INS Number": 251,
    "Food Additive Name": "Sodium nitrate",
    "Food Additive Display Name": "251 (Sodium nitrate)",
    "Food Additive Class": "Preservative, Colour Fixative",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "YES",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "YES",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 116,
    "INS Number": 252,
    "Food Additive Name": "Potassium nitrate",
    "Food Additive Display Name": "252 (Potassium nitrate)",
    "Food Additive Class": "Preservative, Colour Fixative",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "YES",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "YES",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 117,
    "INS Number": 260,
    "Food Additive Name": "Acetic acid, glacial",
    "Food Additive Display Name": "260 (Acetic acid, glacial)",
    "Food Additive Class": "Preservative, Acidity Regulator",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "YES",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 118,
    "INS Number": 261,
    "Food Additive Name": "Potassium acetates",
    "Food Additive Display Name": "261 (Potassium acetates)",
    "Food Additive Class": "Preservative, Acidity Regulator",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "YES",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 119,
    "INS Number": "261(i)",
    "Food Additive Name": "Potassium acetate",
    "Food Additive Display Name": "261(i) (Potassium acetate)",
    "Food Additive Class": "Preservative, Acidity Regulator",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "YES",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 120,
    "INS Number": "261(ii)",
    "Food Additive Name": "Potassium diacetate",
    "Food Additive Display Name": "261(ii) (Potassium diacetate)",
    "Food Additive Class": "Preservative, Acidity Regulator",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "YES",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 121,
    "INS Number": 262,
    "Food Additive Name": "Sodium acetates",
    "Food Additive Display Name": "262 (Sodium acetates)",
    "Food Additive Class": "Preservative, Acidity Regulator, Sequestrant",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "YES",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "YES",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 122,
    "INS Number": "262(i)",
    "Food Additive Name": "Sodium acetate",
    "Food Additive Display Name": "262(i) (Sodium acetate)",
    "Food Additive Class": "Preservative, Acidity Regulator, Sequestrant",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "YES",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "YES",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 123,
    "INS Number": "262(ii)",
    "Food Additive Name": "Sodium diacetate",
    "Food Additive Display Name": "262(ii) (Sodium diacetate)",
    "Food Additive Class": "Preservative, Acidity Regulator, Sequestrant",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "YES",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "YES",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 124,
    "INS Number": 263,
    "Food Additive Name": "Calcium acetate",
    "Food Additive Display Name": "263 (Calcium acetate)",
    "Food Additive Class": "Preservative, Stabilizer, Acidity Regulator",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "YES",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 125,
    "INS Number": 264,
    "Food Additive Name": "Ammonium acetate",
    "Food Additive Display Name": "264 (Ammonium acetate)",
    "Food Additive Class": "Acidity Regulator",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 126,
    "INS Number": 265,
    "Food Additive Name": "Dehydroacetic acid",
    "Food Additive Display Name": "265 (Dehydroacetic acid)",
    "Food Additive Class": "Preservative",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "YES",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 127,
    "INS Number": 266,
    "Food Additive Name": "Sodium dehydroacetate",
    "Food Additive Display Name": "266 (Sodium dehydroacetate)",
    "Food Additive Class": "Preservative",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "YES",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 128,
    "INS Number": 270,
    "Food Additive Name": "Lactic acid (L-, D—and Dl-)",
    "Food Additive Display Name": "270 (Lactic acid (L-, D—and Dl-))",
    "Food Additive Class": "Acidity Regulator",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 129,
    "INS Number": 280,
    "Food Additive Name": "Propionic acid",
    "Food Additive Display Name": "280 (Propionic acid)",
    "Food Additive Class": "Preservative",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "YES",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 130,
    "INS Number": 281,
    "Food Additive Name": "Sodium propionate",
    "Food Additive Display Name": "281 (Sodium propionate)",
    "Food Additive Class": "Preservative",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "YES",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 131,
    "INS Number": 282,
    "Food Additive Name": "Calcium propionate",
    "Food Additive Display Name": "282 (Calcium propionate)",
    "Food Additive Class": "Preservative",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "YES",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 132,
    "INS Number": 283,
    "Food Additive Name": "Potassium propionate",
    "Food Additive Display Name": "283 (Potassium propionate)",
    "Food Additive Class": "Preservative",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "YES",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 133,
    "INS Number": 290,
    "Food Additive Name": "Carbon dioxide",
    "Food Additive Display Name": "290 (Carbon dioxide)",
    "Food Additive Class": "Carbonating Agent, Packing Agent",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "YES",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "YES",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 134,
    "INS Number": 296,
    "Food Additive Name": "Malic acid (DL-L-)",
    "Food Additive Display Name": "296 (Malic acid (DL-L-))",
    "Food Additive Class": "Acidity Regulator, Flavouring Agent",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "YES",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 135,
    "INS Number": 297,
    "Food Additive Name": "Fumaric acid",
    "Food Additive Display Name": "297 (Fumaric acid)",
    "Food Additive Class": "Acidity Regulator",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 136,
    "INS Number": 300,
    "Food Additive Name": "Ascorbic acid (L)",
    "Food Additive Display Name": "300 (Ascorbic acid (L))",
    "Food Additive Class": "Antioxidant",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "YES",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 137,
    "INS Number": 301,
    "Food Additive Name": "Sodium ascorbate",
    "Food Additive Display Name": "301 (Sodium ascorbate)",
    "Food Additive Class": "Antioxidant",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "YES",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 138,
    "INS Number": 302,
    "Food Additive Name": "Calcium ascorbate",
    "Food Additive Display Name": "302 (Calcium ascorbate)",
    "Food Additive Class": "Antioxidant",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "YES",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 139,
    "INS Number": 303,
    "Food Additive Name": "Potassium ascorbate",
    "Food Additive Display Name": "303 (Potassium ascorbate)",
    "Food Additive Class": "Antioxidant",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "YES",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 140,
    "INS Number": 304,
    "Food Additive Name": "Ascorbyl palmitate",
    "Food Additive Display Name": "304 (Ascorbyl palmitate)",
    "Food Additive Class": "Antioxidant",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "YES",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 141,
    "INS Number": 305,
    "Food Additive Name": "Ascorbyl stearate",
    "Food Additive Display Name": "305 (Ascorbyl stearate)",
    "Food Additive Class": "Antioxidant",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "YES",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 142,
    "INS Number": 306,
    "Food Additive Name": "Mixed tocopherols",
    "Food Additive Display Name": "306 (Mixed tocopherols)",
    "Food Additive Class": "Antioxidant",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "YES",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 143,
    "INS Number": 307,
    "Food Additive Name": "Alpha-tocopherol",
    "Food Additive Display Name": "307 (Alpha-tocopherol)",
    "Food Additive Class": "Antioxidant",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "YES",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 144,
    "INS Number": 308,
    "Food Additive Name": "Synthetic gamma-tocopherol",
    "Food Additive Display Name": "308 (Synthetic gamma-tocopherol)",
    "Food Additive Class": "Antioxidant",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "YES",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 145,
    "INS Number": 309,
    "Food Additive Name": "Synthetic delta-tocopherol",
    "Food Additive Display Name": "309 (Synthetic delta-tocopherol)",
    "Food Additive Class": "Antioxidant",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "YES",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 146,
    "INS Number": 310,
    "Food Additive Name": "Propyl gallate",
    "Food Additive Display Name": "310 (Propyl gallate)",
    "Food Additive Class": "Antioxidant",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "YES",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 147,
    "INS Number": 311,
    "Food Additive Name": "Octyl gallate",
    "Food Additive Display Name": "311 (Octyl gallate)",
    "Food Additive Class": "Antioxidant",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "YES",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 148,
    "INS Number": 312,
    "Food Additive Name": "Dodecyl gallate",
    "Food Additive Display Name": "312 (Dodecyl gallate)",
    "Food Additive Class": "Antioxidant",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "YES",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 149,
    "INS Number": 313,
    "Food Additive Name": "Ethyl gallate",
    "Food Additive Display Name": "313 (Ethyl gallate)",
    "Food Additive Class": "Antioxidant",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "YES",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 150,
    "INS Number": 314,
    "Food Additive Name": "Guaiac resin",
    "Food Additive Display Name": "314 (Guaiac resin)",
    "Food Additive Class": "Antioxidant",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "YES",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 151,
    "INS Number": 315,
    "Food Additive Name": "Isoascorbic acid",
    "Food Additive Display Name": "315 (Isoascorbic acid)",
    "Food Additive Class": "Antioxidant",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "YES",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 152,
    "INS Number": 316,
    "Food Additive Name": "Sodium isoascorbate",
    "Food Additive Display Name": "316 (Sodium isoascorbate)",
    "Food Additive Class": "Antioxidant",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "YES",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 153,
    "INS Number": 317,
    "Food Additive Name": "Potassium isoascorbate",
    "Food Additive Display Name": "317 (Potassium isoascorbate)",
    "Food Additive Class": "Antioxidant",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "YES",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 154,
    "INS Number": 318,
    "Food Additive Name": "Calcium isoascrobate",
    "Food Additive Display Name": "318 (Calcium isoascrobate)",
    "Food Additive Class": "Antioxidant",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "YES",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 155,
    "INS Number": 319,
    "Food Additive Name": "Tertiary butylhydroquinone",
    "Food Additive Display Name": "319 (Tertiary butylhydroquinone)",
    "Food Additive Class": "Antioxidant",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "YES",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 156,
    "INS Number": 320,
    "Food Additive Name": "Butylated hydroxyanisole",
    "Food Additive Display Name": "320 (Butylated hydroxyanisole)",
    "Food Additive Class": "Antioxidant",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "YES",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 157,
    "INS Number": 321,
    "Food Additive Name": "Butylated hydroxytoluene",
    "Food Additive Display Name": "321 (Butylated hydroxytoluene)",
    "Food Additive Class": "Antioxidant",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "YES",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 158,
    "INS Number": 322,
    "Food Additive Name": "Lecithins",
    "Food Additive Display Name": "322 (Lecithins)",
    "Food Additive Class": "Antioxidant, Emulsifier",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "YES",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "YES",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 159,
    "INS Number": 323,
    "Food Additive Name": "Anoxomer",
    "Food Additive Display Name": "323 (Anoxomer)",
    "Food Additive Class": "Antioxidant",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "YES",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 160,
    "INS Number": 324,
    "Food Additive Name": "Ethoxyquin",
    "Food Additive Display Name": "324 (Ethoxyquin)",
    "Food Additive Class": "Antioxidant",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "YES",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 161,
    "INS Number": 325,
    "Food Additive Name": "Sodium lactate",
    "Food Additive Display Name": "325 (Sodium lactate)",
    "Food Additive Class": "Antioxidant, Synergist, Humectant, Bulking Agent",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "YES",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "YES",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "YES",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 162,
    "INS Number": 326,
    "Food Additive Name": "Potassium lactate",
    "Food Additive Display Name": "326 (Potassium lactate)",
    "Food Additive Class": "Antioxidant, Synergist, Acidity Regulator",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "YES",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 163,
    "INS Number": 327,
    "Food Additive Name": "Calcium lactate",
    "Food Additive Display Name": "327 (Calcium lactate)",
    "Food Additive Class": "Acidity Regulator, Flour Treatment Agent",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "YES",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 164,
    "INS Number": 328,
    "Food Additive Name": "Ammonium lactate",
    "Food Additive Display Name": "328 (Ammonium lactate)",
    "Food Additive Class": "Acidity Regulator, Flour Treatment Agent",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "YES",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 165,
    "INS Number": 329,
    "Food Additive Name": "Magnesium lactate (D-,L-)",
    "Food Additive Display Name": "329 (Magnesium lactate (D-,L-))",
    "Food Additive Class": "Acidity Regulator, Flour Treatment Agent",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "YES",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 166,
    "INS Number": 330,
    "Food Additive Name": "Citric acid",
    "Food Additive Display Name": "330 (Citric acid)",
    "Food Additive Class": "Acidity Regulator, Synergist For Sequestrant",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "YES",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 167,
    "INS Number": 331,
    "Food Additive Name": "Sodium citrates",
    "Food Additive Display Name": "331 (Sodium citrates)",
    "Food Additive Class":
      "Acidity Regulator, Sequestrant Emulsifier Stabilizer",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "YES",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "YES",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 168,
    "INS Number": "331(i)",
    "Food Additive Name": "Sodium dihydrogen citrate",
    "Food Additive Display Name": "331(i) (Sodium dihydrogen citrate)",
    "Food Additive Class":
      "Acidity Regulator, Sequestrant Emulsifier, Stabilizer",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "YES",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 169,
    "INS Number": "331(ii)",
    "Food Additive Name": "Disodium monohydrogen citrate",
    "Food Additive Display Name": "331(ii) (Disodium monohydrogen citrate)",
    "Food Additive Class":
      "Acidity Regulator, Stabilizer, Sequestrant, Emulsifier",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "YES",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "YES",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 170,
    "INS Number": "331(iii)",
    "Food Additive Name": "Trisodium citrate",
    "Food Additive Display Name": "331(iii) (Trisodium citrate)",
    "Food Additive Class":
      "Acidity Regulator, Sequestrant, Emulsifier, Stabilizer",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "YES",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "YES",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 171,
    "INS Number": 332,
    "Food Additive Name": "Potassium citrates",
    "Food Additive Display Name": "332 (Potassium citrates)",
    "Food Additive Class": "Acidity Regulator, Sequestrant, Stabilizer",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "YES",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 172,
    "INS Number": "332(i)",
    "Food Additive Name": "Potassium dihydrogen citrate",
    "Food Additive Display Name": "332(i) (Potassium dihydrogen citrate)",
    "Food Additive Class": "Acidity Regulator, Sequestrant, Stabilizer",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "YES",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 173,
    "INS Number": "332(ii)",
    "Food Additive Name": "Tripotassium citrate",
    "Food Additive Display Name": "332(ii) (Tripotassium citrate)",
    "Food Additive Class": "Acidity Regulator, Sequestrant, Stabilizer",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "YES",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 174,
    "INS Number": 333,
    "Food Additive Name": "calcium citrates",
    "Food Additive Display Name": "333 (calcium citrates)",
    "Food Additive Class": "Acidity Regulator, Firming Agent, Sequestrant",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "YES",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "YES",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 175,
    "INS Number": 334,
    "Food Additive Name": "Tartaric acid [L(+)-]",
    "Food Additive Display Name": "334 (Tartaric acid [L(+)-])",
    "Food Additive Class":
      "Acidity Regulator, Sequestrant, Antioxidant Synergist",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "YES",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "YES",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 176,
    "INS Number": 335,
    "Food Additive Name": "Sodium tartrates",
    "Food Additive Display Name": "335 (Sodium tartrates)",
    "Food Additive Class": "Stabilizer, Sequestrant,",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "YES",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 177,
    "INS Number": "335(i)",
    "Food Additive Name": "Monosodium tartrate",
    "Food Additive Display Name": "335(i) (Monosodium tartrate)",
    "Food Additive Class": "Stabilizer, Sequestrant",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "YES",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 178,
    "INS Number": "335(ii)",
    "Food Additive Name": "Disodium tartrate",
    "Food Additive Display Name": "335(ii) (Disodium tartrate)",
    "Food Additive Class": "Stabilizer, Sequestrant",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "YES",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 179,
    "INS Number": 336,
    "Food Additive Name": "Potassium tartrate",
    "Food Additive Display Name": "336 (Potassium tartrate)",
    "Food Additive Class": "Stabilizer, Sequestrant",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "YES",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 180,
    "INS Number": "336(i)",
    "Food Additive Name": "Monopotassium tartrate",
    "Food Additive Display Name": "336(i) (Monopotassium tartrate)",
    "Food Additive Class": "Stabilizer, Sequestrant",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "YES",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 181,
    "INS Number": "336(ii)",
    "Food Additive Name": "Dipotassium tartrate",
    "Food Additive Display Name": "336(ii) (Dipotassium tartrate)",
    "Food Additive Class": "Stabilizer, Sequestrant",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "YES",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 182,
    "INS Number": 337,
    "Food Additive Name": "Potassium sodium tartrate",
    "Food Additive Display Name": "337 (Potassium sodium tartrate)",
    "Food Additive Class": "Stabilizer, Sequestrant",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "YES",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 183,
    "INS Number": 338,
    "Food Additive Name": "Orthophosphoric acid",
    "Food Additive Display Name": "338 (Orthophosphoric acid)",
    "Food Additive Class": "Acidity Regulator, Antioxidant Synergist",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "YES",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 184,
    "INS Number": 339,
    "Food Additive Name": "Sodium phosphates",
    "Food Additive Display Name": "339 (Sodium phosphates)",
    "Food Additive Class":
      "Acidity Regulator, Texturizer, Sequestrant, Stabilizer Emulsifier, Water Retention Agent",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "YES",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "YES",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "YES",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "YES",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 185,
    "INS Number": "339(i)",
    "Food Additive Name": "Monosodium orthophosphate",
    "Food Additive Display Name": "339(i) (Monosodium orthophosphate)",
    "Food Additive Class":
      "Acidity Regulator, Texturizer, Sequestrant, Stabilizer, Emulsifier, Water Retention Agent",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "YES",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "YES",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "YES",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "YES",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 186,
    "INS Number": "339(ii)",
    "Food Additive Name": "Disodium orthophosphate",
    "Food Additive Display Name": "339(ii) (Disodium orthophosphate)",
    "Food Additive Class":
      "Acidity Regulator, Texturizer, Sequestrant, Stabilizer Emulsifier, Water Retention Agent",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "YES",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "YES",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "YES",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "YES",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 187,
    "INS Number": "339(iii)",
    "Food Additive Name": "Trisodium orthophosphate",
    "Food Additive Display Name": "339(iii) (Trisodium orthophosphate)",
    "Food Additive Class":
      "Sequestrant, Stabilizer, Emulsifier, Water Retention Agent, Acidity Regulator, Texturizer",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "YES",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "YES",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "YES",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "YES",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 188,
    "INS Number": 340,
    "Food Additive Name": "Potassium Phosphates",
    "Food Additive Display Name": "340 (Potassium Phosphates)",
    "Food Additive Class":
      "Acidity Regulator, Texturizer, Sequestrant, Stabilizer, Emulsifier, Water Retention Agent",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "YES",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "YES",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "YES",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "YES",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 189,
    "INS Number": "340(i)",
    "Food Additive Name": "Monopotassium orthophosphate",
    "Food Additive Display Name": "340(i) (Monopotassium orthophosphate)",
    "Food Additive Class":
      "Acidity Regulator, Texturizer, Sequestrant, Stabilizer Emulsifier, Water Retention Agent",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "YES",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "YES",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "YES",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "YES",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 190,
    "INS Number": "340(ii)",
    "Food Additive Name": "Dipotassium orthophosphate",
    "Food Additive Display Name": "340(ii) (Dipotassium orthophosphate)",
    "Food Additive Class":
      "Acidity Regulator, Texturizer, Sequestrant, Stabilizer, Emulsifier, Water Retention Agent",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "YES",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "YES",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "YES",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "YES",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 191,
    "INS Number": "340(iii)",
    "Food Additive Name": "Tripotassium orthophosphate",
    "Food Additive Display Name": "340(iii) (Tripotassium orthophosphate)",
    "Food Additive Class":
      "Acidity Regulator, Texturizer, Sequestrant, Stabilizer, Emulsifier, Water Retention Agent",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "YES",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "YES",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "YES",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "YES",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 192,
    "INS Number": 341,
    "Food Additive Name": "Calcium phosphates",
    "Food Additive Display Name": "341 (Calcium phosphates)",
    "Food Additive Class":
      "Acidity Regulator, Texturizer, Water Retention Agent, Flour Treatment Agent, Raising Agent, Firming Agent, Anticaking Agent",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "YES",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "YES",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "YES",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "YES",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "YES",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "YES",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 193,
    "INS Number": "341(i)",
    "Food Additive Name": "Monocalcium orthophosphate",
    "Food Additive Display Name": "341(i) (Monocalcium orthophosphate)",
    "Food Additive Class":
      "Acidity Regulator, Texturizer, Water Retention Agent, Flour Treatment Agent, Firming Agent, Anticaking Agent",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "YES",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "YES",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "YES",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "YES",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "YES",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 194,
    "INS Number": "341(ii)",
    "Food Additive Name": "Dicalcium orthophosphate",
    "Food Additive Display Name": "341(ii) (Dicalcium orthophosphate)",
    "Food Additive Class":
      "Acidity Regulator, Texturizer, Flour Treatment Agent, Raising Agent, Firming Agent, Anticaking Agent",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "YES",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "YES",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "YES",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "YES",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "YES",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 195,
    "INS Number": "341(iii)",
    "Food Additive Name": "Tricalcium orthophosphate",
    "Food Additive Display Name": "341(iii) (Tricalcium orthophosphate)",
    "Food Additive Class":
      "Acidity Regulator, Texturizer, Water Retention Agent, Flour Treatment Agent, Firming Agent, Anticaking Agent",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "YES",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "YES",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "YES",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "YES",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "YES",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 196,
    "INS Number": 342,
    "Food Additive Name": "Ammonium phosphates",
    "Food Additive Display Name": "342 (Ammonium phosphates)",
    "Food Additive Class": "Acidity Regulator, Flour Treatment Agent",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "YES",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 197,
    "INS Number": "342(i)",
    "Food Additive Name": "Monoamonium orthophosphate",
    "Food Additive Display Name": "342(i) (Monoamonium orthophosphate)",
    "Food Additive Class": "Acidity Regulator, Flour Treatment Agent",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "YES",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 198,
    "INS Number": "342(ii)",
    "Food Additive Name": "Diammonium orthophosphate",
    "Food Additive Display Name": "342(ii) (Diammonium orthophosphate)",
    "Food Additive Class": "Acidity Regulator, Flour Treatment Agent",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "YES",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 199,
    "INS Number": 343,
    "Food Additive Name": "Magnesium phosphates",
    "Food Additive Display Name": "343 (Magnesium phosphates)",
    "Food Additive Class": "Acidity Regulator, Anticaking Agent",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "YES",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 200,
    "INS Number": "343(i)",
    "Food Additive Name": "Monomagnesium orthophosphate",
    "Food Additive Display Name": "343(i) (Monomagnesium orthophosphate)",
    "Food Additive Class": "Acidity Regulator, Anticaking Agent",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "YES",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 201,
    "INS Number": "343(ii)",
    "Food Additive Name": "Dimagnesium orthophosphate",
    "Food Additive Display Name": "343(ii) (Dimagnesium orthophosphate)",
    "Food Additive Class": "Acidity Regulator, Anticaking Agent",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "YES",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 202,
    "INS Number": "343(iii)",
    "Food Additive Name": "Trimagnesium orthophosphate",
    "Food Additive Display Name": "343(iii) (Trimagnesium orthophosphate)",
    "Food Additive Class": "Acidity Regulator, Anticaking Agent",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "YES",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 203,
    "INS Number": 344,
    "Food Additive Name": "Lecithin citrate",
    "Food Additive Display Name": "344 (Lecithin citrate)",
    "Food Additive Class": "Preservative",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "YES",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 204,
    "INS Number": 345,
    "Food Additive Name": "Magnesium citrate",
    "Food Additive Display Name": "345 (Magnesium citrate)",
    "Food Additive Class": "Acidity Regulator",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 205,
    "INS Number": 349,
    "Food Additive Name": "Ammonium malate",
    "Food Additive Display Name": "349 (Ammonium malate)",
    "Food Additive Class": "Acidity Regulator",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 206,
    "INS Number": 350,
    "Food Additive Name": "Sodium malates",
    "Food Additive Display Name": "350 (Sodium malates)",
    "Food Additive Class": "Acidity Regulator, Humectant",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "YES",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 207,
    "INS Number": "350(i)",
    "Food Additive Name": "Sodium hydrogen malate",
    "Food Additive Display Name": "350(i) (Sodium hydrogen malate)",
    "Food Additive Class": "Acidity Regulator, Humectant",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "YES",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 208,
    "INS Number": "350(ii)",
    "Food Additive Name": "Sodium malate",
    "Food Additive Display Name": "350(ii) (Sodium malate)",
    "Food Additive Class": "Acidity Regulator, Humectant",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "YES",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 209,
    "INS Number": 351,
    "Food Additive Name": "Potassium malates.",
    "Food Additive Display Name": "351 (Potassium malates.)",
    "Food Additive Class": "Acidity Regulator",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 210,
    "INS Number": "351(i)",
    "Food Additive Name": "Potassium hydrogen malate",
    "Food Additive Display Name": "351(i) (Potassium hydrogen malate)",
    "Food Additive Class": "Acidity Regulator",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 211,
    "INS Number": "351(ii)",
    "Food Additive Name": "Potassium malate",
    "Food Additive Display Name": "351(ii) (Potassium malate)",
    "Food Additive Class": "Acidity Regulator",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 212,
    "INS Number": 352,
    "Food Additive Name": "Calcium malates",
    "Food Additive Display Name": "352 (Calcium malates)",
    "Food Additive Class": "Acidity Regulator",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 213,
    "INS Number": "352(i)",
    "Food Additive Name": "Calcium hydrogen malate",
    "Food Additive Display Name": "352(i) (Calcium hydrogen malate)",
    "Food Additive Class": "Acidity Regulator",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 214,
    "INS Number": "352(ii)",
    "Food Additive Name": "Calcium malate",
    "Food Additive Display Name": "352(ii) (Calcium malate)",
    "Food Additive Class": "Acidity Regulator",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 215,
    "INS Number": 353,
    "Food Additive Name": "Metatartaric acid",
    "Food Additive Display Name": "353 (Metatartaric acid)",
    "Food Additive Class": "Acidity Regulator",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 216,
    "INS Number": 354,
    "Food Additive Name": "Calcium tartrate",
    "Food Additive Display Name": "354 (Calcium tartrate)",
    "Food Additive Class": "Acidity Regulator",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 217,
    "INS Number": 355,
    "Food Additive Name": "Adipic acid",
    "Food Additive Display Name": "355 (Adipic acid)",
    "Food Additive Class": "Acidity Regulator",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 218,
    "INS Number": 356,
    "Food Additive Name": "Sodium adipates",
    "Food Additive Display Name": "356 (Sodium adipates)",
    "Food Additive Class": "Acidity Regulator",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 219,
    "INS Number": 357,
    "Food Additive Name": "Potassium adipates",
    "Food Additive Display Name": "357 (Potassium adipates)",
    "Food Additive Class": "Acidity Regulator",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 220,
    "INS Number": 359,
    "Food Additive Name": "Ammonium adipates",
    "Food Additive Display Name": "359 (Ammonium adipates)",
    "Food Additive Class": "Acidity Regulator",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 221,
    "INS Number": 363,
    "Food Additive Name": "Succinic acid",
    "Food Additive Display Name": "363 (Succinic acid)",
    "Food Additive Class": "Acidity Regulator",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 222,
    "INS Number": "364(i)",
    "Food Additive Name": "Monosodium succinate",
    "Food Additive Display Name": "364(i) (Monosodium succinate)",
    "Food Additive Class": "Acidity Regulator, Flavour Enhancer",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "YES",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 223,
    "INS Number": "364(ii)",
    "Food Additive Name": "Disodium succinate",
    "Food Additive Display Name": "364(ii) (Disodium succinate)",
    "Food Additive Class": "Acidity Regulator, Flavour Enhancer",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "YES",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 224,
    "INS Number": 365,
    "Food Additive Name": "Sodium fumarates",
    "Food Additive Display Name": "365 (Sodium fumarates)",
    "Food Additive Class": "Acidity Regulator",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 225,
    "INS Number": 366,
    "Food Additive Name": "Potassium fumarates",
    "Food Additive Display Name": "366 (Potassium fumarates)",
    "Food Additive Class": "Acidity Regulator",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 226,
    "INS Number": 367,
    "Food Additive Name": "Calcium fumarates",
    "Food Additive Display Name": "367 (Calcium fumarates)",
    "Food Additive Class": "Acidity Regulator",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 227,
    "INS Number": 368,
    "Food Additive Name": "Ammonium fumarates",
    "Food Additive Display Name": "368 (Ammonium fumarates)",
    "Food Additive Class": "Acidity Regulator",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 228,
    "INS Number": 370,
    "Food Additive Name": "1, 4-Heptonolactone",
    "Food Additive Display Name": "370 (1, 4-Heptonolactone)",
    "Food Additive Class": "Acidity Regulator, Sequestrant",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "YES",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 229,
    "INS Number": 375,
    "Food Additive Name": "Nicotinic acid",
    "Food Additive Display Name": "375 (Nicotinic acid)",
    "Food Additive Class": "Colour Retention Agent",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "YES",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 230,
    "INS Number": 380,
    "Food Additive Name": "Ammonium citrates",
    "Food Additive Display Name": "380 (Ammonium citrates)",
    "Food Additive Class": "Acidity Regulator",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 231,
    "INS Number": 381,
    "Food Additive Name": "Ferric ammonium citrate",
    "Food Additive Display Name": "381 (Ferric ammonium citrate)",
    "Food Additive Class": "Anticaking Agent",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "YES",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 232,
    "INS Number": 383,
    "Food Additive Name": "Calcium glycerophosphate",
    "Food Additive Display Name": "383 (Calcium glycerophosphate)",
    "Food Additive Class": "Thickener, Gelling Agent, Stabilizer",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "YES",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "YES",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 233,
    "INS Number": 384,
    "Food Additive Name": "Isopropyl citrates",
    "Food Additive Display Name": "384 (Isopropyl citrates)",
    "Food Additive Class": "Antioxidant, Preservative, Sequestrant",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "YES",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "YES",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "YES",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 234,
    "INS Number": 385,
    "Food Additive Name": "Calcium disodium ethylene- diamine-tetra-acetate",
    "Food Additive Display Name":
      "385 (Calcium disodium ethylene- diamine-tetra-acetate)",
    "Food Additive Class": "Antioxidant, Preservative, Sequestrant",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "YES",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "YES",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "YES",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 235,
    "INS Number": 386,
    "Food Additive Name": "Disodium ethylene-diamine-tetra-acetate",
    "Food Additive Display Name":
      "386 (Disodium ethylene-diamine-tetra-acetate)",
    "Food Additive Class": "Antioxidant, Preservative, Sequestrant",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "YES",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "YES",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "YES",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 236,
    "INS Number": 387,
    "Food Additive Name": "Oxy stearin",
    "Food Additive Display Name": "387 (Oxy stearin)",
    "Food Additive Class": "Antioxidant, Sequestrant",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "YES",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "YES",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 237,
    "INS Number": 388,
    "Food Additive Name": "Thiodipropionic acid",
    "Food Additive Display Name": "388 (Thiodipropionic acid)",
    "Food Additive Class": "Antioxidant",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "YES",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 238,
    "INS Number": 389,
    "Food Additive Name": "Dilauryl thiodipropionate",
    "Food Additive Display Name": "389 (Dilauryl thiodipropionate)",
    "Food Additive Class": "Antioxidant",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "YES",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 239,
    "INS Number": 390,
    "Food Additive Name": "Distearyl thiodipropionate",
    "Food Additive Display Name": "390 (Distearyl thiodipropionate)",
    "Food Additive Class": "Antioxidant",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "YES",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 240,
    "INS Number": 391,
    "Food Additive Name": "Phytic acid",
    "Food Additive Display Name": "391 (Phytic acid)",
    "Food Additive Class": "Antioxidant",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "YES",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 241,
    "INS Number": 399,
    "Food Additive Name": "Calcium lactobionate",
    "Food Additive Display Name": "399 (Calcium lactobionate)",
    "Food Additive Class": "Stabilizer",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 242,
    "INS Number": 400,
    "Food Additive Name": "Alginic acid",
    "Food Additive Display Name": "400 (Alginic acid)",
    "Food Additive Class": "Thickener, Stabilizer",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "YES",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 243,
    "INS Number": 401,
    "Food Additive Name": "Sodium alginate",
    "Food Additive Display Name": "401 (Sodium alginate)",
    "Food Additive Class": "Thickener, Stabilizer, Gelling Agent",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "YES",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "YES",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 244,
    "INS Number": 402,
    "Food Additive Name": "Potassium alginate",
    "Food Additive Display Name": "402 (Potassium alginate)",
    "Food Additive Class": "Thickener, Stabilizer",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "YES",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 245,
    "INS Number": 403,
    "Food Additive Name": "Ammonium alginate",
    "Food Additive Display Name": "403 (Ammonium alginate)",
    "Food Additive Class": "Thickener, Stabilizer",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "YES",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 246,
    "INS Number": 404,
    "Food Additive Name": "Calcium alginate",
    "Food Additive Display Name": "404 (Calcium alginate)",
    "Food Additive Class":
      "Thickener, Stabilizer, Gelling Agent, Antifoaming Agent",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "YES",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "YES",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "YES",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 247,
    "INS Number": 405,
    "Food Additive Name": "Propylene glycol alginate",
    "Food Additive Display Name": "405 (Propylene glycol alginate)",
    "Food Additive Class": "Thickener, Emulsifier",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "YES",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "YES",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 248,
    "INS Number": 406,
    "Food Additive Name": "Agar",
    "Food Additive Display Name": "406 (Agar)",
    "Food Additive Class": "Thickener, Gelling Agent, Stabilizer",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "YES",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "YES",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 249,
    "INS Number": 407,
    "Food Additive Name":
      "Carrageenan and its Na, K, NH4 salts (includes furcellaran)",
    "Food Additive Display Name":
      "407 (Carrageenan and its Na, K, NH4 salts (includes furcellaran))",
    "Food Additive Class": "Thickener, Gelling Agent, Stabilizer",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "YES",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "YES",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 250,
    "INS Number": "407a",
    "Food Additive Name": "Processed Euchema Seaweed (PES)",
    "Food Additive Display Name": "407a (Processed Euchema Seaweed (PES))",
    "Food Additive Class": "Thickener, Stabilizer",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "YES",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 251,
    "INS Number": 408,
    "Food Additive Name": "Bakers yeast glycan",
    "Food Additive Display Name": "408 (Bakers yeast glycan)",
    "Food Additive Class": "Thickener, Gelling Agent, Stabilizer",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "YES",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "YES",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 252,
    "INS Number": 409,
    "Food Additive Name": "Arabinogalactan",
    "Food Additive Display Name": "409 (Arabinogalactan)",
    "Food Additive Class": "Thickener, Gelling Agent, Stabilizer",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "YES",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "YES",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 253,
    "INS Number": 410,
    "Food Additive Name": "Carob bean gum",
    "Food Additive Display Name": "410 (Carob bean gum)",
    "Food Additive Class": "Thickener, Stabilizer",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "YES",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 254,
    "INS Number": 411,
    "Food Additive Name": "Oat gum",
    "Food Additive Display Name": "411 (Oat gum)",
    "Food Additive Class": "Thickener, Stabilizer",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "YES",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 255,
    "INS Number": 412,
    "Food Additive Name": "Guar gum",
    "Food Additive Display Name": "412 (Guar gum)",
    "Food Additive Class": "Thickener, Stabilizer, Emulsifier",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "YES",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "YES",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 256,
    "INS Number": 413,
    "Food Additive Name": "Tragacanth gum",
    "Food Additive Display Name": "413 (Tragacanth gum)",
    "Food Additive Class": "Thickener, Stabilizer, Emulsifier",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "YES",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "YES",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 257,
    "INS Number": 414,
    "Food Additive Name": "Gum arabic (acacia gum)",
    "Food Additive Display Name": "414 (Gum arabic (acacia gum))",
    "Food Additive Class": "Thickener, Stabilizer",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "YES",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 258,
    "INS Number": 415,
    "Food Additive Name": "Xanthan gum",
    "Food Additive Display Name": "415 (Xanthan gum)",
    "Food Additive Class": "Thickener, Stabilizer, Emulsifier, Foaming Agent",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "YES",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "YES",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "YES",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 259,
    "INS Number": 416,
    "Food Additive Name": "Karaya gum",
    "Food Additive Display Name": "416 (Karaya gum)",
    "Food Additive Class": "Thickener, Stabilizer",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "YES",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 260,
    "INS Number": 417,
    "Food Additive Name": "Tara gum",
    "Food Additive Display Name": "417 (Tara gum)",
    "Food Additive Class": "Thickener, Stabilizer",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "YES",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 261,
    "INS Number": 418,
    "Food Additive Name": "Gellan gum",
    "Food Additive Display Name": "418 (Gellan gum)",
    "Food Additive Class": "Thickener, Stabilizer, Gelling Agent",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "YES",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "YES",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 262,
    "INS Number": 419,
    "Food Additive Name": "Gum ghatti",
    "Food Additive Display Name": "419 (Gum ghatti)",
    "Food Additive Class": "Thickener, Stabilizer, Emulsifier",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "YES",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "YES",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 263,
    "INS Number": 420,
    "Food Additive Name": "Sorbitol and sorbitol syrup",
    "Food Additive Display Name": "420 (Sorbitol and sorbitol syrup)",
    "Food Additive Class":
      "Sweetener, Humectant, Sequestrant, Texturizer, Emulsifier",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "YES",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "YES",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "YES",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "YES",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "YES",
    Thickener: "YES",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 264,
    "INS Number": 421,
    "Food Additive Name": "Mannitol",
    "Food Additive Display Name": "421 (Mannitol)",
    "Food Additive Class": "Sweetener, Anticaking Agent",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "YES",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "YES",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "YES",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 265,
    "INS Number": 422,
    "Food Additive Name": "Glycerol",
    "Food Additive Display Name": "422 (Glycerol)",
    "Food Additive Class": "Humectant, Bodying Agent",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "YES",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "YES",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 266,
    "INS Number": 424,
    "Food Additive Name": "Curd lan",
    "Food Additive Display Name": "424 (Curd lan)",
    "Food Additive Class": "Thickener, Stabilizer",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "YES",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 267,
    "INS Number": 425,
    "Food Additive Name": "Konjac flour",
    "Food Additive Display Name": "425 (Konjac flour)",
    "Food Additive Class": "Thickener",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "YES",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 268,
    "INS Number": 429,
    "Food Additive Name": "Peptones",
    "Food Additive Display Name": "429 (Peptones)",
    "Food Additive Class": "Emulsifier",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "YES",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 269,
    "INS Number": 430,
    "Food Additive Name": "Polyoxyethylene (8) stearate",
    "Food Additive Display Name": "430 (Polyoxyethylene (8) stearate)",
    "Food Additive Class": "Emulsifier",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "YES",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 270,
    "INS Number": 431,
    "Food Additive Name": "Polyoxyethylene (40) stearate",
    "Food Additive Display Name": "431 (Polyoxyethylene (40) stearate)",
    "Food Additive Class": "Emulsifier",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "YES",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 271,
    "INS Number": 432,
    "Food Additive Name": "Polyoxyethylene (20) sorbitan Monolaurate",
    "Food Additive Display Name":
      "432 (Polyoxyethylene (20) sorbitan Monolaurate)",
    "Food Additive Class": "Emulsifier, Dispersing Agent",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "YES",
    "Dough Conditioner": "NO",
    Emulsifier: "YES",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 272,
    "INS Number": 433,
    "Food Additive Name": "Polyoxyethylene (20) sorbitan Monoleate",
    "Food Additive Display Name":
      "433 (Polyoxyethylene (20) sorbitan Monoleate)",
    "Food Additive Class": "Emulsifier, Dispersing Agent",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "YES",
    "Dough Conditioner": "NO",
    Emulsifier: "YES",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 273,
    "INS Number": 434,
    "Food Additive Name": "Polyoxyethylene (20) sorbitan Monopalmitate",
    "Food Additive Display Name":
      "434 (Polyoxyethylene (20) sorbitan Monopalmitate)",
    "Food Additive Class": "Emulsifier, Dispersing Agent",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "YES",
    "Dough Conditioner": "NO",
    Emulsifier: "YES",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 274,
    "INS Number": 435,
    "Food Additive Name": "Polyoxyethylene (20) sorbitan Monostearate",
    "Food Additive Display Name":
      "435 (Polyoxyethylene (20) sorbitan Monostearate)",
    "Food Additive Class": "Emulsifier, Dispersing Agent",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "YES",
    "Dough Conditioner": "NO",
    Emulsifier: "YES",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 275,
    "INS Number": 436,
    "Food Additive Name": "Polyoxyethylene (20) sorbitan Tristearate",
    "Food Additive Display Name":
      "436 (Polyoxyethylene (20) sorbitan Tristearate)",
    "Food Additive Class": "Emulsifier, Dispersing Agent",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "YES",
    "Dough Conditioner": "NO",
    Emulsifier: "YES",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 276,
    "INS Number": 440,
    "Food Additive Name": "Pectins",
    "Food Additive Display Name": "440 (Pectins)",
    "Food Additive Class": "Thickener, Emulsifier, Stabilizer, Gelling Agent",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "YES",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "YES",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "YES",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 277,
    "INS Number": 441,
    "Food Additive Name": "Superglycerinated hydrogenated Rapeseed oil",
    "Food Additive Display Name":
      "441 (Superglycerinated hydrogenated Rapeseed oil)",
    "Food Additive Class": "Emulsifier",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "YES",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 278,
    "INS Number": 442,
    "Food Additive Name": "Ammonium salts of phosphatidic Acid",
    "Food Additive Display Name": "442 (Ammonium salts of phosphatidic Acid)",
    "Food Additive Class": "Emulsifier",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "YES",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 279,
    "INS Number": 443,
    "Food Additive Name": "Brominated vegetable oil",
    "Food Additive Display Name": "443 (Brominated vegetable oil)",
    "Food Additive Class": "Emulsifier, Stabilizer",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "YES",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 280,
    "INS Number": 444,
    "Food Additive Name": "Sucrose acetate isobutyrate",
    "Food Additive Display Name": "444 (Sucrose acetate isobutyrate)",
    "Food Additive Class": "Emulsifier, Stabilizer",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "YES",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 281,
    "INS Number": 445,
    "Food Additive Name": "Glycerol esters of wood resin",
    "Food Additive Display Name": "445 (Glycerol esters of wood resin)",
    "Food Additive Class": "Emulsifier, Stabilizer",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "YES",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 282,
    "INS Number": 446,
    "Food Additive Name": "Succistearin",
    "Food Additive Display Name": "446 (Succistearin)",
    "Food Additive Class": "Emulsifier",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "YES",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 283,
    "INS Number": 450,
    "Food Additive Name": "Diphosphates",
    "Food Additive Display Name": "450 (Diphosphates)",
    "Food Additive Class":
      "Acidity Regulator, Texturizer, Sequestrant, Stabilizer, Emulsifier, Water Retention Agent",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "YES",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "YES",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "YES",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "YES",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 284,
    "INS Number": "450(i)",
    "Food Additive Name": "Disodium diphosphate",
    "Food Additive Display Name": "450(i) (Disodium diphosphate)",
    "Food Additive Class":
      "Acidity Regulator, Texturizer, Sequestrant, Stabilizer, Emulsifier, Water Retention Agent",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "YES",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "YES",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "YES",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "YES",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 285,
    "INS Number": "450(ii)",
    "Food Additive Name": "Trisodium diphosphate",
    "Food Additive Display Name": "450(ii) (Trisodium diphosphate)",
    "Food Additive Class":
      "Acidity Regulator, Texturizer, Sequestrant, Stabilizer, Emulsifier, Water Retention Agent",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "YES",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "YES",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "YES",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "YES",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 286,
    "INS Number": "450(iii)",
    "Food Additive Name": "Tetrasodium diphosphate",
    "Food Additive Display Name": "450(iii) (Tetrasodium diphosphate)",
    "Food Additive Class":
      "Acidity Regulator, Texturizer, Sequestrant, Stabilizer, Emulsifier, Water Retention Agent",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "YES",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "YES",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "YES",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "YES",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 287,
    "INS Number": "450(iv)",
    "Food Additive Name": "Dipotassium diphosphate",
    "Food Additive Display Name": "450(iv) (Dipotassium diphosphate)",
    "Food Additive Class":
      "Acidity Regulator, Texturizer, Sequestrant, Stabilizer, Emulsifier, Water Retention Agent",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "YES",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "YES",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "YES",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "YES",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 288,
    "INS Number": "450(v)",
    "Food Additive Name": "Tetrapotassium diphosphate",
    "Food Additive Display Name": "450(v) (Tetrapotassium diphosphate)",
    "Food Additive Class":
      "Emulsifier, Stabilizer, Acidity Regulator, Raising Agent Sequestrant, Water Retention Agent",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "YES",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "YES",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "YES",
    Sequestrant: "YES",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 289,
    "INS Number": "450(vi)",
    "Food Additive Name": "Dicalcium diphosphate",
    "Food Additive Display Name": "450(vi) (Dicalcium diphosphate)",
    "Food Additive Class":
      "Acidity Regulator, Texturizer, Sequestrant Stabilizer, Emulsifier, Water Retention Agent",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "YES",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "YES",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "YES",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "YES",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 290,
    "INS Number": "450(vii)",
    "Food Additive Name": "Calcium dihydrogen diphosphate",
    "Food Additive Display Name": "450(vii) (Calcium dihydrogen diphosphate)",
    "Food Additive Class":
      "Emulsifier, Raising Agent, Stabilizer, Sequestrant, Acidity, Regulator, Water Retention Agent",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "YES",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "YES",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "YES",
    Sequestrant: "YES",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 291,
    "INS Number": "450(viii)",
    "Food Additive Name": "Dimagnesium diphosphate",
    "Food Additive Display Name": "450(viii) (Dimagnesium diphosphate)",
    "Food Additive Class":
      "Acidity Regulator, Texturizer, Sequestrant, Stabilizer, Emulsifier, Water Retention Agent",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "YES",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "YES",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "YES",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "YES",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 292,
    "INS Number": 451,
    "Food Additive Name": "Triphosphates",
    "Food Additive Display Name": "451 (Triphosphates)",
    "Food Additive Class": "Sequestrant, Acidity Regulator, Texturizer",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "YES",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "YES",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 293,
    "INS Number": "451(i)",
    "Food Additive Name": "Pentasodium",
    "Food Additive Display Name": "451(i) (Pentasodium)",
    "Food Additive Class": "Sequestrant, Acidity Regulator, Texturizer",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "YES",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "YES",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 294,
    "INS Number": "451(ii)",
    "Food Additive Name": "Pentapotassium triphosphate",
    "Food Additive Display Name": "451(ii) (Pentapotassium triphosphate)",
    "Food Additive Class": "Sequestrant, Acidity Regulator, Texturizer",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "YES",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "YES",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 295,
    "INS Number": 452,
    "Food Additive Name": "Polyphosphates",
    "Food Additive Display Name": "452 (Polyphosphates)",
    "Food Additive Class":
      "Acidity Regulator, Texturizer, Sequestrant Stabilizer, Emulsifier, Water Retention Agent",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "YES",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "YES",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "YES",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "YES",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 296,
    "INS Number": "452(i)",
    "Food Additive Name": "Sodium polyphosphate",
    "Food Additive Display Name": "452(i) (Sodium polyphosphate)",
    "Food Additive Class":
      "Acidity Regulator, Texturizer, Sequestrant Stabilizer, Emulsifier, Water Retention Agent",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "YES",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "YES",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "YES",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "YES",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 297,
    "INS Number": "452(ii)",
    "Food Additive Name": "Potassium Polyphosphate",
    "Food Additive Display Name": "452(ii) (Potassium Polyphosphate)",
    "Food Additive Class":
      "Acidity Regulator, Texturizer, Sequestrant Stabilizer, Emulsifier, Water Retention Agent",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "YES",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "YES",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "YES",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "YES",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 298,
    "INS Number": "452(iii)",
    "Food Additive Name": "Sodium calcium polyphosphate",
    "Food Additive Display Name": "452(iii) (Sodium calcium polyphosphate)",
    "Food Additive Class":
      "Emulsifier, Stabilizer, Acidity Regulator, Raising Agent, Sequestrant, Water Retention Agent",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "YES",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "YES",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "YES",
    Sequestrant: "YES",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 299,
    "INS Number": "452(iv)",
    "Food Additive Name": "Calcium polyphosphates",
    "Food Additive Display Name": "452(iv) (Calcium polyphosphates)",
    "Food Additive Class":
      "Emulsifier, Stabilizer, Acidity Regulator, Raising Agent, Sequestrant, Water Retention Agent",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "YES",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "YES",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "YES",
    Sequestrant: "YES",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 300,
    "INS Number": "452(v)",
    "Food Additive Name": "Ammonium polyphosphates",
    "Food Additive Display Name": "452(v) (Ammonium polyphosphates)",
    "Food Additive Class":
      "Emulsifier, Stabilizer, Acidity Regulator, Raising Agent, Sequestrant, Water Retention Agent",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "YES",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "YES",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "YES",
    Sequestrant: "YES",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 301,
    "INS Number": 458,
    "Food Additive Name": "Gamma Cyclodextrin",
    "Food Additive Display Name": "458 (Gamma Cyclodextrin)",
    "Food Additive Class": "Stabilizer, Binder",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 302,
    "INS Number": 459,
    "Food Additive Name": "Beta-cyclodextrin",
    "Food Additive Display Name": "459 (Beta-cyclodextrin)",
    "Food Additive Class": "Stabilizer, Binder",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 303,
    "INS Number": 460,
    "Food Additive Name": "Cellulose",
    "Food Additive Display Name": "460 (Cellulose)",
    "Food Additive Class":
      "Emulsifier, Dispersing Agent, Anticaking Agent, Texturizer",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "YES",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "YES",
    "Dough Conditioner": "NO",
    Emulsifier: "YES",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "YES",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 304,
    "INS Number": "460(i)",
    "Food Additive Name": "Microcystalline cellulose",
    "Food Additive Display Name": "460(i) (Microcystalline cellulose)",
    "Food Additive Class": "Emulsifier, Dispersing Agent, Anticaking Agent",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "YES",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "YES",
    "Dough Conditioner": "NO",
    Emulsifier: "YES",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 305,
    "INS Number": "460(ii)",
    "Food Additive Name": "Powdered cellulose",
    "Food Additive Display Name": "460(ii) (Powdered cellulose)",
    "Food Additive Class": "Emulsifier Dispersing Agent, Anticaking Agent",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "YES",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "YES",
    "Dough Conditioner": "NO",
    Emulsifier: "YES",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 306,
    "INS Number": 461,
    "Food Additive Name": "Methyl cellulose",
    "Food Additive Display Name": "461 (Methyl cellulose)",
    "Food Additive Class": "Thickener, Emulsifier, Stabilizer",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "YES",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "YES",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 307,
    "INS Number": 462,
    "Food Additive Name": "Ethyl cellulose",
    "Food Additive Display Name": "462 (Ethyl cellulose)",
    "Food Additive Class": "Binder, Filler",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "YES",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 308,
    "INS Number": 463,
    "Food Additive Name": "Hydroxypropyl cellulose",
    "Food Additive Display Name": "463 (Hydroxypropyl cellulose)",
    "Food Additive Class": "Thickener, Emulsifier, Stabilizer",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "YES",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "YES",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 309,
    "INS Number": 464,
    "Food Additive Name": "Hydroxypropyl methyl cellulose",
    "Food Additive Display Name": "464 (Hydroxypropyl methyl cellulose)",
    "Food Additive Class": "Thickener, Emulsifier, Stabilizer",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "YES",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "YES",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 310,
    "INS Number": 465,
    "Food Additive Name": "Methyl ethyl cellulose",
    "Food Additive Display Name": "465 (Methyl ethyl cellulose)",
    "Food Additive Class":
      "Thickener Antifoaming Agent, Emulsifier, Stabilizer",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "YES",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "YES",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "YES",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 311,
    "INS Number": 466,
    "Food Additive Name": "Sodium carboxymethyl cellulose",
    "Food Additive Display Name": "466 (Sodium carboxymethyl cellulose)",
    "Food Additive Class": "Thickener, Emulsifier, Stabilizer",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "YES",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "YES",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 312,
    "INS Number": 467,
    "Food Additive Name": "Ethyl hydroxyethyl cellulose",
    "Food Additive Display Name": "467 (Ethyl hydroxyethyl cellulose)",
    "Food Additive Class": "Thickener, Emulsifier, Stabilizer",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "YES",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "YES",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 313,
    "INS Number": 468,
    "Food Additive Name": "Croscaramellose",
    "Food Additive Display Name": "468 (Croscaramellose)",
    "Food Additive Class": "Stabilizer, Binder",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 314,
    "INS Number": 469,
    "Food Additive Name":
      "Sodium carboxymethyl cellulose, enzymatically hydrolysed",
    "Food Additive Display Name":
      "469 (Sodium carboxymethyl cellulose, enzymatically hydrolysed)",
    "Food Additive Class": "Thickener, Stabilizer",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "YES",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 315,
    "INS Number": 470,
    "Food Additive Name":
      "Salts of fatty acids (with base Al, Ca, Na, Mg, K, and NH4)",
    "Food Additive Display Name":
      "470 (Salts of fatty acids (with base Al, Ca, Na, Mg, K, and NH4))",
    "Food Additive Class": "Emulsifier, Stabilizer, Anticaking Agent",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "YES",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "YES",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 316,
    "INS Number": 471,
    "Food Additive Name": "Mono-and di-glycerides of fatty acids",
    "Food Additive Display Name": "471 (Mono-and di-glycerides of fatty acids)",
    "Food Additive Class": "Emulsifier, Stabilizer",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "YES",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 317,
    "INS Number": "472a",
    "Food Additive Name": "Acetic and fatty acid esters of glycerol",
    "Food Additive Display Name":
      "472a (Acetic and fatty acid esters of glycerol)",
    "Food Additive Class": "Emulsifier, Stabilizer, Sequestrant",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "YES",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "YES",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 318,
    "INS Number": "472b",
    "Food Additive Name": "Lactic and fatty acid esters of glycerol",
    "Food Additive Display Name":
      "472b (Lactic and fatty acid esters of glycerol)",
    "Food Additive Class": "Emulsifier, Stabilizer, Sequestrant",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "YES",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "YES",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 319,
    "INS Number": "472c",
    "Food Additive Name": "Citric and fatty acid esters of glycerol",
    "Food Additive Display Name":
      "472c (Citric and fatty acid esters of glycerol)",
    "Food Additive Class": "Emulsifier, Stabilizer, Sequestrant",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "YES",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "YES",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 320,
    "INS Number": "472d",
    "Food Additive Name":
      "Tartaric acid esters of mono and diglycerides of fatty acids",
    "Food Additive Display Name":
      "472d (Tartaric acid esters of mono and diglycerides of fatty acids)",
    "Food Additive Class": "Emulsifier, Stabilizer, Sequestrant",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "YES",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "YES",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 321,
    "INS Number": "472e",
    "Food Additive Name": "Diacetyltartric and fatty acid ester of glycerol",
    "Food Additive Display Name":
      "472e (Diacetyltartric and fatty acid ester of glycerol)",
    "Food Additive Class": "Emulsifier, Stabilizer, Sequestrant",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "YES",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "YES",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 322,
    "INS Number": "472f",
    "Food Additive Name":
      "Mixed tartaric, acetic and fatty acid esters of glycerol",
    "Food Additive Display Name":
      "472f (Mixed tartaric, acetic and fatty acid esters of glycerol)",
    "Food Additive Class": "Emulsifier, Stabilizer, Sequestrant",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "YES",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "YES",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 323,
    "INS Number": "472g",
    "Food Additive Name": "Succinylated monoglycerides",
    "Food Additive Display Name": "472g (Succinylated monoglycerides)",
    "Food Additive Class": "Emulsifier, Stabilizer, Sequestrant",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "YES",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "YES",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 324,
    "INS Number": 473,
    "Food Additive Name": "Sucrose esters of fatty acids",
    "Food Additive Display Name": "473 (Sucrose esters of fatty acids)",
    "Food Additive Class": "Emulsifier, Stabilizer, Sequestrant",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "YES",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "YES",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 325,
    "INS Number": 474,
    "Food Additive Name": "Sucroglycerides",
    "Food Additive Display Name": "474 (Sucroglycerides)",
    "Food Additive Class": "Emulsifier, Stabilizer, Sequestrant",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "YES",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "YES",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 326,
    "INS Number": 475,
    "Food Additive Name": "Polyglycerol esters of fatty acid",
    "Food Additive Display Name": "475 (Polyglycerol esters of fatty acid)",
    "Food Additive Class": "Emulsifier, Stabilizer, Sequestrant",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "YES",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "YES",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 327,
    "INS Number": 476,
    "Food Additive Name":
      "Polyglycerol esters of interesterified ricinoleic acid",
    "Food Additive Display Name":
      "476 (Polyglycerol esters of interesterified ricinoleic acid)",
    "Food Additive Class": "Emulsifier, Stabilizer, Sequestrant",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "YES",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "YES",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 328,
    "INS Number": 477,
    "Food Additive Name": "Propylene glycol esters of fatty Acids",
    "Food Additive Display Name":
      "477 (Propylene glycol esters of fatty Acids)",
    "Food Additive Class": "Emulsifier, Stabilizer, Sequestrant",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "YES",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "YES",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 329,
    "INS Number": 478,
    "Food Additive Name":
      "Lactylated fatty acid esters of glycerol and propylene glycol",
    "Food Additive Display Name":
      "478 (Lactylated fatty acid esters of glycerol and propylene glycol)",
    "Food Additive Class": "Emulsifier, Stabilizer, Sequestrant",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "YES",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "YES",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 330,
    "INS Number": 479,
    "Food Additive Name":
      "Thermally oxidized soya bean oil with mono-and di-glycerides of fatty acids",
    "Food Additive Display Name":
      "479 (Thermally oxidized soya bean oil with mono-and di-glycerides of fatty acids)",
    "Food Additive Class": "Emulsifier, Stabilizer, Sequestrant",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "YES",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "YES",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 331,
    "INS Number": 480,
    "Food Additive Name": "Dioctyl sodium sulphosuccinate",
    "Food Additive Display Name": "480 (Dioctyl sodium sulphosuccinate)",
    "Food Additive Class": "Emulsifier, Wetting Agent",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "YES",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "YES",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 332,
    "INS Number": 481,
    "Food Additive Name": "Sodium lactylate",
    "Food Additive Display Name": "481 (Sodium lactylate)",
    "Food Additive Class": "Emulsifier, Stabilizer",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "YES",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 333,
    "INS Number": "481(i)",
    "Food Additive Name": "Sodium stearoyl lactylates",
    "Food Additive Display Name": "481(i) (Sodium stearoyl lactylates)",
    "Food Additive Class": "Emulsifier, Stabilizer",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "YES",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 334,
    "INS Number": "481(ii)",
    "Food Additive Name": "Sodium oleyl lactylate",
    "Food Additive Display Name": "481(ii) (Sodium oleyl lactylate)",
    "Food Additive Class": "Emulsifier, Stabilizer",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "YES",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 335,
    "INS Number": 482,
    "Food Additive Name": "Calcium lactylates",
    "Food Additive Display Name": "482 (Calcium lactylates)",
    "Food Additive Class": "Emulsifier, Stabilizer",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "YES",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 336,
    "INS Number": "482(i)",
    "Food Additive Name": "Calcium stearoyl lactylate",
    "Food Additive Display Name": "482(i) (Calcium stearoyl lactylate)",
    "Food Additive Class": "Emulsifier, Stabilizer",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "YES",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 337,
    "INS Number": "482(ii)",
    "Food Additive Name": "Calcium oleyl lactylates",
    "Food Additive Display Name": "482(ii) (Calcium oleyl lactylates)",
    "Food Additive Class": "Emulsifier, Stabilizer",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "YES",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 338,
    "INS Number": 483,
    "Food Additive Name": "Stearyl tartrate",
    "Food Additive Display Name": "483 (Stearyl tartrate)",
    "Food Additive Class": "Flour Treatment Agent",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "YES",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 339,
    "INS Number": 484,
    "Food Additive Name": "Stearyl citrate",
    "Food Additive Display Name": "484 (Stearyl citrate)",
    "Food Additive Class": "Emulsifier, Sequestrant",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "YES",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "YES",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 340,
    "INS Number": 485,
    "Food Additive Name": "Sodium stearoyl fumarate",
    "Food Additive Display Name": "485 (Sodium stearoyl fumarate)",
    "Food Additive Class": "Emulsifier",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "YES",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 341,
    "INS Number": 486,
    "Food Additive Name": "Calcium stearoyl fumarate",
    "Food Additive Display Name": "486 (Calcium stearoyl fumarate)",
    "Food Additive Class": "Emulsifier",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "YES",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 342,
    "INS Number": 487,
    "Food Additive Name": "Sodium laurylsulphate",
    "Food Additive Display Name": "487 (Sodium laurylsulphate)",
    "Food Additive Class": "Emulsifier",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "YES",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 343,
    "INS Number": 488,
    "Food Additive Name": "Ethoxylated mono-and di-glycerides",
    "Food Additive Display Name": "488 (Ethoxylated mono-and di-glycerides)",
    "Food Additive Class": "Emulsifier",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "YES",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 344,
    "INS Number": 489,
    "Food Additive Name": "Methyl glucoside-coconut oil ester",
    "Food Additive Display Name": "489 (Methyl glucoside-coconut oil ester)",
    "Food Additive Class": "Emulsifier",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "YES",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 345,
    "INS Number": 491,
    "Food Additive Name": "Sorbitan monostearate",
    "Food Additive Display Name": "491 (Sorbitan monostearate)",
    "Food Additive Class": "Emulsifier",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "YES",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 346,
    "INS Number": 492,
    "Food Additive Name": "Sorbitan tristearate",
    "Food Additive Display Name": "492 (Sorbitan tristearate)",
    "Food Additive Class": "Emulsifier",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "YES",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 347,
    "INS Number": 493,
    "Food Additive Name": "Sorbitan monolaurate",
    "Food Additive Display Name": "493 (Sorbitan monolaurate)",
    "Food Additive Class": "Emulsifier",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "YES",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 348,
    "INS Number": 494,
    "Food Additive Name": "Sorbitan monooleate",
    "Food Additive Display Name": "494 (Sorbitan monooleate)",
    "Food Additive Class": "Emulsifier",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "YES",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 349,
    "INS Number": 495,
    "Food Additive Name": "Sorbitan monopalmitate",
    "Food Additive Display Name": "495 (Sorbitan monopalmitate)",
    "Food Additive Class": "Emulsifier",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "YES",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 350,
    "INS Number": 496,
    "Food Additive Name": "Sorbitan trioleate",
    "Food Additive Display Name": "496 (Sorbitan trioleate)",
    "Food Additive Class": "Stabilizer, Emulsifier",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "YES",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 351,
    "INS Number": 500,
    "Food Additive Name": "Sodium carbonates",
    "Food Additive Display Name": "500 (Sodium carbonates)",
    "Food Additive Class": "Acidity Regulator, Raising Agent, Anticaking Agent",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "YES",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "YES",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 352,
    "INS Number": "500(i)",
    "Food Additive Name": "Sodium carbonate",
    "Food Additive Display Name": "500(i) (Sodium carbonate)",
    "Food Additive Class": "Acidity Regulator, Raising Agent, Anticaking Agent",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "YES",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "YES",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 353,
    "INS Number": "500(ii)",
    "Food Additive Name": "Sodium hydrogen carbonate",
    "Food Additive Display Name": "500(ii) (Sodium hydrogen carbonate)",
    "Food Additive Class": "Acidity Regulator, Raising Agent, Anticaking Agent",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "YES",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "YES",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 354,
    "INS Number": "500(iii)",
    "Food Additive Name": "Sodium sesquicarbonate",
    "Food Additive Display Name": "500(iii) (Sodium sesquicarbonate)",
    "Food Additive Class": "Acidity Regulator, Raising Agent, Anticaking Agent",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "YES",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "YES",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 355,
    "INS Number": 501,
    "Food Additive Name": "Potassium carbonates",
    "Food Additive Display Name": "501 (Potassium carbonates)",
    "Food Additive Class": "Acidity Regulator, Stabilizer",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 356,
    "INS Number": "501(i)",
    "Food Additive Name": "Potassium carbonate",
    "Food Additive Display Name": "501(i) (Potassium carbonate)",
    "Food Additive Class": "Acidity Regulator, Stabilizer",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 357,
    "INS Number": "501(ii)",
    "Food Additive Name": "Potassium hydrogen carbonate",
    "Food Additive Display Name": "501(ii) (Potassium hydrogen carbonate)",
    "Food Additive Class": "Acidity Regulator, Stabilizer",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 358,
    "INS Number": 503,
    "Food Additive Name": "Ammonium carbonates",
    "Food Additive Display Name": "503 (Ammonium carbonates)",
    "Food Additive Class": "Acidity Regulator, Raising Agent",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "YES",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 359,
    "INS Number": "503(i)",
    "Food Additive Name": "Ammonium carbonate",
    "Food Additive Display Name": "503(i) (Ammonium carbonate)",
    "Food Additive Class": "Acidity Regulator, Raising Agent",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "YES",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 360,
    "INS Number": "503(ii)",
    "Food Additive Name": "Ammonium hydrogen carbonate",
    "Food Additive Display Name": "503(ii) (Ammonium hydrogen carbonate)",
    "Food Additive Class": "Acidity Regulator, Raising Agent",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "YES",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 361,
    "INS Number": 504,
    "Food Additive Name": "Magnesium carbonates",
    "Food Additive Display Name": "504 (Magnesium carbonates)",
    "Food Additive Class":
      "Acidity Regulator, Anticaking Agent, Colour Retention Agent",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "YES",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "YES",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 362,
    "INS Number": "504(i)",
    "Food Additive Name": "Magnesium carbonate",
    "Food Additive Display Name": "504(i) (Magnesium carbonate)",
    "Food Additive Class":
      "Acidity Regulator, Anticaking Agent, Colour Retention Agent",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "YES",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "YES",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 363,
    "INS Number": "504(ii)",
    "Food Additive Name": "Magnesium hydrogen carbonate",
    "Food Additive Display Name": "504(ii) (Magnesium hydrogen carbonate)",
    "Food Additive Class":
      "Acidity Regulator, Anticaking Agent, Colour Retention Agent",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "YES",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "YES",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 364,
    "INS Number": 505,
    "Food Additive Name": "Ferrous carbonate",
    "Food Additive Display Name": "505 (Ferrous carbonate)",
    "Food Additive Class": "Acidity Regulator",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 365,
    "INS Number": 507,
    "Food Additive Name": "Hydrochloric acid",
    "Food Additive Display Name": "507 (Hydrochloric acid)",
    "Food Additive Class": "Acidity Regulator Acid",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 366,
    "INS Number": 508,
    "Food Additive Name": "Potassium chloride",
    "Food Additive Display Name": "508 (Potassium chloride)",
    "Food Additive Class": "Gelling Agent",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "YES",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 367,
    "INS Number": 509,
    "Food Additive Name": "Calcium chloride",
    "Food Additive Display Name": "509 (Calcium chloride)",
    "Food Additive Class": "Firming Agent",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "YES",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 368,
    "INS Number": 510,
    "Food Additive Name": "Ammonium chloride",
    "Food Additive Display Name": "510 (Ammonium chloride)",
    "Food Additive Class": "Flour Treatment Agent",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "YES",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 369,
    "INS Number": 511,
    "Food Additive Name": "Magnesium chloride",
    "Food Additive Display Name": "511 (Magnesium chloride)",
    "Food Additive Class": "Firming Agent",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "YES",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 370,
    "INS Number": 512,
    "Food Additive Name": "Stannous chloride",
    "Food Additive Display Name": "512 (Stannous chloride)",
    "Food Additive Class": "Antioxidant, Colour Retention Agent",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "YES",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "YES",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 371,
    "INS Number": 513,
    "Food Additive Name": "Sulphuric acid",
    "Food Additive Display Name": "513 (Sulphuric acid)",
    "Food Additive Class": "Acidity Regulator",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 372,
    "INS Number": 514,
    "Food Additive Name": "Sodium sulphates",
    "Food Additive Display Name": "514 (Sodium sulphates)",
    "Food Additive Class": "Acidity Regulator",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 373,
    "INS Number": 515,
    "Food Additive Name": "Potassium sulphates",
    "Food Additive Display Name": "515 (Potassium sulphates)",
    "Food Additive Class": "Acidity Regulator",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 374,
    "INS Number": 516,
    "Food Additive Name": "Calcium Sulphate",
    "Food Additive Display Name": "516 (Calcium Sulphate)",
    "Food Additive Class": "Dough Conditioner, Sequestrant, Firming Agent",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "YES",
    Emulsifier: "NO",
    "Firming Agent": "YES",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "YES",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "YES",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 375,
    "INS Number": 517,
    "Food Additive Name": "Ammonium sulphate",
    "Food Additive Display Name": "517 (Ammonium sulphate)",
    "Food Additive Class": "Flour Treatment Agent, Stabilizer",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "YES",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 376,
    "INS Number": 518,
    "Food Additive Name": "Magnesium sulphate",
    "Food Additive Display Name": "518 (Magnesium sulphate)",
    "Food Additive Class": "Firming Agent",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "YES",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 377,
    "INS Number": 519,
    "Food Additive Name": "Cupric sulphate",
    "Food Additive Display Name": "519 (Cupric sulphate)",
    "Food Additive Class": "Colour Fixative, Preservative",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "YES",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "YES",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 378,
    "INS Number": 520,
    "Food Additive Name": "Aluminium sulphate",
    "Food Additive Display Name": "520 (Aluminium sulphate)",
    "Food Additive Class": "Firming Agent",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "YES",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 379,
    "INS Number": 521,
    "Food Additive Name": "Aluminium sodium Sulphate",
    "Food Additive Display Name": "521 (Aluminium sodium Sulphate)",
    "Food Additive Class": "Firming Agent",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "YES",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 380,
    "INS Number": 522,
    "Food Additive Name": "Aluminium potassium Sulphate",
    "Food Additive Display Name": "522 (Aluminium potassium Sulphate)",
    "Food Additive Class": "Acidity Regulator, Stabilizer",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 381,
    "INS Number": 523,
    "Food Additive Name": "Aluminium ammonium Sulphate",
    "Food Additive Display Name": "523 (Aluminium ammonium Sulphate)",
    "Food Additive Class": "Stabilizer, Firming Agent",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "YES",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 382,
    "INS Number": 524,
    "Food Additive Name": "Sodium hydroxide",
    "Food Additive Display Name": "524 (Sodium hydroxide)",
    "Food Additive Class": "Acidity Regulator",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 383,
    "INS Number": 525,
    "Food Additive Name": "Potassium hydroxide",
    "Food Additive Display Name": "525 (Potassium hydroxide)",
    "Food Additive Class": "Acidity Regulator",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 384,
    "INS Number": 526,
    "Food Additive Name": "Calcium hydroxide",
    "Food Additive Display Name": "526 (Calcium hydroxide)",
    "Food Additive Class": "Acidity Regulator, Firming Agent",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "YES",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 385,
    "INS Number": 527,
    "Food Additive Name": "Ammonium hydroxide",
    "Food Additive Display Name": "527 (Ammonium hydroxide)",
    "Food Additive Class": "Acidity Regulator",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 386,
    "INS Number": 528,
    "Food Additive Name": "Magnesium hydroxide",
    "Food Additive Display Name": "528 (Magnesium hydroxide)",
    "Food Additive Class": "Acidity Regulator, Colour Retention Agent",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "YES",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 387,
    "INS Number": 529,
    "Food Additive Name": "Calcium oxide",
    "Food Additive Display Name": "529 (Calcium oxide)",
    "Food Additive Class": "Acidity Regulator, Colour Retention Agent",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "YES",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 388,
    "INS Number": 530,
    "Food Additive Name": "Magnesium oxide",
    "Food Additive Display Name": "530 (Magnesium oxide)",
    "Food Additive Class": "Anticaking Agent",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "YES",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 389,
    "INS Number": 535,
    "Food Additive Name": "Sodium ferrocyanide",
    "Food Additive Display Name": "535 (Sodium ferrocyanide)",
    "Food Additive Class": "Anticaking Agent",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "YES",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 390,
    "INS Number": 536,
    "Food Additive Name": "Potassium ferrocyanide",
    "Food Additive Display Name": "536 (Potassium ferrocyanide)",
    "Food Additive Class": "Anticaking Agent",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "YES",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 391,
    "INS Number": 537,
    "Food Additive Name": "Ferrous hexacyanomanganate",
    "Food Additive Display Name": "537 (Ferrous hexacyanomanganate)",
    "Food Additive Class": "Anticaking Agent",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "YES",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 392,
    "INS Number": 538,
    "Food Additive Name": "Calcium ferrocyanide",
    "Food Additive Display Name": "538 (Calcium ferrocyanide)",
    "Food Additive Class": "Anticaking Agent",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "YES",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 393,
    "INS Number": 539,
    "Food Additive Name": "Sodium thiosulphate",
    "Food Additive Display Name": "539 (Sodium thiosulphate)",
    "Food Additive Class": "Antioxidant, Sequestrant",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "YES",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "YES",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 394,
    "INS Number": 541,
    "Food Additive Name": "Sodium aluminium phosphate",
    "Food Additive Display Name": "541 (Sodium aluminium phosphate)",
    "Food Additive Class": "Acidity Regulator, Emulsifier",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "YES",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 395,
    "INS Number": "541(i)",
    "Food Additive Name": "Sodium aluminium phosphate-acidic",
    "Food Additive Display Name": "541(i) (Sodium aluminium phosphate-acidic)",
    "Food Additive Class": "Acidity Regulator, Emulsifier",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "YES",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 396,
    "INS Number": "541(ii)",
    "Food Additive Name": "Sodium aluminium phosphate-basic",
    "Food Additive Display Name": "541(ii) (Sodium aluminium phosphate-basic)",
    "Food Additive Class": "Acidity Regulator, Emulsifier",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "YES",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 397,
    "INS Number": 542,
    "Food Additive Name":
      "Bone phosphate (essentially calcium phosphate, tribasic)",
    "Food Additive Display Name":
      "542 (Bone phosphate (essentially calcium phosphate, tribasic))",
    "Food Additive Class":
      "Emulsifier, Anticaking Agent, Water Retention Agent",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "YES",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "YES",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "YES",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 398,
    "INS Number": 550,
    "Food Additive Name": "Sodium silicates",
    "Food Additive Display Name": "550 (Sodium silicates)",
    "Food Additive Class": "Anticaking Agent",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "YES",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 399,
    "INS Number": "550(i)",
    "Food Additive Name": "Sodium silicate",
    "Food Additive Display Name": "550(i) (Sodium silicate)",
    "Food Additive Class": "Anticaking Agent",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "YES",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 400,
    "INS Number": "550(ii)",
    "Food Additive Name": "Sodium metasilicate",
    "Food Additive Display Name": "550(ii) (Sodium metasilicate)",
    "Food Additive Class": "Anticaking Agent",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "YES",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 401,
    "INS Number": 551,
    "Food Additive Name": "Silicon dioxide, amorphous",
    "Food Additive Display Name": "551 (Silicon dioxide, amorphous)",
    "Food Additive Class": "Anticaking Agent",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "YES",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 402,
    "INS Number": 552,
    "Food Additive Name": "Calcium silicate",
    "Food Additive Display Name": "552 (Calcium silicate)",
    "Food Additive Class": "Anticaking Agent",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "YES",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 403,
    "INS Number": 553,
    "Food Additive Name": "Magnesium silicates",
    "Food Additive Display Name": "553 (Magnesium silicates)",
    "Food Additive Class": "Anticaking Agent, Dusting Powder",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "YES",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 404,
    "INS Number": "553(i)",
    "Food Additive Name": "Magnesium silicate",
    "Food Additive Display Name": "553(i) (Magnesium silicate)",
    "Food Additive Class": "Anticaking Agent, Dusting Powder",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "YES",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 405,
    "INS Number": "553(ii)",
    "Food Additive Name": "Magnesium trisilicate",
    "Food Additive Display Name": "553(ii) (Magnesium trisilicate)",
    "Food Additive Class": "Anticaking Agent, Dusting Powder",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "YES",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 406,
    "INS Number": "553(iii)",
    "Food Additive Name": "Talc",
    "Food Additive Display Name": "553(iii) (Talc)",
    "Food Additive Class": "Anticaking Agent, Dusting Powder",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "YES",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 407,
    "INS Number": 554,
    "Food Additive Name": "Sodium aluminosilicate",
    "Food Additive Display Name": "554 (Sodium aluminosilicate)",
    "Food Additive Class": "Anticaking Agent",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "YES",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 408,
    "INS Number": 555,
    "Food Additive Name": "Potassium aluminium silicate",
    "Food Additive Display Name": "555 (Potassium aluminium silicate)",
    "Food Additive Class": "Anticaking Agent",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "YES",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 409,
    "INS Number": 556,
    "Food Additive Name": "Calcium aluminium silicate",
    "Food Additive Display Name": "556 (Calcium aluminium silicate)",
    "Food Additive Class": "Anticaking Agent",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "YES",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 410,
    "INS Number": 557,
    "Food Additive Name": "Zinc silicate",
    "Food Additive Display Name": "557 (Zinc silicate)",
    "Food Additive Class": "Anticaking Agent",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "YES",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 411,
    "INS Number": 558,
    "Food Additive Name": "Bentonite",
    "Food Additive Display Name": "558 (Bentonite)",
    "Food Additive Class": "Anticaking Agent",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "YES",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 412,
    "INS Number": 559,
    "Food Additive Name": "Aluminium silicate",
    "Food Additive Display Name": "559 (Aluminium silicate)",
    "Food Additive Class": "Anticaking Agent",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "YES",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 413,
    "INS Number": 560,
    "Food Additive Name": "Potassium silicate",
    "Food Additive Display Name": "560 (Potassium silicate)",
    "Food Additive Class": "Anticaking Agent",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "YES",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 414,
    "INS Number": 570,
    "Food Additive Name": "Fatty acids",
    "Food Additive Display Name": "570 (Fatty acids)",
    "Food Additive Class": "Foam Stabilizer, Glazing Agent, Antifoaming Agent",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "YES",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "YES",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 415,
    "INS Number": 574,
    "Food Additive Name": "Gluconic acid (D-)",
    "Food Additive Display Name": "574 (Gluconic acid (D-))",
    "Food Additive Class": "Acidity Regulator, Raising Agent",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "YES",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 416,
    "INS Number": 575,
    "Food Additive Name": "Glucono delta-lactone",
    "Food Additive Display Name": "575 (Glucono delta-lactone)",
    "Food Additive Class": "Acidity Regulator, Raising Agent",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "YES",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 417,
    "INS Number": 576,
    "Food Additive Name": "Sodium gluconate",
    "Food Additive Display Name": "576 (Sodium gluconate)",
    "Food Additive Class": "Sequestrant",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "YES",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 418,
    "INS Number": 577,
    "Food Additive Name": "Potassium gluconate",
    "Food Additive Display Name": "577 (Potassium gluconate)",
    "Food Additive Class": "Sequestrant",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "YES",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 419,
    "INS Number": 578,
    "Food Additive Name": "Calcium gluconate",
    "Food Additive Display Name": "578 (Calcium gluconate)",
    "Food Additive Class": "Acidity Regulator, Firming Agent",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "YES",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 420,
    "INS Number": 579,
    "Food Additive Name": "Ferrous gluconate",
    "Food Additive Display Name": "579 (Ferrous gluconate)",
    "Food Additive Class": "Colour Retention Agent",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "YES",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 421,
    "INS Number": 580,
    "Food Additive Name": "Magnesium gluconate",
    "Food Additive Display Name": "580 (Magnesium gluconate)",
    "Food Additive Class": "Acidity Regulator, Firming Agent",
    "Acidity Regulator": "YES",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "YES",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 422,
    "INS Number": 585,
    "Food Additive Name": "Ferrous lactate",
    "Food Additive Display Name": "585 (Ferrous lactate)",
    "Food Additive Class": "Colour Retention Agent",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "YES",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 423,
    "INS Number": 586,
    "Food Additive Name": "4-Hexylresorcinol",
    "Food Additive Display Name": "586 (4-Hexylresorcinol)",
    "Food Additive Class": "Colour Retention Agent, Antioxidant",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "YES",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "YES",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 424,
    "INS Number": 620,
    "Food Additive Name": "Glutamic acid (L (+)-)",
    "Food Additive Display Name": "620 (Glutamic acid (L (+)-))",
    "Food Additive Class": "Flavour Enhancer",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "YES",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 425,
    "INS Number": 621,
    "Food Additive Name": "Monosodium glutamate",
    "Food Additive Display Name": "621 (Monosodium glutamate)",
    "Food Additive Class": "Flavour Enhancer",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "YES",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 426,
    "INS Number": 622,
    "Food Additive Name": "Monopotassium glutamate",
    "Food Additive Display Name": "622 (Monopotassium glutamate)",
    "Food Additive Class": "Flavour Enhancer",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "YES",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 427,
    "INS Number": 623,
    "Food Additive Name": "Calcium glutamate",
    "Food Additive Display Name": "623 (Calcium glutamate)",
    "Food Additive Class": "Flavour Enhancer",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "YES",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 428,
    "INS Number": 624,
    "Food Additive Name": "Monoammonium glutamate",
    "Food Additive Display Name": "624 (Monoammonium glutamate)",
    "Food Additive Class": "Flavour Enhancer",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "YES",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 429,
    "INS Number": 625,
    "Food Additive Name": "Magnesium glutamate",
    "Food Additive Display Name": "625 (Magnesium glutamate)",
    "Food Additive Class": "Flavour Enhancer",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "YES",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 430,
    "INS Number": 626,
    "Food Additive Name": "Guanylic acid",
    "Food Additive Display Name": "626 (Guanylic acid)",
    "Food Additive Class": "Flavour Enhancer",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "YES",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 431,
    "INS Number": 627,
    "Food Additive Name": "Disodium 5'-guanylate",
    "Food Additive Display Name": "627 (Disodium 5'-guanylate)",
    "Food Additive Class": "Flavour Enhancer",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "YES",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 432,
    "INS Number": 628,
    "Food Additive Name": "Dipotassium 5'-guanylate",
    "Food Additive Display Name": "628 (Dipotassium 5'-guanylate)",
    "Food Additive Class": "Flavour Enhancer",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "YES",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 433,
    "INS Number": 629,
    "Food Additive Name": "Calcium 5'-guanylate",
    "Food Additive Display Name": "629 (Calcium 5'-guanylate)",
    "Food Additive Class": "Flavour Enhancer",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "YES",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 434,
    "INS Number": 630,
    "Food Additive Name": "Inosinic acid",
    "Food Additive Display Name": "630 (Inosinic acid)",
    "Food Additive Class": "Flavour Enhancer",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "YES",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 435,
    "INS Number": 631,
    "Food Additive Name": "Disodium 5'-inosinate",
    "Food Additive Display Name": "631 (Disodium 5'-inosinate)",
    "Food Additive Class": "Flavour Enhancer",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "YES",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 436,
    "INS Number": 632,
    "Food Additive Name": "Potassium Inosate",
    "Food Additive Display Name": "632 (Potassium Inosate)",
    "Food Additive Class": "Flavour Enhancer",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "YES",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 437,
    "INS Number": 633,
    "Food Additive Name": "Calcium 5'-inosinate",
    "Food Additive Display Name": "633 (Calcium 5'-inosinate)",
    "Food Additive Class": "Flavour Enhancer",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "YES",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 438,
    "INS Number": 634,
    "Food Additive Name": "Calcium 5'-ribonucleotides",
    "Food Additive Display Name": "634 (Calcium 5'-ribonucleotides)",
    "Food Additive Class": "Flavour Enhancer",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "YES",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 439,
    "INS Number": 635,
    "Food Additive Name": "Disodium 5'-ribonucleotides",
    "Food Additive Display Name": "635 (Disodium 5'-ribonucleotides)",
    "Food Additive Class": "Flavour Enhancer",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "YES",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 440,
    "INS Number": 636,
    "Food Additive Name": "Maltol",
    "Food Additive Display Name": "636 (Maltol)",
    "Food Additive Class": "Flavour Enhancer",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "YES",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 441,
    "INS Number": 637,
    "Food Additive Name": "Ethyl maltol",
    "Food Additive Display Name": "637 (Ethyl maltol)",
    "Food Additive Class": "Flavour Enhancer",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "YES",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 442,
    "INS Number": 638,
    "Food Additive Name": "Sodium L-Aspartate",
    "Food Additive Display Name": "638 (Sodium L-Aspartate)",
    "Food Additive Class": "Flavour Enhancer",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "YES",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 443,
    "INS Number": 639,
    "Food Additive Name": "DL-Alanine",
    "Food Additive Display Name": "639 (DL-Alanine)",
    "Food Additive Class": "Flavour Enhancer",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "YES",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 444,
    "INS Number": 640,
    "Food Additive Name": "Glycine",
    "Food Additive Display Name": "640 (Glycine)",
    "Food Additive Class": "Flavour Enhancer",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "YES",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 445,
    "INS Number": 641,
    "Food Additive Name": "L-Leucine",
    "Food Additive Display Name": "641 (L-Leucine)",
    "Food Additive Class": "Flavour Enhancer",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "YES",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 446,
    "INS Number": 642,
    "Food Additive Name": "Lysin hydrochloride",
    "Food Additive Display Name": "642 (Lysin hydrochloride)",
    "Food Additive Class": "Flavour Enhancer",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "YES",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 447,
    "INS Number": "900a",
    "Food Additive Name": "Polydimethylsiloxane",
    "Food Additive Display Name": "900a (Polydimethylsiloxane)",
    "Food Additive Class": "Antifoaming Agent, Anticaking Agent, Emulsifier",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "YES",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "YES",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "YES",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 448,
    "INS Number": "900b",
    "Food Additive Name": "Methylphenylpolysiloxane",
    "Food Additive Display Name": "900b (Methylphenylpolysiloxane)",
    "Food Additive Class": "Antifoaming Agent",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "YES",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 449,
    "INS Number": 901,
    "Food Additive Name": "Beeswax, white and yellow",
    "Food Additive Display Name": "901 (Beeswax, white and yellow)",
    "Food Additive Class": "Glazing Agent, Release Agent",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "YES",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "YES",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 450,
    "INS Number": 902,
    "Food Additive Name": "Candeilla Wax",
    "Food Additive Display Name": "902 (Candeilla Wax)",
    "Food Additive Class": "Glazing Agent",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "YES",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 451,
    "INS Number": 903,
    "Food Additive Name": "Carnaubawax",
    "Food Additive Display Name": "903 (Carnaubawax)",
    "Food Additive Class": "Glazing Agent",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "YES",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 452,
    "INS Number": 904,
    "Food Additive Name": "Shellac",
    "Food Additive Display Name": "904 (Shellac)",
    "Food Additive Class": "Glazing Agent",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "YES",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 453,
    "INS Number": "905a",
    "Food Additive Name": "Mineral oil, food grade",
    "Food Additive Display Name": "905a (Mineral oil, food grade)",
    "Food Additive Class": "Glazing Agent, Release Agent, Sealing Agent",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "YES",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "YES",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 454,
    "INS Number": "905b",
    "Food Additive Name": "Petrolatum Petroleumielly",
    "Food Additive Display Name": "905b (Petrolatum Petroleumielly)",
    "Food Additive Class": "Glazing Agent, Release Agent, Sealing Agent",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "YES",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "YES",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 455,
    "INS Number": "905c",
    "Food Additive Name": "Petroleum wax",
    "Food Additive Display Name": "905c (Petroleum wax)",
    "Food Additive Class": "Glazing Agent, Release Agent, Sealing Agent",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "YES",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "YES",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 456,
    "INS Number": "905c(i)",
    "Food Additive Name": "Microcrystallinewax",
    "Food Additive Display Name": "905c(i) (Microcrystallinewax)",
    "Food Additive Class": "Glazing Agent",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "YES",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 457,
    "INS Number": "905c(ii)",
    "Food Additive Name": "Paraffin wax",
    "Food Additive Display Name": "905c(ii) (Paraffin wax)",
    "Food Additive Class": "Glazing Agent",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "YES",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 458,
    "INS Number": 906,
    "Food Additive Name": "Benzoin gum",
    "Food Additive Display Name": "906 (Benzoin gum)",
    "Food Additive Class": "Glazing Agent",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "YES",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 459,
    "INS Number": 907,
    "Food Additive Name": "Hydrogenated poly-1 decene",
    "Food Additive Display Name": "907 (Hydrogenated poly-1 decene)",
    "Food Additive Class": "Glazing Agent",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "YES",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 460,
    "INS Number": 908,
    "Food Additive Name": "Rice bran wax",
    "Food Additive Display Name": "908 (Rice bran wax)",
    "Food Additive Class": "Glazing Agent",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "YES",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 461,
    "INS Number": 909,
    "Food Additive Name": "Spermaceti wax",
    "Food Additive Display Name": "909 (Spermaceti wax)",
    "Food Additive Class": "Glazing Agent",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "YES",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 462,
    "INS Number": 910,
    "Food Additive Name": "Wax esters",
    "Food Additive Display Name": "910 (Wax esters)",
    "Food Additive Class": "Glazing Agent",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "YES",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 463,
    "INS Number": 911,
    "Food Additive Name": "Methyl esters of fatty acids",
    "Food Additive Display Name": "911 (Methyl esters of fatty acids)",
    "Food Additive Class": "Glazing Agent",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "YES",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 464,
    "INS Number": 913,
    "Food Additive Name": "Lanolin",
    "Food Additive Display Name": "913 (Lanolin)",
    "Food Additive Class": "Glazing Agent",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "YES",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 465,
    "INS Number": 915,
    "Food Additive Name":
      "Glycerol-, methyl-, or penta- erithrytol esters of colophane",
    "Food Additive Display Name":
      "915 (Glycerol-, methyl-, or penta- erithrytol esters of colophane)",
    "Food Additive Class": "Glazing Agent",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "YES",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 466,
    "INS Number": 916,
    "Food Additive Name": "Calcium iodate",
    "Food Additive Display Name": "916 (Calcium iodate)",
    "Food Additive Class": "Flour Treatment Agent",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "YES",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 467,
    "INS Number": 917,
    "Food Additive Name": "Potassium iodate",
    "Food Additive Display Name": "917 (Potassium iodate)",
    "Food Additive Class": "Flour Treatment Agent",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "YES",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 468,
    "INS Number": 918,
    "Food Additive Name": "Nitrogen oxide",
    "Food Additive Display Name": "918 (Nitrogen oxide)",
    "Food Additive Class": "Flour Treatment Agent",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "YES",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 469,
    "INS Number": 919,
    "Food Additive Name": "Nitrosyl chloride",
    "Food Additive Display Name": "919 (Nitrosyl chloride)",
    "Food Additive Class": "Flour Treatment Agent",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "YES",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 470,
    "INS Number": 920,
    "Food Additive Name":
      "L-Cysteine and its hydrochlorides- sodium and potassium salts",
    "Food Additive Display Name":
      "920 (L-Cysteine and its hydrochlorides- sodium and potassium salts)",
    "Food Additive Class": "Flour Treatment Agent",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "YES",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 471,
    "INS Number": 921,
    "Food Additive Name":
      "L-Cysteine and its hydrochlorides- sodium and potassium salts",
    "Food Additive Display Name":
      "921 (L-Cysteine and its hydrochlorides- sodium and potassium salts)",
    "Food Additive Class": "Flour Treatment Agent",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "YES",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 472,
    "INS Number": 922,
    "Food Additive Name": "Potassium persulphate",
    "Food Additive Display Name": "922 (Potassium persulphate)",
    "Food Additive Class": "Flour Treatment Agent",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "YES",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 473,
    "INS Number": 923,
    "Food Additive Name": "Ammonium persulphate",
    "Food Additive Display Name": "923 (Ammonium persulphate)",
    "Food Additive Class": "Flour Treatment Agent",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "YES",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 474,
    "INS Number": "924a",
    "Food Additive Name": "Potassium bromate",
    "Food Additive Display Name": "924a (Potassium bromate)",
    "Food Additive Class": "Flour Treatment Agent",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "YES",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 475,
    "INS Number": "924b",
    "Food Additive Name": "Calcium bromate",
    "Food Additive Display Name": "924b (Calcium bromate)",
    "Food Additive Class": "Flour Treatment Agent",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "YES",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 476,
    "INS Number": 925,
    "Food Additive Name": "Chlorine",
    "Food Additive Display Name": "925 (Chlorine)",
    "Food Additive Class": "Flour Treatment Agent",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "YES",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 477,
    "INS Number": 926,
    "Food Additive Name": "Chlorine dioxide",
    "Food Additive Display Name": "926 (Chlorine dioxide)",
    "Food Additive Class": "Flour Treatment Agent",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "YES",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 478,
    "INS Number": "927a",
    "Food Additive Name": "Azodicarbonamide",
    "Food Additive Display Name": "927a (Azodicarbonamide)",
    "Food Additive Class": "Flour Treatment Agent",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "YES",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 479,
    "INS Number": "927b",
    "Food Additive Name": "Carbamide (urea)",
    "Food Additive Display Name": "927b (Carbamide (urea))",
    "Food Additive Class": "Flour Treatment Agent",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "YES",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 480,
    "INS Number": 928,
    "Food Additive Name": "Benzoyl peroxide",
    "Food Additive Display Name": "928 (Benzoyl peroxide)",
    "Food Additive Class": "Flour Treatment Agent, Preservative",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "YES",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "YES",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 481,
    "INS Number": 929,
    "Food Additive Name": "Acetone peroxide",
    "Food Additive Display Name": "929 (Acetone peroxide)",
    "Food Additive Class": "Flour Treatment Agent",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "YES",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 482,
    "INS Number": 930,
    "Food Additive Name": "Calcium peroxide",
    "Food Additive Display Name": "930 (Calcium peroxide)",
    "Food Additive Class": "Flour Treatment Agent",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "YES",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 483,
    "INS Number": 938,
    "Food Additive Name": "Argon",
    "Food Additive Display Name": "938 (Argon)",
    "Food Additive Class": "Packing Gas",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "YES",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 484,
    "INS Number": 939,
    "Food Additive Name": "Helium",
    "Food Additive Display Name": "939 (Helium)",
    "Food Additive Class": "Packing Gas",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "YES",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 485,
    "INS Number": 940,
    "Food Additive Name": "Dichlorodifluoromethane",
    "Food Additive Display Name": "940 (Dichlorodifluoromethane)",
    "Food Additive Class": "Propellant, Liquid Freezant",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "YES",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "YES",
  },
  {
    "Sr. No": 486,
    "INS Number": 941,
    "Food Additive Name": "Nitrogen",
    "Food Additive Display Name": "941 (Nitrogen)",
    "Food Additive Class": "Packing Gas, Freezant",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "YES",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "YES",
  },
  {
    "Sr. No": 487,
    "INS Number": 942,
    "Food Additive Name": "Nitrous oxide",
    "Food Additive Display Name": "942 (Nitrous oxide)",
    "Food Additive Class": "Propellant",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "YES",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 488,
    "INS Number": "943a",
    "Food Additive Name": "Butane",
    "Food Additive Display Name": "943a (Butane)",
    "Food Additive Class": "Propellant",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "YES",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 489,
    "INS Number": "943b",
    "Food Additive Name": "Isobutane",
    "Food Additive Display Name": "943b (Isobutane)",
    "Food Additive Class": "Propellant",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "YES",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 490,
    "INS Number": 944,
    "Food Additive Name": "Propane",
    "Food Additive Display Name": "944 (Propane)",
    "Food Additive Class": "Propellant",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "YES",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 491,
    "INS Number": 945,
    "Food Additive Name": "Chloropentafluoroethane",
    "Food Additive Display Name": "945 (Chloropentafluoroethane)",
    "Food Additive Class": "Propellant",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "YES",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 492,
    "INS Number": 946,
    "Food Additive Name": "Octafluorocyclobutane",
    "Food Additive Display Name": "946 (Octafluorocyclobutane)",
    "Food Additive Class": "Propellant",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "YES",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 493,
    "INS Number": 948,
    "Food Additive Name": "Oxygen",
    "Food Additive Display Name": "948 (Oxygen)",
    "Food Additive Class": "Packing Gas",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "YES",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 494,
    "INS Number": 950,
    "Food Additive Name": "Acesulfame potassium",
    "Food Additive Display Name": "950 (Acesulfame potassium)",
    "Food Additive Class": "Sweetener, Flavour Enhancer",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "YES",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "YES",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "YES",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 495,
    "INS Number": 951,
    "Food Additive Name": "Aspartame",
    "Food Additive Display Name": "951 (Aspartame)",
    "Food Additive Class": "Sweetener, Flavour Enhancer",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "YES",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "YES",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "YES",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 496,
    "INS Number": 952,
    "Food Additive Name": "Cyclamic acid (and Na, K, Ca Salts)",
    "Food Additive Display Name": "952 (Cyclamic acid (and Na, K, Ca Salts))",
    "Food Additive Class": "Sweetener",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "YES",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "YES",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 497,
    "INS Number": 953,
    "Food Additive Name": "Isomalt (isomaltitol)",
    "Food Additive Display Name": "953 (Isomalt (isomaltitol))",
    "Food Additive Class":
      "Sweetener, Anticaking Agent, Bulking Agent, Glazing Agent",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "YES",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "YES",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "YES",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "YES",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "YES",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 498,
    "INS Number": 954,
    "Food Additive Name": "Saccharin (and Na, K, Ca salts)",
    "Food Additive Display Name": "954 (Saccharin (and Na, K, Ca salts))",
    "Food Additive Class": "Sweetener",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "YES",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "YES",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 499,
    "INS Number": 955,
    "Food Additive Name": "Sucralose (trichlorogalactosucrose)",
    "Food Additive Display Name": "955 (Sucralose (trichlorogalactosucrose))",
    "Food Additive Class": "Sweetener",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "YES",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "YES",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 500,
    "INS Number": 956,
    "Food Additive Name": "Alitame",
    "Food Additive Display Name": "956 (Alitame)",
    "Food Additive Class": "Sweetener",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "YES",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "YES",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 501,
    "INS Number": 957,
    "Food Additive Name": "Thaumatin",
    "Food Additive Display Name": "957 (Thaumatin)",
    "Food Additive Class": "Sweetener, Flavour Enhancer",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "YES",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "YES",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "YES",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 502,
    "INS Number": 958,
    "Food Additive Name": "Glycyrrhizin",
    "Food Additive Display Name": "958 (Glycyrrhizin)",
    "Food Additive Class": "Sweetener, Flavour Enhancer",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "YES",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "YES",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "YES",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 503,
    "INS Number": 959,
    "Food Additive Name": "Neohesperidine dihydrochalcone",
    "Food Additive Display Name": "959 (Neohesperidine dihydrochalcone)",
    "Food Additive Class": "Sweetener",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "YES",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "YES",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 504,
    "INS Number": 960,
    "Food Additive Name": "Stevioside",
    "Food Additive Display Name": "960 (Stevioside)",
    "Food Additive Class": "Sweetener",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "YES",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "YES",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 505,
    "INS Number": 964,
    "Food Additive Name": "Polyglycitol syrup",
    "Food Additive Display Name": "964 (Polyglycitol syrup)",
    "Food Additive Class": "Sweetener",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "YES",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "YES",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "YES",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 506,
    "INS Number": 965,
    "Food Additive Name": "Maltitol and matitol Syrup",
    "Food Additive Display Name": "965 (Maltitol and matitol Syrup)",
    "Food Additive Class": "Sweetener, Stabilizer, Emulsifier",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "YES",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "YES",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "YES",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 507,
    "INS Number": 966,
    "Food Additive Name": "Lactitol",
    "Food Additive Display Name": "966 (Lactitol)",
    "Food Additive Class": "Sweetener, Texturizer",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "YES",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "YES",
    Thickener: "YES",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 508,
    "INS Number": 967,
    "Food Additive Name": "Xylitol",
    "Food Additive Display Name": "967 (Xylitol)",
    "Food Additive Class":
      "Sweetener, Humectant, Stabilizer, Emulsifier, Thickener",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "YES",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "YES",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "YES",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "YES",
    Thickener: "YES",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 509,
    "INS Number": 968,
    "Food Additive Name": "Erythritol",
    "Food Additive Display Name": "968 (Erythritol)",
    "Food Additive Class": "Sweetener, Flavour Enhancer, Humectant",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "YES",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "YES",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "YES",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "YES",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 510,
    "INS Number": 999,
    "Food Additive Name": "Qulillaia extracts",
    "Food Additive Display Name": "999 (Qulillaia extracts)",
    "Food Additive Class": "Foaming Agent",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "YES",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 511,
    "INS Number": 1000,
    "Food Additive Name": "Cholic acid",
    "Food Additive Display Name": "1000 (Cholic acid)",
    "Food Additive Class": "Emulsifier",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "YES",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 512,
    "INS Number": 1001,
    "Food Additive Name": "Choline salts and esters",
    "Food Additive Display Name": "1001 (Choline salts and esters)",
    "Food Additive Class": "Emulsifier",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "YES",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 513,
    "INS Number": "1001(i)",
    "Food Additive Name": "Choline acentate",
    "Food Additive Display Name": "1001(i) (Choline acentate)",
    "Food Additive Class": "Emulsifier",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "YES",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 514,
    "INS Number": "1001(ii)",
    "Food Additive Name": "Choline carbonate",
    "Food Additive Display Name": "1001(ii) (Choline carbonate)",
    "Food Additive Class": "Emulsifier",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "YES",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 515,
    "INS Number": "1001(iii)",
    "Food Additive Name": "Choline chloride",
    "Food Additive Display Name": "1001(iii) (Choline chloride)",
    "Food Additive Class": "Emulsifier",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "YES",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 516,
    "INS Number": "1001(iv)",
    "Food Additive Name": "Choline citrate",
    "Food Additive Display Name": "1001(iv) (Choline citrate)",
    "Food Additive Class": "Emulsifier",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "YES",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 517,
    "INS Number": "1001(v)",
    "Food Additive Name": "Choline tartrate",
    "Food Additive Display Name": "1001(v) (Choline tartrate)",
    "Food Additive Class": "Emulsifier",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "YES",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 518,
    "INS Number": "1001(vi)",
    "Food Additive Name": "Choline lactate",
    "Food Additive Display Name": "1001(vi) (Choline lactate)",
    "Food Additive Class": "Emulsifier",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "YES",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 519,
    "INS Number": 1100,
    "Food Additive Name": "Amylases",
    "Food Additive Display Name": "1100 (Amylases)",
    "Food Additive Class": "Flour Treatment Agent",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "YES",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 520,
    "INS Number": 1101,
    "Food Additive Name": "Proteases",
    "Food Additive Display Name": "1101 (Proteases)",
    "Food Additive Class":
      "Flour Treatment Agent, Stabilizer, Tenderizer, Flavour Enhancer",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "YES",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "YES",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 521,
    "INS Number": "1101(i)",
    "Food Additive Name": "Protease",
    "Food Additive Display Name": "1101(i) (Protease)",
    "Food Additive Class":
      "Flour Treatment Agent, Stabilizer, Tenderizer, Flavour Enhancer",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "YES",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "YES",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 522,
    "INS Number": "1101(ii)",
    "Food Additive Name": "Papain",
    "Food Additive Display Name": "1101(ii) (Papain)",
    "Food Additive Class":
      "Flour Treatment Agent, Stabilizer, Tenderizer, Flavour Enhancer",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "YES",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "YES",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 523,
    "INS Number": "1101(iii)",
    "Food Additive Name": "Bromelain",
    "Food Additive Display Name": "1101(iii) (Bromelain)",
    "Food Additive Class":
      "Flour Treatment Agent, Stabilizer, Tenderizer, Flavour Enhancer",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "YES",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "YES",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 524,
    "INS Number": "1101(iv)",
    "Food Additive Name": "Ficin",
    "Food Additive Display Name": "1101(iv) (Ficin)",
    "Food Additive Class":
      "Flour Treatment Agent, Stabilizer, Tenderizer, Flavour Enhancer",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "YES",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "YES",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 525,
    "INS Number": 1102,
    "Food Additive Name": "Glucose oxidase",
    "Food Additive Display Name": "1102 (Glucose oxidase)",
    "Food Additive Class": "Antioxidant",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "YES",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 526,
    "INS Number": 1103,
    "Food Additive Name": "Invertases",
    "Food Additive Display Name": "1103 (Invertases)",
    "Food Additive Class": "Stabilizer",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 527,
    "INS Number": 1104,
    "Food Additive Name": "Lipases",
    "Food Additive Display Name": "1104 (Lipases)",
    "Food Additive Class": "Flavour Enhancer",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "YES",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 528,
    "INS Number": 1105,
    "Food Additive Name": "Lysozyme",
    "Food Additive Display Name": "1105 (Lysozyme)",
    "Food Additive Class": "Preservative",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "YES",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 529,
    "INS Number": 1200,
    "Food Additive Name": "Polydextroses A and N",
    "Food Additive Display Name": "1200 (Polydextroses A and N)",
    "Food Additive Class":
      "Bulking Agent, Stabilizer, Thickener, Humectant Texturizer",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "YES",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "YES",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "YES",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 530,
    "INS Number": 1201,
    "Food Additive Name": "Polyvinylpyrrolidone",
    "Food Additive Display Name": "1201 (Polyvinylpyrrolidone)",
    "Food Additive Class":
      "Bodying Agent, Stabilizer, Clarifying Agent, Dispersing Agent",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "YES",
    "Dough Conditioner": "NO",
    Emulsifier: "YES",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "YES",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "YES",
    Freezant: "NO",
  },
  {
    "Sr. No": 531,
    "INS Number": 1202,
    "Food Additive Name": "Polyvinylpolypyrrolidone",
    "Food Additive Display Name": "1202 (Polyvinylpolypyrrolidone)",
    "Food Additive Class": "Colour Stabilizer, Colloidal, Stabilizer",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "YES",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 532,
    "INS Number": 1503,
    "Food Additive Name": "Castor oil",
    "Food Additive Display Name": "1503 (Castor oil)",
    "Food Additive Class": "Release Agent",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "YES",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 533,
    "INS Number": 1505,
    "Food Additive Name": "Triethyl citrate",
    "Food Additive Display Name": "1505 (Triethyl citrate)",
    "Food Additive Class": "Foam Stabilizer",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 534,
    "INS Number": 1518,
    "Food Additive Name": "Triacetin",
    "Food Additive Display Name": "1518 (Triacetin)",
    "Food Additive Class": "Humectant",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "YES",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 535,
    "INS Number": 1520,
    "Food Additive Name": "Propylene glycol",
    "Food Additive Display Name": "1520 (Propylene glycol)",
    "Food Additive Class": "Humectant, Wetting Agent, Dispersing Agent",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "YES",
    "Dough Conditioner": "NO",
    Emulsifier: "YES",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "YES",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 536,
    "INS Number": 1521,
    "Food Additive Name": "Polyethylene glycol",
    "Food Additive Display Name": "1521 (Polyethylene glycol)",
    "Food Additive Class": "Antifoaming Agent",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "YES",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "NO",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 537,
    "INS Number": 1400,
    "Food Additive Name": "Dextrins, roasted starch white and yellow",
    "Food Additive Display Name":
      "1400 (Dextrins, roasted starch white and yellow)",
    "Food Additive Class": "Stabilizer, Thickener, Binder",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "YES",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 538,
    "INS Number": 1401,
    "Food Additive Name": "Acid-treated starch",
    "Food Additive Display Name": "1401 (Acid-treated starch)",
    "Food Additive Class": "Stabilizer, Thickener, Binder",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "YES",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 539,
    "INS Number": 1402,
    "Food Additive Name": "Alkaline treated starch",
    "Food Additive Display Name": "1402 (Alkaline treated starch)",
    "Food Additive Class": "Stabilizer, Thickener, Binder",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "YES",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 540,
    "INS Number": 1403,
    "Food Additive Name": "Bleached starch",
    "Food Additive Display Name": "1403 (Bleached starch)",
    "Food Additive Class": "Stabilizer, Thickener, Binder",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "YES",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 541,
    "INS Number": 1404,
    "Food Additive Name": "Oxidised starch",
    "Food Additive Display Name": "1404 (Oxidised starch)",
    "Food Additive Class": "Stabilizer, Thickener, Binder",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "YES",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 542,
    "INS Number": 1405,
    "Food Additive Name": "Starches, enzyme-treated",
    "Food Additive Display Name": "1405 (Starches, enzyme-treated)",
    "Food Additive Class": "Thickener",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "NO",
    Sweetener: "NO",
    Thickener: "YES",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 543,
    "INS Number": 1410,
    "Food Additive Name": "Monostarch phosphate",
    "Food Additive Display Name": "1410 (Monostarch phosphate)",
    "Food Additive Class": "Stabilizer, Thickener, Binder",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "YES",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 544,
    "INS Number": 1411,
    "Food Additive Name": "Distarch glycerol",
    "Food Additive Display Name": "1411 (Distarch glycerol)",
    "Food Additive Class": "Stabilizer, Thickener, Binder",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "YES",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 545,
    "INS Number": 1412,
    "Food Additive Name":
      "Distarch phosphate esterified with sodium trimetaphosphate",
    "Food Additive Display Name":
      "1412 (Distarch phosphate esterified with sodium trimetaphosphate)",
    "Food Additive Class": "Stabilizer, Thickener, Binder",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "YES",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 546,
    "INS Number": 1413,
    "Food Additive Name": "Phosphated distarch phosphate",
    "Food Additive Display Name": "1413 (Phosphated distarch phosphate)",
    "Food Additive Class": "Stabilizer, Thickener, Binder",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "YES",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 547,
    "INS Number": 1414,
    "Food Additive Name": "Acetylated distarch phosphate",
    "Food Additive Display Name": "1414 (Acetylated distarch phosphate)",
    "Food Additive Class": "Emulsifier, Thickener, Binder",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "YES",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "YES",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 548,
    "INS Number": 1420,
    "Food Additive Name": "Starch acetate esterified with Acetic anhydride",
    "Food Additive Display Name":
      "1420 (Starch acetate esterified with Acetic anhydride)",
    "Food Additive Class": "Stabilizer, Thickener",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "YES",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 549,
    "INS Number": 1421,
    "Food Additive Name": "Starch acetate esterified with vinyl acetate",
    "Food Additive Display Name":
      "1421 (Starch acetate esterified with vinyl acetate)",
    "Food Additive Class": "Stabilizer, Thickener",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "YES",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 550,
    "INS Number": 1422,
    "Food Additive Name": "Acetylated distarch adipate",
    "Food Additive Display Name": "1422 (Acetylated distarch adipate)",
    "Food Additive Class": "Stabilizer, Thickener, Binder, Emulsifier",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "YES",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "YES",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 551,
    "INS Number": 1423,
    "Food Additive Name": "Acetylated distarch glycord",
    "Food Additive Display Name": "1423 (Acetylated distarch glycord)",
    "Food Additive Class": "Stabilizer, Thickener",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "YES",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 552,
    "INS Number": 1440,
    "Food Additive Name": "Hydroxypropyl starch",
    "Food Additive Display Name": "1440 (Hydroxypropyl starch)",
    "Food Additive Class": "Stabilizer, Thickener, Binder, Emulsifier",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "YES",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "YES",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 553,
    "INS Number": 1442,
    "Food Additive Name": "Hydroxypropyl distarch phosphate",
    "Food Additive Display Name": "1442 (Hydroxypropyl distarch phosphate)",
    "Food Additive Class": "Stabilizer, Thickener",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "YES",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 554,
    "INS Number": 1443,
    "Food Additive Name": "Hydroxypropyl distarch",
    "Food Additive Display Name": "1443 (Hydroxypropyl distarch)",
    "Food Additive Class": "Stabilizer, Thickener",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "YES",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
  {
    "Sr. No": 555,
    "INS Number": 1450,
    "Food Additive Name": "Starch sodium octenyl succinate",
    "Food Additive Display Name": "1450 (Starch sodium octenyl succinate)",
    "Food Additive Class": "Stabilizer, Thickener, Binder",
    "Acidity Regulator": "NO",
    "Alkalising Agent": "NO",
    "Anticaking Agent": "NO",
    "Anti-Clouding Agent": "NO",
    "Antifoaming Agent": "NO",
    Antioxidant: "NO",
    "Artificial Sweetener": "NO",
    "Ascorbic Acid": "NO",
    "Bleaching Agent": "NO",
    "Bulking Agent": "NO",
    "Calcium Propionate": "NO",
    "Caloric Sweetener": "NO",
    "Carbonating Agent": "NO",
    Carrier: "NO",
    Colour: "NO",
    "Colour retention agent": "NO",
    "Dispersing Agent": "NO",
    "Dough Conditioner": "NO",
    Emulsifier: "NO",
    "Firming Agent": "NO",
    Flavour: "NO",
    "Flavour Enhancer": "NO",
    "Flavouring Agent": "NO",
    "Flour Treatment Agent": "NO",
    "Foaming Agent": "NO",
    "Gelling Agent": "NO",
    "Glazing Agent": "NO",
    Humectant: "NO",
    Improvers: "NO",
    "Lactulose Syrup": "NO",
    "Lactic Acid": "NO",
    "Leavening Agent": "NO",
    "Modified Starch": "NO",
    "Moisture Retention Agent": "NO",
    "Neutralising Agent": "NO",
    Oligofructose: "NO",
    "Packaging Gas": "NO",
    "Potassium Metabisulphite": "NO",
    Polyol: "NO",
    Preservatives: "NO",
    "Processing Aid": "NO",
    Propellant: "NO",
    "Phyto Or Plant Stanol": "NO",
    "Phyto Or Plant Sterol": "NO",
    "Raising Agent": "NO",
    Sequestrant: "NO",
    "Sodium Metabisulphite": "NO",
    "Softening Agent": "NO",
    Stabiliser: "YES",
    Sweetener: "NO",
    Thickener: "YES",
    Trehalose: "NO",
    Yeast: "NO",
    "Clarifying Agent": "NO",
    Freezant: "NO",
  },
]
export const insDropdown = ins.map((el) => el["Food Additive Display Name"])

export const insByClass = (className) => {
  let insDropdown = []

  for (let i = 0; i < ins.length; i++) {
    if (ins[i][className] === "YES") {
      insDropdown.push(ins[i]["Food Additive Display Name"])
    }
  }
  return insDropdown
}
