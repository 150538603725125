import React, { useEffect, useState } from "react"
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined"
import "../../pages/AILabelReview/sidePanel.css"
import { getDigitalDriveDocumentsLabelById } from "../../services/ApiServicesv2"
import { useLocation, useParams } from "react-router-dom"
import DigitalLockerDocLine from "./DigitalLockerDocLine"
import { toast } from "react-toastify"

const DigitalLockerModal = ({ onClose, setIsModalOpen }) => {
  const params = useParams()
  const location = useLocation()
  const [labelData, setLabelData] = useState([])
  const [searchTerm, setSearchTerm] = useState("")
  const [currentData, setCurrentData] = useState([])

  const fetchData = async () => {
    try {
      const label_drive_documents = await getDigitalDriveDocumentsLabelById(
        params.id,
      )

      if (label_drive_documents.status === "success") {
        setLabelData(label_drive_documents.data)
      } else {
      }
    } catch (error) {
      toast.error("Failed to fetch Documents")
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    setCurrentData(labelData.filter((data) => data.document_name.length != 0))
  }, [labelData])

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value)
  }

  const filteredDocuments = labelData
    ? labelData.filter((doc) =>
        doc.document_name.toLowerCase().includes(searchTerm.toLowerCase()),
      )
    : [...labelData]

  return (
    <div
      style={{
        borderRadius: "10px",
        boxShadow: "0px 8px 30px rgba(255, 255, 255, 0.3)",
      }}
      className="z-10 duration-[1000] h-[354px] w-[608px] border-[1px] rounded bg-[#FFFFFF]"
    >
      <div className="m-[16px]">
        <div className="flex mb-[24px]">
          <div className="font-[600px] text-[24px] mr-[250px]">
            Label Drive Documents
          </div>
          <button onClick={onClose}>
            <CancelOutlinedIcon />
          </button>
        </div>
        <input
          className="pl-[12px] h-[48px] w-[568px] rounded border-[1px]"
          placeholder="Search Document"
          type="text"
          value={searchTerm}
          onChange={handleSearchChange}
        />
        <div className="mt-2 flex flex-col overflow-hidden overflow-x-hidden overflow-y-auto max-h-[205px] scroll-width">
          {currentData.length > 0 && filteredDocuments.length > 0 ? (
            filteredDocuments.map(
              (doc) =>
                doc.document_name && (
                  <DigitalLockerDocLine
                    key={doc.id}
                    document_name={doc.document_name}
                    size={doc.size}
                    password={doc.password}
                    document_type={doc.document_type}
                    status={doc.status}
                    document_url={doc.document_url}
                    setIsModalOpen={setIsModalOpen}
                  />
                ),
            )
          ) : (
            <div className="bg-white mt-[60px] pt-2 flex pb-2 flex-col justify-center items-center  shadow-md border-black border-opacity-10 w-100 h-100">
              <h1 className="text-center w-100 text-[20px]">
                No Document found.
              </h1>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default DigitalLockerModal
