import ApiServices from "../../services/ApiServices"
import React, { useState, useEffect } from "react"

export default function AWSFilePreview({ s3Key, ...rest }) {
  const [link, setLink] = useState("")

  useEffect(() => {
    ApiServices.getFromS3(s3Key).then(setLink)
  }, [s3Key])

  return link !== "" ? (
    <iframe src={link.data} alt="" {...rest} crossOrigin="" />
  ) : (
    <></>
  )
}
