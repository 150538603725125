import React, { Fragment, useEffect } from "react"
import { Menu, Transition } from "@headlessui/react"
// import { getStripeCustomer } from "../../services/ApiServices"
// import { getPlanName } from "../../helpers/helper"
import { getUserRole } from "../../services/ApiServicesv2"
import { toast } from "react-toastify"

interface IProps {
  Allbusinesses: any[]
  active_business: string
  switchBusiness: (id: string) => void
  lbUserId: string
}

interface IBusiness {
  name: string
  id: string
  user_type: string
}

const BusinessSelector = ({
  Allbusinesses,
  active_business,
  switchBusiness,
  lbUserId,
}: IProps) => {
  const [businesses, setBusinesses] = React.useState<IBusiness[]>([])

  useEffect(() => {
    fetchBusinessData()
  }, [Allbusinesses])

  const fetchBusinessData = async () => {
    const temp: IBusiness[] = []

    // Fetch all the business plan details
    for (let i = 0; i < Allbusinesses.length; i++) {
      if (Allbusinesses[i].type !== "Fopl") {
        const business = Allbusinesses[i]

        // Fetch the business plan details
        //const res = await getStripeCustomer(business.id)
        const user_role = await getUserRole(business.id, lbUserId)

        if (user_role?.code !== 200) {
          toast.error(user_role?.message)
        } else {
          temp.push({
            name: business.name,
            id: business.id,
            user_type: user_role.data.role,
          })
        }
      }
    }
    setBusinesses(temp)
  }

  return (
    <Menu as="div" className="relative">
      <div>
        <Menu.Button className="inline-flex items-center p-2 hover:bg-gray-100 focus:bg-gray-100 rounded-lg">
          <span className="sr-only">Businesses</span>
          <div className="hidden md:flex md:flex-col md:items-end md:leading-tight">
            {businesses.map(
              (business, idx) =>
                active_business === business.id && (
                  <React.Fragment key={idx}>
                    <div
                      className="hidden md:flex md:flex-col md:leading-tight"
                      key={idx}
                    >
                      <span className={"font-semibold"}>{business.name}</span>
                      <span
                        className="text-sm text-gray-600"
                        style={{ textAlign: "left" }}
                      >
                        {business.user_type}
                      </span>
                    </div>
                  </React.Fragment>
                ),
            )}
          </div>
          <svg
            viewBox="0 0 20 20"
            fill="currentColor"
            className="hidden sm:block h-6 w-6 text-gray-300"
          >
            <path
              fillRule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute left-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none cursor-pointer">
          {businesses.length > 0
            ? businesses
                .sort((a, b) => (a.name > b.name ? 1 : -1))
                .map((busi) => (
                  <Menu.Item
                    as="div"
                    key={busi.id}
                    onClick={() => switchBusiness(busi.id)}
                    className={
                      active_business === busi.id
                        ? "bg-gray-200 w-full inline-flex items-center p-2 hover:bg-gray-200 focus:bg-gray-200"
                        : "w-full inline-flex items-center p-2 hover:bg-gray-200 focus:bg-gray-200"
                    }
                  >
                    <div className="hidden md:flex md:flex-col md:leading-tight">
                      <span className={"font-semibold"}>{busi.name}</span>
                      <span className="text-sm text-gray-600">
                        {busi.user_type}
                      </span>
                    </div>
                  </Menu.Item>
                ))
            : ""}
        </Menu.Items>
      </Transition>
    </Menu>
  )
}

export default BusinessSelector
