export default function niInfoValidator(output) {
  if (!output.mandatory_nutrient && output.single_ingredients === "Yes")
    return true
  if (
    output.mandatory_nutrient?.length === 0 &&
    output.single_ingredients === "Yes"
  )
    return true
  if (!output.mandatory_nutrient)
    return "Please provide mandatory nutrient information"

  const data = output.mandatory_nutrient[0]
  const saturated_fat = parseFloat(data.total_sfa ?? "0")
  const total_fat = parseFloat(data.total_fat ?? "0")
  const total_sugar = parseFloat(data.free_sugars ?? "0")
  const added_sugar = parseFloat(data.total_free_sugars ?? "0")
  const total_carbohydrate = parseFloat(data.carbohydrates ?? "0")
  const trans_fat = parseFloat(data.trans_fat ?? "0")
  const energy = parseFloat(data.energy ?? "0")
  const sodium = parseFloat(data.sodium ?? "0")
  const cholesterol = parseFloat(data.cholesterol ?? "0")
  const protein = parseFloat(data.protein ?? "0")

  const validateValue = (value, name) => {
    if (value == null) {
      return `${name} must be a number.`
    }

    const parsedValue = parseFloat(value ?? "0")

    const integerOrOneDecimalRegex = /^(\d{1,2}(\.\d)?|100(\.0)?)$/

    if (isNaN(parsedValue)) {
      return `${name} must be a number.`
    }

    if (parsedValue < 0) {
      return `${name} must be greater than or equal to 0.`
    }

    if (name === "Sodium") {
    } else if (parsedValue > 100) {
      return `${name} must be less than or equal to 100.`
    }

    if (name === "Sodium") {
      const regex = /^\d+(\.\d)?$/

      if (!regex.test(value.toString()))
        return `${name} must be an integer or have up to one decimal place.`
    } else if (name === "Trans Fat") {
      const regex = /^(100(\.0{1,2})?|(\d{1,2}(\.\d{1,2})?))$/
      if (!regex.test(value.toString()))
        return `${name} must be an integer or have up to two decimal place.`
    } else if (!integerOrOneDecimalRegex.test(value.toString())) {
      return `${name} must be an integer or have up to one decimal place.`
    }
  }

  const validateNutritionInformation = (data) => {
    const fields = [
      { key: "total_sfa", name: "Saturated Fat" },
      { key: "total_fat", name: "Total Fat" },
      { key: "free_sugars", name: "Total Sugars" },
      { key: "total_free_sugars", name: "Added Sugars" },
      { key: "carbohydrates", name: "Total Carbohydrate" },
      { key: "trans_fat", name: "Trans Fat" },
      { key: "sodium", name: "Sodium" },
      { key: "cholesterol", name: "Cholestrol" },
      { key: "protein", name: "Protein" },
    ]

    let messages = fields.map((field) => {
      const value = data[field.key]
      const message = validateValue(value, field.name)
      return message ? message : null
    })

    messages = messages.filter((message) => message !== null)

    return messages
  }

  const errorMessages = validateNutritionInformation(data)

  // if (errorMessages.length > 0) {
  //   const msg = errorMessages.join(", ")
  //   return msg
  // }

  if (
    energy < 0 ||
    !Number.isInteger(energy) ||
    data.energy === undefined ||
    data.energy.toString() !== parseInt(data.energy).toString()
  )
    errorMessages.push("Energy must be a whole number")

  if (energy > 999) {
    errorMessages.push("Energy should not be greater than 3 digits")
  }

  if (saturated_fat > total_fat) {
    errorMessages.push("Saturated Fat cannot be more than Total Fat")
  }

  if (total_sugar > total_carbohydrate) {
    errorMessages.push("Total Sugar cannot be more than Carbohydrate")
  }

  if (added_sugar > total_carbohydrate || added_sugar > total_sugar) {
    errorMessages.push(
      "Added Sugar cannot be more than Total Sugar & Carbohydrate",
    )
  }

  if (trans_fat > saturated_fat || trans_fat > total_fat) {
    errorMessages.push(
      "Trans Fat cannot be more than Saturated Fat & Total Fat",
    )
  }

  if (total_carbohydrate + protein + total_fat > 100) {
    errorMessages.push(
      "Sum of Protein, Carbohydrate & Total Fat cannot be greater than 100g",
    )
  }

  if (errorMessages.length > 0) {
    // for (const error of errorMessages) {
    //   toast.error(errorMessages);
    //   return;
    // }
    return errorMessages
  }
  // Non Mandatory Ingredients

  return true
}

export const protein_carbs_fat_sum_error_msg =
  "Sum of Protein, Carbohydrate & Total Fat cannot be greater than 100g"
export const protein_carbs_fat_sum_warning_msg =
  "Total carbs + protein + fat exceeding 100g. Please check the values again"
