import React from "react"
import { useHistory, useParams } from "react-router-dom"
import LockOutlinedIcon from "@mui/icons-material/LockOutlined"
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined"
import TruncatedString from "../../common/TruncatedString/TruncatedString"

const DigitalLockerDocLine = ({
  document_type,
  document_name,
  size,
  status,
  password,
  document_url,
}) => {
  const history = useHistory()
  const params = useParams()
  const handleViewFile = () => {
    const data = {
      state: {
        item: {
          id: params.id,
          url: document_url,
          name: document_name,
          type: document_type,
        },
      },
    }

    const dataString = encodeURIComponent(JSON.stringify(data))
    const full_url = `${"/ppf/label-drive"}/${params.id}?data=${dataString}`
    window.open(full_url, "_blank")
  }

  return (
    <div
      onClick={handleViewFile}
      style={{ borderRadius: "8px" }}
      className="cursor-pointer flex items-center min-h-[48px] w-[568px] border-b bg-[#F1F1F1] mt-[16px]"
    >
      <div className="flex text-[16px] px-[16px] py-[16px]">
        <div className="flex min-w-[235px]">
          <div className="font-medium pr-1">
            <TruncatedString text={document_name} size={12} />
          </div>
          <div className="text-[#2F80ED] text-[10px] mr-[50px]">
            {password ? <LockOutlinedIcon /> : <LockOpenOutlinedIcon />}
          </div>
        </div>
        <div className="text-[#555555] mr-[50px] min-w-[113px]">{status}</div>
        <div className="text-[#555555] mr-[4px] min-w-[32px]">
          {document_type.toUpperCase()}
        </div>
        <span
          style={{ borderColor: "#555555" }}
          className="w-[2px] h-[21px] text-[#555555] border-r mr-[4px] mt-[1px]"
        ></span>
        <div className="text-[#555555] ml-[4px]">{size}</div>
      </div>
    </div>
  )
}

export default DigitalLockerDocLine
