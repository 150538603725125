import {
  GET_CATEGORIES_REQUEST,
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORIES_FAILURE,
  SET_FOOD_CATEGORIES,
} from "./categoriesTypes"

const initialState = {
  loading: false,
  error: false,
  error_message: "",
  categories: [],
  food_categories: {},
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CATEGORIES_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case GET_CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        error_message: "",
        categories: action.payload,
      }
    case GET_CATEGORIES_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        error_message: "Error retrieving categories",
      }
    case SET_FOOD_CATEGORIES:
      return {
        ...state,
        food_categories: action.payload,
      }
    default:
      return state
  }
}

export default reducer
