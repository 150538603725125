import React from "react"
import EstimationModal from "../../components/EstimationModal"

const PricingPlanInfo = ({
  pricingData,
  wrapperClass,
  reducedInfo = false,
}) => {
  return (
    <div className={`flex-col items-center shrink-0 flex ${wrapperClass}`}>
      <div className="w-[46px] h-[46px] rounded-full bg-[#f2f2f280] flex items-center justify-center">
        <img src={pricingData.icon} alt="" />
      </div>
      <div className="mt-2 font-bold text-xl">{pricingData.name}</div>
      <div className="mt-2 font-medium text-xl text-[#555555]">
        {pricingData.recipes}
      </div>
      {!reducedInfo && (
        <div className="mt-2 text-sm font-normal text-[#555555] text-center h-[80px]">
          {pricingData.description}
        </div>
      )}
      {!reducedInfo && (
        <div className="mt-2 flex items-center justify-center">
          <img src={pricingData.planAbout} alt="" />
        </div>
      )}
      <div className="mt-4 text-2xl font-semibold">
        {pricingData.name !== "Enterprise Plan" ? <span>&#8377;</span> : ""}
        {pricingData.pricing}
      </div>
      <div className="mt-2 text-sm font-medium">* {pricingData.duration}</div>
      <div className="mt-2 text-xs font-medium text-[#555555]">
        {pricingData.additional}
      </div>
      <div
        className={`mt-4 ${pricingData.ctaClass} cursor-pointer text-xl font-bold text-white py-[13px] rounded-lg`}
        onClick={() => pricingData?.onClick()}
      >
        {pricingData.ctaText}
      </div>
      <p className="text-sm pt-2 text-center mb-2">
        {pricingData.additionalInfo}
      </p>
      {pricingData.name != "Enterprise Plan" && (
        <EstimationModal plan_id={pricingData.id} />
      )}
    </div>
  )
}

export default PricingPlanInfo
