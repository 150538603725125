import { Button, Snackbar } from "@material-ui/core"
import { Alert } from "@material-ui/lab"
import React, { useState } from "react"
import "./AlertBox.css"
const AlertBox = ({ message, type }) => {
  const [open, setOpen] = useState(true)

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        action={
          <Button color="secondary" size="small" onClick={handleClose}>
            X
          </Button>
        }
      >
        <Alert onClose={handleClose} severity={type}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  )
}

export default AlertBox
