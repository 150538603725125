import axios from "axios"
import config from "../config"
import { getCommonHeaders } from "./ApiServicesv2"

export async function uploadPDFFileS3(file, file_name) {
  try {
    const headers = await getCommonHeaders()
    const formData = new FormData()

    formData.append("file", file)
    formData.append("file_name", file_name)

    const response = await axios.post(
      `${config.APIENDPOINT}/api/v2/file/upload/pdf`,
      formData,
      { headers: { ...headers, "Content-Type": "multipart/form-data" } },
    )

    return response.data
  } catch (error) {
    return error
  }
}

export async function getFileFomS3(file_name) {
  try {
    let file
    const regex = /public\/(.*)/
    const match = file_name.match(regex)

    if (match) {
      file = match[1]
    } else {
      file = file_name
    }

    const headers = await getCommonHeaders()

    const response = await axios.get(
      `${config.APIENDPOINT}/api/v2/file/get/pdf/${file}`,
      { headers },
    )

    return response.data
  } catch (error) {
    return error
  }
}

export async function uploadImageS3(file, file_name) {
  try {
    const headers = await getCommonHeaders()
    const formData = new FormData()

    formData.append("image", file)
    formData.append("file_name", file_name)

    const response = await axios.post(
      `${config.APIENDPOINT}/api/v2/file/upload/image`,
      formData,
      { headers: { ...headers, "Content-Type": "multipart/form-data" } },
    )

    return response.data
  } catch (error) {
    return error
  }
}

export async function getImageS3(file_name) {
  try {
    const headers = await getCommonHeaders()

    const response = await axios.get(
      `${config.APIENDPOINT}/api/v2/file/get/image/${file_name}`,
      { headers },
    )

    return response.data
  } catch (error) {
    return error
  }
}

export async function deleteFileFromS3(file_name) {
  try {
    const headers = await getCommonHeaders()

    const response = await axios.delete(
      `${config.APIENDPOINT}/api/v2/file/remove/${file_name}`,
      { headers },
    )

    return response.data
  } catch (error) {
    return error
  }
}
