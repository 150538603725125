import {
  SET_RECIPE_LIMIT_REACHED,
  SET_INACTIVE,
  SET_ALLOW_TO_PROTECTED_ROUTES,
} from "./SubscriptionTypes"

const initialState = {
  plans: [],
  subscriptions: [],
  inactive_subscriptions: [],
  user_has_active_subscription: false,
  allow_to_protected_routes: false,
  loading: false,
  error: false,
  error_message: "",
  recipe_limit_reached: false,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_RECIPE_LIMIT_REACHED:
      return {
        ...state,
        recipe_limit_reached: true,
      }
    case SET_INACTIVE:
      return {
        ...state,
        inactive_subscriptions: action.payload,
      }
    case SET_ALLOW_TO_PROTECTED_ROUTES:
      return {
        ...state,
        allow_to_protected_routes: action.payload,
      }
    default:
      return state
  }
}

export default reducer
