// Validation rules for FSSAI license
let validation_rules = {
  0: "digit === 1 || digit === 2",
  1: { state: "digit >= 1 && digit <= 3", central: "digit === 0" },
  2: { state: "digit <= 5", central: "digit === 0" },
  3: "digit <= 9",
  4: "digit >= 1 && digit <= 9",
  5: "digit <= 9",
  6: "digit <= 9",
  7: "digit >= 1 && digit <=9",
  8: "Number.isInteger(digit)",
}

//State -   2-22-45663-123123
//Central - 1-00-45663-123123
//Central - 1-00-14022-002711

function isValidLicense(lic_no, lic_type) {
  let digits = Array.from(lic_no.toString())
  let error_count = 0

  if (Number.isInteger(parseInt(lic_no)) && digits.length === 14) {
    for (let i = 0; i < digits.length; i++) {
      let is_valid_digit = false
      let digit = parseInt(digits[i])

      // for the last 6 digits
      if (i > 7) {
        is_valid_digit = eval(validation_rules[8])
      }
      // for the first 8 digits
      else {
        // for the second & third digits (state/central license check)
        if (i == 1 || i == 2) {
          let rule =
            lic_type === "state"
              ? validation_rules[i].state
              : validation_rules[i].central
          is_valid_digit = eval(rule)
        } else {
          is_valid_digit = eval(validation_rules[i])
        }
      }

      if (!is_valid_digit) {
        error_count++
      }
    }

    //if all digits are valid
    if (error_count === 0) {
      return true
    }

    return true
  } else {
    return false
  }
}

export default isValidLicense
