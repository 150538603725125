import React from "react"
import { useHistory } from "react-router-dom"

interface QkButtonProps {
  data: {
    link: string
    key: string
  }
}

export default function QkButton({ data }: QkButtonProps) {
  const history = useHistory()

  const redirectToLink = () => {
    history.push(data.link)
  }

  return (
    <button
      className={`focus:outline-none focus:ring-0 bg-yellow-400 px-4 py-3 rounded-lg font-bold`}
      onClick={() => redirectToLink()}
    >
      <span>{data.key}</span>
    </button>
  )
}
