import {
  SET_RECIPE_LIMIT_REACHED,
  SET_INACTIVE,
  SET_ALLOW_TO_PROTECTED_ROUTES,
} from "./SubscriptionTypes"
import apis from "../../services/ApiServices"
import moment from "moment"
import { getBlogs } from "../Blogs/blogActions"
import { getFaqs } from "../Faq/faqActions"
import config from "../../config"

export const allowToProtectedRoutes = (allow) => {
  return {
    type: SET_ALLOW_TO_PROTECTED_ROUTES,
    payload: allow,
  }
}

export const recipeLimitReached = () => {
  return {
    type: SET_RECIPE_LIMIT_REACHED,
  }
}
