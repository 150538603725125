/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: "ap-south-1",
  aws_cognito_identity_pool_id:
    "ap-south-1:ab9d52de-6ae7-4b6c-9e21-54f65ece2cf7",
  aws_cognito_region: "ap-south-1",
  aws_user_pools_id: "ap-south-1_R8ArRu20Y",
  aws_user_pools_web_client_id: "3ta87am1bopbgb7kotjp3ag0b6",
  // authenticationFlowType: 'CUSTOM_AUTH',
  oauth: {},
  aws_cognito_username_attributes: ["EMAIL", "PHONE_NUMBER"],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: ["NAME"],
  aws_cognito_mfa_configuration: "OPTIONAL",
  aws_cognito_mfa_types: ["SMS", "TOTP"],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [
      "REQUIRES_LOWERCASE",
      "REQUIRES_UPPERCASE",
      "REQUIRES_NUMBERS",
      "REQUIRES_SYMBOLS",
    ],
  },
  aws_cognito_verification_mechanisms: ["EMAIL"],
  aws_cloud_logic_custom: [
    {
      name: "AdminQueries",
      endpoint: "https://cw2ax645zb.execute-api.ap-south-1.amazonaws.com/dev",
      region: "ap-south-1",
    },
    {
      name: "UserManagement",
      endpoint: "https://ufn9ija9ug.execute-api.ap-south-1.amazonaws.com/dev",
      region: "ap-south-1",
    },
    {
      name: "BusinessManagement",
      endpoint: "https://8r53xx1lml.execute-api.ap-south-1.amazonaws.com/dev",
      region: "ap-south-1",
    },
    {
      name: "FoodManagement",
      endpoint: "https://w8mm2slj89.execute-api.ap-south-1.amazonaws.com/dev",
      region: "ap-south-1",
    },
    {
      name: "ItemsManagement",
      endpoint: "https://1k5kxb9o2j.execute-api.ap-south-1.amazonaws.com/dev",
      region: "ap-south-1",
    },
    {
      name: "ContentManagement",
      endpoint: "https://t08ddvty5g.execute-api.ap-south-1.amazonaws.com/dev",
      region: "ap-south-1",
    },
    {
      name: "NotificationManagement",
      endpoint: "https://u50j17kjx1.execute-api.ap-south-1.amazonaws.com/dev",
      region: "ap-south-1",
    },
    {
      name: "MedianCrudApi",
      endpoint: "https://pxsmy5ho21.execute-api.ap-south-1.amazonaws.com/dev",
      region: "ap-south-1",
    },
    {
      name: "AnalyzerUserApi",
      endpoint: "https://sx5dsns5fg.execute-api.ap-south-1.amazonaws.com/dev",
      region: "ap-south-1",
    },
    {
      name: "PnlManagement",
      endpoint: "https://xde1234iog.execute-api.ap-south-1.amazonaws.com/dev",
      region: "ap-south-1",
    },
    {
      name: "AmplitudeManagement",
      endpoint: "https://nlmxfn6c19.execute-api.ap-south-1.amazonaws.com/dev",
      region: "ap-south-1",
    },
  ],
  aws_appsync_graphqlEndpoint:
    "https://4cdmlibkvvew3e7tgekbxjxzkq.appsync-api.ap-south-1.amazonaws.com/graphql",
  aws_appsync_region: "ap-south-1",
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
  aws_dynamodb_all_tables_region: "ap-south-1",
  aws_dynamodb_table_schemas: [
    {
      tableName: "Users-dev",
      region: "ap-south-1",
    },
    {
      tableName: "Businesses-dev",
      region: "ap-south-1",
    },
    {
      tableName: "UserBusinessMapping-dev",
      region: "ap-south-1",
    },
    {
      tableName: "Recipes-dev",
      region: "ap-south-1",
    },
    {
      tableName: "Items-dev",
      region: "ap-south-1",
    },
    {
      tableName: "Content-dev",
      region: "ap-south-1",
    },
    {
      tableName: "Notifications-dev",
      region: "ap-south-1",
    },
    {
      tableName: "MedianValues-dev",
      region: "ap-south-1",
    },
    {
      tableName: "AnalyzerUser-dev",
      region: "ap-south-1",
    },
  ],
  aws_user_files_s3_bucket: "folsolbucket151525-dev",
  aws_user_files_s3_bucket_region: "ap-south-1",
}

export default awsmobile
