import React from "react"
export default function UK() {
  return (
    <svg
      width="32"
      height="24"
      viewBox="0 0 32 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 0V24H32V0H0Z"
        fill="#2E42A5"
      />
      <mask
        id="mask0_5761_6184"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="32"
        height="24"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0 0V24H32V0H0Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_5761_6184)">
        <path
          d="M-3.56445 22.2837L3.47724 25.2617L32.1585 3.2361L35.8728 -1.18938L28.3427 -2.18474L16.6443 7.30673L7.22834 13.7018L-3.56445 22.2837Z"
          fill="white"
        />
        <path
          d="M-2.59961 24.3694L0.987806 26.0977L34.5398 -1.60128H29.5027L-2.59961 24.3694Z"
          fill="#F50100"
        />
        <path
          d="M35.5625 22.2837L28.5208 25.2617L-0.160427 3.2361L-3.87476 -1.18938L3.65532 -2.18474L15.3537 7.30673L24.7697 13.7018L35.5625 22.2837Z"
          fill="white"
        />
        <path
          d="M35.3223 23.7835L31.7348 25.5117L17.448 13.6524L13.2123 12.3273L-4.23212 -1.17185H0.805027L18.2397 12.0069L22.8707 13.5958L35.3223 23.7835Z"
          fill="#F50100"
        />
        <mask id="path-7-inside-1_5761_6184" fill="white">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M19.7776 -2H12.222V8H-1.97266V16H12.222V26H19.7776V16H34.0273V8H19.7776V-2Z"
          />
        </mask>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M19.7776 -2H12.222V8H-1.97266V16H12.222V26H19.7776V16H34.0273V8H19.7776V-2Z"
          fill="#F50100"
        />
        <path
          d="M12.222 -2V-4H10.222V-2H12.222ZM19.7776 -2H21.7776V-4H19.7776V-2ZM12.222 8V10H14.222V8H12.222ZM-1.97266 8V6H-3.97266V8H-1.97266ZM-1.97266 16H-3.97266V18H-1.97266V16ZM12.222 16H14.222V14H12.222V16ZM12.222 26H10.222V28H12.222V26ZM19.7776 26V28H21.7776V26H19.7776ZM19.7776 16V14H17.7776V16H19.7776ZM34.0273 16V18H36.0273V16H34.0273ZM34.0273 8H36.0273V6H34.0273V8ZM19.7776 8H17.7776V10H19.7776V8ZM12.222 0H19.7776V-4H12.222V0ZM14.222 8V-2H10.222V8H14.222ZM-1.97266 10H12.222V6H-1.97266V10ZM0.0273438 16V8H-3.97266V16H0.0273438ZM12.222 14H-1.97266V18H12.222V14ZM14.222 26V16H10.222V26H14.222ZM19.7776 24H12.222V28H19.7776V24ZM17.7776 16V26H21.7776V16H17.7776ZM34.0273 14H19.7776V18H34.0273V14ZM32.0273 8V16H36.0273V8H32.0273ZM19.7776 10H34.0273V6H19.7776V10ZM17.7776 -2V8H21.7776V-2H17.7776Z"
          fill="white"
          mask="url(#path-7-inside-1_5761_6184)"
        />
      </g>
    </svg>
  )
}
