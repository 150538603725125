import React from "react"
export default function Canada(){
  return(
    <svg width="32" height="24" viewBox="0 0 32 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_5456_38506)">
<rect width="32" height="24" rx="2" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M8 0H24V24H8V0Z" fill="#F7FCFF"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M15.9764 7L16 19H15.4005L15.7683 16.0303C13.0782 16.5144 11.8443 16.6343 12.0667 16.3902C12.2891 16.146 12.4644 15.8 12.5925 15.3521L9 12.9553C9.37779 12.9506 9.72035 12.8568 10.0277 12.674C10.335 12.4912 10.1814 11.8578 9.56668 10.7737L11.3805 11.0376L12.0667 10.2918L13.4352 11.7241H14.0503L13.4352 8.44343L14.5378 9.05932L15.9764 7ZM15.9764 7L17.4622 9.05932L18.5648 8.44343L17.9497 11.7241H18.5648L19.9333 10.2918L20.6195 11.0376L22.4333 10.7737C21.8186 11.8578 21.665 12.4912 21.9723 12.674C22.2796 12.8568 22.6222 12.9506 23 12.9553L19.4075 15.3521C19.5356 15.8 19.7109 16.146 19.9333 16.3902C20.1557 16.6343 18.9218 16.5144 16.2317 16.0303L16.5995 19H16L15.9764 7Z" fill="#E31D1C"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M24 0H32V24H24V0Z" fill="#E31D1C"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M0 0H8V24H0V0Z" fill="#E31D1C"/>
</g>
<defs>
<clipPath id="clip0_5456_38506">
<rect width="32" height="24" rx="2" fill="white"/>
</clipPath>
</defs>
</svg>

  )
}