import React from "react"

export default function SelectedRecipeWidget({ values }: any) {
  const recipe_name =
    values?.recipe?.name || values?.extraData?.selected_recipe?.name

  if (!recipe_name) return null

  return (
    <div className="mt-2 text-md border-2 border-dashed border-gray-300 rounded-lg bg-gray-100 py-2 px-4 w-full flex justify-start items-center">
      Recipe Name:
      <b className="ml-1">{recipe_name || "No recipe selected"}</b>
    </div>
  )
}
