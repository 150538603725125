import React from "react"

const FieldInformation: React.FC = () => {
  return (
    <div className="mt-1 text-[orange] italic text-[12px]">
      The claim "100% Fruit Juice" is only allowed if the juice is made entirely
      from fruit components. If the juice is reconstituted, the "100% Fruit
      Juice" claim must be removed.
    </div>
  )
}

export default FieldInformation
