import {
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_FAILURE,
  CLEAR_REGISTER_ERRORS,
} from "./registerTypes"

const initialState = {
  loading: false,
  error: false,
  registered: false,
  error_message: "",
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_REGISTER_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case USER_REGISTER_SUCCESS:
      return {
        error_message: "",
        loading: false,
        error: false,
        registered: true,
      }
    case USER_REGISTER_FAILURE:
      return {
        registered: false,
        error: true,
        loading: false,
        error_message: action.payload,
      }
    case CLEAR_REGISTER_ERRORS:
      return {
        ...state,
        error: false,
        loading: false,
        error_message: "",
      }
    default:
      return state
  }
}

export default reducer
