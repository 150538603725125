import ApiServices from "../services/ApiServices"
import { toast } from "react-toastify"
import { messages } from "../constants/messages"
import { uploadPDFFileS3 } from "../services/fileUpload"

const today = new Date()
const Year = today.getFullYear()
let mm = today.getMonth() + 1 // Months start at 0!
let dd = today.getDate()
let hh = today.getHours()
let min = today.getMinutes()
let ss = today.getSeconds()

if (dd < 10) dd = "0" + dd
if (mm < 10) mm = "0" + mm
if (hh < 10) hh = "0" + hh
if (min < 10) min = "0" + min
if (ss < 10) ss = "0" + ss

const formattedToday =
  dd + "_" + mm + "_" + Year + "_" + hh + "_" + min + "_" + ss

export const fileupload = async (file, businessId) => {
  const today = new Date()
  const Year = today.getFullYear()
  let mm = today.getMonth() + 1 // Months start at 0!
  let dd = today.getDate()
  let hh = today.getHours()
  let min = today.getMinutes()
  let ss = today.getSeconds()

  if (dd < 10) dd = "0" + dd
  if (mm < 10) mm = "0" + mm
  if (hh < 10) hh = "0" + hh
  if (min < 10) min = "0" + min
  if (ss < 10) ss = "0" + ss

  const formattedToday =
    dd + "_" + mm + "_" + Year + "_" + hh + "_" + min + "_" + ss

  if (file) {
    const file_name_split = file.name.split(".")
    const file_ext = file_name_split[file_name_split.length - 1]
    const s3_file_name = businessId + "_" + formattedToday + "." + file_ext
    // if (file_ext !== " png"|| file_ext !== " jpeg" || file_ext !== " jpg") {
    //   toast.error(messages.formatError)
    //   return false;
    // }
    const upload = await ApiServices.uploadImagesToS3(file, s3_file_name)
    return upload
  }
}
export const fileuploadv2 = async (file, businessId) => {
  if (file) {
    const file_name_split = file.name.split(".")
    const file_ext = file_name_split[file_name_split.length - 1]
    const doc_name = Date.now().toString()
    const s3_file_name = businessId + "_" + doc_name + "." + file_ext
    // if (file_ext !== " png"|| file_ext !== "jpeg" || file_ext !== "jpg") {
    //   toast.error(messages.formatError)
    //   return false;
    // }
    // const upload = await ApiServices.uploadFileToS3(file, s3_file_name)
    const upload = await uploadPDFFileS3(file, s3_file_name)
    return upload
  }
}

export const multifileupload = async (files, businessId) => {
  let images = []
  if (files?.length > 0) {
    for (let upload_obj of files) {
      const file = upload_obj
      const file_name_split = file.name.split(".")
      const file_ext = file_name_split[file_name_split.length - 1]
      const s3_file_name = businessId + "_" + formattedToday + "." + file_ext
      // if (file_ext !== " png"|| file_ext !== " jpeg" || file_ext !== " jpg") {
      //   toast.error(messages.formatError)
      //   return
      // }
      const upload = await ApiServices.uploadImagesToS3(file, s3_file_name)
      images.push(upload.key)
    }
    return images
  }
}
