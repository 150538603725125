import { FieldType } from "../constants/enum"

const ingredients = (data, output) => {
  let final = {
    name: "",
    description: "",
    fields: [
      {
        title: "Ingredient {{ index }}",
        type: FieldType.TEXT,
        reference: "",
        validators: "",
        layout: 4,
        description: "",
        list: [],
        items: [],
        key: "ingredient_name",
        visible: true,
        required: true,
      },
      {
        title: "Display Name On Label",
        type: FieldType.TEXT,
        reference: "",
        validators: "",
        layout: 4,
        description: "",
        list: [],
        items: [],
        key: "name",
        visible: true,
        required: true,
      },
      {
        title: "Proportion (%)",
        type: FieldType.NUMBER,
        reference: "",
        validators: "",
        layout: 4,
        description: "",
        list: [],
        items: [],
        key: "proportion",
        visible: true,
        min: 0,
        max: 100,
      },
      {
        title: "Is this a compound ingredient ?",
        type: FieldType.RADIO,
        reference: "",
        validators: "",
        layout: 5,
        description: "",
        list: [],
        items: ["Yes", "No"],
        key: "compound",
        visible: output.single_ingredients !== "Yes",
        default: "No",
      },
      {
        title: "",
        type: FieldType.BLANK,
        reference: "",
        validator: "",
        layout: 2,
        description: "",
        list: [],
        items: [],
        key: "",
        visible: true,
      },
      {
        title: "Display proportion on label ?",
        type: FieldType.RADIO,
        reference: "",
        validators: "",
        layout: 5,
        description: "",
        list: [],
        items: ["Yes", "No"],
        key: "show_ingredient_proportion",
        visible: output.single_ingredients === "No",
        default: "No",
        customStyle: "ml-14",
      },
    ],
  }

  if (output.single_ingredients !== "Yes") {
    final.fields.push({
      title: "Ingredient list of compound ingredient",
      type: FieldType.TEXT,
      reference: "",
      validators: "",
      layout: 5,
      description: "",
      list: [],
      items: [],
      key: "ingredient",
      visible: 'output.compound === "Yes"',
      maxLength: 450,
    })
  }

  return final
}

export default ingredients
