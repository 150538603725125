import npopLogo from "../../assets/images/default/Organic_1.jpg"
import pgsIndiaLogo from "../../assets/images/banners/PGS India Organic logo.jpeg"

export const label_preview_images = {
  Milk: "https://labelblindimage.s3.ap-south-1.amazonaws.com/label-preview-download-category-images/milk_product.png",
  TransFatLogo:
    "https://labelblindimage.s3.ap-south-1.amazonaws.com/label-preview-download-category-images/transfatLogo.png",
  vegLogo:
    "https://labelblindimage.s3.ap-south-1.amazonaws.com/label-preview-download-category-images/trimmed-veg-logo.png",
  nonVegLogo:
    "https://labelblindimage.s3.ap-south-1.amazonaws.com/label-preview-download-category-images/non_veg_logo.png",
  eggLogo:
    "https://labelblindimage.s3.ap-south-1.amazonaws.com/label-preview-download-category-images/contains_egg_logo.png",
  veganLogo:
    "https://labelblindimage.s3.ap-south-1.amazonaws.com/label-preview-download-category-images/vegan_logo.png",
  irradiated_logo:
    "https://labelblindimage.s3.ap-south-1.amazonaws.com/label-preview-download-category-images/irradiated_logo.png",
  fortified_logo:
    "https://labelblindimage.s3.ap-south-1.amazonaws.com/label-preview-download-category-images/FortifiedLogo.png",
  organic_logo:
    "https://labelblindimage.s3.ap-south-1.amazonaws.com/label-preview-download-category-images/organicLogo.png",
  npop_logo: npopLogo,
  pgs_india_logo: pgsIndiaLogo,
  agmark_logo:
    "https://labelblindimage.s3.ap-south-1.amazonaws.com/Agmark+logo.gif",
  bis_logo: "https://labelblindimage.s3.ap-south-1.amazonaws.com/BIS+LOGO.jpeg",
  india_tea_logo:
    "https://labelblindimage.s3.ap-south-1.amazonaws.com/india+tea+logo.jpg",
  tea_board_logo:
    "https://labelblindimage.s3.ap-south-1.amazonaws.com/tea+board+logo.jpeg",
}
