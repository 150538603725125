import { FieldType } from "../constants/enum"

const labelDimension = (output) => ({
  name: "",
  description: "",
  fields: [
    {
      title: "Height",
      layout: 6,
      type: FieldType.NUMBER,
      description: "",
      units: [],
      items: [],
      key: "height",
      visible: true,
      customStyle: "[&>label]:flex",
      required: true,
      default: 0,
    },
    {
      title: "Width",
      type: FieldType.NUMBER,
      layout: 6,
      description: "",
      units: [],
      items: [],
      key: "width",
      visible: true,
      customStyle: "[&>label]:flex",
      required: true,
      default: 0,
    },
  ],
})

export default labelDimension
