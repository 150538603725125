import { fetchAllPlans } from "../../services/ApiServices"
import PricingSummary from "./pricingSummary"
import React, { Fragment, useEffect, useState } from "react"
import { Dialog, Transition } from "@headlessui/react"

const PricingModal = ({ open, onClose }) => {
  const [plansApiData, setPlansApiData] = useState([])
  const [loading, setLoading] = useState(false)

  const getAllPlans = async () => {
    let response = []
    try {
      response = await fetchAllPlans()
    } catch (error) {
      console.error(error)
    }
    setPlansApiData(response)
    setLoading(false)
  }

  useEffect(() => {
    setLoading(true)
    getAllPlans()
  }, [])

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={() => onClose()}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className="inline-block align-bottom 
            bg-white rounded-lg text-left 
            shadow-xl transform transition-all 
            sm:my-8 sm:align-middle max-w-7xl sm:w-full"
            >
              <div className="max-h-[550px] my-4 px-4 overflow-y-auto ">
                <div className="text-xl font-bold">
                  Please purchase a Menu labelling plan to continue
                </div>
                <div className="w-[60px] h-[5px] mt-1 bg-[#FFD700] rounded" />
                <PricingSummary
                  plansApiData={plansApiData}
                  loading={loading}
                  fromModal
                />
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default PricingModal
