import React from "react"
import { useSelector } from "react-redux"
import { Redirect } from "react-router-dom"
import Loading from "../../pages/Loading/Loading"

const Protected = (props) => {
  const userHasBusinesses = useSelector(
    (state) => state.login.userHasBusinesses,
  )
  const subscription_loading = useSelector(
    (state) => state.subscription.loading,
  )
  const superAdmin = useSelector((state) => state.profile.superadminAccess)
  const profile_loading = useSelector((state) => state.profile.loading)
  const login_loading = useSelector((state) => state.login.loading)

  return superAdmin ? (
    <props.component {...props} />
  ) : subscription_loading || profile_loading || login_loading ? (
    <Loading />
  ) : userHasBusinesses ? (
    <props.component {...props} />
  ) : (
    <Redirect to="/user-registration" />
  )
}

export default Protected
