import React, { useState, useEffect } from "react"
import FormControl from "@material-ui/core/FormControl"
import RadioGroup from "@material-ui/core/RadioGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Radio from "@material-ui/core/Radio"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"
import { sendAnalyticsEvent } from "../../Utils/analytics"
import { MANDATORY_DECLARATION_SELECTED } from "../../Utils/analyticsEvents"
import { FONT_SIZES } from "../../constants/FONTS"
import TextField from "@material-ui/core/TextField"

const useStyles = makeStyles((theme) => ({
  radioHeadings: {
    color: "black",
    paddingTop: theme.spacing(3.75),
    fontStyle: "bahnscrift",
    fontSize: FONT_SIZES.xs,
  },
  fields: {
    paddingBottom: theme.spacing(1.875),
  },
}))

export const mandatoryDeclarationsCheck = (
  ingredients,
  customIngredients,
  mandatoryDeclaration,
  setMandatoryDeclaration,
) => {
  let trueMandatoryDeclarations = {},
    falseMandatoryDeclarations = {}
  let isCustomIngredient = false
  ingredients?.length > 0
    ? ingredients.map((selectedIngredient) => {
        customIngredients?.map((ingredient) => {
          if (ingredient._id === selectedIngredient.ingredient_id) {
            isCustomIngredient = true
            const selectedMandatoryDeclarations = Object.fromEntries(
              Object.entries(ingredient._source).filter(
                ([key, value]) => value === true,
              ),
            )
            const notSelectedMandatoryDeclarations = Object.fromEntries(
              Object.entries(ingredient._source).filter(
                ([key, value]) => value === false,
              ),
            )
            let allTrueMandatoryDeclarations = Object.assign(
              trueMandatoryDeclarations,
              selectedMandatoryDeclarations,
            )
            let allFalseMandatoryDeclarations = Object.assign(
              falseMandatoryDeclarations,
              notSelectedMandatoryDeclarations,
            )
            setMandatoryDeclaration({
              ...mandatoryDeclaration,
              ...allFalseMandatoryDeclarations,
              ...allTrueMandatoryDeclarations,
            })
          }
          !isCustomIngredient &&
            setMandatoryDeclaration(initialMandatoryDeclarations)
        })
      })
    : setMandatoryDeclaration(initialMandatoryDeclarations)
}

export const initialMandatoryDeclarations = {
  more_polyols: null,
  more_polydextrose: null,
  caffeine_added: null,
  monosodium_glutamate: null,
  plant_stanols_esters: null,
  plant_sterols: null,
  non_caloric_sweetener: null,
  steviol_glycoside: null,
  thaumatin: null,
  aspartame: null,
  sucralose: null,
  neotame: null,
  acesulfame_potassium: null,
  aspartame_acesulfame_potassium_salt: null,
  saccharins: null,
  erythritol: null,
  plant_stanol_amount: "",
}

export const parseMandatoryDeclarationFromDetails = (items) => {
  return {
    more_polyols: items?.more_polyols || false,
    more_polydextrose: items?.more_polydextrose || false,
    caffeine_added: items?.caffeine_added || false,
    monosodium_glutamate: items?.monosodium_glutamate || false,
    plant_stanols_esters: items?.plant_stanols_esters || false,
    plant_sterols: items?.plant_sterols || false,
    non_caloric_sweetener: items?.non_caloric_sweetener || false,
    steviol_glycoside: items?.steviol_glycoside || false,
    thaumatin: items?.thaumatin || false,
    aspartame: items?.aspartame || false,
    sucralose: items?.sucralose || false,
    neotame: items?.neotame || false,
    acesulfame_potassium: items?.acesulfame_potassium || false,
    aspartame_acesulfame_potassium_salt:
      items?.aspartame_acesulfame_potassium_salt || false,
    saccharins: items?.saccharins || false,
    erythritol: items?.erythritol || false,
    plant_stanol_amount: items?.plant_stanol_amount || "",
  }
}

const MandatoryDeclarations = ({
  mandatoryDeclaration,
  setMandatoryDeclaration,
}) => {
  const classes = useStyles()
  const isNotProtectedNewRecipe =
    window.location.pathname.split("/")[1] === "merchant-recipe"
  const onChangeHandler = (event, value) => {
    if (isNotProtectedNewRecipe) {
      sendAnalyticsEvent(MANDATORY_DECLARATION_SELECTED, {
        "Page Name": "Merchant Recipe",
        Label: event.target.name,
        "Ingredient Name": value,
        "Business Id": window.location.pathname.split("/")[2],
        "Recipe Id": window.location.pathname.split("/")[3],
      })
    }
    const targetName = event.target.name
    const targetValue =
      targetName === "plant_stanol_amount"
        ? event.target.value
        : event.target.value === "Yes"
    setMandatoryDeclaration({
      ...mandatoryDeclaration,
      [targetName]: targetValue,
    })
  }
  return (
    <div>
      <FormControl component="fieldset">
        <Grid container direction="row" spacing={2}>
          <Grid item xs={6}>
            <div className={classes.container}>
              {!isNotProtectedNewRecipe && (
                <div className={classes.fields}>
                  <small className={classes.radioHeadings}>
                    10% Or More Polyols
                  </small>
                  <RadioGroup
                    row
                    aria-label="position"
                    name="more_polyols"
                    value={mandatoryDeclaration.more_polyols ? "Yes" : "No"}
                    onChange={onChangeHandler}
                  >
                    <FormControlLabel
                      value="Yes"
                      control={<Radio color="primary" />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="No"
                      control={<Radio color="primary" />}
                      label="No"
                    />
                  </RadioGroup>
                </div>
              )}
              <div className={classes.fields}>
                {isNotProtectedNewRecipe ? (
                  <small className={classes.radioHeadings}>
                    Monosodium Glutamate (MSG)
                  </small>
                ) : (
                  <small className={classes.radioHeadings}>
                    Monosodium Glutamate
                  </small>
                )}
                <RadioGroup
                  row
                  aria-label="position"
                  name="monosodium_glutamate"
                  value={
                    mandatoryDeclaration.monosodium_glutamate ? "Yes" : "No"
                  }
                  onChange={onChangeHandler}
                >
                  <FormControlLabel
                    value="Yes"
                    control={<Radio color="primary" />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value="No"
                    control={<Radio color="primary" />}
                    label="No"
                  />
                </RadioGroup>
              </div>
              {!isNotProtectedNewRecipe && (
                <div className={classes.fields}>
                  <small className={classes.radioHeadings}>
                    Added Caffeine
                  </small>
                  <RadioGroup
                    row
                    aria-label="position"
                    name="caffeine_added"
                    value={mandatoryDeclaration.caffeine_added ? "Yes" : "No"}
                    onChange={onChangeHandler}
                  >
                    <FormControlLabel
                      value="Yes"
                      control={<Radio color="primary" />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="No"
                      control={<Radio color="primary" />}
                      label="No"
                    />
                  </RadioGroup>
                </div>
              )}
            </div>
          </Grid>
          <Grid item xs={6}>
            {!isNotProtectedNewRecipe && (
              <>
                <div className={classes.fields}>
                  <small className={classes.radioHeadings}>
                    10% Or More Polydextrose
                  </small>
                  <RadioGroup
                    row
                    aria-label="position"
                    name="more_polydextrose"
                    value={
                      mandatoryDeclaration.more_polydextrose ? "Yes" : "No"
                    }
                    onChange={onChangeHandler}
                  >
                    <FormControlLabel
                      value="Yes"
                      control={<Radio color="primary" />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="No"
                      control={<Radio color="primary" />}
                      label="No"
                    />
                  </RadioGroup>
                </div>
                {/* <div> */}
                <div className={classes.fields}>
                  <small className={classes.radioHeadings}>
                    Plant Stanol Esters
                  </small>
                  <RadioGroup
                    row
                    aria-label="position"
                    name="plant_stanols_esters"
                    value={
                      mandatoryDeclaration.plant_stanols_esters ? "Yes" : "No"
                    }
                    onChange={onChangeHandler}
                  >
                    <FormControlLabel
                      value="Yes"
                      control={<Radio color="primary" />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="No"
                      control={<Radio color="primary" />}
                      label="No"
                    />
                  </RadioGroup>
                </div>
                {mandatoryDeclaration.plant_stanols_esters && (
                  <div>
                    <TextField
                      type={"text"}
                      value={mandatoryDeclaration.plant_stanol_amount || ""}
                      onChange={onChangeHandler}
                      required={true}
                      name={"plant_stanol_amount"}
                    />
                    <small className={classes.radioHeadings}>
                      g/100g or 100ml
                    </small>
                  </div>
                )}
                {/* </div> */}
              </>
            )}
          </Grid>
          <div className={classes.fields}>
            {isNotProtectedNewRecipe ? (
              <small className={classes.radioHeadings}>
                Zero-caloric Sweetener (Stevia, Saccharin, Aspartame, Sucralose,
                Thaumatin, Neotame, Acesulfame Potassium, Erythritol,
                Aspartame-Acesulfame potassium salt)
              </small>
            ) : (
              <small className={classes.radioHeadings}>
                Zero-caloric Sweetener (Stevia, Saccharin, Aspartame, Sucralose,
                Thaumatin, Neotame, Acesulfame Potassium, Erythritol,
                Aspartame-Acesulfame potassium salt)
              </small>
            )}
            <RadioGroup
              row
              aria-label="position"
              name="non_caloric_sweetener"
              value={mandatoryDeclaration.non_caloric_sweetener ? "Yes" : "No"}
              onChange={onChangeHandler}
            >
              <FormControlLabel
                value="Yes"
                control={<Radio color="primary" />}
                label="Yes"
              />
              <FormControlLabel
                value="No"
                control={<Radio color="primary" />}
                label="No"
              />
            </RadioGroup>
          </div>
          {mandatoryDeclaration.non_caloric_sweetener === true &&
          !isNotProtectedNewRecipe ? (
            <Grid container direction="row" spacing={3}>
              <Grid item xs={3}>
                <div className={classes.container}>
                  <div className={classes.fields}>
                    <small className={classes.radioHeadings}>
                      Steviol glycoside (E960)
                    </small>
                    <RadioGroup
                      row
                      aria-label="position"
                      name="steviol_glycoside"
                      value={
                        mandatoryDeclaration.steviol_glycoside ? "Yes" : "No"
                      }
                      onChange={onChangeHandler}
                    >
                      <FormControlLabel
                        value="Yes"
                        control={<Radio color="primary" />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="No"
                        control={<Radio color="primary" />}
                        label="No"
                      />
                    </RadioGroup>
                  </div>

                  <div className={classes.fields}>
                    <small className={classes.radioHeadings}>
                      Thaumatin (E957)
                    </small>
                    <RadioGroup
                      row
                      aria-label="position"
                      name="thaumatin"
                      value={mandatoryDeclaration.thaumatin ? "Yes" : "No"}
                      onChange={onChangeHandler}
                    >
                      <FormControlLabel
                        value="Yes"
                        control={<Radio color="primary" />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="No"
                        control={<Radio color="primary" />}
                        label="No"
                      />
                    </RadioGroup>
                  </div>

                  <div className={classes.fields}>
                    <small className={classes.radioHeadings}>
                      Aspartame (E951)
                    </small>
                    <RadioGroup
                      row
                      aria-label="position"
                      name="aspartame"
                      value={mandatoryDeclaration.aspartame ? "Yes" : "No"}
                      onChange={onChangeHandler}
                    >
                      <FormControlLabel
                        value="Yes"
                        control={<Radio color="primary" />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="No"
                        control={<Radio color="primary" />}
                        label="No"
                      />
                    </RadioGroup>
                  </div>
                </div>
              </Grid>
              <Grid item xs={3}>
                <div className={classes.container}>
                  <div className={classes.fields}>
                    <small className={classes.radioHeadings}>
                      Sucralose (E955)
                    </small>
                    <RadioGroup
                      row
                      aria-label="position"
                      name="sucralose"
                      value={mandatoryDeclaration.sucralose ? "Yes" : "No"}
                      onChange={onChangeHandler}
                    >
                      <FormControlLabel
                        value="Yes"
                        control={<Radio color="primary" />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="No"
                        control={<Radio color="primary" />}
                        label="No"
                      />
                    </RadioGroup>
                  </div>

                  <div className={classes.fields}>
                    <small className={classes.radioHeadings}>
                      Neotame (961)
                    </small>
                    <RadioGroup
                      row
                      aria-label="position"
                      name="neotame"
                      value={mandatoryDeclaration.neotame ? "Yes" : "No"}
                      onChange={onChangeHandler}
                    >
                      <FormControlLabel
                        value="Yes"
                        control={<Radio color="primary" />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="No"
                        control={<Radio color="primary" />}
                        label="No"
                      />
                    </RadioGroup>
                  </div>

                  <div className={classes.fields}>
                    <small className={classes.radioHeadings}>
                      Acesulfame potassium (E950)
                    </small>
                    <RadioGroup
                      row
                      aria-label="position"
                      name="acesulfame_potassium"
                      value={
                        mandatoryDeclaration.acesulfame_potassium ? "Yes" : "No"
                      }
                      onChange={onChangeHandler}
                    >
                      <FormControlLabel
                        value="Yes"
                        control={<Radio color="primary" />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="No"
                        control={<Radio color="primary" />}
                        label="No"
                      />
                    </RadioGroup>
                  </div>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className={classes.container}>
                  <div className={classes.fields}>
                    <small className={classes.radioHeadings}>
                      Aspartame-Acesulfame potassium salt
                    </small>
                    <RadioGroup
                      row
                      aria-label="position"
                      name="aspartame_acesulfame_potassium_salt"
                      value={
                        mandatoryDeclaration.aspartame_acesulfame_potassium_salt
                          ? "Yes"
                          : "No"
                      }
                      onChange={onChangeHandler}
                    >
                      <FormControlLabel
                        value="Yes"
                        control={<Radio color="primary" />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="No"
                        control={<Radio color="primary" />}
                        label="No"
                      />
                    </RadioGroup>
                  </div>

                  <div className={classes.fields}>
                    <small className={classes.radioHeadings}>
                      Saccharins (E954)
                    </small>
                    <RadioGroup
                      row
                      aria-label="position"
                      name="saccharins"
                      value={mandatoryDeclaration.saccharins ? "Yes" : "No"}
                      onChange={onChangeHandler}
                    >
                      <FormControlLabel
                        value="Yes"
                        control={<Radio color="primary" />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="No"
                        control={<Radio color="primary" />}
                        label="No"
                      />
                    </RadioGroup>
                  </div>

                  <div className={classes.fields}>
                    <small className={classes.radioHeadings}>
                      Erythritol (E968)
                    </small>
                    <RadioGroup
                      row
                      aria-label="position"
                      name="erythritol"
                      value={mandatoryDeclaration.erythritol ? "Yes" : "No"}
                      onChange={onChangeHandler}
                    >
                      <FormControlLabel
                        value="Yes"
                        control={<Radio color="primary" />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="No"
                        control={<Radio color="primary" />}
                        label="No"
                      />
                    </RadioGroup>
                  </div>
                </div>
              </Grid>
            </Grid>
          ) : (
            ""
          )}
        </Grid>
      </FormControl>
    </div>
  )
}
export default MandatoryDeclarations
