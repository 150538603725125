export const FONT_SIZES = {
  xs: 14,
  sm: 15,
  md: 16,
  lg: 18,
  xl: 20,
  xxl: 24,
  xxxl: 28,
  xxxxl: 35,
  xxxxxl: 50,
}
