import { BusinessType } from "../constants/enum"
import { BASE_ROUTES } from "../constants/routes"

export const getBusinessRoute = (routes, businessType) => {
  const isManufacturerBusiness = businessType === "Manufacturer"

  const resolveBaseRoute = (route) =>
    isManufacturerBusiness
      ? `${BASE_ROUTES.PPF}${route}`
      : `${BASE_ROUTES.MENU_LABEL}${route}`

  return Array.isArray(routes)
    ? routes.map((route) => resolveBaseRoute(route))
    : resolveBaseRoute(routes)
}
