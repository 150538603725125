import { FONT_SIZES } from "../../constants/FONTS"
import { COLORS } from "../../Utils/colorPalette"

const dashboardStyles = (theme) => {
  return {
    divPadding: {
      padding: 20,
    },
    clearFiltersPadding: {
      display: "flex",
      alignItems: "end",
      padding: 20,
      [theme.breakpoints.down("md")]: {
        padding: 0,
        paddingTop: 20,
      },
    },
    tablePadding: {
      border: `1px solid ${COLORS.ascent.tableBorder}`,
      background: "white",
    },
    topCardBorder: {
      borderRadius: 2,
    },
    cardContentStyles: {
      display: "flex",
      "&.MuiPaper-root": {
        boxShadow: "none",
      },
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      padding: 20,
      boxShadow: "none",
      height: 120,
      "&.MuiCardContent-root": {
        paddingBottom: 20,
      },
    },
    typographyStyles: { fontSize: FONT_SIZES.xl, justifyContent: "center" },
    subTextStyle: {
      fontSize: FONT_SIZES.md,
      paddingLeft: 5,
      color: COLORS.grey.n4,
    },
    labelWrapper: { display: "flex", alignItems: "baseline" },
    chipOneStyles: {
      color: theme.palette.success.dark,
      background: COLORS.green.lighter,
    },
    chipTwoStyles: {
      color: theme.palette.error.dark,
      background: COLORS.red.lighter,
    },
    chipThreeStyles: {
      color: COLORS.gold.pendingChip,
      background: COLORS.yellow.lighter,
    },
    firstCardContentStyles: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      height: 200,
    },
    mainDivStyles: {
      background: "white",
      margin: 0,
      padding: 0,
    },
    autoCompleteStyles: {
      marginTop: 20,
      marginRight: "5%",
      [theme.breakpoints.down("md")]: {
        marginRight: 0,
      },
    },
    datePickerStyles: {
      width: "95%",
      marginTop: 20,
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
    },
    labelNameStyles: {
      textDecoration: "underline",
      "&:hover": {
        color: COLORS.red.dark,
      },
    },
    createdAtWidth: { width: "25%" },
    clearFilterButtonStyles: {
      border: `1px solid ${COLORS.red.light}`,
      color: COLORS.ascent.asterick,
      textTransform: "none",
      fontSize: FONT_SIZES.xs,
    },
    finalGrid: {
      marginTop: 20,
      marginBottom: 0,
    },
    tableWidthStyles: { minWidth: 400 },
    viewAllStyles: {
      display: "flex",
      justifyContent: "center",
      marginTop: 20,
      paddingBottom: 20,
    },
    viewAllButtonStyles: {
      background: COLORS.gold.dark,
      width: 100,
    },
    totalLabelCount: {
      fontSize: FONT_SIZES.xxxxl,
      [theme.breakpoints.down("md")]: {
        fontSize: FONT_SIZES.xxxl,
      },
    },
    completeLabelCount: {
      color: COLORS.green.dark,
      fontSize: FONT_SIZES.xxxxl,
      [theme.breakpoints.down("md")]: {
        fontSize: FONT_SIZES.xxxl,
      },
    },
    incompleteLabelCount: {
      color: COLORS.gold.chip,
      fontSize: FONT_SIZES.xxxxl,
      [theme.breakpoints.down("md")]: {
        fontSize: FONT_SIZES.xxxl,
      },
    },
    renewalLabelCount: {
      color: COLORS.red.dark,
      fontSize: FONT_SIZES.xxxxl,
      [theme.breakpoints.down("md")]: {
        fontSize: FONT_SIZES.xxxl,
      },
    },
    navbarMobileLinkStyles: {
      fontFamily: "Bahnscrift",
      fontSize: FONT_SIZES.sm,
      textTransform: "capitalize",
      fontWeight: 600,
      color: COLORS.black.dark,
    },
    accountSummaryStyles: { marginBottom: 15, fontSize: FONT_SIZES.xxxl },
    mainTableStyles: {
      backgroundColor: "white",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginLeft: 0,
      marginRight: 0,
      [theme.breakpoints.down("md")]: {
        marginLeft: 50,
        marginRight: 50,
      },
    },
    createLabelButton: {
      marginTop: 20,
    },
    rolesDetails: {
      backgroundColor: COLORS.bg.white,
      padding: 15,
      margin: "10px 0px",
      textAlign: "left",
      "& h6": {
        marginBottom: 5,
        "& div": {
          marginLeft: 5,
        },
      },
    },
  }
}

export { dashboardStyles }
