import {
  GET_STEPS_REQUEST,
  GET_STEPS_SUCCESS,
  GET_STEPS_FAILURE,
} from "./stepsTypes"
import apis from "../../services/ApiServices"

export const getStepsRequest = () => {
  return {
    type: GET_STEPS_REQUEST,
  }
}

export const getStepsSuccess = (steps) => {
  return {
    type: GET_STEPS_SUCCESS,
    payload: steps,
  }
}

export const getStepsFailure = () => {
  return {
    type: GET_STEPS_FAILURE,
  }
}

export const getAllSteps = () => {
  return async (dispatch) => {
    dispatch(getStepsRequest())
    let response = await apis.getAllContent()
    if ("success" in response) {
      let items = response.result.items
      let steps = items.filter((item) => item.type === "STEP")

      console.log("Retrieved steps from database: ", steps)
      dispatch(getStepsSuccess(steps))
    } else {
      dispatch(getStepsFailure())
    }
  }
}
