import * as React from "react"
import Box from "@mui/material/Box"
import Tooltip from "@mui/material/Tooltip"
import { Country } from "../../constants/enum"

export default function AnchorElTooltips({
  component: Component,
  country,
  disable_text,
}) {
  const positionRef = React.useRef({
    x: 0,
    y: 0,
  })
  const popperRef = React.useRef(null)
  const areaRef = React.useRef(null)

  const handleMouseMove = (event) => {
    positionRef.current = { x: event.clientX, y: event.clientY }

    if (popperRef.current != null) {
      popperRef.current?.update()
    }
  }

  let disabledMsg="";
  if(disable_text){
    disabledMsg=disable_text;
  }else if( Country[country]){
    disabledMsg=`Not applicable for ${Country[country]}`
  }
  return (
    <Tooltip
      title={
        // Country[country]
        //   ? `Not applicable for ${Country[country]}`
        //   : disable_text
        disable_text
          ? disable_text
          : Country[country] && `Not applicable for ${Country[country]}`
      }
      placement="top"
      arrow
      className="w-full"
      PopperProps={{
        popperRef,
        anchorEl: {
          getBoundingClientRect: () => {
            return new DOMRect(
              positionRef.current.x,
              areaRef.current.getBoundingClientRect().y,
              0,
              0,
            )
          },
        },
      }}
    >
      <div
        ref={areaRef}
        onMouseMove={handleMouseMove}
        style={{ display: "inline-block" }}
      >
        {Component}
      </div>
    </Tooltip>
  )
}
