import React, { useEffect } from "react"
import { Popover } from "@headlessui/react"
import { classNames } from "../Utils/helpers"
import { fetchPlanDetails } from "../services/ApiServices"

interface IProps {
  plan_id: string
}

const terms: { [key: string]: number } = {
  "6 Months": 6,
  "12 Months": 12,
}

export default function EstimationModal({ plan_id }: IProps) {
  const [selectedTerm, setSelectedTerm] = React.useState(0)
  const [planData, setPlanData] = React.useState<any>(null)
  const [calculatedData, setCalculatedData] = React.useState<any>(null)

  useEffect(() => {
    fetchPlanDetails(plan_id).then((res) => {
      setPlanData(res)
    })
  }, [])

  useEffect(() => {
    if (planData) {
      const plan_cost =
        planData?.amount * terms[Object.keys(terms)[selectedTerm]]
      const gst = (plan_cost + planData?.set_up_cost) * 0.18
      const total_cost = plan_cost + gst + planData?.set_up_cost
      setCalculatedData({
        plan_cost,
        total_cost,
        gst,
      })
    }
  }, [planData, selectedTerm])

  return (
    <Popover className="relative">
      <Popover.Button className="border-none outline-none text-blue-700 font-bold">
        See Estimate
      </Popover.Button>

      <Popover.Panel className="absolute rounded-lg z-40 bg-white w-96 transform -translate-x-[10rem]">
        <div className="p-4 rounded-lg shadow-lg w-full bg-white">
          <div className="flex justify-center items-center w-full">
            <div className="flex rounded-full border">
              {Object.keys(terms).map((term, index) => (
                <div
                  className={classNames(
                    "rounded-full px-4 py-2 whitespace-nowrap",
                    selectedTerm === index
                      ? "bg-yellow-400 text-white font-semibold"
                      : "text-black",
                  )}
                  onClick={() => setSelectedTerm(index)}
                >
                  {term}
                </div>
              ))}
            </div>
          </div>
          <h1 className="font-bold text-xl mb-4">Estimate</h1>
          <div className="grid grid-cols-2 gap-0 w-full">
            <p className="text-gray-500 text-sm">
              {planData?.name} (₹{planData?.amount} x{" "}
              {Object.keys(terms)[selectedTerm]})
            </p>
            <p className="text-gray-500 font-bold text-right">
              ₹ {calculatedData?.plan_cost}
            </p>
            <p className="text-gray-500 text-sm">Annual Set up Cost</p>
            <p className="text-gray-500 font-bold text-right">
              ₹ {planData?.set_up_cost}
            </p>
            <p className="text-gray-500 text-sm">GST (18%)</p>
            <p className="text-gray-500 font-bold text-right">
              ₹ {calculatedData?.gst}
            </p>
            <p className="text-gray-500 text-sm font-bold mt-2">Total</p>
            <p className="text-gray-500 font-bold text-right mt-2">
              ₹ {calculatedData?.total_cost}
            </p>
          </div>
          <hr className="my-2" />
          <div>
            <p>Terms</p>
            <ol className="list-decimal list-inside">
              <li className="text-gray-500 text-sm">
                Check all details of order on cart page
              </li>
            </ol>
          </div>
        </div>
      </Popover.Panel>
    </Popover>
  )
}
