/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: "ap-south-1",
  aws_cognito_identity_pool_id:
    "ap-south-1:edda7d99-1f7a-448d-9f8a-a6266e301870",
  aws_cognito_region: "ap-south-1",
  aws_user_pools_id: "ap-south-1_9dVc0JvXq",
  aws_user_pools_web_client_id: "1u4v99j0rn1l4ejj5tlqdo008o",
  oauth: {},
  aws_cognito_username_attributes: ["EMAIL", "PHONE_NUMBER"],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: ["NAME"],
  aws_cognito_mfa_configuration: "OPTIONAL",
  aws_cognito_mfa_types: ["SMS", "TOTP"],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [
      "REQUIRES_LOWERCASE",
      "REQUIRES_UPPERCASE",
      "REQUIRES_NUMBERS",
      "REQUIRES_SYMBOLS",
    ],
  },
  aws_cognito_verification_mechanisms: ["EMAIL"],
  aws_cloud_logic_custom: [
    {
      name: "AdminQueries",
      endpoint: "https://2evkbm6vzi.execute-api.ap-south-1.amazonaws.com/prod",
      region: "ap-south-1",
    },
    {
      name: "UserManagement",
      endpoint: "https://cd5uw22m8e.execute-api.ap-south-1.amazonaws.com/prod",
      region: "ap-south-1",
    },
    {
      name: "BusinessManagement",
      endpoint: "https://de56h6gqr3.execute-api.ap-south-1.amazonaws.com/prod",
      region: "ap-south-1",
    },
    {
      name: "FoodManagement",
      endpoint: "https://cjxu29mpti.execute-api.ap-south-1.amazonaws.com/prod",
      region: "ap-south-1",
    },
    {
      name: "ItemsManagement",
      endpoint: "https://pir30zoag2.execute-api.ap-south-1.amazonaws.com/prod",
      region: "ap-south-1",
    },
    {
      name: "ContentManagement",
      endpoint: "https://8oh84o1qqi.execute-api.ap-south-1.amazonaws.com/prod",
      region: "ap-south-1",
    },
    {
      name: "NotificationManagement",
      endpoint: "https://jfppvblic8.execute-api.ap-south-1.amazonaws.com/prod",
      region: "ap-south-1",
    },
    {
      name: "AnalyzerApi",
      endpoint: "https://3kjrihedq4.execute-api.ap-south-1.amazonaws.com/prod",
      region: "ap-south-1",
    },
    {
      name: "MedianCrudApi",
      endpoint: "https://zy9e571w67.execute-api.ap-south-1.amazonaws.com/prod",
      region: "ap-south-1",
    },
    {
      name: "AnalyzerUserApi",
      endpoint: "https://todurhyrz9.execute-api.ap-south-1.amazonaws.com/prod",
      region: "ap-south-1",
    },
    {
      name: "PnlManagement",
      endpoint: "https://ug9iv2ugck.execute-api.ap-south-1.amazonaws.com/prod",
      region: "ap-south-1",
    },
    {
      name: "AmplitudeManagement",
      endpoint: "https://oeyw3d9uqj.execute-api.ap-south-1.amazonaws.com/prod",
      region: "ap-south-1",
    },
  ],
  aws_appsync_graphqlEndpoint:
    "https://mgifgghzqrdwnkfes5zdiwgalu.appsync-api.ap-south-1.amazonaws.com/graphql",
  aws_appsync_region: "ap-south-1",
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
  aws_dynamodb_all_tables_region: "ap-south-1",
  aws_dynamodb_table_schemas: [
    {
      tableName: "Users-prod",
      region: "ap-south-1",
    },
    {
      tableName: "Businesses-prod",
      region: "ap-south-1",
    },
    {
      tableName: "UserBusinessMapping-prod",
      region: "ap-south-1",
    },
    {
      tableName: "Recipes-prod",
      region: "ap-south-1",
    },
    {
      tableName: "Items-prod",
      region: "ap-south-1",
    },
    {
      tableName: "Content-prod",
      region: "ap-south-1",
    },
    {
      tableName: "Notifications-prod",
      region: "ap-south-1",
    },
    {
      tableName: "MedianValues-prod",
      region: "ap-south-1",
    },
    {
      tableName: "AnalyzerUser-prod",
      region: "ap-south-1",
    },
  ],
  aws_user_files_s3_bucket: "folsolbucket232929-prod",
  aws_user_files_s3_bucket_region: "ap-south-1",
}

export default awsmobile
