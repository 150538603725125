import {
  GET_BLOGS_REQUEST,
  GET_BLOGS_SUCCESS,
  GET_BLOGS_FAILURE,
} from "./blogTypes"

const initialState = {
  loading: false,
  error: false,
  error_message: "",
  blogs: [],
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_BLOGS_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case GET_BLOGS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        error_message: false,
        blogs: action.payload,
      }
    case GET_BLOGS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        error_message: "error retrieving blogs",
      }
    default:
      return state
  }
}

export default reducer
