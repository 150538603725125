import { BUSINESS_MODEL } from "../Utils/constants"
import { uuidv4 } from "../Utils/helpers"
import { exportsCountries } from "../constants/exportsCountries"
import {
  ppfUniversalAccessFeatures,
  universalAccessFeatures,
} from "../constants/features"
import { getStripeCustomer } from "../services/ApiServices"
import { checkIsSubscriptionFreeTrial } from "../services/ApiServicesv2"
import { format, isToday, isYesterday, subDays } from "date-fns"

const helper = {
  isLogin: function () {
    const user = localStorage.getItem("currentUser")
    if (user) {
      return true
    }
    return false
  },

  getToken: function () {
    const user = localStorage.getItem("currentUser")
    if (!user || user === null || user === undefined) {
      return false
    } else {
      const decoded = JSON.parse(user)
      if (!decoded.token) {
        return false
      } else {
        return decoded.token
      }
    }
  },
  getUser: function () {
    const user = localStorage.getItem("currentUser")
    if (!user || user === null || user === undefined) {
      return false
    } else {
      const decoded = JSON.parse(user)
      if (!decoded.token) {
        return false
      } else {
        return decoded.userId
      }
    }
  },
  getBusiness: function () {
    const user = localStorage.getItem("currentUser")
    if (!user || user === null || user === undefined) {
      return false
    } else {
      const decoded = JSON.parse(user)
      if (!decoded.business) {
        return false
      } else {
        return decoded.business
      }
    }
  },
  switchBusiness: function (business) {
    const user = localStorage.getItem("currentUser")
    if (!user || user === null || user === undefined) {
      return false
    } else {
      const decoded = JSON.parse(user)
      const data = JSON.stringify({
        token: decoded.token,
        userId: decoded.userId,
        business: business,
        expiry: decoded.expiry,
      })
      localStorage.setItem("currentUser", data)
    }
  },
}

export function isNewUser() {
  const loginData = JSON.parse(
    JSON.parse(localStorage.getItem("persist:root")).login,
  )
  if (loginData.allBusinesses?.length > 0) {
    const signUpDate = new Date(loginData.allBusinesses[0].createdAt)
    if (signUpDate < new Date("2023-01-12")) return false
  }

  return true
}

export function formatDate(timestampInSeconds) {
  const date = new Date(timestampInSeconds * 1000)

  const utc_date = Date.UTC(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
  )
  const now = Date.now()

  const formatted_date = new Date(utc_date)
  const current_date = new Date(now)

  const days_ago = Math.round(
    (current_date - formatted_date) / (1000 * 60 * 60 * 24),
  )
  let prefix

  if (
    days_ago === 0 &&
    formatted_date.getUTCDate() === current_date.getUTCDate()
  ) {
    prefix = "Today"
  } else if (
    days_ago === 1 ||
    (formatted_date.getUTCDate() === current_date.getUTCDate() - 1 &&
      days_ago < 2)
  ) {
    prefix = "Yesterday"
  } else if (days_ago < 7) {
    prefix = `${days_ago} days ago`
  } else {
    prefix = formatted_date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
      timeZone: "UTC",
    })
  }

  const time_string = formatted_date.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
    timeZone: "UTC",
  })

  return `${prefix} at ${time_string}`
}

const isObjectEmpty = (objectName) => {
  return Object.keys(objectName).length === 0
}

export async function hasActivePlan(stripe_data = null) {
  const newUser = isNewUser()

  if (!newUser) return true

  const customer = stripe_data ?? (await getStripeCustomer())

  const check_is_subscription_free_trail = await checkIsSubscriptionFreeTrial()

  if (check_is_subscription_free_trail.status === "success") {
    return true
  } else {
    // Check if object is empty
    if (!customer) return true

    // Check if user free trial is expired
    if (customer?.plan_details?.type === "free_trial_expired") return false

    return !!customer?.plan_details?.name
  }
}

export async function hasActivePPfPlan() {
  const customer = await getStripeCustomer()
  return (
    !!customer?.plan_details?.name &&
    customer?.plan_details?.business_type &&
    customer?.plan_details?.business_type === BUSINESS_MODEL.ppf
  )
}

export const extractInitialForUserProfile = (username) => {
  const parts = username.split(" ")
  const initials =
    parts.length > 1
      ? parts[0].charAt(0) + parts[1].charAt(0)
      : parts[0].charAt(0)
  return initials.toUpperCase()
}

export const userProfileColorScheme = (str) => {
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }

  let color = "#"
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff
    color += ("00" + value.toString(16)).substr(-2)
  }
  return color
}

export async function getCurrentPPFPlanFeatures() {
  const customer = await getStripeCustomer()
  let features = { ...ppfUniversalAccessFeatures }

  // Commenting this feature code because there some issue with features. which is preventing
  // some users from from using functionality

  // if (
  //   !!customer?.plan_details?.name &&
  //   customer?.plan_details?.business_type &&
  //   customer?.plan_details?.business_type === BUSINESS_MODEL.ppf &&
  //   customer?.plan_details?.features
  // ) {
  //   console.log({ customer })
  //   debugger
  //   features = JSON.parse(customer?.plan_details?.features)
  // }
  return features
}

export async function getCurrentPlanFeatures() {
  const newUser = isNewUser()

  const customer = await getStripeCustomer()
  let features = JSON.parse(customer?.plan_details?.features ?? "{}")
  const maxRecipe = customer?.max_recipe

  if (!newUser)
    return {
      ...universalAccessFeatures,
      MAX_RECIPES_LIMIT:
        maxRecipe > 0 ? maxRecipe : universalAccessFeatures.MAX_RECIPES_LIMIT,
    }
  else
    features = {
      ...features,
      MAX_RECIPES_LIMIT:
        maxRecipe > 0 ? maxRecipe : customer?.plan_details?.max_limit,
    }

  return features
}

export async function containsFeature(feature) {
  const features = await getCurrentPlanFeatures()
  if (features[feature]) return features[feature]

  return false
}

/**
 * This function will return the plan name of the current business coming from stripe data api call
 * @param {*} stripeData
 */
export function getPlanName(stripeData) {
  let type = "Free Plan"
  if (!stripeData.plan_details || !stripeData.plan_details?.name) {
    type = ""
  }
  if (stripeData.plan_details?.name) {
    type = stripeData.plan_details?.name
  }
  if (stripeData.plan_details?.type === "free_trial") {
    type = "Free Trial"
  }
  if (stripeData.plan_details?.type === "free_trial_expired") {
    type = "No Active Plan"
  }

  return type
}

export function convertTimestampToDate(timestamp) {
  const date = new Date(timestamp * 1000)
  const day = date.getUTCDate().toString().padStart(2, "0")
  const month = (date.getUTCMonth() + 1).toString().padStart(2, "0")
  const year = date.getUTCFullYear().toString().slice(-2)

  const hours = date.getUTCHours()
  const minutes = date.getUTCMinutes().toString().padStart(2, "0")

  const ampm = hours >= 12 ? "PM" : "AM"
  const formattedHour = ((hours + 11) % 12) + 1

  return `${day}/${month}/${year}, ${formattedHour}:${minutes}${ampm}`
}

export function getCountryBySymbol(symbol) {
  if (!["AUS-NZ", "IND", "UK", "USA", "CA", "GCC"].includes(symbol)) {
    symbol = "MULTI"
  }
  return exportsCountries.find((country) => country.symbol === symbol)
}

export function formatDateToExportLabelFormat(dateString) {
  const [year, month, day] = dateString.split("-")
  const date = new Date(`${year}-${month}-${day}`)

  const monthAbbreviations = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ]

  const formattedDay = date.getDate().toString().padStart(2, "0")
  const formattedMonth = monthAbbreviations[date.getMonth()]
  const formattedYear = date.getFullYear()

  return `${formattedDay} ${formattedMonth} ${formattedYear}`
}

export function formatDateCanada(dateString) {
  const monthAbbreviations = [
    "JN",
    "FB",
    "MR",
    "AL",
    "MY",
    "JN",
    "JL",
    "AU",
    "SP",
    "OC",
    "NV",
    "DC",
  ]

  const dateParts = dateString.split("-")
  const year = dateParts[0].slice(2) // Last two digits of the year
  const month = parseInt(dateParts[1], 10) // Convert month part to an integer
  const day = dateParts[2]

  if (month < 1 || month > 12) {
    return "Invalid month"
  }

  return `${day} ${monthAbbreviations[month - 1]} ${year}`
}

export const translatedLanguageByCountry = (country) => {
  if (country === "CA") {
    return "fr"
  } else if (country === "GCC") {
    return "ar"
  }
  return "en"
}

// export function debounce(func, delay) {
//   let timeoutId;

//   return function (...args) {
//     const context = this;

//     // Clear the existing timer
//     if (timeoutId) clearTimeout(timeoutId);

//     // Set a new timer
//     timeoutId = setTimeout(() => {
//       func.apply(context, args);
//     }, delay);
//   };
// }

export function formatDateToExportLabelFormatUSA(date) {
  const [year, month, day] = date.split("-")
  const formattedMonth = month.padStart(2, "0")
  const formattedDay = day.padStart(2, "0")
  const formattedYear = year

  return `${formattedMonth}-${formattedDay}-${formattedYear}`
}

export function formatDateToExportLabelFormatAUSNZ(dateString) {
  const [year, month, day] = dateString.split("-")
  const date = new Date(`${year}-${month}-${day}`)

  const formattedDay = date.getDate().toString().padStart(2, "0")
  const formattedMonth = (date.getMonth() + 1).toString().padStart(2, "0")
  const formattedYear = date.getFullYear().toString().slice(-2)

  return `${formattedDay}/${formattedMonth}/${formattedYear}`
}

export function formatDateToUSandUKFormat(dateString) {
  const [year, month, day] = dateString.split("-")

  const formattedDate = `${month}/${day}/${year}`

  return formattedDate
}

export function normalizeNumericValues(numeric_value) {
  let number = parseFloat(numeric_value)
  if (!Number.isFinite(number) || isNaN(number) || number === undefined) {
    return 0
  }
  return number
}

export function normalizeNumericValuesString(numeric_value) {
  let number = parseFloat(numeric_value)
  if (!Number.isFinite(number) || isNaN(number) || number === undefined) {
    return ""
  }
  return number
}
export function addStyleToTextEditorOutput(htmlString, tailwindClass) {
  const parser = new DOMParser()
  const doc = parser.parseFromString(htmlString, "text/html")
  const pTag = doc.querySelector("p")

  if (pTag) {
    const spanTag = document.createElement("span")

    for (let attr of pTag.attributes) {
      spanTag.setAttribute(attr.name, attr.value)
    }
    spanTag.innerHTML = pTag.innerHTML

    spanTag.classList.add(...tailwindClass.split(" "))

    pTag.parentNode.replaceChild(spanTag, pTag)
  }

  return doc.body.innerHTML
}

export function combineAllergens(prev_val, list) {
  let prev_list = [...prev_val]

  list.forEach((item) => {
    let nested_allergens = []

    if (item?.additional) {
      nested_allergens = item.additional.map((subItem) => {
        if (prev_list?.includes(subItem.value)) {
          return subItem.value
        }
      })
    }

    nested_allergens = nested_allergens.filter((item) => item !== undefined)

    prev_list = prev_list?.filter((item) => !nested_allergens.includes(item))

    const formatted_allergen = `${item.value} (${nested_allergens.join(", ")})`
    if (nested_allergens?.length > 0) {
      if (prev_list.includes(item.value)) {
        const index = prev_list.indexOf(item.value)
        if (index > -1) {
          prev_list.splice(index, 1)
        }
      }
      prev_list.push(formatted_allergen)
    }
  })
  return prev_list?.join(", ")
}

export function capitalize(str) {
  return str.replace(/\b\w/g, (char, index) => {
    if (index > 0 && str[index - 1] === "'") {
      return char.toLowerCase()
    }
    return char.toUpperCase()
  })
}

export const hasValidAllergens = (allergens_array) => {
  if (!Array.isArray(allergens_array)) return false

  const filtered_allergens = allergens_array.filter(
    (allergen) =>
      allergen !== null && allergen !== "" && typeof allergen === "string",
  )

  return filtered_allergens.length > 0
}

export function generateSugarObject(sugar_list) {
  if (!sugar_list || sugar_list.length === 0) return null

  let sugar_proportion = sugar_list?.reduce(
    (sum, item) => sum + parseFloat(item.proportion || 0),
    0,
  )

  sugar_proportion = parseFloat(sugar_proportion.toFixed(1))

  if (!sugar_proportion) {
    sugar_proportion = 0
  }

  let combined_name =
    sugar_list.length === 1
      ? sugar_list[0]?.name
      : "Sugar (" + sugar_list.map((item) => item.name).join(", ") + ")"

  const sugarObject = {
    info: {
      compound: "No",
      show_ingredient_proportion: "Yes",
      ingredient_name: combined_name,
      name: combined_name,
      proportion: sugar_proportion,
      id: "the_random_sugar_Id_48387948hfduhfkhahdfkjakghruyeiu",
      show_ingredient_proportions: "No",
      is_sugar: true,
      is_only_sugar: true,
    },
    name: combined_name,
  }

  return sugarObject
}

export function processCanadaAdditives(additives) {
  if (!additives || !Array.isArray(additives)) return

  return additives.map((additive) => {
    if (additive.ins_number === undefined || additive.ins_number === null) {
      delete additive.additive_value_show
    }

    return additive
  })
}

export function filterCanadaAllergens(allergens) {
  if (!allergens || !Array.isArray(allergens)) return []

  const excluded_allergens = [
    "Nuts",
    "Cereals containing gluten substance",
    "Crustacean",
    "Fish",
    "Molluscs",
  ]

  const filtered_allergens = allergens?.filter(
    (alg) => !excluded_allergens?.includes(alg),
  )

  return filtered_allergens
}

export const getCountryFlag = (country_code) => {
  const country = exportsCountries.find((item) => item.symbol === country_code)
  return country ? country.flag : null
}

export function updateAllergensArray(array) {
  if (!Array.isArray(array)) {
    return array
  }

  return array.map((item) => {
    if (item === "oat" || item === "Oat") {
      return `${item}s`
    } else if (item === "nut") {
      return "nuts"
    }
    return item
  })
}

export function capitalizeFirstWord(inputString) {
  try {
    if (typeof inputString !== "string") return inputString
    const words = inputString.split(" ")
    if (words.length > 0) {
      words[0] = words[0].charAt(0).toUpperCase() + words[0].slice(1)
      return words.join(" ")
    }
    return inputString
  } catch (error) {
    return inputString
  }
}

export default helper
