import React from "react"

export default function EarthIcon({ className }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 10C19 14.9706 14.9706 19 10 19M19 10C19 5.02944 14.9706 1 10 1M19 10C19 8.067 14.9706 6.5 10 6.5C5.02944 6.5 1 8.067 1 10M19 10C19 11.933 14.9706 13.5 10 13.5C5.02944 13.5 1 11.933 1 10M10 19C5.02944 19 1 14.9706 1 10M10 19C12.2091 19 14 14.9706 14 10C14 5.02944 12.2091 1 10 1M10 19C7.79086 19 6 14.9706 6 10C6 5.02944 7.79086 1 10 1M1 10C1 5.02944 5.02944 1 10 1"
        stroke="#94a3b8"
        stroke-width="1.5"
      />
    </svg>
  )
}
