export const getFileNameUsingCurrentTime = () => {
  const date = new Date()

  const day = date.getDate().toString().padStart(2, "0")

  const month = (date.getMonth() + 1).toString().padStart(2, "0")

  const year = date.getFullYear()

  let hours = date.getHours()

  const minutes = date.getMinutes().toString().padStart(2, "0")

  const seconds = date.getSeconds().toString().padStart(2, "0")

  const ampm = hours >= 12 ? "PM" : "AM"

  hours = hours % 12

  hours = hours ? hours : 12

  hours = hours.toString().padStart(2, "0")

  const timestamp = `${day}${month}${year}_${hours}${minutes}${seconds}`

  return timestamp
}
