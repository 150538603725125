import React from "react"
import { useHistory } from "react-router-dom"
import PlusCircle from "../common/Icons/PlusCircle"

type QkButtonProps = {
  data: {
    key: string
    link: string
    disabled: boolean
  }
}

export default function QkButton({ data }: QkButtonProps) {
  const history = useHistory()
  const redirectToLink = () => {
    if (data.disabled) return
    history.push(data.link)
  }
  return (
    <button
      className={`flex py-3 px-1  items-center font-bold ${
        data.disabled ? "opacity-50 cursor-not-allowed" : ""
      }`}
      onClick={() => redirectToLink()}
    >
      {" "}
      <PlusCircle className="h-4 w-4 text-primary" />
      <span className="text-primary ml-1 text-base">{data.key}</span>
    </button>
  )
}
