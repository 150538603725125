import { Disclosure } from "@headlessui/react"
import React, { useState, useEffect } from "react"
import ChevronRight from "../../common/Icons/ChevronRight"
import PublicLayout from "../../common/layouts/PublicLayout"
import { classNames } from "../../Utils/helpers"
import {
  defaultBusinessPlanDetails,
  defaultEnterprisePlanDetails,
  defaultStarterPlanDetails,
  planDuration,
  plansJson,
} from "./plansInfo"
import { featuresMapping } from "../../constants/features"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import { fetchAllPlans } from "../../services/ApiServices"
import PricingSummary from "./pricingSummary"
import { sendAnalyticsEvent } from "../../Utils/analytics"
import { PRICING_PAGE_VISIT } from "../../Utils/analyticsEvents"
import { useSelector } from "react-redux"

const disclaimers = [
  "1. GST Extra",
  "2. Price does not include label designing, printing and lab testing costs",
  "3. Plans to be renewed after expiry of subscription period",
]

export default function MobilePaymentPage() {
  const [pricingSectionDetails, setPricingSectionDetails] = useState([])
  const [plansApiData, setPlansApiData] = useState([])
  const authenticated = useSelector((state) => state.login.authenticated)

  const getAllPlans = async () => {
    const response = await fetchAllPlans()
    setPlansApiData(response)
    const starterPlanDetails = response.find(
      (plan) =>
        plan?.name === "Starter Plan" &&
        plan?.business_type === "Menu Labeling",
    )
    const pricingPlan = {
      starterPlan: { ...defaultStarterPlanDetails(authenticated) },
      businessPlan: { ...defaultBusinessPlanDetails(authenticated) },
      enterprisePlan: { ...defaultEnterprisePlanDetails(authenticated) },
    }
    pricingPlan.starterPlan.name = starterPlanDetails?.name
    pricingPlan.starterPlan.recipes = `${starterPlanDetails?.max_limit} Recipes`
    pricingPlan.starterPlan.features = JSON.parse(starterPlanDetails?.features)
    pricingPlan.starterPlan.pricing = `${starterPlanDetails?.amount} / mth`
    pricingPlan.starterPlan.duration =
      planDuration?.[starterPlanDetails?.interval]
    pricingPlan.starterPlan.id = starterPlanDetails?._id
    pricingPlan.starterPlan.additional = `plus, Rs.${
      starterPlanDetails?.amc_data?.unit_amount / 100
    } annual Cloud Set-up`

    const businessPlanDetails = response.find(
      (plan) =>
        plan?.name === "Business Plan" &&
        plan?.business_type === "Menu Labeling",
    )
    pricingPlan.businessPlan.name = businessPlanDetails?.name
    pricingPlan.businessPlan.recipes = `${businessPlanDetails?.max_limit} Recipes`
    pricingPlan.businessPlan.features = JSON.parse(
      businessPlanDetails?.features,
    )
    pricingPlan.businessPlan.pricing = `${businessPlanDetails?.amount} / mth`
    pricingPlan.businessPlan.duration =
      planDuration?.[businessPlanDetails?.interval]
    pricingPlan.businessPlan.id = businessPlanDetails?._id
    pricingPlan.businessPlan.additional = `plus, Rs.${
      businessPlanDetails?.amc_data?.unit_amount / 100
    } annual Cloud Set-up`

    setPricingSectionDetails([
      { ...pricingPlan.starterPlan },
      { ...pricingPlan.businessPlan },
      { ...pricingPlan.enterprisePlan },
    ])
    // setLoading(false)
  }

  useEffect(() => {
    getAllPlans()

    // Send analytics event
    sendAnalyticsEvent(PRICING_PAGE_VISIT)
  }, [])

  return (
    <PublicLayout>
      <div className="px-2 pt-[100px]">
        <h1 className="font-bold text-2xl">Simple, Fair Pricing</h1>
        <div className="font-bold text-xl">Menu Labelling</div>
        <div className="h-1 w-20 rounded-full mt-3 bg-yellow-500" />
      </div>
      <div className="px-4">
        <PricingSummary plansApiData={plansApiData} />
      </div>

      <div className="px-2 pt-4">
        <h1 className="font-bold text-2xl">Compare Plans</h1>
        <div className="h-1 w-20 rounded-full mt-3 bg-yellow-500" />
      </div>
      <div className="my-4 bg-gray-300 p-2 flex text-base justify-around">
        <div className="text-green-500">Starter</div>
        <div className="text-blue-500">Business</div>
        <div className="text-orange-500">Enterprise</div>
      </div>
      {plansJson.map((planDetails, planIndex) => (
        <Disclosure as="div" className="p-2">
          {({ open }) => (
            <>
              <dt className="text-lg">
                <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-400 py-2 border-b">
                  <span className="font-bold text-gray-900">
                    {planDetails.key}
                  </span>
                  <span className="ml-6 h-7 flex items-center">
                    <ChevronRight
                      className={classNames(
                        open ? "rotate-90" : "rotate-0",
                        "h-6 w-6 transform",
                      )}
                      aria-hidden="true"
                    />
                  </span>
                </Disclosure.Button>
              </dt>
              <Disclosure.Panel as="dd" className="py-4">
                {planDetails.values.map((planSubdetails, index) => (
                  <div className="py-2">
                    <p className="text-sm">
                      {featuresMapping?.[planSubdetails?.key]}
                    </p>
                    <div className="flex justify-around mt-2">
                      {pricingSectionDetails?.map((data) =>
                        data?.features?.[planSubdetails?.key] ? (
                          <CheckCircleIcon
                            htmlColor="#70C043"
                            fontSize="small"
                          />
                        ) : (
                          <p>-</p>
                        ),
                      )}
                    </div>
                  </div>
                ))}
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      ))}

      <div className="mb-4 mt-8 px-3">
        <div className="text-2xl font-bold">Disclaimers</div>
        <div className="w-[60px] h-[5px] mt-2 mb-5 bg-[#FFD700] rounded" />
        {disclaimers.map((data) => (
          <div className="text-base text-[#555555]">{data}</div>
        ))}
      </div>
    </PublicLayout>
  )
}
