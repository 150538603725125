import classNames from "classnames"
import React from "react"

const QkDateTime = (props) => {
  const key = props.data.key
  const value = props.values[key]

  if (!props.values || props.data.visible === false) return <></>

  const today = new Date()
  const isoToday = today.toISOString().slice(0, 16)

  return (
    <div>
      <input
        name={key}
        id={key}
        type="datetime-local"
        min={props.data.allowedFutureDateOnly ? isoToday : undefined}
        value={value || ""}
        onChange={(e) => props.handleChange(e.target.value)}
        className={classNames(
          "relative z-0 shadow-sm border w-full rounded-lg leading-8 placeholder:text-textgraylight focus:ring-black focus:border-none",
        )}
        disabled={props.data.disabled}
      />
    </div>
  )
}

export default QkDateTime
