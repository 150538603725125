import {
  RECIPE_REQUEST,
  RECIPE_SUCCESS,
  RECIPE_FAILURE,
  CLEAR_RECIPE_ERRORS,
} from "./recipeTypes"

const initialState = {
  loading: false,
  error: false,
  error_message: "",
  recipe: [],
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case RECIPE_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case RECIPE_SUCCESS:
      return {
        error_message: "",
        loading: false,
        error: false,
        recipe: action.payload,
      }
    case RECIPE_FAILURE:
      return {
        error: true,
        loading: false,
        error_message: action.payload,
      }
    case CLEAR_RECIPE_ERRORS:
      return {
        ...state,
        error: false,
        loading: false,
        error_message: "",
      }
    default:
      return state
  }
}

export default reducer
