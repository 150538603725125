import React, { Component } from "react"
import { ToastContainer } from "react-toastify"
import Footer from "../../components/footer/Footer"
import Navbar from "../../components/navbar/Navbar"

export default class PublicLayout extends Component {
  render() {
    return (
      <div className="public-layout">
        <ToastContainer />
        <Navbar />
        {this.props.children}
        <Footer />
      </div>
    )
  }
}
