import { FieldType } from "../constants/enum"
import { convertToBaseUnit } from '../qkFormV2/helper'

export const mrp = (output) => ({
  name: "",
  description: "",
  fields: [
    {
      title: "MRP (Rs.)",
      type: FieldType.NUMBER,
      reference: "",
      validator: "",
      layout: 50,
      description: "",
      list: [],
      items: [],
      key: "mrp",
      required: false,
      visible: true,
      customStyle: "[&>label]:flex",
    },
  ],
})

export const usp = (output) => {
  let usp_rs_value = ""
  let manual_usp_rs = ""

  if (
    output &&
    output.usp_title &&
    output.usp_title[0]
    // &&
    // (output.usp_title[0].usp_rs.length === 0 ||
    //   typeof output.usp_title[0].usp_rs.length === "undefined")
  ) {
    if (
      output.net_quantity &&
      output &&
      output.mrp_title &&
      output.mrp_title[0] &&
      output.mrp_title[0].mrp
    ) {
      const mrp_price = output.mrp_title[0].mrp
      const usp_price = Number((mrp_price / convertToBaseUnit(output?.net_quantity, output?.net_quantity_unit)).toFixed(2))
      usp_rs_value = usp_price
    }
  } else {
    const usp_rs =
      output &&
      output.usp_title &&
      output.usp_title[0] &&
      output.usp_title[0].usp_rs

    manual_usp_rs = parseFloat(parseFloat(usp_rs).toFixed(2))
  }

  if (
    output &&
    output.usp_title &&
    output.usp_title[0] &&
    output.usp_title[0].usp_auto_calculation &&
    output.usp_title[0].usp_auto_calculation[0] === "USP auto calculation"
  ) {
    output.usp_title[0].usp_rs = usp_rs_value
    output.usp_title[0].usp_unit = output.serving_size_unit
  }

  return {
    name: "",
    description: "",
    fields: [
      {
        title: "USP (Cost per Unit-Rs.)",
        type: FieldType.NUMBER,
        reference: "",
        validator: "",
        layout: 50,
        description: "",
        list: [],
        items: [],
        key: "usp_rs",
        required: false,
        visible: true,
        customStyle: "",
        value:
          output &&
          output.usp_title &&
          output.usp_title[0] &&
          output.usp_title[0].usp_auto_calculation &&
          output.usp_title[0].usp_auto_calculation[0] === "USP auto calculation"
            ? output.usp_title[0].usp_rs
            : manual_usp_rs,
        disable:
          output &&
          output.usp_title &&
          output.usp_title[0] &&
          output.usp_title[0].usp_auto_calculation &&
          output.usp_title[0].usp_auto_calculation[0] === "USP auto calculation"
            ? true
            : false,
      },
      {
        title: "",
        type: FieldType.CHECKBOX,
        reference: "",
        validator: "",
        layout: 8,
        description: "",
        items: ["USP auto calculation"],
        key: "usp_auto_calculation",
        visible: true,
        required: false,
        disable: false,
      },
      {
        title: "USP (Unit)",
        type: FieldType.DROPDOWN,
        reference: "",
        validator: "",
        layout: 50,
        description: "",
        list: [],
        items: ["g", "L", "ml", "kg"],
        key: "usp_unit",
        visible: true,
        default: false,
        customStyle: "",
        value:
          output &&
          output.usp_title &&
          output.usp_title[0] &&
          output.usp_title[0].usp_auto_calculation &&
          output.usp_title[0].usp_auto_calculation[0] === "Usp auto calculation"
            ? output.serving_size_unit
            : "",
        disable:
          output &&
          output.usp_title &&
          output.usp_title[0] &&
          output.usp_title[0].usp_auto_calculation &&
          output.usp_title[0].usp_auto_calculation[0] === "USP auto calculation"
            ? true
            : false,
      },
    ],
  }
}

export const dateOfPkgMfg = (output) => ({
  name: "",
  description: "",
  fields: [
    {
      title: "Select Date of Pkg/Mfg",
      type: FieldType.DROPDOWN,
      reference: "",
      validator: "",
      layout: 50,
      description: "",
      list: [],
      items: ["Date of Packaging", "Date of Manufacture"],
      key: "typeOf_packaging_manufacture",
      visible: true,
      default: false,
      customStyle: "",
    },
    {
      title: "",
      type: FieldType.DATE,
      validator: "",
      layout: 50,
      description: "",
      key: "dateOf_packaging_manufacture",
      visible: true,
      required: false,
      help: "",
    },
  ],
})

export const dateOfUsebyExp = (output) => ({
  name: "",
  description: "",
  fields: [
    {
      title: "Select Use By/Expiry",
      type: FieldType.DROPDOWN,
      reference: "",
      validator: "",
      layout: 50,
      description: "",
      list: [],
      items: ["Use By", "Date of Expiry"],
      key: "typeOf_useBy_expiry",
      visible: true,
      default: false,
    },
    {
      title: "Expiry Date :",
      type: FieldType.DATE,
      validator: "",
      layout: 50,
      description: "",
      key: "dateOf_useBy_expiry",
      visible: true,
      required: false,
      help: "",
    },
  ],
})

export const batch_no = (output) => ({
  name: "",
  description: "",
  fields: [
    {
      title: "Batch No.",
      type: FieldType.TEXT,
      reference: "",
      validator: "",
      layout: 50,
      description: "",
      list: [],
      items: [],
      key: "batch_no",
      required: false,
      visible: true,
      customStyle: "",
    },
  ],
})
