import { FieldType } from "../constants/enum"
import insMapping from "./mapping/ins_mapping.json"

const additives = (schemaData, output) => ({
  name: "",
  description: "",
  fields: [
    {
      title: "Class Name",
      type: FieldType.AUTOSUGGEST,
      reference: "",
      validators: "",
      layout: 6,
      description: "",
      list: [],
      items: schemaData.additives,
      key: "name",
      visible: true,
      cantCreateQuery: true,
      sort: 1,
      dropdownIcon: true,
    },
    // {
    //   title: "INS/E",
    //   type: FieldType.DROPDOWN,
    //   reference: "",
    //   validators: "",
    //   layout: 6,
    //   description: "",
    //   list: [],
    //   items: ["E", "INS"],
    //   key: "type",
    //   visible: 'output.name != "Flavour" && output.name != "Others"',
    // },
    {
      title: "Additive Name/ INS Number",
      type: FieldType.ADDITIVE_AUTOSUGGEST,
      reference: "",
      validators: "",
      layout: 6,
      description: "",
      schemaData: schemaData,
      selected_options: output?.additives,
      country: "IND",
      list: [],
      items: [],
      key: "ins_number",
      visible: 'output.name != "Flavour" && output.name != "Others"',
      sort: 1,
      cantCreateQuery: true,
      dropdownIcon: true,
    },
    {
      title: "Proportion (%)",
      type: FieldType.NUMBER,
      reference: "",
      validators: "",
      layout: 6,
      description: "",
      list: [],
      items: [],
      key: "proportion",
      visible: true,
      min: 0,
      max: 100,
    },
    {
      title: "Choose the format for Additive declaration in Ingredient List",
      type: FieldType.RADIO,
      reference: "",
      validator: "",
      layout: 12,
      description: "",
      list: [],
      items: ["INS Number", "Additive Name"],
      key: "additive_value_show",
      default: "INS Number",
      required: true,
      visible: true,
      customStyle: "gap-15",
    },
    {
      title: "Nature of compound",
      type: FieldType.DROPDOWN,
      reference: "",
      validators: "",
      layout: 6,
      description: "",
      list: [],
      items: ["Natural", "Nature-identical", "Artificial"],
      key: "nature",
      visible: '["Flavour", "Colour"].includes(output.name)',
    },
    {
      title: "Common Name",
      type: FieldType.TEXT,
      reference: "",
      validators: "",
      layout: 6,
      description: "",
      list: [],
      items: [],
      key: "common_name",
      visible: 'output.nature == "Artificial"',
      required: 'output.name != "Flavour"',
    },
    {
      title: "Name of the Additive",
      type: FieldType.DROPDOWN,
      reference: "",
      validators: "",
      layout: 6,
      description: "",
      list: [],
      items: insMapping.Others,
      key: "additive_name",
      visible: 'output.name == "Others"',
    },
  ],
})

export default additives
