export const GET_LABELS_REQUEST = "GET_LABELS_REQUEST"
export const GET_LABELS_SUCCESS = "GET_LABELS_SUCCESS"
export const GET_LABELS_FAILURE = "GET_LABELS_FAILURE"

export const CREATE_LABEL_REQUEST = "CREATE_LABEL_REQUEST"
export const CREATE_LABEL_SUCCESS = "CREATE_LABEL_SUCCESS"
export const CREATE_LABEL_FAILURE = "CREATE_LABEL_FAILURE"

export const UPDATE_LABEL_REQUEST = "UPDATE_LABEL_REQUEST"
export const UPDATE_LABEL_SUCCESS = "UPDATE_LABEL_SUCCESS"
export const UPDATE_LABEL_FAILURE = "UPDATE_LABEL_FAILURE"

export const DELETE_LABEL_REQUEST = "DELETE_LABEL_REQUEST"
export const DELETE_LABEL_SUCCESS = "DELETE_LABEL_SUCCESS"
export const DELETE_LABEL_FAILURE = "DELETE_LABEL_FAILURE"
