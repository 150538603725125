import {
  GET_BLOGS_REQUEST,
  GET_BLOGS_SUCCESS,
  GET_BLOGS_FAILURE,
} from "./blogTypes"
import apis from "../../services/ApiServices"

export const getBlogsRequest = () => {
  return {
    type: GET_BLOGS_REQUEST,
  }
}

export const getBlogsSuccess = (blogs) => {
  return {
    type: GET_BLOGS_SUCCESS,
    payload: blogs,
  }
}

export const getBlogsFailure = () => {
  return {
    type: GET_BLOGS_FAILURE,
  }
}

export const getBlogs = (items) => {
  return async (dispatch) => {
    console.log("Inside get Blogs")
    dispatch(getBlogsRequest())

    if (items.length > 0) {
      let blogs = items
        .filter((item) => item.type === "Blog")
        .map((blog) => blog.details)

      for (let blog of blogs) {
        // get Image
        let image = await apis.getFromS3(blog.image)

        if (image) {
          blog.image = image
        } else {
          blog.image = ""
        }

        // get content
        let content_url = await apis.getFromS3(blog.content)

        if (content_url) {
          let response = await fetch(content_url)
          let data = await response.text()

          blog.content = data
        }
      }

      dispatch(getBlogsSuccess(blogs))
    } else {
      dispatch(getBlogsFailure())
    }
  }
}
