export const GRID_LAYOUT = {
  2: "col-span-2",
  3: "col-span-3",
  4: "col-span-4",
  5: "col-span-5",
  6: "col-span-6",
  9: "col-span-9",
  12: "col-span-12",
  DEFAULT: "col-span-6",
}
