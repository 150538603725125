// returns the browser name, type and version, and sets body classes
// detects major browsers: IE, Edge, Firefox, Chrome, Safari, Opera, Android
// based on: https://github.com/ded/bowser
// list of user agen strings: http://www.webapps-online.com/online-tools/user-agent-strings/dv
export function getBrowser() {
  var ua = navigator.userAgent,
    browser

  // helper functions to deal with common regex
  function getFirstMatch(regex) {
    var match = ua.match(regex)
    return (match && match.length > 1 && match[1]) || ""
  }

  function getSecondMatch(regex) {
    var match = ua.match(regex)
    return (match && match.length > 1 && match[2]) || ""
  }

  // start detecting
  if (/opera|opr/i.test(ua)) {
    browser = {
      name: "Opera",
      type: "opera",
      version:
        getFirstMatch(/version\/(\d+(\.\d+)?)/i) ||
        getFirstMatch(/(?:opera|opr)[\s\/](\d+(\.\d+)?)/i),
    }
  } else if (/msie|trident/i.test(ua)) {
    browser = {
      name: "Internet Explorer",
      type: "msie",
      version: getFirstMatch(/(?:msie |rv:)(\d+(\.\d+)?)/i),
    }
  } else if (/chrome.+? edge/i.test(ua)) {
    browser = {
      name: "Microsft Edge",
      type: "msedge",
      version: getFirstMatch(/edge\/(\d+(\.\d+)?)/i),
    }
  } else if (/chrome|crios|crmo/i.test(ua)) {
    browser = {
      name: "Google Chrome",
      type: "chrome",
      version: getFirstMatch(/(?:chrome|crios|crmo)\/(\d+(\.\d+)?)/i),
    }
  } else if (/firefox/i.test(ua)) {
    browser = {
      name: "Firefox",
      type: "firefox",
      version: getFirstMatch(/(?:firefox)[ \/](\d+(\.\d+)?)/i),
    }
  } else if (!/like android/i.test(ua) && /android/i.test(ua)) {
    browser = {
      name: "Android",
      type: "android",
      version: getFirstMatch(/version\/(\d+(\.\d+)?)/i),
    }
  } else if (/safari/i.test(ua)) {
    browser = {
      name: "Safari",
      type: "safari",
      version: getFirstMatch(/version\/(\d+(\.\d+)?)/i),
    }
  } else {
    browser = {
      name: getFirstMatch(/^(.*)\/(.*) /),
      version: getSecondMatch(/^(.*)\/(.*) /),
    }
    browser.type = browser.name.toLowerCase().replace(/\s/g, "")
  }
  return browser
}
