import React from "react"
import Table from "@material-ui/core/Table"
import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Paper from "@material-ui/core/Paper"
import Chip from "@material-ui/core/Chip"
import { COLORS } from "../../Utils/colorPalette"
import { Link } from "react-router-dom"
import { UserDashboardAnalyticsMap } from "../../Utils/analyticsEvents"
import { sendAnalyticsEvent } from "../../Utils/analytics"
import ApiServices from "../../services/ApiServices"
import { BASE_ROUTES } from "../../constants/routes"

const DashboardTable = ({
  labels,
  classes,
  businessType,
  recipeBrands,
  businessId,
  children,
  recipeCreatorNames,
}) => {
  const rows = []
  for (let i = 0; i < labels?.length; i++) {
    if (businessType?.length > 0 && businessType === "Manufacturer") {
      if (!labels[i]?.name) continue
      rows.push({
        labelName: labels[i]?.name,
        createdAt: labels[i]?.createdOn,
        status: (
          <Chip label={labels[i]?.status} className={classes.chipThreeStyles} />
        ),
        brands: labels[i]?.category,
        key: labels[i]?.id,
      })
    } else if (businessType?.length > 0 && businessType === "Restaurant") {
      const formattedDate = labels[i]?.createdAt
        ? new Date(
            new Date(labels[i].createdAt).getTime() + 5.5 * 60 * 60 * 1000,
          )
            .toISOString()
            .slice(0, 19)
            .replace("T", " ")
        : "-"
      rows.push({
        labelName: labels[i]?.name,
        createdAt: formattedDate,
        status: (
          <Chip label={labels[i]?.status} className={classes.chipThreeStyles} />
        ),
        brands: labels[i]?.category || "Combo Meals",
        key: labels[i]?.recipe_id,
      })
    }
  }

  const tableHeaders = [
    "Sr no.",
    businessType === "Manufacturer" ? "Label Name" : "Recipe Name",
    "Created",
    "Status",
    businessType === "Manufacturer" ? "Category" : "Category",
  ]

  return (
    <div className={classes.mainTableStyles}>
      <TableContainer component={Paper} className={classes.tablePadding}>
        <Table className={classes.tableWidthStyles} aria-label="simple table">
          <TableHead>
            <TableRow>
              {tableHeaders.map((tableHeader) => (
                <TableCell align={tableHeader === "Status" ? "center" : "left"}>
                  {tableHeader}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((row, index) => (
              <TableRow key={row?.key}>
                <TableCell>{index + 1}</TableCell>
                <TableCell component="th" scope="row">
                  <Link
                    key={row.key}
                    onClick={() =>
                      sendAnalyticsEvent(
                        businessType === "Restaurant"
                          ? UserDashboardAnalyticsMap.view_recipe_event
                          : UserDashboardAnalyticsMap.view_label_event,
                        {
                          "Page Name": "User Dashboard",
                        },
                      )
                    }
                    to={
                      businessType === "Restaurant"
                        ? {
                            pathname: `${BASE_ROUTES.MENU_LABEL}/recipe/nutrition-list`,
                            state: {
                              businessId,
                              recipeId: row.key,
                            },
                          }
                        : {
                            pathname: `/ppf/label/v3/${row.key}`,
                          }
                    }
                    className={classes.labelNameStyles}
                  >
                    {row?.labelName}
                  </Link>
                </TableCell>
                <TableCell className={classes.createdAtWidth}>
                  {row?.createdAt}
                </TableCell>
                <TableCell align="center">{row?.status}</TableCell>
                <TableCell>{row?.brands}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {children}
    </div>
  )
}

export default DashboardTable
