import Sidebar from "../../components/sidebar/Sidebar"
import React, { Suspense, lazy } from "react"
import { Switch } from "react-router-dom"
import { useSelector } from "react-redux"
import Protected from "../../components/Protected/Protected"
import PrivateRoute from "../PrivateRoute"
import Loading from "../../pages/Loading/Loading"
import { BASE_ROUTES } from "../../constants/routes"
import UserDashboard from "../../components/UserDashboard"
import InviteRoute from "../InvitedRoute"
import config from "../../config"
import ValidateLabel from "../../pages/AILabelValidation/CreateLabel"
import EditLabel from "../../pages/AILabelValidation/EditLabel"

const CreateNewRecipe = lazy(() =>
  import("../../pages/NutritionAnalysis/Recipes/CreateNewRecipe"),
)
const CreateBaseRecipe = lazy(() =>
  import("../../pages/NutritionAnalysis/BaseRecipes/CreateBaseRecipe"),
)
const CreateIngredient = lazy(() =>
  import("../../pages/NutritionAnalysis/Ingredients/CreateIngredient"),
)
const NutritionLabel = lazy(() =>
  import("../../pages/NutritionAnalysis/NutritionLabel"),
)
const BaseRecipeNutritionAnalysis = lazy(() =>
  import("../../pages/NutritionAnalysis/ListingPages/BaseRecipeListing"),
)
const IngredientsNutritionAnalysis = lazy(() =>
  import("../../pages/NutritionAnalysis/ListingPages/IngredientsListing"),
)
const RecipesNutritionAnalysis = lazy(() =>
  import("../../pages/NutritionAnalysis/ListingPages/RecipeListing"),
)
const Labelv3 = lazy(() => import("../../pages/LabelMaker/label_v3_layout"))

const AILabel = lazy(() => import("../../pages/AILabelValidation/Dashboard"))
const AILabelReview = lazy(() => import("../../pages/AILabelReview/Dashboard"))
const DigitalLocker = lazy(() => import("../../pages/DigitalLocker/Dashboard"))

const PrintLabel = lazy(() => import("../../pages/PrintLabel"))
const LabelMakerListing = lazy(() =>
  import("../../pages/LabelMaker/ListingPage"),
)
const MeraQRCode = lazy(() => import("../../pages/MeraQRCode/MeraQRCode"))
const GenerateDigitalLabel = lazy(() =>
  import("../../pages/Generate-digital-label/GenerateDigitalLabel"),
)
const BusinessProfile = lazy(() =>
  import("../../pages/business-profile/BusinessProfile"),
)
const AddBrand = lazy(() => import("../../pages/AddBrands/AddBrand"))
const InviteUsers = lazy(() => import("../../pages/userModule/InviteUsers"))

const viewLabelById = lazy(() =>
  import("../../pages/AILabelValidation/viewLabel"),
)

const viewLabelReview = lazy(() =>
  import("../../pages/AILabelReview/viewLabelReview"),
)

const viewDigitalLockerLabel = lazy(() =>
  import("../../pages/DigitalLocker/viewLabelDocument"),
)

const exportsLabeling = lazy(() =>
  import("../../pages/ExportsLabel/Dashboard/ExportsDashboard"),
)

const createNewExportsLabel = lazy(() =>
  import("../../pages/ExportsLabel/CreateExportsLabel"),
)

const exportsNewLabelMaker = lazy(() =>
  import("../../pages/ExportsLabel/ExportsLabelMaker"),
)

const CreateNewMultiCountryExportLabel = lazy(() =>
  import("../../pages/ExportsLabel/ExportsMultiCountrylabelMaker"),
)

const PPFRouter = () => {
  const ppfPlanFeatures = useSelector((state) => state.ui.ppfPlanFeatures)
  const ENABLE_USER_MODULE = config.ENABLE_USERMODULE

  const nutritionAnalysisRoutes = [
    <PrivateRoute
      component={() => <Protected component={CreateNewRecipe} />}
      path={`${BASE_ROUTES.PPF}/nutrition-analysis/create-new-recipe`}
    />,
    <PrivateRoute
      component={() => <Protected component={CreateBaseRecipe} />}
      path={`${BASE_ROUTES.PPF}/nutrition-analysis/create-base-recipe`}
    />,
    <PrivateRoute
      component={() => <Protected component={CreateIngredient} />}
      path={`${BASE_ROUTES.PPF}/nutrition-analysis/create-ingredient`}
    />,
    <PrivateRoute
      component={() => <Protected component={NutritionLabel} />}
      path={`${BASE_ROUTES.PPF}/nutrition-analysis/nutrition-label`}
    />,
    <PrivateRoute
      component={() => <Protected component={CreateNewRecipe} />}
      path={`${BASE_ROUTES.PPF}/nutrition-analysis/edit-new-recipe/:id`}
    />,
    <PrivateRoute
      component={() => <Protected component={CreateBaseRecipe} />}
      path={`${BASE_ROUTES.PPF}/nutrition-analysis/edit-base-recipe/:id`}
    />,
    <PrivateRoute
      component={() => <Protected component={CreateIngredient} />}
      path={`${BASE_ROUTES.PPF}/nutrition-analysis/edit-ingredient/:id`}
    />,
    <PrivateRoute
      component={() => <Protected component={RecipesNutritionAnalysis} />}
      path={`${BASE_ROUTES.PPF}/nutrition-analysis/recipes`}
      exact
    />,
    <PrivateRoute
      component={() => <Protected component={BaseRecipeNutritionAnalysis} />}
      path={`${BASE_ROUTES.PPF}/nutrition-analysis/baseRecipes`}
      exact
    />,
    <PrivateRoute
      component={() => <Protected component={IngredientsNutritionAnalysis} />}
      path={`${BASE_ROUTES.PPF}/nutrition-analysis/ingredients`}
      exact
    />,
  ]

  const labelMakerRoutes = [
    <PrivateRoute
      component={LabelMakerListing}
      path={`${BASE_ROUTES.PPF}/label-maker`}
      exact
    />,
    <PrivateRoute
      component={() => <Protected component={Labelv3} />}
      path={`${BASE_ROUTES.PPF}/label/v3/:id/`}
      exact
    />,
  ]

  const AILabelValidationRoutes = [
    <PrivateRoute
      component={AILabel}
      path={`${BASE_ROUTES.PPF}/ai-label-validation`}
      exact
    />,
    <PrivateRoute
      component={() => <Protected component={ValidateLabel} />}
      path={`${BASE_ROUTES.PPF}/ai-label-validation/validate`}
    />,
    <PrivateRoute
      component={() => <Protected component={EditLabel} />}
      path={`${BASE_ROUTES.PPF}/ai-label-validation/edit/:id`}
    />,
    <PrivateRoute
      component={() => <Protected component={viewLabelById} />}
      path={`${BASE_ROUTES.PPF}/ai-label-validation/:id/:version`}
    />,
  ]

  const AILabelReviewRoutes = [
    <PrivateRoute
      component={() => <Protected component={AILabelReview} />}
      path={`${BASE_ROUTES.PPF}/ai-label-review`}
      exact
    />,

    <PrivateRoute
      component={() => <Protected component={viewLabelReview} />}
      path={`${BASE_ROUTES.PPF}/ai-label-review/:id/:current_version`}
    />,
  ]

  const DigitalLockerRoutes = [
    <PrivateRoute
      component={() => <Protected component={DigitalLocker} />}
      path={`${BASE_ROUTES.PPF}/label-drive`}
      exact
    />,

    <PrivateRoute
      component={() => <Protected component={viewDigitalLockerLabel} />}
      path={`${BASE_ROUTES.PPF}/label-drive/:id`}
    />,
  ]

  const ExportsLabelRoutes = [
    <PrivateRoute
      component={() => <Protected component={exportsLabeling} />}
      path={`${BASE_ROUTES.PPF}/exports`}
      exact
    />,
    <PrivateRoute
      component={() => <Protected component={exportsNewLabelMaker} />}
      path={`${BASE_ROUTES.PPF}/exports/label-maker/:id`}
      exact
    />,

    <PrivateRoute
      component={() => <Protected component={createNewExportsLabel} />}
      path={`${BASE_ROUTES.PPF}/exports/create`}
    />,

    <PrivateRoute
      component={() => (
        <Protected component={CreateNewMultiCountryExportLabel} />
      )}
      path={`${BASE_ROUTES.PPF}/exports/multi-country/create`}
    />,

    //CreateNewMultiCountryExportLabel
  ]

  return (
    <div>
      <Sidebar>
        <div className="w-full h-full">
          <Suspense fallback={<Loading />}>
            <Switch>
              {ppfPlanFeatures?.["NUTRITION_ANALYSIS"] &&
                nutritionAnalysisRoutes}
              {ppfPlanFeatures?.["LABEL_MAKER"] && labelMakerRoutes}
              {ppfPlanFeatures?.["LABEL_VALIDATION"] && AILabelValidationRoutes}
              {ppfPlanFeatures?.["LABEL_VALIDATION"] && AILabelReviewRoutes}
              {ppfPlanFeatures?.["LABEL_VALIDATION"] && DigitalLockerRoutes}
              {true && ExportsLabelRoutes}

              {ppfPlanFeatures?.["READY_FOR_PRINT"] && (
                <PrivateRoute
                  component={PrintLabel}
                  path={`${BASE_ROUTES.PPF}/print-label`}
                  exact
                />
              )}
              {ppfPlanFeatures?.["MERA_QR_CODE"] && (
                <PrivateRoute
                  component={MeraQRCode}
                  path={`${BASE_ROUTES.PPF}/mera-qrcode`}
                  exact
                />
              )}
              <PrivateRoute
                component={() => <Protected component={GenerateDigitalLabel} />}
                path={`${BASE_ROUTES.PPF}/digital-label/:businessId/:productId`}
              />
              <PrivateRoute
                component={() => <Protected component={UserDashboard} />}
                path={`${BASE_ROUTES.PPF}/user-dashboard`}
                exact
              />
              <PrivateRoute
                component={() => <Protected component={BusinessProfile} />}
                path={`${BASE_ROUTES.PPF}/business-profile`}
              />
              <PrivateRoute
                component={() => <Protected component={AddBrand} />}
                path={`${BASE_ROUTES.PPF}/edit-brand/:brandId`}
              />
              <PrivateRoute
                component={() => <Protected component={AddBrand} />}
                path={`${BASE_ROUTES.PPF}/add-brand`}
              />
              {ENABLE_USER_MODULE ? (
                <InviteRoute
                  component={() => <Protected component={InviteUsers} />}
                  path={`${BASE_ROUTES.PPF}/:org/users`}
                />
              ) : null}
            </Switch>
          </Suspense>
        </div>
      </Sidebar>
    </div>
  )
}

export default PPFRouter
