import React from "react"
import { Dialog, Transition } from "@headlessui/react"
import { Fragment, useState } from "react"
import { toast } from "react-toastify"
import { CrossIcon } from "../assets/icons/Icons"
import QkFieldInput from "./QkFieldInput"

export default function QkModal({ data, parentData }) {
  let [isOpen, setIsOpen] = useState(false)
  let [modalOutput, setModalOutput] = useState({})

  function handleChange(key, d) {
    setModalOutput((prev) => ({
      ...prev,
      [key]: d,
    }))
  }
  function closeModal() {
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
  }
  const handleAction = async () => {
    try {
      const payload = {
        ...modalOutput,
        business_id: parentData.businessId,
        business_name: parentData.businessName ?? undefined,
        user_name: parentData.userName ?? undefined,
      }
      const response = await parentData.api(payload)
      if (response) {
        toast.success("Request sent successfully.")
      }
    } catch (err) {
      console.log(err)
      toast.error("Request could not be sent.")
    }
    closeModal()
  }

  return (
    <>
      <button
        type="button"
        onClick={openModal}
        className={parentData?.openButtonStyle}
      >
        {parentData?.openButtonText}
      </button>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-lg transform overflow-hidden bg-white rounded-lg p-5 align-middle shadow-xl transition-all">
                  <div className="flex w-full justify-between">
                    <Dialog.Title
                      as="h3"
                      className="text-xl text-left font-bold leading-6 mt-2"
                    >
                      {data?.name}
                      <div className="w-[46px] h-[3px] rounded-[1px] mt-2 bg-[#DF6F13]" />
                    </Dialog.Title>
                    <div className="p-3 cursor-pointer" onClick={closeModal}>
                      <CrossIcon />
                    </div>
                  </div>
                  <div className="grid grid-cols-12 md:grid-cols-12  mx-1 my-6 gap-6 items-start justify-center">
                    {data?.fields?.map((field) => {
                      return (
                        <QkFieldInput
                          key={field.name}
                          data={field ?? {}}
                          values={modalOutput ?? {}}
                          handleChange={(d) => handleChange(field.key, d)}
                        />
                      )
                    })}
                  </div>

                  <div className="flex justify-end">
                    <button
                      type="button"
                      className="bg-yellow-400  text-base text-black text-sm py-3 px-4 font-bold rounded-lg inline-flex items-center"
                      onClick={handleAction}
                    >
                      {parentData?.actionButtonText}
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}
