import React from "react"

export default function PrintMenuIcon(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={"stroke-slate-400 group-hover:stroke-amber-300"}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 4H6C3.17157 4 1.75736 4 0.87868 4.87868C0 5.75736 0 7.17157 0 10C0 12.8284 0 14.2426 0.87868 15.1213C1.37323 15.6159 2.03743 15.8321 3.02795 15.9266C2.99998 15.2038 2.99999 14.3522 3 13.5C2.72386 13.5 2.5 13.2761 2.5 13C2.5 12.7239 2.72386 12.5 3 12.5H17C17.2761 12.5 17.5 12.7239 17.5 13C17.5 13.2761 17.2761 13.5003 17 13.5003C17 14.3525 17 15.2039 16.9721 15.9266C17.9626 15.8321 18.6268 15.6159 19.1213 15.1213C20 14.2426 20 12.8284 20 10C20 7.17157 20 5.75736 19.1213 4.87868C18.2426 4 16.8284 4 14 4ZM7 8.75C7.41421 8.75 7.75 8.41421 7.75 8C7.75 7.58579 7.41421 7.25 7 7.25H4C3.58579 7.25 3.25 7.58579 3.25 8C3.25 8.41421 3.58579 8.75 4 8.75H7ZM15 9C15.5523 9 16 8.55229 16 8C16 7.44772 15.5523 7 15 7C14.4477 7 14 7.44772 14 8C14 8.55229 14.4477 9 15 9Z"
        // fill="#FFD700"
      />
      <path
        d="M15.1211 0.878679C14.2424 0 12.8282 0 9.99977 0C7.17134 0 5.75712 0 4.87844 0.87868C4.38608 1.37105 4.16961 2.03157 4.07444 3.01484C4.63368 2.99996 5.25183 2.99998 5.92943 3H14.0706C14.748 2.99998 15.366 2.99996 15.9251 3.01483C15.8299 2.03156 15.6135 1.37105 15.1211 0.878679Z"
        // fill="#FFD700"
      />
      <path
        d="M16 13.5C16 16.3284 16 18.2426 15.1213 19.1213C14.2426 20 12.8284 20 10 20C7.17158 20 5.75736 20 4.87868 19.1213C4 18.2426 4 16.3284 4 13.5H16Z"
        // fill="#FFD700"
      />
    </svg>
  )
}
