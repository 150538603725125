import React, { useEffect } from "react"
import Button from "@material-ui/core/Button"
import Menu from "@material-ui/core/Menu"
import { withStyles } from "@material-ui/core/styles"
import "./Navbar.css"
import IconButton from "@material-ui/core/IconButton"
import MenuIcon from "@material-ui/icons/Menu"
import Drawer from "@material-ui/core/Drawer"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import MenuItem from "@material-ui/core/MenuItem"
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import { Link, withRouter } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import {
  logoutUser,
  setBusinessesType,
  setUserHasBusinesses,
} from "../../Redux"
import ApiServices from "../../services/ApiServices"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import { infoCenterUrl } from "../../constants/externalLinks"
import { BASE_ROUTES } from "../../constants/routes"
import { getBusinessById, getUserRole } from "../../services/ApiServicesv2"

const styles = (theme) => ({
  root: {
    position: "absolute",
    right: 0,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
  },
})

function AppBarCollapse(props) {
  const active_business = useSelector((state) => state.login.active_business)
  const lbUserId = useSelector((state) => state.login.lbUserId)
  const business_type = useSelector((state) => state.login.business_type)
  const businessList = useSelector((state) => state.login.allBusinesses)
  const user_has_active_subscription = useSelector(
    (state) => state.subscription.user_has_active_subscription,
  )
  const [Allbusinesses, setAllBusinesses] = React.useState([])
  const [open, setOpen] = React.useState(false)
  const [anchorE1, setanchorE1] = React.useState(null)

  useEffect(() => {
    getAllBusiness()
  }, [])

  const homeLink = () => {
    if (window.location.href.includes(`${BASE_ROUTES.MENU_LABEL}/`)) {
      return `${BASE_ROUTES.MENU_LABEL}/`
    } else if (window.location.href.includes(`${BASE_ROUTES.PPF}/`)) {
      return `${BASE_ROUTES.PPF}/`
    }

    return "/"
  }

  const getAllBusiness = async () => {
    const allBusiness = businessList
    let businessesArray = []
    if (allBusiness) {
      for (let allbusi of allBusiness) {
        const getBusinessName = await getBusinessById(allbusi.id)
        businessesArray.push({
          name: getBusinessName?.data?.business_name,
          id: getBusinessName?.data?.id,
        })
      }
      setAllBusinesses(businessesArray)
    }
  }

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  const handleCloseBusiness = () => {
    setanchorE1(null)
  }

  const switchBusiness = async (businessId) => {
    const businessDetails = await getBusinessById(businessId)

    let permission

    const businessMapDetails = await getUserRole(active_business, lbUserId)

    if (businessMapDetails && businessMapDetails?.data) {
      permission = businessMapDetails?.data?.role
    }

    let business_type = ""

    if (businessDetails?.data) {
      if (businessDetails?.data?.type === "Manufacturer") {
        business_type = "Manufacturer"
        dispatch(setBusinessesType(business_type))
        dispatch(setUserHasBusinesses(businessId, permission))
        setanchorE1(null)
        props.history.push({
          pathname: `${BASE_ROUTES.PPF}/label-maker/`,
        })
      } else {
        business_type = "Restaurant"
        dispatch(setBusinessesType(business_type))
        dispatch(setUserHasBusinesses(businessId, permission))
        setanchorE1(null)
        props.history.push({
          pathname: `${BASE_ROUTES.MENU_LABEL}/recipe/`,
        })
      }
    }
  }

  const businessChange = (event) => {
    setanchorE1(event.currentTarget)
  }

  const dispatch = useDispatch()
  const authenticated = useSelector((state) => state.login.authenticated)

  return (
    <div className={props.classes.root}>
      <div id="top-menu">
        <a className="nav-link" href="/packed-food-labelling/">
          <Button>Packed Food Labelling</Button>
        </a>
        <a className="nav-link" href="/menu-labelling/">
          <Button>Menu Labelling</Button>
        </a>
        <a
          className="nav-link"
          href="https://foodlabelsolutions.com/fopl/front-of-pack-labelling/"
        >
          <Button>Digital FOPL</Button>
        </a>
        <Link className="nav-link" to="/report/nutraceuticals-labelling-compliance-report">
          <Button style={{ background: "gold" }}>Nutraceutical Report</Button>
        </Link>
        <Link className="nav-link" to="/about-us">
          <Button>About us</Button>
        </Link>
        <a className="nav-link" href={infoCenterUrl}>
          <Button color="inherit">Info Centre</Button>
        </a>

        <Link className="nav-link" to="/free-ebook-compliant-food-label">
          <Button>Download Free E-Book</Button>
        </Link>

        {user_has_active_subscription ? (
          business_type === "Manufacturer" ? (
            <span>
              <Link to="/dashboard" className="nav-link">
                <Button color="inherit">Dashboard</Button>
              </Link>
              <button
                className="logout-btn"
                onClick={() => dispatch(logoutUser())}
              >
                Logout
              </button>
            </span>
          ) : business_type === "Restaurant" ? (
            <span>
              <Link
                to={`${BASE_ROUTES.MENU_LABEL}/recipe`}
                className="nav-link"
              >
                <Button color="inherit">Dashboard</Button>
              </Link>
              <button
                className="logout-btn"
                onClick={() => dispatch(logoutUser())}
              >
                Logout
              </button>
            </span>
          ) : (
            ""
          )
        ) : (
          Allbusinesses.length > 0 && (
            <span>
              <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={businessChange}
                className="business-change"
              >
                {Allbusinesses.length > 0 && Allbusinesses[0].name}
                &nbsp;&nbsp;
                <ExpandMoreIcon className="dropdown-arrow" />
              </Button>
              <Menu
                id="simple-menu"
                className="business-dropdown"
                anchorEl={anchorE1}
                keepMounted
                open={Boolean(anchorE1)}
                onClose={handleCloseBusiness}
              >
                {Allbusinesses.sort((a, b) => (a.name > b.name ? 1 : -1)).map(
                  (busi, idx) => (
                    <MenuItem
                      key={idx}
                      onClick={(e) => switchBusiness(busi.id)}
                      className={active_business === busi.id ? "switched" : ""}
                    >
                      {busi.name}
                    </MenuItem>
                  ),
                )}
              </Menu>
              <button
                className="logout-btn"
                onClick={() => dispatch(logoutUser())}
              >
                Logout
              </button>
            </span>
          )
        )}
      </div>
      <div className="menu-icon" id="topmenu">
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          className="icon"
          open={open}
        >
          <MenuIcon />
        </IconButton>
        <Drawer
          className={props.classes.drawer}
          variant="persistent"
          anchor="right"
          open={open}
          classes={{
            paper: props.classes.drawerPaper,
          }}
        >
          <div className={props.classes.drawerHeader}>
            <IconButton onClick={handleDrawerClose}>
              {props.direction === "rtl" ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
          </div>
          <List className="mobile-menu">
            <a className="nav-link" href="/packed-food-labelling">
              <ListItem button key="Packed Food Labelling">
                <ListItemText primary="Packed Food Labelling" />
              </ListItem>
            </a>
            <a className="nav-link" href="/menu-labelling/">
              <ListItem button key="Menu Labelling">
                <ListItemText primary="Menu Labelling" />
              </ListItem>
            </a>
            <a
              className="nav-link"
              href="https://foodlabelsolutions.com/fopl/front-of-pack-labelling/"
            >
              <ListItem
                button
                key="Digital FOPL"
                style={{ background: "gold" }}
              >
                <ListItemText primary="Digital FOPL" />
              </ListItem>
            </a>
            <Link className="nav-link" to="/about-us">
              <ListItem button key="About us">
                <ListItemText primary="About us" />
              </ListItem>
            </Link>
            <Link to={`${BASE_ROUTES.MENU_LABEL}/plans`} className="nav-link">
              <ListItem button key="Pricing">
                <ListItemText primary="Pricing" />
              </ListItem>
            </Link>

            <a href={infoCenterUrl} className="nav-link">
              <ListItem button key="Blogs">
                <ListItemText primary="Info Centre" />
              </ListItem>
            </a>

            <Link className="nav-link" to="/free-ebook-compliant-food-label">
              <ListItem button key="Free Ebook Compliant Food Label">
                <ListItemText primary="Free Ebook Compliant Food Label" />
              </ListItem>
            </Link>

            {!authenticated ? (
              <span>
                <Link to="/login" className="nav-link">
                  <ListItem button key="Login">
                    <ListItemText primary="Login" />
                  </ListItem>
                </Link>
                <Link to="/register" className="nav-link">
                  <ListItem button key="Signup">
                    <ListItemText primary="Signup" />
                  </ListItem>
                </Link>
              </span>
            ) : user_has_active_subscription ? (
              business_type === "Manufacturer" ? (
                <Link to="/dashboard" className="nav-link">
                  <ListItem button key="Dashboard">
                    <ListItemText primary="Dashboard" />
                  </ListItem>
                </Link>
              ) : (
                <Link
                  to={`${BASE_ROUTES.MENU_LABEL}/recipe`}
                  className="nav-link"
                >
                  <ListItem button key="Dashboard">
                    <ListItemText primary="Dashboard" />
                  </ListItem>
                </Link>
              )
            ) : Allbusinesses.lengh > 0 ? (
              <Menu
                id="simple-menu"
                className="business-dropdown"
                anchorEl={anchorE1}
                keepMounted
                open={Boolean(anchorE1)}
                onClose={handleCloseBusiness}
              >
                <MenuItem
                  onClick={(e) => switchBusiness(Allbusinesses[0].id)}
                  className={
                    active_business === Allbusinesses[0].id ? "switched" : ""
                  }
                >
                  {Allbusinesses[0].name}
                </MenuItem>
              </Menu>
            ) : (
              <button
                className="logout-btn"
                onClick={() => dispatch(logoutUser())}
              >
                Logout
              </button>
            )}
          </List>
        </Drawer>
      </div>
    </div>
  )
}
export default withRouter(withStyles(styles)(AppBarCollapse))
