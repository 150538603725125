import React from "react"
import Box from "@material-ui/core/Box"
import Card from "@material-ui/core/Card"
import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"

const MaterialCard = ({
  labelName,
  labelCount,
  companyName,
  classes,
  count,
  className,
}) => {
  return (
    <Card className={classes.topCardBorder}>
      <CardContent className={classes.cardContentStyles}>
        <Typography className={classes.typographyStyles} gutterBottom>
          {labelName}
        </Typography>
        <div className={classes.labelWrapper}>
          <Typography className={className} component="div">
            {labelCount}
            {"  "}
          </Typography>
        </div>
        {companyName?.length > 0 && (
          <Typography variant="h4" component="div">
            Company Name: {companyName}
          </Typography>
        )}
      </CardContent>
    </Card>
  )
}

export default MaterialCard
