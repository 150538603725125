import Sidebar from "../../components/sidebar/Sidebar"
import React, { Suspense, lazy } from "react"
import { Switch } from "react-router-dom"
import Protected from "../../components/Protected/Protected"
import PrivateRoute from "../PrivateRoute"
import Loading from "../../pages/Loading/Loading"
import { BASE_ROUTES } from "../../constants/routes"
import UserDashboard from "../../components/UserDashboard"
import InviteRoute from "../InvitedRoute"
import config from "../../config"

const BusinessProfile = lazy(() =>
  import("../../pages/business-profile/BusinessProfile"),
)
const AddBrand = lazy(() => import("../../pages/AddBrands/AddBrand"))
const RecipeReview = lazy(() => import("../../pages/RecipeReview/RecipeReview"))
const IngredientsHome = lazy(() => import("../../pages/Ingredients"))
const BaseRecipeHome = lazy(() => import("../../pages/BaseRecipe"))
const BuffetTags = lazy(() => import("../../pages/FoodTags"))
const CreateBuffet = lazy(() => import("../../pages/FoodTags/CreateBuffet"))
const Guide = lazy(() => import("../../pages/Guide"))
const CreateNewGuide = lazy(() => import("../../pages/Guide/CreateNewGuide"))
const EditGuide = lazy(() => import("../../pages/Guide/EditGuide"))
const Recipe = lazy(() => import("../../pages/recipe/Recipe"))
const NutritionList = lazy(() => import("../../pages/NutritionListV2"))
const RecipeVersionHistory = lazy(() =>
  import("../../pages/RecipeVersionHistory/RecipeVersionHistory"),
)
const StandardRecipe = lazy(() =>
  import("../../pages/standard-recipe/StandardRecipe"),
)
const RecipeDetails = lazy(() =>
  import("../../pages/recipe-details/RecipeDetails"),
)
const NewRecipe = lazy(() => import("../../pages/NewRecipe/NewRecipe"))
const EditTag = lazy(() => import("../../pages/FoodTags/EditTag"))
const InviteUsers = lazy(() => import("../../pages/userModule/InviteUsers"))
const PartnerApis = lazy(() => import("../../pages/PartnerApis/PartnerApis"))
const DownloadMenuLabels = lazy(() =>
  import("../../pages/menu-labelling/download-label/downloadLabel"),
)
const comboMeals = lazy(() =>
  import("../../pages/CreateComboMeals/CreateComboMeals"),
)

const calorieBalance = lazy(() =>
  import("../../pages/CalorieBalance/Dashboard/CalorieBalanceDashboard"),
)

const calorieBalanceOptimize = lazy(() =>
  import("../../pages/CalorieBalance/CalorieBalanceOptimizer"),
)

const calorieBalaneCreateRecipeTypeSelection = lazy(() =>
  import("../../pages/CalorieBalance/CalorieBalanceCreateRecipeTypeSelection"),
)

const calorieBalanceNewRecipe = lazy(() =>
  import("../../pages/CalorieBalance/CalorieBalanceNewRecipe"),
)

const calorieBalanceSummerizedNiTables = lazy(() =>
  import("../../pages/CalorieBalance/FinalSummerizedNITables"),
)

const calorieBalanceEditRecipe = lazy(() =>
  import("../../pages/CalorieBalance/CalorieBalanceEditRecipe"),
)

const MenuLabellingRouter = () => {
  const ENABLE_USER_MODULE = config.ENABLE_USERMODULE
  return (
    <div>
      <Sidebar>
        <div className="w-full h-full">
          <Suspense fallback={<Loading />}>
            <Switch>
              <PrivateRoute
                component={() => <Protected component={UserDashboard} />}
                path={`${BASE_ROUTES.MENU_LABEL}/user-dashboard`}
                exact
              />
              <PrivateRoute
                component={() => <Protected component={BusinessProfile} />}
                path={`${BASE_ROUTES.MENU_LABEL}/business-profile`}
              />
              <PrivateRoute
                component={() => <Protected component={AddBrand} />}
                path={`${BASE_ROUTES.MENU_LABEL}/edit-brand/:brandId`}
              />
              <PrivateRoute
                component={() => <Protected component={AddBrand} />}
                path={`${BASE_ROUTES.MENU_LABEL}/add-brand`}
              />
              <PrivateRoute
                component={() => <Protected component={RecipeReview} />}
                path={`${BASE_ROUTES.MENU_LABEL}/:org_name/review`}
              />
              <PrivateRoute
                component={() => <Protected component={IngredientsHome} />}
                path={`${BASE_ROUTES.MENU_LABEL}/ingredients`}
              />
              <PrivateRoute
                component={() => <Protected component={BaseRecipeHome} />}
                path={`${BASE_ROUTES.MENU_LABEL}/base-recipe`}
              />
              <PrivateRoute
                component={() => <Protected component={BuffetTags} />}
                path={`${BASE_ROUTES.MENU_LABEL}/buffet-tags`}
                exact
              />
              <PrivateRoute
                component={() => <Protected component={CreateBuffet} />}
                path={`${BASE_ROUTES.MENU_LABEL}/buffet-tag/create`}
                exact
              />
              <PrivateRoute
                component={() => <Protected component={Guide} />}
                path={`${BASE_ROUTES.MENU_LABEL}/guide`}
                exact
              />
              <PrivateRoute
                component={() => <Protected component={CreateNewGuide} />}
                path={`${BASE_ROUTES.MENU_LABEL}/guide/create-new-guide`}
                exact
              />
              <PrivateRoute
                component={() => <Protected component={EditGuide} />}
                path={`${BASE_ROUTES.MENU_LABEL}/guide/:guideId`}
                exact
              />
              <PrivateRoute
                component={() => <Protected component={Recipe} />}
                path={`${BASE_ROUTES.MENU_LABEL}/recipe`}
                exact
              />
              <PrivateRoute
                component={() => <Protected component={NutritionList} />}
                path={`${BASE_ROUTES.MENU_LABEL}/recipe/nutrition-list`}
              />
              <PrivateRoute
                component={() => <Protected component={RecipeVersionHistory} />}
                path={`${BASE_ROUTES.MENU_LABEL}/recipe/:recipeId/versions`}
              />
              <PrivateRoute
                component={() => <Protected component={StandardRecipe} />}
                path={`${BASE_ROUTES.MENU_LABEL}/add-recipe`}
              />
              <PrivateRoute
                component={() => <Protected component={RecipeDetails} />}
                path={`${BASE_ROUTES.MENU_LABEL}/add-recipe-details`}
              />
              <PrivateRoute
                component={() => <Protected component={NewRecipe} />}
                path={`${BASE_ROUTES.MENU_LABEL}/add-new-recipe`}
              />
              <PrivateRoute
                component={() => <Protected component={NewRecipe} />}
                path={`${BASE_ROUTES.MENU_LABEL}/edit-recipe/:recipeId`}
              />
              <PrivateRoute
                component={() => <Protected component={comboMeals} />}
                path={`${BASE_ROUTES.MENU_LABEL}/create-combo-meals`}
              />
              <PrivateRoute
                component={() => <Protected component={comboMeals} />}
                path={`${BASE_ROUTES.MENU_LABEL}/edit-combo-meals/:id`}
              />
              <PrivateRoute
                component={() => <Protected component={EditTag} />}
                path={`${BASE_ROUTES.MENU_LABEL}/buffet-tag/:guideId`}
                exact
              />
              <PrivateRoute
                component={() => <Protected component={PartnerApis} />}
                path={`${BASE_ROUTES.MENU_LABEL}/partner-api`}
                exact
              />
              <PrivateRoute
                component={() => <Protected component={calorieBalance} />}
                path={`${BASE_ROUTES.MENU_LABEL}/calorie-balance`}
                exact
              />
              <PrivateRoute
                component={() => (
                  <Protected
                    component={calorieBalaneCreateRecipeTypeSelection}
                  />
                )}
                path={`${BASE_ROUTES.MENU_LABEL}/calorie-balance/select-type`}
                exact
              />

              <PrivateRoute
                component={() => (
                  <Protected component={calorieBalanceNewRecipe} />
                )}
                path={`${BASE_ROUTES.MENU_LABEL}/calorie-balance/create-new-recipe`}
                exact
              />
              <PrivateRoute
                component={() => (
                  <Protected component={calorieBalanceOptimize} />
                )}
                path={`${BASE_ROUTES.MENU_LABEL}/calorie-balance/compare`}
                exact
              />
              <PrivateRoute
                component={() => (
                  <Protected component={calorieBalanceEditRecipe} />
                )}
                path={`${BASE_ROUTES.MENU_LABEL}/calorie-balance/compare/edit`}
                exact
              />

              <PrivateRoute
                component={() => (
                  <Protected component={calorieBalanceSummerizedNiTables} />
                )}
                path={`${BASE_ROUTES.MENU_LABEL}/calorie-balance/summary`}
                exact
              />

              {ENABLE_USER_MODULE ? (
                <InviteRoute
                  component={() => <Protected component={InviteUsers} />}
                  path={`${BASE_ROUTES.MENU_LABEL}/:org/users`}
                />
              ) : null}
              <PrivateRoute
                component={() => <Protected component={DownloadMenuLabels} />}
                path={`${BASE_ROUTES.MENU_LABEL}/download`}
              />
            </Switch>
          </Suspense>
        </div>
      </Sidebar>
    </div>
  )
}

export default MenuLabellingRouter
