import {
  GET_CATEGORIES_REQUEST,
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORIES_FAILURE,
  SET_FOOD_CATEGORIES,
} from "./categoriesTypes"
import apis from "../../services/ApiServices"
import { getAllCategories } from "../../services/ApiServicesv2"

export const getCategoriesRequest = () => {
  return {
    type: GET_CATEGORIES_REQUEST,
  }
}

export const getCategoriesSuccess = (categories) => {
  return {
    type: GET_CATEGORIES_SUCCESS,
    payload: categories,
  }
}

export const getCategoriesFailure = (error_message) => {
  return {
    type: GET_CATEGORIES_FAILURE,
    payload: error_message,
  }
}

export const getCategories = () => {
  return async (dispatch) => {
    dispatch(getCategoriesRequest())
    let response = await apis.getAllContent()
    if ("success" in response) {
      let items = response.result.items
      let categories = items.filter((item) => item.type === "CATEGORY")
      dispatch(getCategoriesSuccess(categories))
    } else {
      dispatch(getCategoriesFailure())
    }
  }
}

export const addCategory = (category) => {
  return async (dispatch) => {
    let response = await apis.addContent(category)
    if ("success" in response) {
      dispatch(getCategories())
    }
  }
}

export const getFoodCategories = () => {
  return async (dispatch) => {
    dispatch(getCategoriesRequest())
    let response = await getAllCategories()
    if (response?.status === "success") {
      dispatch(setFoodCategories(response?.data))
    }
  }
}

export const setFoodCategories = (food_categories) => {
  food_categories.level_1 = food_categories.level_1.filter(
    (item) => item !== undefined,
  )
  food_categories.level_1 = [
    ...food_categories.level_1,
    {
      category_value: "Indian Sweets and Indian Snacks & Savoury Products",
      level_value: "18",
    },
  ]
  food_categories.level_2 = [
    ...food_categories.level_2,
    {
      category_value: "Indian Sweets",
      level_value: "18.1",
    },
    {
      category_value: "Indian Snacks and Savories Products",
      level_value: "18.2",
    },
  ]
  food_categories.level_3 = [
    ...food_categories.level_3,
    {
      category_value: "Milk Based Sweets",
      level_value: "18.1.1",
    },
    {
      category_value: "Cereal or pulses flour/starch based sweets",
      level_value: "18.1.2",
    },
    {
      category_value: "Dry Fruit and Nut Based Sweets",
      level_value: "18.1.3",
    },
    {
      category_value: "Indian Confections",
      level_value: "18.1.4",
    },
    {
      category_value: "Fruit and Vegetable Based Sweets",
      level_value: "18.1.5",
    },
    {
      category_value: "Cereal or pulses flour/starch based Snacks",
      level_value: "18.2.1",
    },
    {
      category_value: "Fruit and Vegetable Based Snacks and Savouries",
      level_value: "18.2.2",
    },
    {
      category_value: "Dry Fruit and Nut Based Snacks and Savoruies",
      level_value: "18.2.3",
    },
    {
      category_value: "Ready to eat and Ready to cook products",
      level_value: "18.2.4",
    },
  ]

  food_categories.level_4 = [
    ...food_categories.level_4,
    {
      category_value: "Khoa Bases Sweets",
      level_value: "18.1.1.1",
    },
    {
      category_value: "Chhana Based Sweets",
      level_value: "18.1.1.2",
    },
    {
      category_value: "Fermented Milk Products Based Indian Sweets",
      level_value: "18.1.1.3",
    },
    {
      category_value: "Other Composite Milk Products Based Indian Sweets",
      level_value: "18.1.1.4",
    },
    {
      category_value: "Cereal based sweets",
      level_value: "18.1.2.1",
    },
    {
      category_value: "Pulses based sweets",
      level_value: "18.1.2.2",
    },
    {
      category_value: "Starch based sweets",
      level_value: "18.1.2.3",
    },
  ]

  return {
    type: SET_FOOD_CATEGORIES,
    payload: food_categories,
  }
}

export const updateCategory = (category) => {
  return async (dispatch) => {
    let response = await apis.updateContent(category)
    if ("success" in response) {
      dispatch(getCategories())
    }
  }
}

export const deleteCategory = (category) => {
  return async (dispatch) => {
    console.log("Delete category called")
    let response = await apis.deleteContent(category)
    console.log("category delete response: ", response)
    if ("success" in response) {
      dispatch(getCategories())
    }
  }
}
