/*  
    TYPES
        fields 
            - textfield 
            - dropdown 
            - dynamiclist 
            - draggableList
            - radiogroup
            - checkboxgroup
            - fileupload 
            - info_text

        variants
            - text              (applicable for textfield)
            - number            (applicable for textfield)

        Validators: 
            - image             (applicable for fileupload)
            - pdf               (applicable for fileupload)
            - fssai_lic_no      (applicable for textfield)
            - ins_number        (applicable for textfield)
            - compound_list     (applicable for textfield)

    STEP OBJECTS 
        <step_name>
            - title
            - title_info
            - content 
            - filters
            - layout

    RULES FOR FIELDS
        Common attributes for fields
            - all fields will have the display property set to true by default 
            - all fields have the required property set to false by default unless specified by stepgen users
            - all fields have a common property indicating whether the field is common for all categories 
            - all fields have a categories property indicating where the field appears 
                - system refers to this incase the common property is set to false, to know in which category to render the field
                - in-case common is set to true, categories will be empty 
            - all fields have a categoriesHide property indicating where the field is to be hidden
            - all fields have a handler property indicating the name of the handler function to be called to handle trigger events
            - all fields have a filters object property which contains the category specific attributes for fields 
                - every filter is a list of objects that contain a categories property and a values property which holds the category specific attribute values for the field
                
        Default attributes for all fields
            - default values are provided for label and layout fields 
            - default file-types are provided for any fileuploads 
            - default variants are provided for all textfields 
            - default validators will be provided for all textfields 
                - if validator field is set to null then input is not validated 
        
        Textfields
            - textfields and fileuploads will have a variant property 

        Dropdown
            - if the objects are provided in dropdown options instead of string values then the options are category specific                                                                                               

        Dynamic Lists
            - dynamiclists will have a fields property 
            - dynamiclists will have a displayState property to manage the display state of individual fields within the list 
*/
import { insDropdown } from "./Utils/insInfo.js"
import { allergenOptions } from "./constants/allergenOptions"

export const fields = {
  TEXTFIELD: "TEXTFIELD",
  DROPDOWN: "DROPDOWN",
  DRAGGABLE_LIST: "DRAGGABLE_LIST",
  DYNAMICLIST: "DYNAMICLIST",
  RADIOGROUP: "RADIOGROUP",
  CHECKBOXGROUP: "CHECKBOXGROUP",
  FILEUPLOAD: "FILEUPLOAD",
  INFO_TEXT: "INFO_TEXT",
}

export const variants = {
  // For textfields
  TEXT: "TEXT",
  NUMBER: "NUMBER",

  // for info_text fields
  FIELD_LABEL: "FIELD_LABEL",
  FIELD_LABEL_SM: "FIELD_LABEL_SM",
  WARNING_MESSAGE: "WARNING_MESSAGE",
  INFO_MESSAGE: "INFO_MESSAGE",
  INFO_BANNER: "INFO_BANNER",
}

export const validators = {
  IMAGE: "IMAGE",
  PDF: "PDF",
  DATE: "DATE",
  FSSAI_LICENSE: "FSSAI_LICENSE",
  INS_NUMBER: "INS_NUMBER",
  COMPOUND_LIST: "COMPOUND_LIST",
  PHONE_NUMBER: "PHONE_NUMBER",
  EMAIL: "EMAIL",
  PAN_NUMBER: "PAN_NUMBER",
  GST_NUMBER: "GST_NUMBER",
  INGREDIENT_NAME: "INGREDIENT_NAME",
}

export const step_names = {
  NAME_OF_FOOD: "name_of_food",
  INGREDIENTS_AND_ADDITIVES: "ingredients_and_additives",
  ALLERGENS: "allergens",
  DECLARATIONS: "declarations",
  NUTRITION_INFORMATION: "nutrition_information",
  MANUFACTURER_DETAILS: "manufacturer_details",
  BATCH_IDENTIFICATION: "batch_identification",
  USAGE_INSTRUCTIONS: "usage_instructions",
  MANDATORY_DECLARATIONS: "mandatory_declarations",
  BRAND_INFORMATION: "brand_information",
}

// S3 keys for banner images
const fortified_details_key = "fortified_dimensions.png"
const jaivik_bharat_details_key = "jaivik_bharat_details.png"
const fssai_logo_details_key = "fssai_logo_details.png"
const transfat_free_logo = "ftransfat.png"

// The label schema
export const steps = {
  name_of_food: {
    title: "Name of food",
    content: {
      flavour: {
        type: fields.TEXTFIELD,
        label: "Type / Flavour / Class",
        variant: variants.TEXT,
        validator: null,
        value: "",
        display: true,
        required: true,
        common: true,
        categories: [],
        categoriesHide: [],
      },
      food_name: {
        type: fields.DROPDOWN,
        label: "Food Name",
        // variant: variants.TEXT,
        value: "",
        options: [
          {
            categories: ["1", "1.1", "1.1.1", "1.1.1.1"],
            values: [
              "Buffalo Milk",
              "Cow Milk",
              "Goat Milk",
              "Sheep Milk",
              "Camel Milk",
              "Mixed Milk",
              "Standardised Milk",
              "Toned Milk",
              "Double Toned Milk",
              "Skimmed Milk",
              "Full Cream Milk",
              "Raw Milk",
              "Pasteurised Milk",
              "Boiled Milk",
            ],
          },
          {
            categories: ["2.1", "2.1.1", "2.1.2"],
            values: [
              "Refined Oil",
              "Blended Oil",
              "Ghee",
              "Cold Pressed",
              "Filtered",
            ],
          },
          {
            categories: ["6.1"],
            values: [
              "Whole Wheat",
              "Broken Wheat",
              "Durum Wheat",
              "Bulgar Wheat",
              "Buckwheat",
              "Corn (Maize)",
              "Sorghum (Jowar)",
              "Pearl Barley (Jau)",
              "Whole Oat Groats",
              "Steel Cut Oats",
              "Rolled Oats",
              "Instant Oats",
              "White Quinoa",
              "Red Quinoa",
              "Black Quinoa",
              "Tri Color/Rainbow Quinoa",
              "Couscous",
              "Finger Millet (Ragi)",
              "Pearl Millet (Bajra)",
              "Foxtail Millet",
              "Little Millet",
              "Kodo Millet",
              "Proso Millet",
              "Barnyard Millet",
              "Browntop Millet",
              "Amaranth",
              "Lentil (Masur)",
              "Black gram (Urad)",
              "Green gram (Moong)",
              "Bengal gram Chana or Chickpea or Kabuli chana or Chhole or Green Chickpea (Hara chana)",
              "Red gram (Arhar)",
              "Horse gram (Kulthi)",
              "Field bean (Black, Brown, White)",
              "Peas dry (Matar)",
              "Soyabean",
              "Kidney beans (Rajma)",
              "Blackeyed beans (lobhia)",
              "Blackeyed white beans (lobhia)",
              "Moth bean (matki)",
              "Brown Rice (De husked)",
              "Milled Rice",
              "Parboiled brown (de husked) rice",
              "Milled parboiled rice",
              "Basmati Rice",
              "Black Rice",
              "Red Rice",
              "Broken Rice",
            ],
          },
          {
            categories: ["6.2", "6.2.1"],
            values: [
              "Whole Wheat Flour",
              "Proten rich (paushtik) Flour ",
              "Refined Wheat (Maida)",
              "Proten rich (paushtik) Maida",
              "Durum wheat Atta",
              "Semolina (Suji)",
              "Buckwheat (Kuttu) Flour",
              "Besan",
              "Barley Flour",
              "Corn (Maize)",
              "Wheat Bran",
              "Oats Bran",
              "Rice Bran",
              "Sago Flour",
              "Finger Millet (Ragi) Flour",
              "Pearl Millet (Bajra) Flour",
              "Foxtail Millet Flour",
              "Little Millet Flour",
              "Kodo Millet Flour",
              "Proso Millet Flour",
              "Barnyard Millet Flour",
              "Browntop Millet Flour",
              "Oats Flour",
              "Oats Groats",
              "Rice Flour",
              "Full fat Soyabean Flour",
              "Defatted Soyabean Flour",
              "Multigrain Atta",
            ],
          },
          {
            categories: ["6.3"],
            values: [
              "Granola",
              "Granola Bars",
              "Instant Oats",
              "Quick cooking Oats",
              "Oatmeal",
              "Cornflakes",
              "Multigrain Cereals",
              "Muesli",
              "Wheatflates",
              "Ragi flakes",
              "Chivda",
              "Popcorn",
            ],
          },
          {
            categories: ["11.5"],
            values: ["Honey"],
          },
          {
            categories: ["12.2", "12.2.1"],
            values: [
              "Basil",
              "Celery",
              "Coriander (Dhania)",
              "Chives",
              "Dill",
              "Mint",
              "Parsley",
              "Oregano",
              "Sage",
              "Tarragon",
              "Thyme",
              "Neem",
              "Ginkgo",
              "Ginseng Root",
              "Allspice",
              "Asafoetida (Hing)",
              "Anise",
              "Annatto",
              "Aniseed (Saunf)",
              "Ajwain (Bishop's Weed)",
              "Badian Seed",
              "Bay Leaf (Tej Patta)",
              "Capers",
              "Caraway (Shahjira)",
              "Cardamom (Elaichi)",
              "Large Cardamom (Badielaichi)",
              "Cinnamon (Dalchini)",
              "Cassia (Taj)",
              "Cloves (Laung)",
              "Cumin (Jeera)",
              "Cumin Black (Kalonji)",
              "Curry Powder",
              "Dried Mango (Amchur)",
              "Fennel (Saunf)",
              "Fenugreek (Methi)",
              "Garlic (Lahsun)",
              "Ginger (Adrak)",
              "Galangal",
              "Horse Radish",
              "Juniper Berries",
              "Kokum",
              "Liquorice (Mulethi)",
              "Lavender",
              "Lemon Grass",
              "Mace (Jaipatri)",
              "Mustard (Sarson)",
              "Mustard (Rai)",
              "Nigella Seeds",
              "Nutmeg (Jaiphal)",
              "Oregano",
              "Paprika",
              "Peppers Black (Kalimirch)",
              "Light Black Pepper",
              "Pepper White",
              "Pimento",
              "Pomegranate Seed (Anardana)",
              "Poppy-Seed (Khaskhas)",
              "Rosemary",
              "Saffron (Kesar)",
              "Sumac",
              "Sweet Flag",
              "Star Anise",
              "Tamarind (Imli)",
              "Turmeric (Haldi)",
              "Vanilla",
              "Winter Leek",
              "Jamaican Pepper",
              "Vanilla Beans",
              "Vanilla Pods",
              "Stone Flower",
              "Cinnamon Flower",
              "Chilli Seasoning ",
              "Curry Roux",
              "Mixed Spices",
              "Chilli Paste",
              "Curry Paste",
              "Curry Powder",
              "Sambar Masala",
              "Pav Bhaji Masala",
              "Kitchen King Masala",
              "Rasam Masala",
              "Chole Masala",
              "Shahi Paneer Masala",
              "Others",
            ],
          },
        ],
        validator: null,
        display: true,
        required: true,
        common: true,
        categories: [],
        categoriesHide: [],
      },
      other_info: {
        type: fields.TEXTFIELD,
        label: "Other info",
        variant: variants.TEXT,
        validator: null,
        value: "",
        display: true,
        required: false,
        common: true,
        categories: [],
        categoriesHide: [],
      },
      brand_logo: {
        type: fields.FILEUPLOAD,
        label: "Upload Brand Logo",
        validator: validators.IMAGE,
        value: "",
        display: true,
        required: false,
        common: true,
        categories: [],
        categoriesHide: [],
      },
    },
    filters: {
      food_name: [
        {
          categories: ["1.1.1.1"],
          values: {
            type: fields.DROPDOWN,
            options: [
              "Buffalo Milk",
              "Cow Milk",
              "Goat Milk",
              "Sheep Milk",
              "Camel Milk",
              "Mixed Milk",
              "Standardised Milk",
              "Toned Milk",
              "Double Toned Milk",
              "Skimmed Milk",
              "Full Cream Milk",
              "Raw Milk",
              "Pasteurised Milk",
              "Boiled Milk",
            ],
          },
        },
        {
          categories: ["2.1", "2.1.1", "2.1.2"],
          values: {
            type: fields.DROPDOWN,
            options: [
              "Refined Oil",
              "Blended Oil",
              "Ghee",
              "Cold Pressed",
              "Filtered",
            ],
          },
        },
        {
          categories: ["6.1"],
          values: {
            type: fields.DROPDOWN,
            options: [
              "Whole Wheat",
              "Broken Wheat",
              "Durum Wheat",
              "Bulgar Wheat",
              "Buckwheat",
              "Corn (Maize)",
              "Sorghum (Jowar)",
              "Pearl Barley (Jau)",
              "Whole Oat Groats",
              "Steel Cut Oats",
              "Rolled Oats",
              "Instant Oats",
              "White Quinoa",
              "Red Quinoa",
              "Black Quinoa",
              "Tri Color/Rainbow Quinoa",
              "Couscous",
              "Finger Millet (Ragi)",
              "Pearl Millet (Bajra)",
              "Foxtail Millet",
              "Little Millet",
              "Kodo Millet",
              "Proso Millet",
              "Barnyard Millet",
              "Browntop Millet",
              "Amaranth",
              "Lentil (Masur)",
              "Black gram (Urad)",
              "Green gram (Moong)",
              "Bengal gram Chana or Chickpea or Kabuli chana or Chhole or Green Chickpea (Hara chana)",
              "Red gram (Arhar)",
              "Horse gram (Kulthi)",
              "Field bean (Black, Brown, White)",
              "Peas dry (Matar)",
              "Soyabean",
              "Kidney beans (Rajma)",
              "Blackeyed beans (lobhia)",
              "Blackeyed white beans (lobhia)",
              "Moth bean (matki)",
              "Brown Rice (De husked)",
              "Milled Rice",
              "Parboiled brown (de husked) rice",
              "Milled parboiled rice",
              "Basmati Rice",
              "Black Rice",
              "Red Rice",
              "Broken Rice",
            ],
          },
        },
        {
          categories: ["6.2", "6.2.1"],
          values: {
            type: fields.DROPDOWN,
            options: [
              "Whole Wheat Flour",
              "Proten rich (paushtik) Flour ",
              "Refined Wheat (Maida)",
              "Proten rich (paushtik) Maida",
              "Durum wheat Atta",
              "Semolina (Suji)",
              "Buckwheat (Kuttu) Flour",
              "Besan",
              "Barley Flour",
              "Corn (Maize)",
              "Wheat Bran",
              "Oats Bran",
              "Rice Bran",
              "Sago Flour",
              "Finger Millet (Ragi) Flour",
              "Pearl Millet (Bajra) Flour",
              "Foxtail Millet Flour",
              "Little Millet Flour",
              "Kodo Millet Flour",
              "Proso Millet Flour",
              "Barnyard Millet Flour",
              "Browntop Millet Flour",
              "Oats Flour",
              "Oats Groats",
              "Rice Flour",
              "Full fat Soyabean Flour",
              "Defatted Soyabean Flour",
              "Multigrain Atta",
            ],
          },
        },
        {
          categories: ["6.3"],
          values: {
            type: fields.DROPDOWN,
            options: [
              "Granola",
              "Granola Bars",
              "Instant Oats",
              "Quick cooking Oats",
              "Oatmeal",
              "Cornflakes",
              "Multigrain Cereals",
              "Muesli",
              "Wheatflates",
              "Ragi flakes",
              "Chivda",
              "Popcorn",
            ],
          },
        },
        {
          categories: ["11.5"],
          values: {
            type: fields.DROPDOWN,
            options: ["Honey"],
          },
        },
        {
          categories: ["12.2", "12.2.1"],
          values: {
            type: fields.DROPDOWN,
            options: [
              "Basil",
              "Celery",
              "Coriander (Dhania)",
              "Chives",
              "Dill",
              "Mint",
              "Parsley",
              "Oregano",
              "Sage",
              "Tarragon",
              "Thyme",
              "Neem",
              "Ginkgo",
              "Ginseng Root",
              "Allspice",
              "Asafoetida (Hing)",
              "Anise",
              "Annatto",
              "Aniseed (Saunf)",
              "Ajwain (Bishop's Weed)",
              "Badian Seed",
              "Bay Leaf (Tej Patta)",
              "Capers",
              "Caraway (Shahjira)",
              "Cardamom (Elaichi)",
              "Large Cardamom (Badielaichi)",
              "Cinnamon (Dalchini)",
              "Cassia (Taj)",
              "Cloves (Laung)",
              "Cumin (Jeera)",
              "Cumin Black (Kalonji)",
              "Curry Powder",
              "Dried Mango (Amchur)",
              "Fennel (Saunf)",
              "Fenugreek (Methi)",
              "Garlic (Lahsun)",
              "Ginger (Adrak)",
              "Galangal",
              "Horse Radish",
              "Juniper Berries",
              "Kokum",
              "Liquorice (Mulethi)",
              "Lavender",
              "Lemon Grass",
              "Mace (Jaipatri)",
              "Mustard (Sarson)",
              "Mustard (Rai)",
              "Nigella Seeds",
              "Nutmeg (Jaiphal)",
              "Oregano",
              "Paprika",
              "Peppers Black (Kalimirch)",
              "Light Black Pepper",
              "Pepper White",
              "Pimento",
              "Pomegranate Seed (Anardana)",
              "Poppy-Seed (Khaskhas)",
              "Rosemary",
              "Saffron (Kesar)",
              "Sumac",
              "Sweet Flag",
              "Star Anise",
              "Tamarind (Imli)",
              "Turmeric (Haldi)",
              "Vanilla",
              "Winter Leek",
              "Jamaican Pepper",
              "Vanilla Beans",
              "Vanilla Pods",
              "Stone Flower",
              "Cinnamon Flower",
              "Chilli Seasoning ",
              "Curry Roux",
              "Mixed Spices",
              "Chilli Paste",
              "Curry Paste",
              "Curry Powder",
              "Sambar Masala",
              "Pav Bhaji Masala",
              "Kitchen King Masala",
              "Rasam Masala",
              "Chole Masala",
              "Shahi Paneer Masala",
              "Others",
            ],
          },
        },
      ],
      flavour: [
        {
          categories: ["2.1", "2.1.1", "2.1.2"],
          values: {
            type: fields.DROPDOWN,
            options: [],
          },
        },
        {
          categories: ["6.1"],
          values: {
            type: fields.TEXTFIELD,
          },
        },
        {
          categories: ["6.2", "6.2.1"],
          values: {
            type: fields.TEXTFIELD,
          },
        },
        {
          categories: ["6.3"],
          values: {
            type: fields.TEXTFIELD,
          },
        },
        {
          categories: ["11.5"],
          values: {
            type: fields.DROPDOWN,
            options: [
              "Wild Forest",
              "Clover",
              "Sidr",
              "Acacia",
              "Manuka",
              "Multifloral",
              "Litchi",
              "Jamun",
              "Kashmiri",
            ],
          },
        },
        {
          categories: ["12.2", "12.2.1"],
          values: {
            display: false,
          },
        },
      ],
    },

    layout: [["food_name", "flavour"], ["brand_logo"], ["other_info"]],
  },

  ingredients_and_additives: {
    title: "Ingredients and additives",
    content: {
      single_ingredient_label_text: {
        type: fields.INFO_TEXT,
        variant: variants.FIELD_LABEL,
        value: "Is this a single ingredient product?",
        common: true,
        display: true,
        categories: [],
        categoriesHide: [],
      },
      is_single_ingredient: {
        type: fields.RADIOGROUP,
        common: true,
        // label: [],
        options: ["yes", "no"],
        value: [],
        display: true,
        required: true,
        categories: [],
        categoriesHide: [],
      },
      ingredient_list: {
        type: fields.DYNAMICLIST,
        label: "Ingredients",
        display: true,
        common: true,
        categories: [],
        categoriesHide: [],
        value: [],
        displayState: [],
        fields: {
          ingredient_name: {
            type: fields.TEXTFIELD,
            label: "Ingredient Name",
            variant: variants.TEXT,
            validator: null,
            value: "",
            display: true,
            required: false,
          },
          proportion: {
            type: fields.TEXTFIELD,
            label: "Proportion (%)",
            variant: variants.NUMBER,
            validator: null,
            value: "",
            display: true,
            required: false,
          },
          is_compound: {
            type: fields.RADIOGROUP,
            label: "Compound Ingredient",
            options: ["yes", "no"],
            value: "",
            display: true,
            required: false,
          },
          compound_list: {
            type: fields.TEXTFIELD,
            label: "Compound list",
            variant: variants.TEXT,
            validator: validators.COMPOUND_LIST,
            value: "",
            display: true,
            required: false,
          },
        },
      },

      disclaimer_1: {
        type: fields.INFO_TEXT,
        variant: variants.INFO_MESSAGE,
        value: `
                    Other than by direct addition, an additive may be present in a food as a result of carry-over
                    from a raw material or ingredient used to produce the food. Make sure to add them too,
                    provided- </br> 
                    <ul style="padding-left: 25px;"> 
                        <li> The additive is acceptable for use in the raw material and ingredient as per regulations. </li>
                        <li> 
                            The amount of the additive in raw material and ingredient does not exceed the
                            maximum level specified in regulation 
                        </li>
                    </ul>
                `,
        common: true,
        display: true,
        categories: [],
        categoriesHide: [],
      },

      label_text_1: {
        type: fields.INFO_TEXT,
        variant: variants.FIELD_LABEL,
        value: "Additives",
        common: true,
        display: true,
        categories: [],
        categoriesHide: [],
      },

      additives: {
        type: fields.DYNAMICLIST,
        // label: "Additives",
        display: true,
        common: true,
        categories: [],
        categoriesHide: ["1.2.1.1", "1.4.1", "4.1.1.1", "4.2.1.1"],
        value: [],
        displayState: [],
        fields: {
          class_name: {
            type: fields.DROPDOWN,
            label: "Class Name",
            options: [
              "Acidity Regulator",
              "Anticaking Agent",
              "Antifoaming Agent",
              "Antioxidant",
              "Artificial Sweetener",
              "Bleaching Agent",
              "Bulking Agent",
              "Carbonating Agent",
              "Clarifying Agent",
              "Colour",
              "Colour retention agent",
              "Dispersing Agent",
              "Dough Conditioner",
              "Emulsifier",
              "Firming Agent",
              "Flavour Enhancer",
              "Flour Treatment Agent",
              "Foaming Agent",
              "Freezant",
              "Gelling Agent",
              "Glazing Agent",
              "Humectant",
              "Flavouring Agent",
              "Packaging Gas",
              "Polyol",
              "Preservatives",
              "Propellant",
              "Raising Agent",
              "Sequestrant",
              "Stabiliser",
              "Thickener",
            ],
            value: "",
            display: true,
            required: false,
          },
          ins_type: {
            type: fields.DROPDOWN,
            label: "INS Type",
            options: ["INS", "E"],
            value: "",
            display: true,
            required: false,
          },
          ins_number: {
            type: fields.DROPDOWN,
            label: "INS Number",
            variant: variants.NUMBER,
            validator: validators.INS_NUMBER,
            options: insDropdown,
            value: "",
            display: true,
            required: false,
          },
          additive_nature: {
            type: fields.DROPDOWN,
            label: "Additive Nature",
            options: ["Natural", "Nature Identical", "Artificial"],
            value: "",
            display: true,
            required: false,
          },
          common_name: {
            type: fields.TEXTFIELD,
            label: "Common Name",
            value: "",
            validator: null,
            variant: variants.TEXT,
            display: true,
            required: false,
          },
        },
      },

      ingredients_order: {
        type: fields.DRAGGABLE_LIST,
        label: "Drag to order ingredients in descending order",
        value: [],
        common: true,
        display: true,
        categories: [],
        categoriesHide: [],
      },
    },
    filters: {},
    layout: [
      ["single_ingredient_label_text"],
      ["is_single_ingredient"],
      ["ingredient_list"],
      ["label_text_1", "disclaimer_1"],
      ["additives"],
      ["ingredients_order"],
    ],
  },

  allergens: {
    title: "Allergen information",
    title_info: {
      value:
        "Oils derived from these allergy causing ingredients and raw agricultural commodities are EXEMPT from declaring these allergens.",
      bannerTitle: "",
    },
    content: {
      field_label_1: {
        type: fields.INFO_TEXT,
        variant: variants.FIELD_LABEL,
        value: "Contains no allergens",
        common: true,
        display: true,
        categories: [],
        categoriesHide: [],
      },
      no_allergens: {
        type: fields.CHECKBOXGROUP,
        label: "",
        options: ["no allergens"],
        value: [],
        display: true,
        required: false,
        common: true,
        categories: [],
        categoriesHide: [],
      },
      field_label_2: {
        type: fields.INFO_TEXT,
        variant: variants.FIELD_LABEL,
        value: "Contains",
        common: true,
        display: true,
        categories: [],
        categoriesHide: [],
      },
      contains: {
        type: fields.CHECKBOXGROUP,
        label: "",
        options: allergenOptions,
        value: [],
        display: true,
        required: false,
        common: true,
        categories: [],
        categoriesHide: [],
      },
      field_label_3: {
        type: fields.INFO_TEXT,
        variant: variants.FIELD_LABEL,
        value: "May contain",
        common: true,
        display: true,
        categories: [],
        categoriesHide: [],
      },
      may_contain: {
        type: fields.CHECKBOXGROUP,
        label: "",
        options: allergenOptions,
        value: [],
        display: true,
        required: false,
        common: true,
        categories: [],
        categoriesHide: [],
      },
      field_label_4: {
        type: fields.INFO_TEXT,
        variant: variants.FIELD_LABEL,
        value: "My product is",
        common: true,
        display: true,
        categories: [],
        categoriesHide: [],
      },
      product_is: {
        type: fields.DROPDOWN,
        label: "My product is",
        options: [
          "",
          "Gluten free",
          "Gluten free by nature",
          "Processed in a plant where gluten containing products are manufactured",
        ],
        value: "",
        display: true,
        required: false,
        common: true,
        categories: [],
        categoriesHide: [],
      },
    },
    filters: {},
    layout: [
      ["field_label_1"],
      ["no_allergens"],
      ["field_label_2"],
      ["contains"],
      ["field_label_3"],
      ["may_contain"],
      ["field_label_4"],
      ["product_is"],
    ],
  },

  declarations: {
    title: "Declarations",
    content: {
      disclaimer_1: {
        type: fields.INFO_TEXT,
        variant: variants.INFO_MESSAGE,
        value: `
                    The size of the veg/ non-veg logo is to be determined as follows using area of PDP- <br/>
                    <table border="1" style='margin-bottom: 15px'> 
                        <tr> 
                            <th>S.No</th> 
                            <th> Area of principal display panel in cm square. </th> 
                            <th> Minimum size of diameters of circle in mm </th> 
                            <th> Minimum size of each side of triangle in mm </th> 
                            <th> Minimum size of each side of square in mm </th> 
                        </tr>
                        <tr>
                            <th>1</th> 
                            <th> Upto 100 </th> 
                            <th> 3 </th> 
                            <th> 2.5 </th> 
                            <th> 6 </th> 
                        </tr>
                        <tr>
                            <th>2</th> 
                            <th> Above 100 to 500 </th> 
                            <th> 4 </th> 
                            <th> 3.5 </th> 
                            <th> 8 </th> 
                        </tr>
                        <tr>
                            <th>3</th> 
                            <th> Above 500 to 2500 </th> 
                            <th> 6 </th> 
                            <th> 5 </th> 
                            <th> 12 </th> 
                        </tr>
                        <tr>
                            <th>4</th> 
                            <th> Above 2500 </th> 
                            <th> 8 </th> 
                            <th> 7 </th> 
                            <th> 16 </th> 
                        </tr>
                    </table> 

                    <p> 
                        To be able to use the Vegan logo, the food business operator needs to get the necessary approval from FSSAI, 
                        post which they may use it on their products.
                    </p>
                `,
        display: true,
        common: true,
        categories: [],
        categoriesHide: [],
      },

      field_label_1: {
        type: fields.INFO_TEXT,
        variant: variants.FIELD_LABEL,
        value: "Vegetarian or non-vegetarian",
        common: true,
        display: true,
        categories: [],
        categoriesHide: [],
      },

      product_type: {
        type: fields.RADIOGROUP,
        label: "",
        options: ["veg", "non-veg", "contains egg", "vegan"],
        value: "",
        display: true,
        required: true,
        common: true,
        categories: [],
        categoriesHide: [],
      },

      field_label_2: {
        type: fields.INFO_TEXT,
        variant: variants.FIELD_LABEL,
        value: "Is your product certified organic",
        common: true,
        display: true,
        categories: [],
        categoriesHide: [],
      },

      disclaimer_2: {
        type: fields.INFO_TEXT,
        variant: variants.INFO_MESSAGE,
        value: `
                    <p>
                        Download the Jaivik logo from <a href='https://jaivikbharat.fssai.gov.in/pdf/specifications_logo.pdf'>Jaivik Bharat</a>
                    </p>
                    <p> 
                        Important points with regard to dimensions and colour to be ensured in packaging design:
                    </p>
                `,
        bannerImageKey: jaivik_bharat_details_key,
        common: true,
        display: true,
        categories: [],
        categoriesHide: [],
      },

      is_organic: {
        type: fields.RADIOGROUP,
        label: "",
        options: ["yes", "no"],
        value: "",
        display: true,
        required: false,
        common: true,
        categories: [],
        categoriesHide: [],
      },

      organic_file: {
        type: fields.FILEUPLOAD,
        label: "Upload organic certificate",
        validator: validators.PDF,
        value: "",
        display: false,
        required: true,
        common: true,
        categories: [],
        categoriesHide: [],
      },

      disclaimer_3: {
        type: fields.INFO_TEXT,
        variant: variants.INFO_MESSAGE,
        value: `
                    <p> Download the Food Fortification logo from  <a href='https://jaivikbharat.fssai.gov.in/pdf/specifications_logo.pdf'>FSSAI </a></p>
                    <p> Important points with regard to dimensions and colour to be ensured in packaging design: </p>
                `,
        bannerImageKey: fortified_details_key,
        common: true,
        display: true,
        categories: [],
        categoriesHide: [],
      },

      other_organic_logo: {
        type: fields.RADIOGROUP,
        label: "Any other Organic Logo?",
        options: ["yes", "no"],
        value: "",
        display: false,
        required: true,
        common: true,
        categories: [],
        categoriesHide: [],
      },

      organic_logo_upload: {
        type: fields.FILEUPLOAD,
        label: "Upload Organic Logo",
        validator: validators.IMAGE,
        value: "",
        display: false,
        required: false,
        common: true,
        categories: [],
        categoriesHide: [],
      },

      field_label_3: {
        type: fields.INFO_TEXT,
        variant: variants.FIELD_LABEL,
        value: "Is your product fortified?",
        display: true,
        common: true,
        categories: [],
        categoriesHide: [],
      },

      is_fortified: {
        type: fields.RADIOGROUP,
        label: "",
        options: ["yes", "no"],
        value: "",
        display: true,
        required: false,
        common: true,
        categories: [],
        categoriesHide: [],
      },
      fortification_file: {
        type: fields.FILEUPLOAD,
        label: "Upload fortification certificate",
        validator: validators.PDF,
        value: "",
        display: false,
        required: true,
        common: true,
        categories: [],
        categoriesHide: [],
      },
      fortified_with: {
        type: fields.DYNAMICLIST,
        label: "",
        display: false,
        common: true,
        categories: [],
        categoriesHide: [],
        value: [],
        displayState: [],
        fields: {
          name: {
            type: fields.DROPDOWN,
            label: "fortified with",
            value: "",
            options: [
              {
                categories: ["12.1", "12.1.1", "12.1.2"],
                values: ["Iodine (mg)", "Iron (mg)"],
              },
              {
                categories: ["2.1", "2.1.1", "2.1.2", "2.1.3"],
                values: ["Vitamin A (mcg)", "Vitamin D (mcg)"],
              },
              {
                categories: ["1.1", "1.1.1", "1.1.1.1", "1.1.1.2", "1.1.2"],
                values: [
                  "Iron (mg)",
                  "Folic Acid (mcg)",
                  "Vitamin B12 (mcg)",
                  "Zinc (mg)",
                  "Vitamin A (mcg)",
                  "Vitamin B1 (mg)",
                  "Vitamin B2 (mg)",
                  "Vitamin B3 (mg)",
                  "Vitamin B6 (mg)",
                ],
              },
              {
                categories: [
                  "6.1",
                  "6.2",
                  "6.2.1",
                  "6.2.2",
                  "6.3",
                  "6.4",
                  "6.4.1",
                  "6.4.2",
                  "6.4.3",
                ],
                values: [
                  "Iron (mg)",
                  "Folic Acid (mcg)",
                  "Vitamin B12 (mcg)",
                  "Zinc (mg)",
                  "Vitamin A (mcg)",
                  "Vitamin B1 (mg)",
                  "Vitamin B2 (mg)",
                  "Vitamin B3 (mg)",
                  "Vitamin B6 (mg)",
                ],
              },
              {
                categories: [
                  "14.1",
                  "14.1.2",
                  "14.1.2.1",
                  "14.1.2.2",
                  "14.1.2.3",
                  "14.1.2.4",
                  "14.1.3",
                  "14.1.3.1",
                  "14.1.3.2",
                  "14.1.3.3",
                  "14.1.3.4",
                  "14.1.4",
                  "14.1.4.1",
                  "14.1.4.2",
                  "14.1.4.3",
                ],
                values: ["Vitamin C (mg)"],
              },
            ],
            validator: null,
            display: true,
            required: false,
          },
          quantity: {
            type: fields.TEXTFIELD,
            label: "Quantity per 100 (g/ml)",
            value: "",
            variant: variants.TEXT,
            validator: null,
            display: true,
            required: false,
          },
          unit: {
            type: fields.DROPDOWN,
            label: "Select unit",
            options: ["μg", "IU"],
            value: "",
            display: true,
            required: false,
          },
        },
      },

      field_label_4: {
        type: fields.INFO_TEXT,
        variant: variants.FIELD_LABEL,
        value: "Is your product irradiated?",
        display: true,
        common: true,
        categories: [],
        categoriesHide: [],
      },

      is_irradiated: {
        type: fields.RADIOGROUP,
        label: "",
        options: ["yes", "no"],
        value: "",
        display: true,
        required: false,
        common: true,
        categories: [],
        categoriesHide: [],
      },

      name_of_product: {
        type: fields.TEXTFIELD,
        label: "Name of product",
        value: "",
        variant: variants.TEXT,
        validator: null,
        display: false,
        required: false,
        common: true,
        categories: [],
        categoriesHide: [],
      },

      radiation_purpose: {
        type: fields.TEXTFIELD,
        label: "Purpose of radiation",
        value: "",
        variant: variants.TEXT,
        validator: null,
        display: false,
        required: false,
        common: true,
        categories: [],
        categoriesHide: [],
      },

      license_no: {
        type: fields.TEXTFIELD,
        label: "Operating license no",
        value: "",
        variant: variants.TEXT,
        validator: variants.FSSAI_LICENSE,
        display: false,
        required: false,
        common: true,
        categories: [],
        categoriesHide: [],
      },
    },
    filters: {},
    layout: [
      ["field_label_1", "disclaimer_1"],
      ["product_type"],
      ["field_label_2", "disclaimer_2"],
      ["is_organic"],
      ["organic_file"],
      ["other_organic_logo"],
      ["organic_logo_upload"],
      ["field_label_3", "disclaimer_3"],
      ["is_fortified"],
      ["fortification_file"],
      ["fortified_with"],
      ["field_label_4"],
      ["is_irradiated", "name_of_product", "radiation_purpose", "license_no"],
    ],
  },

  nutrition_information: {
    title: "Nutrition information",
    title_info: {
      value: `
                <p style='margin-bottom: 10px'>
                    1) Unprocessed products that comprise a single ingredient
                </p>
    
                <p style='margin-bottom: 10px'> 
                    2) Processed products which the only processing they have been 
                    subjected to is maturing and that comprise a single ingredient
                </p> 

                <p style='margin-bottom: 10px'> 
                    3) Waters intended for human consumpation, including those where the only 
                    added ingredients are carbon dioxide
                </p>
                
                <p style='margin-bottom: 10px'> 
                    4)  A herb, a spice or mixtures thereof / Curry Powder except Sprinkler masala (masalas meant for direct consumption)
                </p>

                <p style='margin-bottom: 10px'> 
                    5) Salt and salt substitutes
                </p>

                <p style='margin-bottom: 10px'> 
                    6) Table top sweeteners
                </p>

                <p style='margin-bottom: 10px'> 
                    7) Coffee extracts and chicory extracts, whole or 
                    milled coffee beans and whole or milled decaffeinated coffee beans, 
                    coffee, decaffeinated coffee, soluble coffee powder, coffee chicory mixture 
                </p>

                <p style='margin-bottom: 10px'> 
                    8) Herbal and fruit infusions, tea, decaffeinated tea, instant or 
                    soluble tea or tea extract, which do not contain other added ingredients 
                    than flavourings which do not modify the nutritional value of the tea
                </p>

                <p style='margin-bottom: 10px'> 
                    9) Fermented vinegars and substitutes for vinegar, 
                    including those where the only added ingredients are flavourings
                </p>

                <p style='margin-bottom: 10px'> 
                    10) Flavourings, Food additives, Processing aids, Food enzymes, Gelatine, Yeast
                </p>

                <p style='margin-bottom: 10px'> 
                    11) Chewing gums
                </p>

                <p style='margin-bottom: 10px'> 
                    12) Alcoholic Beverages
                </p>

                <p style='margin-bottom: 10px'> 
                    13) Foods for Special Dietary Uses (FSDU), Foods for Special Medical Purposes (FSMP),
                    subject to the compliance of requirement specified in the Food Safety and Standards
                    (Health Supplements, Nutraceuticals, Food for Special Dietary Use, Food for Special
                    Medical Purpose, Functional Food and Novel Food) Regulations, 2016.
                </p>

                <p style='margin-bottom: 10px'> 
                    <b> Please note </b> - Nutritional information shall be required in the mentioned
                    categories only if a nutrition or health claim is made on the label
                </p>
            `,
      bannerTitle: "Categories exempt from nutritional labelling",
    },
    content: {
      field_label_tg: {
        type: fields.INFO_TEXT,
        variant: variants.FIELD_LABEL,
        value: "Target audience",
        common: true,
        display: true,
        categories: [],
        categoriesHide: [],
      },
      field_label_1: {
        type: fields.INFO_TEXT,
        variant: variants.FIELD_LABEL,
        value: "Serving Size Details",
        common: true,
        display: true,
        categories: [],
        categoriesHide: [],
      },
      // disclaimer_1: {
      //     type: fields.INFO_TEXT,
      //     variant: variants.INFO_MESSAGE,
      //     value: `Based on the net weight (g), information on the number of serves must be declared mandatorily on pack by the manufacturer.`,
      //     common: true,
      //     display: true,
      //     categories: [],
      //     categoriesHide: [],
      // },
      serving_size: {
        type: fields.TEXTFIELD,
        label: "Serving size",
        value: "",
        variant: variants.NUMBER,
        validator: null,
        display: true,
        required: false,
        common: true,
        categories: [],
        categoriesHide: [],
      },
      unit: {
        type: fields.DROPDOWN,
        label: "Select Unit",
        options: ["g", "ml", "l"],
        value: "",
        display: true,
        required: false,
        common: true,
        categories: [],
        categoriesHide: [],
      },
      single_ingredient_serve_size_check: {
        type: fields.RADIOGROUP,
        common: true,
        label: "Do you wish to declare serve size for your product?",
        options: ["Yes", "No"],
        value: [],
        display: false,
        required: false,
        categories: [],
        categoriesHide: [],
      },
      target_audience: {
        type: fields.DROPDOWN,
        label: "Select target audience",
        variant: variants.TEXT,
        validator: null,
        value: "",
        display: true,
        required: false,
        common: true,
        categories: [],
        categoriesHide: [],
      },
      target_audience_age: {
        type: fields.DROPDOWN,
        label: "Age groups",
        variant: variants.TEXT,
        validator: null,
        value: "",
        display: true,
        required: false,
        common: true,
        categories: [],
        categoriesHide: [],
      },
      // serving_measure: {
      //     type: fields.TEXTFIELD,
      //     label: "Serving measure",
      //     value: "",
      //     variant: variants.TEXT,
      //     validator: null,
      //     display: true,
      //     required: false,
      //     common: true,
      //     categories: [],
      //     categoriesHide: [],
      // },
      net_weight: {
        type: fields.TEXTFIELD,
        label: "Net weight",
        value: "",
        variant: variants.NUMBER,
        validator: null,
        display: true,
        required: false,
        common: true,
        categories: [],
        categoriesHide: [],
      },
      field_label_3: {
        type: fields.INFO_TEXT,
        variant: variants.FIELD_LABEL,
        value:
          "Does your product use industrial transfat free fat/oil and contains less than 0.2g per 100g or 100ml?",
        display: true,
        common: true,
        categories: [],
        categoriesHide: [],
      },
      is_transfat_free: {
        type: fields.RADIOGROUP,
        label: "",
        options: ["yes", "no"],
        value: "",
        display: true,
        required: false,
        common: true,
        categories: [],
        categoriesHide: [],
      },
      disclaimer_3: {
        type: fields.INFO_TEXT,
        variant: variants.INFO_MESSAGE,
        value: "",
        bannerImageKey: transfat_free_logo,
        common: true,
        display: true,
        categories: [],
        categoriesHide: [],
      },
      lab_report: {
        type: fields.FILEUPLOAD,
        label: "Upload lab report",
        value: "",
        validator: validators.PDF,
        display: true,
        required: false,
        common: true,
        categories: [],
        categoriesHide: [],
      },
      energy: {
        type: fields.TEXTFIELD,
        label: "Energy (kcal)/ 100g",
        value: "",
        variant: variants.TEXT,
        validator: null,
        display: true,
        required: false,
        common: true,
        categories: [],
        categoriesHide: [],
      },
      total_carbohydrates: {
        type: fields.TEXTFIELD,
        label: "Total Carbohydrates (g)/ 100g",
        value: "",
        variant: variants.TEXT,
        validator: null,
        display: true,
        required: false,
        common: true,
        categories: [],
        categoriesHide: [],
        error: "",
      },
      total_sugars: {
        type: fields.TEXTFIELD,
        label: "Total Sugars (g)/ 100g",
        value: "",
        variant: variants.TEXT,
        validator: null,
        display: true,
        required: false,
        common: true,
        categories: [],
        categoriesHide: [],
        error: "",
      },
      added_sugar: {
        type: fields.TEXTFIELD,
        label: "Added sugar (g)/ 100g",
        value: "",
        variant: variants.TEXT,
        validator: null,
        display: true,
        required: false,
        common: true,
        categories: [],
        categoriesHide: [],
        error: "",
      },
      protein: {
        type: fields.TEXTFIELD,
        label: "Protein (g)/ 100g",
        value: "",
        variant: variants.TEXT,
        validator: null,
        display: true,
        required: false,
        common: true,
        categories: [],
        categoriesHide: [],
      },
      total_fat: {
        type: fields.TEXTFIELD,
        label: "Total fat (g)/ 100g",
        value: "",
        variant: variants.TEXT,
        validator: null,
        display: true,
        required: false,
        common: true,
        categories: [],
        categoriesHide: [],
        error: "",
      },
      saturated_fat: {
        type: fields.TEXTFIELD,
        label: "Saturated fat (g)/ 100g ",
        value: "",
        variant: variants.TEXT,
        validator: null,
        display: true,
        required: false,
        common: true,
        categories: [],
        categoriesHide: [],
        error: "",
      },
      trans_fat: {
        type: fields.TEXTFIELD,
        label: "Other than naturally occurring trans fat (g)/ 100g",
        value: "",
        variant: variants.TEXT,
        validator: null,
        display: true,
        required: false,
        common: true,
        categories: [],
        categoriesHide: [],
        error: "",
      },
      cholesterol: {
        type: fields.TEXTFIELD,
        label: "Cholesterol (mg)/ 100g",
        value: "",
        variant: variants.TEXT,
        validator: null,
        display: true,
        required: false,
        common: true,
        categories: [],
        categoriesHide: [],
      },
      sodium: {
        type: fields.TEXTFIELD,
        label: "Sodium (mg)/ 100g",
        value: "",
        variant: variants.TEXT,
        validator: null,
        display: true,
        required: false,
        common: true,
        categories: [],
        categoriesHide: [],
      },
      mufa: {
        type: fields.TEXTFIELD,
        label: "MUFA (g)/ 100g",
        value: "",
        variant: variants.TEXT,
        validator: null,
        display: true,
        required: false,
        common: false,
        categories: ["2.1"],
        categoriesHide: [],
        error: "",
      },
      pufa: {
        type: fields.TEXTFIELD,
        label: "PUFA (g)/ 100g",
        value: "",
        variant: variants.TEXT,
        validator: null,
        display: true,
        required: false,
        common: false,
        categories: ["2.1"],
        categoriesHide: [],
        error: "",
      },
      omega_3_fatty_acids: {
        type: fields.TEXTFIELD,
        label: "Omega 3 fatty acid (g)/ 100g",
        value: "",
        variant: variants.TEXT,
        validator: null,
        display: true,
        required: false,
        common: false,
        categories: ["2.1"],
        categoriesHide: [],
      },
      omega_6_fatty_acids: {
        type: fields.TEXTFIELD,
        label: "Omega 6 fatty acid (g)/ 100g",
        value: "",
        variant: variants.TEXT,
        validator: null,
        display: true,
        required: false,
        common: false,
        categories: ["2.1"],
        categoriesHide: [],
      },
      Nutrition_info_upload_text: {
        type: fields.INFO_TEXT,
        variant: variants.FIELD_LABEL,
        value: "Method",
        common: true,
        display: false,
        categories: [],
        categoriesHide: [],
      },
      Nutrition_info_upload_type: {
        type: fields.RADIOGROUP,
        common: true,
        options: ["IFCT", "LAB Report"],
        value: [],
        display: false,
        required: false,
        categories: [],
        categoriesHide: [],
      },
      Nutrition_info_upload_input_field: {
        type: fields.INFO_TEXT,
        variant: variants.FIELD_LABEL,
        value: "Ingredient",
        common: true,
        display: false,
        categories: [],
        categoriesHide: [],
      },
      Nutrition_info_upload_input: {
        type: fields.DROPDOWN,
        label: "Enter Ingredient",
        options: [],
        value: "",
        display: false,
        required: false,
        common: true,
        categories: [],
        categoriesHide: [],
      },
      field_label_2: {
        type: fields.INFO_TEXT,
        variant: variants.FIELD_LABEL,
        value: "Mandatory Nutrition Information per 100g/ml",
        display: true,
        common: true,
        categories: [],
        categoriesHide: [],
      },
      disclaimer_2: {
        type: fields.INFO_TEXT,
        variant: variants.INFO_MESSAGE,
        value:
          "Declared nutrients to comply to a tolerance of maximum minus(-) 10% of the value for that nutrient at any point in the time within declared shelf life of the product.",
        common: true,
        display: true,
        categories: [],
        categoriesHide: [],
      },
      non_mandatory_nutrients: {
        type: fields.DYNAMICLIST,
        label: "Non mandatory nutrients",
        display: true,
        common: true,
        categories: [],
        categoriesHide: [],
        value: [],
        displayState: [],
        fields: {
          name: {
            type: fields.DROPDOWN,
            label: "Name",
            options: [
              "Oryzanol (mg)",
              "Dietary Fibre (g)",
              "Iron (mg)",
              "Calcium (mg)",
              "Folic Acid (mcg)",
              "Iodine (mg)",
              "Vitamin B6 (mg)",
              "Vitamin B12 (mcg)",
              "Vitamin C (mg)",
              "Zinc (mg)",
              "Mangesium (mg)",
              "Vitamin D (mcg)",
              "Vitamin K1 (mcg)",
              "Vitamin B1 (mg)",
              "Vitamin B2 (mg)",
              "Biotin (mcg)",
              "Vitamin B3 (mg)",
              "Pantothenic Acid (mg)",
              "Vitamin E (mg)",
              "Vitamin A (mcg)",
              "Selenium (mcg)",
              "Copper (mg)",
              "Potassium (mcg)",
              "Chloride (mg)",
              "Manganese (mg)",
              "Phosphorus (mg)",
              "Mufa",
              "Pufa",
              "Omega 3 fatty acids",
              "Omega 6 fatty acids",
            ],
            value: "",
            variant: variants.TEXT,
            display: true,
            required: false,
          },
          quantity: {
            type: fields.TEXTFIELD,
            label: "Quantity",
            value: "",
            variant: variants.NUMBER,
            validator: null,
            display: true,
            required: false,
          },
        },
      },
    },
    filters: {
      target_audience: [
        {
          categories: [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13",
            "14",
            "15",
            "1.1",
            "1.1.1",
            "1.1.1.1",
            "1.1.1.2",
            "1.1.2",
            "1.2",
            "1.2.1.1",
            "1.2.1",
            "1.2.2",
            "1.3",
            "1.3.1",
            "1.3.2",
            "1.4",
            "1.4.1",
            "1.4.2",
            "1.4.3",
            "1.4.4",
            "1.5",
            "1.5.1",
            "1.5.2",
            "1.6",
            "1.6.1",
            "1.6.2",
            "1.6.3",
            "1.6.4",
            "1.6.5",
            "1.6.6",
            "1.7",
            "1.8",
            "1.8.1",
            "1.8.2",
            "2.1",
            "2.1.1",
            "2.1.2",
            "2.1.3",
            "4.1.2.1",
            "6.4.1",
            "6.4.3",
            "6.5",
            "6.6",
            "6.7",
            "6.8.1",
            "6.8.2",
            "6.8.3",
            "6.8.4",
            "6.8.5",
            "6.8.6",
            "6.8.7",
            "6.8.8",
            "6.8.4.1",
            "6.8.4.2",
            "6.8.4.3",
            "12",
            "12.1",
            "12.1.1",
            "12.1.2",
            "12.2",
            "12.2.1",
            "12.2.2",
            "12.3",
            "12.4",
            "12.5",
            "12.5.1",
            "12.5.2",
            "12.6",
            "12.6.1",
            "12.6.2",
            "12.6.3",
            "12.6.4",
            "12.7",
            "12.8",
            "12.9",
            "12.9.1",
            "12.9.2",
            "12.9.2.1",
            "12.9.2.2",
            "12.9.2.3",
            "12.10",
            "14",
            "14.1",
            "14.1.1",
            "14.1.1.1",
            "14.1.1.2",
            "14.1.2",
            "14.1.2.1",
            "14.1.2.2",
            "14.1.2.3",
            "14.1.2.4",
            "14.1.3",
            "14.1.3.1",
            "14.1.3.2",
            "14.1.3.3",
            "14.1.3.4",
            "14.1.4",
            "14.1.4.1",
            "14.1.4.2",
            "14.1.4.3",
            "14.1.5",
            "14.2",
            "14.2.1",
            "14.2.2",
            "14.2.3",
            "14.2.3.1",
            "14.2.3.2",
            "14.2.3.3",
            "14.2.4",
            "14.2.5",
            "14.2.6",
            "14.2.7",
            "15",
            "15.1",
            "15.2",
            "15.3",
            "1.4.4",
            "1.6.1",
            "1.6.2",
            "1.6.3",
            "1.6.4",
            "1.6.5",
            "1.6.6",
            "1.6.2.1",
            "1.6.2.2",
            "1.6.2.3",
            "4.1",
            "4.1.2",
            "4.1.2.2",
            "4.2",
            "4.2.2",
            "4.2.2.1",
            "4.2.2.8",
            "5.1",
            "5.1.1",
            "5.1.2",
            "5.1.3",
            "5.1.4",
            "5.2",
            "5.2.1",
            "5.2.2",
            "5.2.3",
            "5.3",
            "5.4",
            "6.2",
            "6.2.1",
            "6.2.2",
            "7.1",
            "7.1.1",
            "7.1.2",
            "7.1.3",
            "7.1.4",
            "7.1.5",
            "7.1.6",
            "7.1.1.1",
            "7.1.1.2",
            "7.2",
            "7.2.1",
            "7.2.2",
            "7.2.3",
            "8.1",
            "8.1.1",
            "8.1.2",
            "8.2",
            "8.2.1",
            "8.2.2",
            "8.2.3",
            "8.2.1.1",
            "8.2.1.2",
            "8.2.1.3",
            "8.3",
            "8.3.1",
            "8.3.2",
            "8.3.3",
            "8.3.1.1",
            "8.3.1.2",
            "8.3.1.3",
            "8.4",
            "9.1",
            "9.1.1",
            "9.1.2",
            "9.2",
            "9.2.1",
            "9.2.2",
            "9.2.3",
            "9.2.4",
            "9.2.5",
            "9.2.4.1",
            "9.2.4.2",
            "9.2.4.3",
            "9.3",
            "9.3.1",
            "9.3.2",
            "9.3.3",
            "9.3.4",
            "9.4",
            "11.1",
            "11.1.1",
            "11.1.2",
            "11.1.3",
            "11.1.4",
            "11.1.5",
            "11.1.6",
            "11.1.3.1",
            "11.1.3.2",
            "11.2",
            "11.3",
            "11.4",
            "11.5",
          ],
          values: {
            type: fields.DROPDOWN,
            options: ["Infants", "Children", "Adolescents", "Adult"],
          },
        },
      ],
      target_audience_age: [
        {
          categories: [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13",
            "14",
            "15",
            "1.1",
            "1.1.1",
            "1.1.1.1",
            "1.1.1.2",
            "1.1.2",
            "1.2",
            "1.2.1.1",
            "1.2.1",
            "1.2.2",
            "1.3",
            "1.3.1",
            "1.3.2",
            "1.4",
            "1.4.1",
            "1.4.2",
            "1.4.3",
            "1.4.4",
            "1.5",
            "1.5.1",
            "1.5.2",
            "1.6",
            "1.6.1",
            "1.6.2",
            "1.6.3",
            "1.6.4",
            "1.6.5",
            "1.6.6",
            "1.7",
            "1.8",
            "1.8.1",
            "1.8.2",
            "2.1",
            "2.1.1",
            "2.1.2",
            "2.1.3",
            "4.1.2.1",
            "6.4.1",
            "6.4.3",
            "6.5",
            "6.6",
            "6.7",
            "6.8.1",
            "6.8.2",
            "6.8.3",
            "6.8.4",
            "6.8.5",
            "6.8.6",
            "6.8.7",
            "6.8.8",
            "6.8.4.1",
            "6.8.4.2",
            "6.8.4.3",
            "12",
            "12.1",
            "12.1.1",
            "12.1.2",
            "12.2",
            "12.2.1",
            "12.2.2",
            "12.3",
            "12.4",
            "12.5",
            "12.5.1",
            "12.5.2",
            "12.6",
            "12.6.1",
            "12.6.2",
            "12.6.3",
            "12.6.4",
            "12.7",
            "12.8",
            "12.9",
            "12.9.1",
            "12.9.2",
            "12.9.2.1",
            "12.9.2.2",
            "12.9.2.3",
            "12.10",
            "14",
            "14.1",
            "14.1.1",
            "14.1.1.1",
            "14.1.1.2",
            "14.1.2",
            "14.1.2.1",
            "14.1.2.2",
            "14.1.2.3",
            "14.1.2.4",
            "14.1.3",
            "14.1.3.1",
            "14.1.3.2",
            "14.1.3.3",
            "14.1.3.4",
            "14.1.4",
            "14.1.4.1",
            "14.1.4.2",
            "14.1.4.3",
            "14.1.5",
            "14.2",
            "14.2.1",
            "14.2.2",
            "14.2.3",
            "14.2.3.1",
            "14.2.3.2",
            "14.2.3.3",
            "14.2.4",
            "14.2.5",
            "14.2.6",
            "14.2.7",
            "15",
            "15.1",
            "15.2",
            "15.3",
            "1.4.4",
            "1.6.1",
            "1.6.2",
            "1.6.3",
            "1.6.4",
            "1.6.5",
            "1.6.6",
            "1.6.2.1",
            "1.6.2.2",
            "1.6.2.3",
            "4.1",
            "4.1.2",
            "4.1.2.2",
            "4.2",
            "4.2.2",
            "4.2.2.1",
            "4.2.2.8",
            "5.1",
            "5.1.1",
            "5.1.2",
            "5.1.3",
            "5.1.4",
            "5.2",
            "5.2.1",
            "5.2.2",
            "5.2.3",
            "5.3",
            "5.4",
            "6.2",
            "6.2.1",
            "6.2.2",
            "7.1",
            "7.1.1",
            "7.1.2",
            "7.1.3",
            "7.1.4",
            "7.1.5",
            "7.1.6",
            "7.1.1.1",
            "7.1.1.2",
            "7.2",
            "7.2.1",
            "7.2.2",
            "7.2.3",
            "8.1",
            "8.1.1",
            "8.1.2",
            "8.2",
            "8.2.1",
            "8.2.2",
            "8.2.3",
            "8.2.1.1",
            "8.2.1.2",
            "8.2.1.3",
            "8.3",
            "8.3.1",
            "8.3.2",
            "8.3.3",
            "8.3.1.1",
            "8.3.1.2",
            "8.3.1.3",
            "8.4",
            "9.1",
            "9.1.1",
            "9.1.2",
            "9.2",
            "9.2.1",
            "9.2.2",
            "9.2.3",
            "9.2.4",
            "9.2.5",
            "9.2.4.1",
            "9.2.4.2",
            "9.2.4.3",
            "9.3",
            "9.3.1",
            "9.3.2",
            "9.3.3",
            "9.3.4",
            "9.4",
            "11.1",
            "11.1.1",
            "11.1.2",
            "11.1.3",
            "11.1.4",
            "11.1.5",
            "11.1.6",
            "11.1.3.1",
            "11.1.3.2",
            "11.2",
            "11.3",
            "11.4",
            "11.5",
          ],
          values: {
            type: fields.DROPDOWN,
            options: [],
          },
        },
      ],
      trans_fat: [
        {
          categories: [
            "4.1.2.1",
            "6.4.1",
            "6.4.3",
            "6.5",
            "6.6",
            "6.7",
            "6.8.1",
            "6.8.2",
            "6.8.3",
            "6.8.4",
            "6.8.4.1",
            "6.8.4.2",
            "6.8.4.3",
            "6.8.5",
            "6.8.6",
            "6.8.7",
            "6.8.8",
            "12",
            "12.1",
            "12.1.1",
            "12.1.2",
            "12.2",
            "12.2.1",
            "12.2.2",
            "12.3",
            "12.4",
            "12.5",
            "12.5.1",
            "12.5.2",
            "12.6",
            "12.6.1",
            "12.6.2",
            "12.6.3",
            "12.6.4",
            "12.7",
            "12.8",
            "12.9",
            "12.9.1",
            "12.9.2",
            "12.9.2.1",
            "12.9.2.2",
            "12.9.2.3",
            "12.10",
            "14",
            "14.1",
            "14.1.1",
            "14.1.1.1",
            "14.1.1.2",
            "14.1.2",
            "14.1.2.1",
            "14.1.2.2",
            "14.1.2.3",
            "14.1.2.4",
            "14.1.3",
            "14.1.3.1",
            "14.1.3.2",
            "14.1.3.3",
            "14.1.3.4",
            "14.1.4",
            "14.1.4.1",
            "14.1.4.2",
            "14.1.4.3",
            "14.1.5",
            "14.2",
            "14.2.1",
            "14.2.2",
            "14.2.3",
            "14.2.3.1",
            "14.2.3.2",
            "14.2.3.3",
            "14.2.4",
            "14.2.5",
            "14.2.6",
            "14.2.7",
            "15",
            "15.1",
            "15.2",
            "15.3",
          ],
          values: {
            display: false,
          },
        },
      ],
      cholesterol: [
        {
          categories: [
            "4.1.2.1",
            "6.4.1",
            "6.4.3",
            "6.5",
            "6.6",
            "6.7",
            "6.8.1",
            "6.8.2",
            "6.8.3",
            "6.8.4",
            "6.8.4.1",
            "6.8.4.2",
            "6.8.4.3",
            "6.8.5",
            "6.8.6",
            "6.8.7",
            "6.8.8",
            "12",
            "12.1",
            "12.1.1",
            "12.1.2",
            "12.2",
            "12.2.1",
            "12.2.2",
            "12.3",
            "12.4",
            "12.5",
            "12.5.1",
            "12.5.2",
            "12.6",
            "12.6.1",
            "12.6.2",
            "12.6.3",
            "12.6.4",
            "12.7",
            "12.8",
            "12.9",
            "12.9.1",
            "12.9.2",
            "12.9.2.1",
            "12.9.2.2",
            "12.9.2.3",
            "12.10",
            "14",
            "14.1",
            "14.1.1",
            "14.1.1.1",
            "14.1.1.2",
            "14.1.2",
            "14.1.2.1",
            "14.1.2.2",
            "14.1.2.3",
            "14.1.2.4",
            "14.1.3",
            "14.1.3.1",
            "14.1.3.2",
            "14.1.3.3",
            "14.1.3.4",
            "14.1.4",
            "14.1.4.1",
            "14.1.4.2",
            "14.1.4.3",
            "14.1.5",
            "14.2",
            "14.2.1",
            "14.2.2",
            "14.2.3",
            "14.2.3.1",
            "14.2.3.2",
            "14.2.3.3",
            "14.2.4",
            "14.2.5",
            "14.2.6",
            "14.2.7",
            "15",
            "15.1",
            "15.2",
            "15.3",
          ],
          values: {
            display: false,
          },
        },
      ],
    },
    layout: [
      ["field_label_tg"],
      ["target_audience", "target_audience_age"],
      ["field_label_1"],
      ["serving_size", "unit", "net_weight"],
      ["single_ingredient_serve_size_check"],
      ["Nutrition_info_upload_text"],
      ["Nutrition_info_upload_type"],
      ["Nutrition_info_upload_input_field"],
      ["Nutrition_info_upload_input"],
      ["field_label_2", "disclaimer_2"],
      ["energy", "total_carbohydrates", "total_sugars"],
      ["added_sugar", "protein", "total_fat"],
      ["saturated_fat", "sodium", "cholesterol"],
      ["trans_fat"],
      ["mufa", "pufa"],
      ["omega_3_fatty_acids", "omega_6_fatty_acids"],
      ["field_label_3", "disclaimer_3"],
      ["is_transfat_free"],
      ["lab_report"],
      ["non_mandatory_nutrients"],
    ],
  },

  manufacturer_details: {
    title: "Manufacturer details",
    content: {
      manufactured_by: {
        type: fields.DYNAMICLIST,
        label: "Manufactured by",
        common: true,
        categories: [],
        categoriesHide: [],
        value: [],
        displayState: [],
        display: true,
        fields: {
          name: {
            type: fields.TEXTFIELD,
            label: "Name",
            value: "",
            variant: variants.TEXT,
            validator: null,
            display: true,
            required: false,
          },
          address: {
            type: fields.TEXTFIELD,
            label: "Address",
            value: "",
            variant: variants.TEXT,
            validator: null,
            display: true,
            required: false,
          },
          fssai_license_no: {
            type: fields.TEXTFIELD,
            label: "FSSAI license no",
            value: "",
            variant: variants.TEXT,
            validator: validators.FSSAI_LICENSE,
            display: true,
            required: false,
          },
        },
      },

      field_label_1: {
        type: fields.INFO_TEXT,
        variant: variants.FIELD_LABEL,
        value: "Packed By",
        common: true,
        display: true,
        categories: [],
        categoriesHide: [],
      },
      checkbox_packed_by: {
        type: fields.CHECKBOXGROUP,
        label: "",
        options: ["same as manufactured by"],
        value: [],
        common: true,
        display: true,
        categories: [],
        categoriesHide: [],
      },
      packed_by: {
        type: fields.DYNAMICLIST,
        label: "",
        common: true,
        categories: [],
        categoriesHide: [],
        value: [],
        displayState: [],
        display: true,
        fields: {
          name: {
            type: fields.TEXTFIELD,
            label: "Name",
            value: "",
            variant: variants.TEXT,
            validator: null,
            display: true,
            required: false,
          },
          address: {
            type: fields.TEXTFIELD,
            label: "Address",
            value: "",
            variant: variants.TEXT,
            validator: null,
            display: true,
            required: false,
          },
          fssai_license_no: {
            type: fields.TEXTFIELD,
            label: "FSSAI license no",
            value: "",
            variant: variants.TEXT,
            validator: validators.FSSAI_LICENSE,
            display: true,
            required: false,
          },
        },
      },

      field_label_2: {
        type: fields.INFO_TEXT,
        variant: variants.FIELD_LABEL,
        value: "Marketed by",
        common: true,
        display: true,
        categories: [],
        categoriesHide: [],
      },
      checkbox_marketed_by: {
        type: fields.CHECKBOXGROUP,
        label: "",
        options: ["same as manufactured by"],
        value: [],
        common: true,
        display: true,
        categories: [],
        categoriesHide: [],
      },
      marketed_by: {
        type: fields.DYNAMICLIST,
        label: "",
        common: true,
        categories: [],
        categoriesHide: [],
        value: [],
        displayState: [],
        display: true,
        fields: {
          name: {
            type: fields.TEXTFIELD,
            label: "Name",
            value: "",
            variant: variants.TEXT,
            validator: null,
            display: true,
            required: false,
          },
          address: {
            type: fields.TEXTFIELD,
            label: "Address",
            value: "",
            variant: variants.TEXT,
            validator: null,
            display: true,
            required: false,
          },
          fssai_license_no: {
            type: fields.TEXTFIELD,
            label: "FSSAI license no",
            value: "",
            variant: variants.TEXT,
            validator: validators.FSSAI_LICENSE,
            display: true,
            required: false,
          },
        },
      },

      field_label_3: {
        type: fields.INFO_TEXT,
        variant: variants.FIELD_LABEL,
        value: "Is your product imported?",
        common: true,
        display: true,
        categories: [],
        categoriesHide: [],
      },
      is_imported: {
        type: fields.RADIOGROUP,
        label: "",
        options: ["yes", "no"],
        value: "",
        display: true,
        required: false,
        common: true,
        categories: [],
        categoriesHide: [],
      },
      country_of_origin: {
        type: fields.TEXTFIELD,
        label: "Country of origin",
        value: "",
        variant: variants.TEXT,
        validator: null,
        display: false,
        required: false,
        common: true,
        categories: [],
        categoriesHide: [],
      },
      imported_by_name: {
        type: fields.TEXTFIELD,
        label: "Imported By Name",
        value: "",
        variant: variants.TEXT,
        validator: null,
        display: false,
        required: false,
        common: true,
        categories: [],
        categoriesHide: [],
      },
      info_text_1: {
        type: fields.INFO_TEXT,
        variant: variants.FIELD_LABEL,
        value: "Imported By Details",
        display: false,
        common: true,
        categories: [],
        categoriesHide: [],
      },
      imported_by_address: {
        type: fields.TEXTFIELD,
        label: "Imported By Address",
        value: "",
        variant: variants.TEXT,
        validator: null,
        display: false,
        required: false,
        common: true,
        categories: [],
        categoriesHide: [],
      },
      imported_by_license: {
        type: fields.TEXTFIELD,
        label: "Imported By license",
        value: "",
        variant: variants.TEXT,
        validator: validators.FSSAI_LICENSE,
        display: false,
        required: false,
        common: true,
        categories: [],
        categoriesHide: [],
      },
      info_text_2: {
        type: fields.INFO_TEXT,
        variant: variants.FIELD_LABEL,
        value: "Consumer care details",
        display: true,
        common: true,
        categories: [],
        categoriesHide: [],
      },
      consumer_care_phone: {
        type: fields.TEXTFIELD,
        label: "Consumer Care Phone number",
        value: "",
        variant: variants.TEXT,
        validator: validators.PHONE_NUMBER,
        display: true,
        required: false,
        common: true,
        categories: [],
        categoriesHide: [],
      },
      consumer_care_email: {
        type: fields.TEXTFIELD,
        label: "Consumer Care Email",
        value: "",
        variant: variants.TEXT,
        validator: validators.EMAIL,
        display: true,
        required: false,
        common: true,
        categories: [],
        categoriesHide: [],
      },
      consumer_care_address: {
        type: fields.TEXTFIELD,
        label: "Consumer Care Address",
        value: "",
        variant: variants.TEXT,
        validator: null,
        display: true,
        required: false,
        common: true,
        categories: [],
        categoriesHide: [],
      },
      info_text_3: {
        type: fields.INFO_TEXT,
        variant: variants.FIELD_LABEL,
        value: "Upload FSSAI logo",
        display: true,
        common: true,
        categories: [],
        categoriesHide: [],
      },
      disclaimer_1: {
        type: fields.INFO_TEXT,
        variant: variants.INFO_MESSAGE,
        value: `
                    <p> Upload FSSAI Logo along with FSSAI License number </p>
                    <p> 
                        Download fssai logo 
                        <a href='https://fssai.gov.in/knowledge-hub-logos.php?pages=2'>
                            https://fssai.gov.in/knowledge-hub-logos.php?pages=2
                        </a>
                    </p>
                `,
        bannerImageKey: fssai_logo_details_key,
        display: true,
        common: true,
        categories: [],
        categoriesHide: [],
      },
      fssai_logo: {
        type: fields.FILEUPLOAD,
        label: "",
        value: "",
        validator: validators.IMAGE,
        display: true,
        required: false,
        common: true,
        categories: [],
        categoriesHide: [],
      },
      // other_information: {
      //     type: fields.TEXTFIELD,
      //     label: "Other information",
      //     value: "",
      //     variant: variants.TEXT,
      //     validator: null,
      //     display: true,
      //     required: false,
      //     common: true,
      //     categories: [],
      //     categoriesHide: [],
      // }
    },
    filters: {},
    layout: [
      ["manufactured_by"],
      ["field_label_1", "checkbox_packed_by"],
      ["packed_by"],
      ["field_label_2", "checkbox_marketed_by"],
      ["marketed_by"],
      ["info_text_3", "disclaimer_1"],
      ["fssai_logo"],
      ["field_label_3"],
      ["is_imported", "country_of_origin"],
      ["info_text_1"],
      ["imported_by_name", "imported_by_address", "imported_by_license"],
      ["info_text_2"],
      ["consumer_care_phone", "consumer_care_email", "consumer_care_address"],
      // ['other_information']
    ],
  },

  batch_identification: {
    title: "Batch Identification and Date Marking",
    content: {
      field_label_1: {
        type: fields.INFO_TEXT,
        variant: variants.FIELD_LABEL,
        value: "Batch indentification and Date Marking",
        common: true,
        display: true,
        categories: [],
        categoriesHide: [],
      },
      // disclaimer_1: {
      //     type: fields.INFO_TEXT,
      //     variant: variants.INFO_MESSAGE,
      //     value: "A batch number or code number or lot num shall be declared on the label by the food manufacturer",
      //     common: true,
      //     display: true,
      //     categories: [],
      //     categoriesHide: []
      // },
      info_banner_1: {
        type: fields.INFO_TEXT,
        variant: variants.INFO_BANNER,
        value: `
                    <h2 style='margin-bottom: 15px'> 
                        Batch identification and date marking elements to be filled in by the manufacturer post packaging stage, in the formats mentioned below
                    </h2>
                    <p style='margin-bottom: 10px'>
                        <b> Net Quantity </b> <br/>
                        (Mention quantity by weight (g, kg, ml, l), measure or number of commodity in that package, excluding the packaging or wrapper)
                    </p>
                    <p style='margin-bottom: 10px'>
                        <b> MRP (inclusive of all taxes) </b>
                    </p>
                    <p style='margin-bottom: 10px'>
                        <b> Unit Sale Price (USP)- </b> <br/>
                        USP statement needs to be declared in close proximity to the MRP and should based on the unit used for declaring net weight eg- Rs.__ per g or Rs.__ per kg or Rs.__ per litre, etc.
                    </p>
                    <p style='margin-bottom: 10px'> 
                        <b> Batch no./ Lot no. </b>
                    </p>
                    <p style='margin-bottom: 10px'> 
                        (Date Markings - Elements Mentioned Below Should Have The Following Format- <br/>
                        Products with short shelf life upto 3 months- DD/MM/YY <br/>
                        Products with shelf life longer than 3 months- MMM/YY  or  DD/MM/YY)
                    </p>
                    <p style='margin-bottom: 10px'>
                        <b> Date of manufacturing </b> <br/>
                        (Food with shelf-life of not more than seven days, the 'date of manufacture' may not be required to be mentioned on the label)
                    </p>
                    <p style='margin-bottom: 10px'>
                        <b> Date of packing </b>
                    </p>
                    <p style='margin-bottom: 10px'>
                        <b> Expiry date </b>
                    </p>
                    <p style='margin-bottom: 10px'>
                        <b> Best before </b>
                        (optional/ additional info)
                    </p>
                `,
        common: true,
        display: true,
        categories: [],
        categoriesHide: [],
      },
      // best_before: {
      //     type: fields.TEXTFIELD,
      //     label: "Best before (optional)",
      //     value: "",
      //     validator: validators.DATE,
      //     display: true,
      //     required: false,
      //     common: true,
      //     categories: [],
      //     categoriesHide: [],
      // },
      // expiry_date: {
      //     type: fields.TEXTFIELD,
      //     label: "Expiry date",
      //     value: "",
      //     validator: validators.DATE,
      //     display: true,
      //     required: false,
      //     common: true,
      //     categories: [],
      //     categoriesHide: []
      // }
    },
    filters: {},
    layout: [
      ["field_label_1"],
      ["info_banner_1"],
      // ['expiry_date'],
      // ['best_before']
    ],
  },

  usage_instructions: {
    title: "Instructions of use",
    content: {
      general_instructions: {
        type: fields.TEXTFIELD,
        label: "Instructions of use",
        value: "",
        validator: null,
        display: true,
        required: true,
        common: true,
        categories: [],
        categoriesHide: [],
      },
      general_instructions_image: {
        type: fields.FILEUPLOAD,
        label: "Upload instructions image",
        value: "",
        validator: null,
        display: true,
        required: false,
        common: true,
        categories: [],
        categoriesHide: [],
      },
      storage_instructions: {
        type: fields.TEXTFIELD,
        label: "Storage instructions",
        value: "",
        validator: null,
        display: true,
        required: false,
        common: true,
        categories: [],
        categoriesHide: [],
      },
    },
    filters: {},
    layout: [
      ["general_instructions", "storage_instructions"],
      ["general_instructions_image"],
    ],
  },

  mandatory_declarations: {
    title: "Mandatory declarations",
    content: {
      field_label_1: {
        type: fields.INFO_TEXT,
        variant: variants.FIELD_LABEL,
        value: "Does your product contain any of the following?",
        display: true,
        common: true,
        categories: [],
        categoriesHide: [],
      },
      field_label_3: {
        type: fields.INFO_TEXT,
        variant: variants.FIELD_LABEL,
        value: "Is your product",
        display: true,
        common: true,
        categories: [],
        categoriesHide: [],
      },
      disclaimer_1: {
        type: fields.INFO_TEXT,
        variant: variants.INFO_MESSAGE,
        value:
          "The size of numerals and letters for these declarations shall not be less than 3mm based on the letter 1.",
        common: true,
        display: true,
        categories: [],
        categoriesHide: [],
      },
      annato_color: {
        type: fields.RADIOGROUP,
        label: "Annatto colour",
        options: ["yes", "no"],
        value: "no",
        display: true,
        common: true,
        categories: [],
        categoriesHide: [],
      },
      polyols: {
        type: fields.RADIOGROUP,
        label: "10% or more polyols",
        options: ["yes", "no"],
        value: "no",
        display: true,
        common: true,
        categories: [],
        categoriesHide: [],
      },
      polydextrose: {
        type: fields.RADIOGROUP,
        label: "10% or more polydextrose",
        options: ["yes", "no"],
        value: "no",
        display: true,
        common: true,
        categories: [],
        categoriesHide: [],
      },
      caffeine: {
        type: fields.RADIOGROUP,
        label: "Added caffeine",
        options: ["yes", "no"],
        value: "no",
        display: true,
        common: true,
        categories: [],
        categoriesHide: [],
      },
      isomaltulose: {
        type: fields.RADIOGROUP,
        label: "Isomaltulose",
        options: ["yes", "no"],
        value: "no",
        display: true,
        common: true,
        categories: [],
        categoriesHide: [],
      },
      isomaltulose_quantity: {
        type: fields.TEXTFIELD,
        variant: variants.NUMBER,
        validator: null,
        label: "Kcal per 100g or 100ml",
        value: "no",
        display: false,
        common: true,
        categories: [],
        categoriesHide: [],
      },
      sorbitol: {
        type: fields.RADIOGROUP,
        label: "10% or more Sorbitol and Sorbitol syrup",
        options: ["yes", "no"],
        value: "no",
        display: true,
        common: true,
        categories: [],
        categoriesHide: [],
      },
      sweeteners: {
        type: fields.RADIOGROUP,
        label: "",
        options: [
          "Aspartame (Methyl ester)",
          "Acesulfame Potassium",
          "Aspartame-Acesulfame salt",
          "Sucralose",
          "SACCHARINS",
          "Neotame",
          "Steviol Glycoside and Polyols marketed as “Table Top Sweetener”",
        ],
        value: "",
        display: true,
        common: true,
        categories: [],
        categoriesHide: [],
      },
      sweetener_purity_and_weight: {
        type: fields.TEXTFIELD,
        variant: variants.TEXT,
        label: "Purity and weight % of marker compound",
        value: "",
        display: true,
        common: true,
        validator: null,
        categories: [],
        categoriesHide: [],
      },
      low_sodium_salt: {
        type: fields.RADIOGROUP,
        label: "Low Sodium Salt",
        options: ["yes", "no"],
        value: "no",
        display: true,
        common: false,
        categories: ["12.1"],
        categoriesHide: [],
      },
      milk_product: {
        type: fields.CHECKBOXGROUP,
        label: "",
        options: ["Milk or Milk Product"],
        value: "",
        display: true,
        common: false,
        categories: ["1", "1.1", "1.1.1", "1.1.1.1"],
        categoriesHide: [],
      },
      milk_analogue: {
        type: fields.CHECKBOXGROUP,
        label: "",
        options: ["A Milk Analogue"],
        value: "",
        display: true,
        common: false,
        categories: ["1", "1.1", "1.1.1", "1.1.1.1"],
        categoriesHide: [],
      },
      milk_analogue_options: {
        type: fields.RADIOGROUP,
        label: "",
        options: [
          "Does your product contain such constituent not derived from milk that takes place of a milk constituent",
          "Does your product contain such milk constituent whose place is fully taken over by a constituent not derived from milk",
        ],
        value: "",
        display: false,
        common: false,
        categories: ["1", "1.1", "1.1.1", "1.1.1.1"],
        categoriesHide: [],
      },
      // field_label_derived_from_milk: {
      //   type: fields.INFO_TEXT,
      //   variant: variants.FIELD_LABEL_SM,
      //   value: "Contains",
      //   common: false,
      //   display: true,
      //   categories: ["1","1.1","1.1.1","1.1.1.1"],
      //   categoriesHide: [],
      // },
      derived_from_milk_option: {
        type: fields.TEXTFIELD,
        variant: variants.TEXT,
        label:
          "Contains (Blank to be filled with name of the constituent including the source)",
        value: "",
        display: false,
        common: false,
        validator: null,
        categories: ["1", "1.1", "1.1.1", "1.1.1.1"],
        categoriesHide: [],
      },
      // field_label_not_derived_from_milk: {
      //   type: fields.INFO_TEXT,
      //   variant: variants.FIELD_LABEL_SM,
      //   value: "Contains No",
      //   common: false,
      //   display: true,
      //   categories: ["1","1.1","1.1.1","1.1.1.1"],
      //   categoriesHide: [],
      // },
      not_derived_from_milk_option: {
        type: fields.TEXTFIELD,
        variant: variants.TEXT,
        label:
          "Contains no Milk (Blank to be filled with name of the constituent)",
        value: "",
        display: false,
        common: false,
        validator: null,
        categories: ["1", "1.1", "1.1.1", "1.1.1.1"],
        categoriesHide: [],
      },
      non_caloric_sweetener: {
        type: fields.RADIOGROUP,
        label:
          "any non-caloric sweetener mentioned in Food Safety and Standards (Food Products standards and Food Additive) Regulations, 2011 Declaration on label",
        options: ["yes", "no"],
        value: "no",
        display: true,
        common: true,
        categories: [],
        categoriesHide: [],
      },
      sweetener_mixture: {
        type: fields.CHECKBOXGROUP,
        label:
          "Sweeteners or mixture of sweeteners mentioned under Appendix A of Food Safety and standards (Food Products standards and Food Additive) Regulations, 2011",
        options: [
          "Aspartame",
          "Acesulfame potassium",
          "Aspartame-Acesulfame salt",
          "Saccharins",
        ],
        value: [],
        display: true,
        common: true,
        categories: [],
        categoriesHide: [],
      },
      // sweetener: {
      //     type: fields.RADIOGROUP,
      //     label: "Artificial/Non-nutritive sweetener",
      //     options: ['yes', 'no'],
      //     value: "no",
      //     display: true,
      //     common: true,
      //     categories: [],
      //     categoriesHide: [],
      // },
      // sweetener_name: {
      //     type: fields.TEXTFIELD,
      //     variant: variants.TEXT,
      //     validator: null,
      //     label: "Sweetener Name",
      //     value: "no",
      //     display: false,
      //     common: true,
      //     categories: [],
      //     categoriesHide: []
      // },
      // aspartame: {
      //     type: fields.RADIOGROUP,
      //     label: "Aspartame",
      //     options: ['yes', 'no'],
      //     value: "no",
      //     display: true,
      //     common: true,
      //     categories: [],
      //     categoriesHide: [],
      // },
      // mixture_of_sweeteners: {
      //     type: fields.RADIOGROUP,
      //     label: "Mixture of Aspartame (Methyl Ester) and Acesulfame Potassium Sweeteners",
      //     options: ['yes', 'no'],
      //     value: "no",
      //     display: true,
      //     common: true,
      //     categories: [],
      //     categoriesHide: [],
      // },
      trehalose: {
        type: fields.RADIOGROUP,
        label: "Trehalose",
        options: ["yes", "no"],
        value: "no",
        display: true,
        common: true,
        categories: [],
        categoriesHide: [],
      },
      monosodium_glutamate: {
        type: fields.RADIOGROUP,
        label: "Monosodium Glutamate",
        options: ["yes", "no"],
        value: "no",
        display: true,
        common: true,
        categories: [],
        categoriesHide: [],
      },
      plant_stanol_esters: {
        type: fields.RADIOGROUP,
        label: "Plant Stanol Esters",
        options: ["yes", "no"],
        value: "no",
        display: true,
        common: true,
        categories: [],
        categoriesHide: [],
      },
      plant_stanol_esters_quantity: {
        type: fields.TEXTFIELD,
        variant: variants.NUMBER,
        validator: null,
        label: "Quantity per 100 gm/ml",
        value: "no",
        display: false,
        common: true,
        categories: [],
        categoriesHide: [],
      },
      plant_sterols: {
        type: fields.RADIOGROUP,
        label: "Plant Sterols",
        options: ["yes", "no"],
        value: "no",
        display: true,
        common: true,
        categories: [],
        categoriesHide: [],
      },
      plant_sterols_quantity: {
        type: fields.TEXTFIELD,
        variant: variants.NUMBER,
        validator: null,
        label: "Quantity per 100 gm/ml",
        value: "",
        display: false,
        common: true,
        categories: [],
        categoriesHide: [],
      },
      dietary_fibre: {
        type: fields.RADIOGROUP,
        label: "Added Dietary Fibre (Dextrin-soluble fibre)",
        options: ["yes", "no"],
        value: "no",
        display: true,
        common: true,
        categories: [],
        categoriesHide: [],
      },
      treated_maida: {
        type: fields.RADIOGROUP,
        label: "Maida treated with improver or bleaching agents",
        options: ["yes", "no"],
        value: "no",
        display: true,
        common: false,
        categories: ["6.2.1"],
        categoriesHide: [],
      },
      dried_glucose: {
        type: fields.RADIOGROUP,
        label: "Dried glucose syrup with sulphur dioxide exceeding 40 ppm",
        options: ["yes", "no"],
        value: "no",
        display: true,
        common: false,
        categories: ["11.1.3"],
        categoriesHide: [],
      },
      fruit_squash: {
        type: fields.RADIOGROUP,
        label: "Fruit Squash containing additional sodium or potassium salt",
        options: ["yes", "no"],
        value: "no",
        display: true,
        common: false,
        categories: ["14.1.4.2", "14.1.4.3"],
        categoriesHide: [],
      },
      flavour_emulsion_and_paste: {
        type: fields.RADIOGROUP,
        label:
          "This product is a flavour emulsion and flavour paste meant for use in carbonated or non-carbonated beverages",
        options: ["yes", "no"],
        value: "no",
        display: true,
        common: false,
        categories: ["14.1.4.1", "14.1.4.2", "14.1.4.3"],
        categoriesHide: [],
      },
      cheese_coated: {
        type: fields.RADIOGROUP,
        label: "Cheese is coated/packed in food grade waxes",
        options: ["yes", "no"],
        value: "no",
        display: true,
        common: false,
        categories: ["1.6.2"],
        categoriesHide: [],
      },
      frozen_desert_oil: {
        type: fields.RADIOGROUP,
        label:
          "Frozen Desert/Frozen Confection if made with Edible Vegetable Oil",
        options: ["yes", "no"],
        value: "no",
        display: true,
        common: false,
        categories: ["1.7"],
        categoriesHide: [],
      },
      frozen_desert_fat: {
        type: fields.RADIOGROUP,
        label:
          "Frozen Desert/Frozen Confection if made with Edible Vegetable fat",
        options: ["yes", "no"],
        value: "no",
        display: true,
        common: false,
        categories: ["1.7"],
        categoriesHide: [],
      },
      common_salt_for: {
        type: fields.DROPDOWN,
        label: "Common Salt for",
        options: [
          "For iodisation",
          "For iron fortification",
          "For Animal use",
          "For Preservation and not meant for direct consumption",
          "For infant Food products",
          "For medicine",
          "For industrial use",
        ],
        value: "",
        display: true,
        common: false,
        categories: ["12.1.1"],
        categoriesHide: [],
      },
      fresh_fruits_wax: {
        type: fields.RADIOGROUP,
        label: "Fresh fruits coated with wax",
        options: ["yes", "no"],
        value: "no",
        display: true,
        common: false,
        categories: ["4.1.1"],
        categoriesHide: [],
      },
      fresh_fruits_wax_name: {
        type: fields.DROPDOWN,
        label: "Name of was used for coating",
        options: [
          "Bees wax (white)",
          "Bees wax (yellow)",
          "Carnauba wax",
          "Shellac wax",
        ],
        value: "",
        display: true,
        common: false,
        categories: ["4.1.1"],
        categoriesHide: [],
      },
      pan_masala: {
        type: fields.RADIOGROUP,
        label: "This product contains pan masala",
        options: ["yes", "no"],
        value: "no",
        display: true,
        common: false,
        categories: ["5.2"],
        categoriesHide: [],
      },
      table_top_sweetener: {
        type: fields.DROPDOWN,
        label: "This product is a Table-top Sweetener that contains",
        options: [
          "Aspartame (Methyl ester)",
          "Acesulfame Potassium",
          "Sucralose",
          "Neotame",
          "Saccharin solution",
        ],
        value: "",
        display: true,
        common: false,
        categories: ["11.6"],
        categoriesHide: [],
      },
      refined_vegetable_oil: {
        type: fields.RADIOGROUP,
        label: "Refined Vegetable Oil",
        options: ["yes", "no"],
        value: "no",
        display: true,
        common: false,
        categories: ["2.1.2"],
        categoriesHide: [],
      },
      refined_vegetable_oil_name: {
        type: fields.TEXTFIELD,
        variant: variants.TEXT,
        validator: null,
        label: "Name of oil",
        value: "",
        display: true,
        common: false,
        categories: ["2.1.2"],
        categoriesHide: [],
      },
      vanaspati: {
        type: fields.RADIOGROUP,
        label:
          "This vanaspati is made from more than 30 percent of Rice bran oil",
        options: ["yes", "no"],
        value: "no",
        display: true,
        common: false,
        categories: ["2.1.2"],
        categoriesHide: [],
      },
      disclaimer_2: {
        type: fields.INFO_TEXT,
        variant: variants.INFO_MESSAGE,
        value: `The font size of the label declaration ‘Multi-Sourced Edible Vegetable Oils’ shall
                        not be less than 3mm and should not be less than 10mm in case the net quantity of
                        the edible oil contained in packages is 5L and above.`,
        display: true,
        common: false,
        categories: ["2.1.2"],
        categoriesHide: [],
      },
      field_label_2: {
        type: fields.INFO_TEXT,
        variant: variants.FIELD_LABEL_SM,
        value: "This oil is a blended oil",
        common: false,
        display: true,
        categories: ["2.1.2"],
        categoriesHide: [],
      },
      blended_oil: {
        type: fields.RADIOGROUP,
        label: "",
        options: ["yes", "no"],
        value: "no",
        display: true,
        common: false,
        categories: ["2.1.2"],
        categoriesHide: [],
      },
      blended_oil_name_nature_1: {
        type: fields.TEXTFIELD,
        variant: variants.TEXT,
        validator: null,
        label: "Name and nature of oil",
        value: "",
        display: false,
        common: false,
        categories: ["2.1.2"],
        categoriesHide: [],
      },
      blended_oil_percent_1: {
        type: fields.TEXTFIELD,
        variant: variants.NUMBER,
        validator: null,
        label: "% by weight",
        value: "",
        display: false,
        common: false,
        categories: ["2.1.2"],
        categoriesHide: [],
      },
      blended_oil_name_nature_2: {
        type: fields.TEXTFIELD,
        variant: variants.TEXT,
        validator: null,
        label: "Name and nature of oil",
        value: "",
        display: false,
        common: false,
        categories: ["2.1.2"],
        categoriesHide: [],
      },
      blended_oil_percent_2: {
        type: fields.TEXTFIELD,
        variant: variants.NUMBER,
        validator: null,
        label: "% by weight",
        value: "",
        display: false,
        common: false,
        categories: ["2.1.2"],
        categoriesHide: [],
      },
      coffee_chicory: {
        type: fields.RADIOGROUP,
        label: "This product is a mixture of coffee and chicory",
        options: ["yes", "no"],
        value: "no",
        display: true,
        common: false,
        categories: ["14.1.5"],
        categoriesHide: [],
      },
      coffee_chicory_percent_coffee: {
        type: fields.TEXTFIELD,
        variant: variants.NUMBER,
        validator: null,
        label: "% of coffee",
        value: "",
        display: true,
        common: false,
        categories: ["14.1.5"],
        categoriesHide: [],
      },
      coffee_chicory_percent_chicory: {
        type: fields.TEXTFIELD,
        variant: variants.NUMBER,
        validator: null,
        label: "% of chicory",
        value: "",
        display: true,
        common: false,
        categories: ["14.1.5"],
        categoriesHide: [],
      },
      instant_coffee_chicory: {
        type: fields.RADIOGROUP,
        label: "This product is a mixture of instant coffee and chicory",
        options: ["yes", "no"],
        value: "no",
        display: true,
        common: false,
        categories: ["14.1.5"],
        categoriesHide: [],
      },
      instant_coffee_chicory_percent_coffee: {
        type: fields.TEXTFIELD,
        variant: variants.NUMBER,
        validator: null,
        label: "% of coffee",
        value: "",
        display: true,
        common: false,
        categories: ["14.1.5"],
        categoriesHide: [],
      },
      instant_coffee_chicory_percent_chicory: {
        type: fields.TEXTFIELD,
        variant: variants.NUMBER,
        validator: null,
        label: "% of coffee",
        value: "",
        display: true,
        common: false,
        categories: ["14.1.5"],
        categoriesHide: [],
      },
      milk_powder: {
        type: fields.RADIOGROUP,
        label:
          "This product is a milk powder / skimmed milk powder / condensed milk (sweetened and flavoured) that can be reconstituted into liquid milk",
        options: ["yes", "no"],
        value: "no",
        display: true,
        common: false,
        categories: ["1.5"],
        categoriesHide: [],
      },
    },
    filters: {
      // low_sodium_salt: [
      //     {
      //         categories:[
      //             '12.1'
      //         ],
      //         values: {
      //             display: false
      //         }
      //     }
      // ]
    },
    layout: [
      // For does your product contain
      ["field_label_1", "disclaimer_1"],
      ["annatto_color"],
      ["polyols"],
      ["polydextrose"],
      ["caffeine"],
      ["isomaltulose", "isomaltulose_quantity"],
      ["sorbitol"],
      // ['sweetener', 'sweetener_name'],
      // ['aspartame'],
      // ['mixture_of_sweeteners'],
      ["trehalose"],
      ["monosodium_glutamate"],
      ["plant_stanol_esters", "plant_stanol_esters_quantity"],
      ["plant_sterols", "plant_sterols_quantity"],
      ["dietary_fibre"],
      ["treated_maida"],
      ["dried_glucose"],
      ["fruit_squash"],
      ["flavour_emulsion_and_paste"],
      ["cheese_coated"],
      ["frozen_desert_oil"],
      ["frozen_desert_fat"],
      ["common_salt_for"],
      ["fresh_fruits_wax", "fresh_fruits_wax_name"],
      ["pan_masala"],
      ["table_top_sweetener"],
      ["refined_vegetable_oil", "refined_vegetable_oil_name"],
      ["vanaspati"],
      ["field_label_2", "disclaimer_2"],
      ["blended_oil", "blended_oil_name_nature_1", "blended_oil_percent_1"],
      ["blended_oil_name_nature_2", "blended_oil_percent_2"],
      [
        "coffee_chicory",
        "coffee_chicory_percent_coffee",
        "coffee_chicory_percent_chicory",
      ],
      [
        "instant_coffee_chicory",
        "instant_coffee_chicory_percent_coffee",
        "instant_coffee_chicory_percent_chicory",
      ],
      ["non_caloric_sweetener"],
      ["sweetener_mixture"],

      // For is your product
      ["field_label_3"],
      ["sweeteners"],
      ["sweetener_purity_and_weight"],
      ["low_sodium_salt"],
      ["milk_product"],
      ["milk_analogue"],
      ["milk_analogue_options"],
      ["derived_from_milk_option"],
      ["not_derived_from_milk_option"],
      ["milk_powder"],
    ],
  },

  brand_information: {
    title: "Brand Information",
    content: {
      brand_info: {
        type: fields.TEXTFIELD,
        label: "Details about the brand",
        value: "",
        validator: null,
        display: true,
        required: true,
        common: true,
        categories: [],
        categoriesHide: [],
      },
      brand_info_image: {
        type: fields.FILEUPLOAD,
        label: "Upload brand information related images",
        value: "",
        validator: null,
        display: true,
        required: false,
        common: true,
        categories: [],
        categoriesHide: [],
      },
    },
    filters: {},
    layout: [["brand_info"], ["brand_info_image"]],
  },
}
