import { FieldType } from "../constants/enum"

const packDimension = (output) => ({
  name: "",
  description: "",
  fields: [
    {
      title: "Height",
      layout: 50,
      type: FieldType.NUMBER,
      description: "",
      units: [],
      items: [],
      key: "height",
      visible: true,
      customStyle: "[&>label]:flex",
      required: true,
      default: 0,
    },
    {
      title: "Width",
      layout: 50,
      type: FieldType.NUMBER,
      description: "",
      units: [],
      items: [],
      key: "width",
      required: output.pack_shape === "rectangle",
      visible: output.pack_shape === "rectangle",
      customStyle: "[&>label]:flex",
      default: 0,
    },
    {
      title: "Circumference",
      type: FieldType.NUMBER,
      layout: 50,
      description: "",
      units: [],
      items: [],
      key: "circumference",
      required: output.pack_shape === "cylinderical",
      visible: output.pack_shape === "cylinderical",
      customStyle: "[&>label]:flex",
      default: 0,
    },
  ],
})

export default packDimension
