import { FieldType } from "../constants/enum"
import { BASE_ROUTES } from "../constants/routes"

const useStorageInstructions = (schemaData, output) => {
  const storageInstructions = [
    "After opening transfer contents in an airtight container",
    "Always store in deep freezer",
    "Keep away from direct sunlight",
    "Keep refrigerated",
    "Refrigerate after opening",
    "Store in a cool and dry place",
    "Store in a cool, dry and hygienic space",
    "Store in a dry place. Away from heat and light",
  ]


  const instructions = (
    schemaData?.storage_instructions?.map(
      (v) =>
        // key: v.id,
        // value:
        v.instruction,
    ) || []
  ).reverse()
  return {
    name: "",
    description: "",
    fields: [
      {
        title: "Instructions of Use",
        type: FieldType.LONG_TEXT,
        multiLine: true,
        reference: "",
        layout: 12,
        description: "",
        list: [],
        items: [],
        required: false,
        key: "instructions_of_use",
        translate_key: "translated_instructions_of_use",
        translation: true,
        country: output?.country,
        maxLength: 2500,
        visible: true,
        disabled: false,
        translation: true,
      },
      {
        title:
          "Upload Instructions Image (only .jpg and .png images are allowed upto 500 kb in size)",
        type: FieldType.FILE,
        multiple: true,
        reference: "",
        validators: "",
        layout: 12,
        description: "",
        list: [],
        items: [],
        allowedFileType: ["image/png", "image/jpeg"],
        maxAllowedSize: 500 * 1024,
        key: "instruction_image",
        visible: true,
      },
      {
        title: "Storage Instructions",
        type: FieldType.DROPDOWN,
        maxLength: 200,
        reference: "",
        validators: "",
        required: true,
        layout: 6,
        description: "",
        list: [],
        translation: true,
        country: output?.country,
        items: [
          ...instructions,
          ...storageInstructions,
          // "Store in a cool and dry place",
          // "Keep away from direct sunlight",
          // "Keep refrigerated",
          // "Store in a dry place. Away from heat and light",
          // "Store in a cool, dry and hygienic space",
          // "Always store in deep freezer",
          // "Refrigerate after opening",
          // "After opening transfer contents in an airtight container",
        ],
        key: "storage_instructions_text",
        translate_key: "translate_storage_instructions_text",
        dropdownIcon: true,
        maxHeight: "160px",
        maxWidth: "380px",
        visible: true,
      },
      {
        title: " ",
        type: FieldType.TEXT_BUTTON,
        validator: "",
        layout: 6,
        description: "",
        list: [],
        items: [],
        key: "Create new storage instruction",
        visible: true,
        help: "",
        link: `${BASE_ROUTES.PPF}/business-profile`,
      },
    ],
  }
}

export default useStorageInstructions
