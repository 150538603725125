import React from "react"
import { Dialog, Transition } from "@headlessui/react"
import { Fragment } from "react"
import { CrossIcon } from "../../assets/icons/Icons"
import QkFieldInput from "../../qkFormV2/QkFieldInput"
import Spinner from "../../common/Icons/Spinner"

export default function QkModal(props) {
  const parentData = props.data
  const data = props.data.component
  return (
    <>
      <Transition appear show={props.isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-20" onClose={props.closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-lg transform overflow-y-auto overflow-x-hidden bg-white rounded-lg p-5 align-middle shadow-xl transition-all">
                  <div className="flex w-full justify-between">
                    <Dialog.Title
                      as="h3"
                      className="text-xl text-left font-bold leading-6 mt-2"
                    >
                      {data?.name}
                      <div className="w-[46px] h-[3px] rounded-[1px] mt-2 bg-[#DF6F13]" />
                    </Dialog.Title>
                    <div
                      className="p-3 cursor-pointer"
                      onClick={props.closeModal}
                    >
                      <CrossIcon />
                    </div>
                  </div>
                  <div className="grid grid-cols-12 md:grid-cols-12  mx-1 my-6 gap-6 items-start justify-center">
                    {data?.fields?.map((field) => {
                      return (
                        <QkFieldInput
                          key={field.name}
                          data={field ?? {}}
                          values={props.values ?? {}}
                          handleChange={(d) => props.handleChange(field.key, d)}
                        />
                      )
                    })}
                  </div>

                  <div className="flex justify-end">
                    <button
                      type="button"
                      className="bg-primaryyellow  text-base text-black text-sm py-3 px-4 font-bold rounded-lg inline-flex items-center"
                      onClick={props.handleAction}
                      disabled={props.loading ? true : false}
                    >
                      {props.loading ? (
                        <div className="flex items-center h-[100%] justify-center">
                          <Spinner className="w-8 h-8" />{" "}
                        </div>
                      ) : (
                        ""
                      )}
                      {parentData?.actionButtonText}
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}
