import React from "react"

const TruncatedString = ({ text, size, width }) => {
  const MAX_LENGTH = size ? size : 15

  const displayText =
    text.length > MAX_LENGTH ? `${text.substring(0, MAX_LENGTH)}...` : text

  return (
    <div
      title={text}
      style={{
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        maxWidth: width ?? "100px",
      }}
    >
      {displayText}
    </div>
  )
}

export default TruncatedString
