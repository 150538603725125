import React from "react"
import StarterPricingIcon from "../../assets/images/StarterPricingIcon.svg"
import BusinessPlanIcon from "../../assets/images/BusinessPlanIcon.svg"
import EnterprisePlanIcon from "../../assets/images/EnterprisePlanIcon.svg"
import PPFStarterPlanAbout from "../../assets/images/ppfPricing/ppfStarterPlan.svg"
import PPFBusinessPlanAbout from "../../assets/images/ppfPricing/ppfBusiness.svg"
import PPFExperiencePlanAbout from "../../assets/images/ppfPricing/ppfExperience.svg"
import TaskAltIcon from "@mui/icons-material/TaskAlt"
import CloseIcon from "@mui/icons-material/Close"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"

export const starterPlan = {
  LABEL_MAKER: true,
  NI_TABLE: true,
  LM_GUIDELINES: true,
  PLASTIC_WASTE_GUIDELINES: true,
  READY_FOR_PRINT: false,
  MERA_QR_CODE: false,
  LABEL_VALIDATION: false,
  NUTRITION_ANALYSIS: false,
  NI_INFORMATION_ENERGY: false,
  NI_TABLE_PER_100: false,
  CATEGORY_LC: true,
  UNLIMITED_PACKED_ING_DB: false,
  UNLIMITED_BASE_RECIPES: false,
  USER_ACCOUNTS: 1,
  BRANDS: 1,
  READY_TO_INTEGRATE: false,
  SUPPORT: "Email",
  ONBOARDING_TRAINING: false,
  REGULATORY_UPDATES: false,
}
export const businessPlan = {
  LABEL_MAKER: true,
  NI_TABLE: true,
  LM_GUIDELINES: true,
  PLASTIC_WASTE_GUIDELINES: true,
  READY_FOR_PRINT: true,
  MERA_QR_CODE: true,
  LABEL_VALIDATION: true,
  NUTRITION_ANALYSIS: true,
  NI_INFORMATION_ENERGY: true,
  NI_TABLE_PER_100: true,
  CATEGORY_LC: true,
  UNLIMITED_PACKED_ING_DB: true,
  UNLIMITED_BASE_RECIPES: true,
  USER_ACCOUNTS: 3,
  BRANDS: 2,
  READY_TO_INTEGRATE: false,
  SUPPORT: "Email",
  ONBOARDING_TRAINING: true,
  REGULATORY_UPDATES: true,
}

export const enterprisePlan = {
  LABEL_MAKER: true,
  NI_TABLE: true,
  LM_GUIDELINES: true,
  PLASTIC_WASTE_GUIDELINES: true,
  READY_FOR_PRINT: true,
  MERA_QR_CODE: true,
  LABEL_VALIDATION: true,
  NUTRITION_ANALYSIS: true,
  NI_INFORMATION_ENERGY: true,
  NI_TABLE_PER_100: true,
  CATEGORY_LC: true,
  UNLIMITED_PACKED_ING_DB: true,
  UNLIMITED_BASE_RECIPES: true,
  USER_ACCOUNTS: "Custom",
  BRANDS: "Unlimited",
  READY_TO_INTEGRATE: true,
  SUPPORT: "Email & Phone",
  ONBOARDING_TRAINING: true,
  REGULATORY_UPDATES: true,
}

export const plansJson = [
  {
    key: "Packed Food Labelling",
    values: [
      {
        key: "LABEL_MAKER",
      },
      {
        key: "NI_TABLE",
      },
      {
        key: "LM_GUIDELINES",
        exclusive: true,
      },
      {
        key: "PLASTIC_WASTE_GUIDELINES",
      },
      {
        key: "READY_FOR_PRINT",
        exclusive: true,
      },
      {
        key: "MERA_QR_CODE",
        exclusive: true,
      },
      {
        key: "LABEL_VALIDATION",
      },
    ],
  },
  {
    key: "Nutrition Analysis",
    values: [
      {
        key: "NUTRITION_ANALYSIS",
      },
      {
        key: "NI_INFORMATION_ENERGY",
      },
      {
        key: "NI_TABLE_PER_100",
      },
    ],
  },
  {
    key: "Label Management System",
    values: [
      {
        key: "CATEGORY_LC",
      },
      {
        key: "UNLIMITED_PACKED_ING_DB",
      },
      {
        key: "UNLIMITED_BASE_RECIPES",
      },
      {
        key: "USER_ACCOUNTS",
      },
      {
        key: "BRANDS",
      },
    ],
  },
  {
    key: "Integrations",
    values: [
      {
        key: "READY_TO_INTEGRATE",
        exclusive: true,
      },
    ],
  },
  {
    key: "Support",
    values: [
      {
        key: "SUPPORT",
      },
      {
        key: "ONBOARDING_TRAINING",
      },
      {
        key: "REGULATORY_UPDATES",
      },
    ],
  },
]

export const planDuration = {
  1: "Billed Monthly",
  3: "Billed Quaterly",
  6: "Billed Half Yearly",
  12: "Billed Yearly",
}

export const defaultStarterPlanDetails = (authenticated) => ({
  icon: StarterPricingIcon,
  name: "",
  recipes: "",
  description:
    "SME packed food business with fewer products, need quick labelling compliance solutions.",
  planAbout: PPFStarterPlanAbout,
  pricing: "",
  duration: "",
  additional: "plus, Rs 5000 annual Cloud Set-up",
  ctaText: authenticated ? "Upgrade Now" : "Start Free Trial",
  ctaClass: "bg-[#B8CC3F] w-full flex justify-center align-center",
  id: "",
  onClick: () => {},
  features: {},
  additionalInfo: "14-day free trial. No Credit Card Required",
})

export const defaultBusinessPlanDetails = (authenticated) => ({
  icon: BusinessPlanIcon,
  name: "Business Plan",
  recipes: "500 Recipes",
  description:
    "Medium to Large packed food businesses with multiple products, seeking digitized labelling.",
  planAbout: PPFBusinessPlanAbout,
  pricing: "7500 / mth",
  duration: "Billed Half Yearly",
  additional: "plus, Rs 5000 annual Cloud Set-up",
  ctaText: authenticated ? "Upgrade Now" : "Start Free Trial",
  ctaClass: "bg-[#3F48CC] w-full flex justify-center align-center",
  id: "123456",
  onClick: () => {},
  features: businessPlan,
  additionalInfo: "14-day free trial. No Credit Card Required",
})

export const defaultEnterprisePlanDetails = (authenticated) => ({
  icon: EnterprisePlanIcon,
  name: "Enterprise Plan",
  recipes: "Unlimited Recipes",
  description:
    "Large packed food businesses with multiple products and categories, seeking customized digital labelling solutions.",
  planAbout: PPFExperiencePlanAbout,
  pricing: "Custom Pricing",
  duration: "Billed Monthly",
  additional: "plus, Rs 9500 annual Cloud Set-up",
  ctaText: authenticated ? "Contact Us" : "Start Free Trial",
  ctaClass: "bg-[#DF6F13] w-full flex justify-center align-center",
  id: "123456",
  onClick: () => {},
  features: enterprisePlan,
  additionalInfo: "14-day free trial. No Credit Card Required",
})

export const exemptFeatures = ["NI_INFORMATION_ENERGY", "NI_TABLE_PER_100"]

export const manualAndDigitalLabelComparison = [
  [
    {
      heading: "Subscription Model",
    },
    {
      heading: [
        {
          icon: <TaskAltIcon htmlColor="#000" />,
          text: "Plans to suit individual business need",
        },
        {
          icon: <TaskAltIcon htmlColor="#000" />,
          text: "Reducing costs with high volumes",
        },
        {
          icon: <TaskAltIcon htmlColor="#000" />,
          text: "Create labels at your pace",
        },
        {
          icon: <TaskAltIcon htmlColor="#000" />,
          text: "Continuing Benefits",
        },
      ],
    },
    {
      heading: ["Rigid cost structure", "(one size fits all)"],
    },
  ],
  [
    { heading: "Time to Create a Label" },
    { heading: "< 30 minutes" },
    { heading: "2-3 days" },
  ],
  [
    {
      heading: [
        "Comprehensive Labelling Compliance",
        {
          icon: <TaskAltIcon htmlColor="#FFD700" />,
          text: "FSSAI (Labelling and Display)",
        },
        {
          icon: <TaskAltIcon htmlColor="#FFD700" />,
          text: "Legal Metrology",
        },
        {
          icon: <TaskAltIcon htmlColor="#FFD700" />,
          text: "Plastic Waste Management",
        },
      ],
    },
    { heading: ["Digitised ", "High Accuracy"] },
    { heading: ["Manual", "Error Prone"] },
  ],
  [
    {
      heading: [
        "Label Management Solution",
        {
          icon: <TaskAltIcon htmlColor="#FFD700" />,
          text: "All Products at one Place",
        },
        {
          icon: <TaskAltIcon htmlColor="#FFD700" />,
          text: "Cloud based (Access Anytime, Anywhere)",
        },
      ],
    },
    {
      icon: <CheckCircleIcon htmlColor="#000" />,
    },
    {
      icon: <CloseIcon htmlColor="#000" />,
    },
  ],
  [
    {
      heading: [
        "Ready-for-Print Labels",
        {
          icon: <TaskAltIcon htmlColor="#FFD700" />,
          text: "For Standard SKU sizes",
        },
      ],
    },
    {
      icon: <CheckCircleIcon htmlColor="#000" />,
    },
    {
      icon: <CloseIcon htmlColor="#000" />,
    },
  ],
  [
    {
      heading: [
        "Nutrition Analysis of Recipes",
        {
          icon: <TaskAltIcon htmlColor="#FFD700" />,
          text: "Scientific Databases Recognized by Regulations",
        },
      ],
    },
    {
      icon: <CheckCircleIcon htmlColor="#000" />,
    },
    {
      icon: <CloseIcon htmlColor="#000" />,
    },
  ],
  [
    {
      heading: ["Duplicate Labels", "Unlimited Edits"],
    },
    {
      icon: <CheckCircleIcon htmlColor="#000" />,
    },
    {
      icon: <CloseIcon htmlColor="#000" />,
    },
  ],
  [
    {
      heading: [
        "Build Unlimited Database of Base Recipes and Packed Ingredients",
        "(Choose from 1000s of Ingredients on the FoLSol Database®)",
      ],
    },
    {
      icon: <CheckCircleIcon htmlColor="#000" />,
    },
    {
      icon: <CloseIcon htmlColor="#000" />,
    },
  ],
  [
    {
      heading: "Multiple Accounts and Brands for Co-working",
    },
    {
      icon: <CheckCircleIcon htmlColor="#000" />,
    },
    {
      icon: <CloseIcon htmlColor="#000" />,
    },
  ],
  [
    {
      heading: "Ready to integrate with Softwares",
    },
    {
      icon: <CheckCircleIcon htmlColor="#000" />,
    },
    {
      icon: <CloseIcon htmlColor="#000" />,
    },
  ],
]
